.row {
  display: flex;
}

.myexam-tab {
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;

  .myexam-tab-left {
    width: 100%;
    // margin-top:40px;

    .dtoggle-bar {
      display: flex;
      padding: 2px;
      width: 100%;
      background-color: #fff5e6;
      border-radius: 1.5625rem;

      .toggle-maths-div {
        width: 100% !important;
        margin-bottom: 10px;
        .toggle-maths-btn {
          border: none;
          color: #fca834;
          // width: 19.25vw;
          height: 3.125rem;
          min-width: 288px;
          height: fit-content;
          padding-block: 14px;
        }
        .mathbtn-act {
          border-radius: 1.5625rem;
          background: white;
          box-shadow: 0px 5px 5px rgb(170 114 67 / 24%);
        }
        .mathbtn-un {
          border-top-left-radius: 1.5625rem;
          border-bottom-left-radius: 1.5625rem;
          border-top-right-radius: none;
          border-bottom-right-radius: none;
          background-color: #fff5e6;
        }
      }
    }
  }

  .myexam-tab-right {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
}

.stdloopdiv {
  padding-top: 20px;

  .innerdiv {
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 6px 24px 0 rgba(181, 181, 181, 0.24);
    cursor: pointer;

    .studenticon {
      height: 60px;
      width: 60px;
    }
  }
}
.student-detail-main {
  .back-btn {
    background-color: transparent;
    border: 0;
    margin: 20px 0px;
    color: #454c4e;
    font-family: 'proxima_nova_rgbold';
  }
  .head {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 20px;
    .head-left {
      img {
        width: 100%;
      }
      h4 {
        color: #20282a;
        font-family: 'Proxima Nova';
        font-size: 24px;
      }
      p {
        font-size: 18px;
        color: #454c4e;
        font-family: 'proxima_nova_rgregular';
        margin-bottom: 8px;
      }
      .text-left {
        color: #454c4e;
        font-family: 'proxima_nova_rgregular';
        font-size: 12px;
        margin-bottom: 7px;
      }
      .text-right {
        color: #79a2e7;
        font-family: 'proxima_nova_rgbold';
        font-size: 14px;
        text-align: right;
      }
      .progress {
        height: 8px;
        border-radius: 5.5px;
        background-color: #d0e2ff;
        .progress-bar {
          background-color: #79a2e7 !important;
          height: 8px;
          border-radius: 5.5px;
        }
      }
    }
    .head-right {
      padding-left: 60px;
      .row {
        display: flex;
        align-items: center;
        height: 100%;
      }
      .icon {
        height: 60px;
        width: 60px;
        border-radius: 10px;
        float: left;
        margin-right: 15px;
      }
      .orange {
        .icon {
          background-color: #ffe7d9;
        }
        .text {
          h4 {
            color: #f3722c;
          }
        }
      }
      .blue {
        .icon {
          background-color: #d9ffdf;
        }
        .text {
          h4 {
            color: #f3722c;
          }
        }
      }
      .green {
        .icon {
          background-color: #e3fff6;
        }
        .text {
          h4 {
            color: #43aa8b;
          }
        }
      }
      .text {
        p {
          margin-bottom: 0;
          font-family: 'Proxima Nova';
          color: #454c4e;
          font-size: 13px;
        }
        h4 {
          font-size: 20px;
          font-family: 'proxima_nova_rgbold';
        }
      }
    }
  }
  .sub-performance {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.05);
    margin-top: 30px;
    padding: 30px;
    padding-bottom: 0px;
    border-radius: 10px;
    .sub-performance-head {
      font-family: 'proxima_nova_rgbold';
      text-transform: uppercase;
      h3 {
        color: #454c4e;
        font-size: 28px;
      }
      p {
        color: #6f797b;
        font-size: 18px;
      }
    }
    .count-block {
      margin-top: 30px;
      border-radius: 10px;
      h4 {
        font-size: 16px;
        padding: 10px 20px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      .count-block-desc {
        padding: 10px 20px;
        p.score {
          font-size: 12px;
          color: #6f797b;
          font-family: 'Proxima Nova';
          img {
            height: 28px;
            width: 28px;
            margin-top: -10px;
          }
          span.score-num {
            font-size: 24px;
            font-family: 'proxima_nova_rgbold';
            margin: 0px 10px;
          }
          span.score-text {
            color: #6f797b;
          }
        }
        p.desc {
          color: #454c4e;
          font-size: 14px;
          font-family: 'Proxima Nova';
          line-height: 19px;
          margin-bottom: 0px;
        }
      }
    }
    .count-block.success {
      border: 1px solid #43aa8b;
      h4 {
        background-color: #e3fff6;
        color: #4eb092;
      }
      p.score {
        span {
          color: #43aa8b;
        }
      }
    }
    .count-block.error {
      border: 1px solid #ffbc33;
      h4 {
        background-color: #fcf2ce;
        color: #f4a911;
      }
      p.score {
        span {
          color: #f4a911;
        }
      }
    }
    .count-block.error.error-left {
      position: relative;
      left: 50px;
    }
    .count-block.error.error-right {
      position: relative;
      right: 50px;
      &.moveup {
        bottom: 190px;
      }
    }
    left: 50px;
    .sub-performance-common {
      display: inline-block;
      width: 29%;
      padding-bottom: 30px;
    }
    .sub-performance-left {
      padding-right: 20px;
    }
    .sub-performance-right {
      padding-left: 20px;
    }
    .sub-performance-center {
      display: inline-block;
      width: 40%;
      vertical-align: bottom;
      background-image: url('../../../../../assets/images/circleBg.svg');
      background-repeat: no-repeat;
      background-size: contain;
      bottom: 0;
      background-position: bottom;
      height: 300px;
      position: relative;
      .one-line {
        position: absolute;
        bottom: 0;
        left: 36%;
        transform: translate(-50%, 0);
      }
      .two-line {
        position: absolute;
        bottom: 0;
        left: 43%;
        transform: translate(-50%, 0);
      }
      .three-line {
        position: absolute;
        bottom: 0;
        left: 60%;
        transform: translate(-50%, 0);
      }
      .four-line {
        position: absolute;
        bottom: -20px;
        left: 63%;
        transform: translate(-50%, 0);
      }
      // .one-line::after {
      //   content: url('../../../../../assets/images/performance-line1.svg');
      // }
    }
  }
  .assignment-main {
    h3.title {
      color: #20282a;
      font-family: 'proxima_nova_rgbold';
      margin: 40px 0px;
      img {
        width: 40px;
        height: 50px;
        margin-right: 10px;
      }
    }
    .assignment-common-main {
      // display: table;
      // border-spacing: 25px !important;
      // border-collapse: separate !important;
      margin: 10px;
    }
    .assignment-common {
      background: linear-gradient(125.92deg, #c4dafe 0%, #f4f7fc 100%);
      border-radius: 10px;
      padding: 30px;
      margin: 0px 0px 15px 0px;
      // display: table-cell;
      h3 {
        color: #20282a;
        font-family: 'proxima_nova_rgbold';
        font-size: 24px;
        margin-bottom: 3px;
        span {
          float: right;
          background-color: #ffeaf1;
          font-size: 12px;
          text-transform: uppercase;
          padding: 5px 12px;
          border-radius: 9.5px;
        }
      }
      p.desc {
        color: #454c4e;
        font-family: 'Proxima Nova';
        font-size: 18px;
        text-transform: uppercase;
      }
      h5 {
        color: #6f797b;
        font-family: 'Proxima Nova';
        text-transform: uppercase;
        font-size: 14px;
        margin-bottom: 5px;
      }
      p.date {
        margin-bottom: 0;
        font-family: 'Proxima Nova';
        color: #20282a;
        font-size: 16px;
        span {
          float: right;
          color: #79a2e7;
          cursor: pointer;
          text-decoration: underline;
          a {
            color: #79a2e7;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1280px) {
  .student-detail-main {
    .head {
      .head-left {
        width: 35%;
      }
      .head-right {
        width: 64%;
      }
    }
  }
}
@media only screen and (max-width: 1230px) {
  .student-detail-main {
    .assignment-main {
      .assignment-common {
        h3 {
          font-size: 20px;
        }
        p.desc {
          font-size: 14px;
        }
        h5 {
          font-size: 13px;
        }
        p.date {
          font-size: 14px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1180px) {
  .student-detail-main {
    .head {
      padding: 30px;
      .head-left {
        width: 35%;
      }
      .head-right {
        width: 100%;
        padding-left: 15px;
        margin-top: 30px;
      }
    }
    .sub-performance {
      .sub-performance-common {
        .count-block-desc {
          p.score {
            margin-bottom: 5px;
          }
          span.score-text {
            display: block;
            margin-top: 5px;
          }
          p.desc {
            font-size: 12px;
          }
        }
      }
    }
    .assignment-main {
      .assignment-common {
        h3 {
          span.status {
            float: none;
            display: inline-block;
            margin: 10px 0px;
          }
        }
        p.date {
          span {
            display: block;
            float: none;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .student-detail-main {
    .sub-performance-center {
      display: none !important;
    }
    .sub-performance {
      padding-bottom: 30px;
    }
    .sub-performance-common {
      display: table !important;
      padding: 0 !important;
      width: 100% !important;
      border-collapse: separate !important;
      border-spacing: 25px !important;
      .count-block {
        left: 0 !important;
        display: table-cell;
        width: 45%;
      }
    }
    .head {
      .head-left {
        width: 100%;
        max-width: 350px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .student-detail-main {
    .sub-performance {
      .sub-performance-head {
        h3 {
          font-size: 22px;
        }
        p {
          font-size: 15px;
        }
      }
    }
    .assignment-main {
      h3.title {
        margin-bottom: 20px;
        img {
          width: 20px;
          height: 30px;
        }
      }
      .assignment-common-main {
        display: block;
        padding-left: 15px;
        padding-right: 15px;
        .assignment-common {
          width: 100%;
          display: block;
          margin-bottom: 30px;
        }
      }
    }
    .head {
      .head-right {
        .col-md-6 {
          width: 100% !important;
          margin-bottom: 30px;
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .student-detail-main {
    .head {
      .head-left {
        margin: auto;
        text-align: center;
        h4 {
          margin-top: 15px;
          font-size: 18px;
        }
        img {
          height: 100px;
          width: 100px;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .student-detail-main {
    .head {
      .head-right {
        text-align: center;
        .icon {
          float: none;
          margin: auto;
          margin-bottom: 10px;
        }
      }
    }
    .sub-performance {
      .sub-performance-common {
        display: block !important;
        .count-block {
          display: block !important;
          margin: auto;
          margin-bottom: 30px;
          width: 100% !important;
        }
      }
    }
  }
}
.studentListpagination {
  li {
    padding: 5px 10px;
    border-radius: 5px;
    font-family: 'proxima_nova_rgbold';

    &.previous {
      a {
        color: #f4a911;
        svg {
          height: 30px;
          width: 30px;
        }
      }
    }
    &.next {
      a {
        color: #f4a911;
        svg {
          height: 30px;
          width: 30px;
        }
      }
    }
    &.active {
      background-color: #f4a911;
      color: #eee;
    }
    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
      a {
        cursor: not-allowed;
      }
    }
  }
  li::marker {
    display: none;
    background: transparent;
    color: transparent;
  }
}
