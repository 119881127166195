.dashboard-main {
  .dashboard-right {
    .myexam-main {
      .myexam-tab-left {
        .dtoggle-bar {
          .toggle-maths-div {
            width: auto !important;
            min-width: 260px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

.add-history-modal {
  background-color: rgb(0, 0, 0, 0.4);
  background-blend-mode: multiply;
  padding-top: 25px;
  height: 100vh !important;
}

.student-image {
  height: 60px;
  width: 60px;
}
.dothide {
  display: none;
}
.dothide {
  display: none;
}

// my payments

.mypaymenttab .nav-tabs .nav-link.active {
  color: #1c1c1c;
  border-color: transparent transparent #eee !important;
  border-bottom: 6px solid #1c1c1c !important;
  background-color: transparent !important;
}
.mypaymenttab .nav-tabs .nav-link {
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  color: #1c1c1c;
  font-family: 'Proxima Nova';
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
}
.mypaymenttab {
  padding-top: 30px;
}
.nav-link:focus,
.nav-link:hover {
  color: #495057 !important;
}
.mypaymenttab .nav-tabs {
  width: 50% !important;
}
.paymentstdid {
  color: #20282a;
  font-weight: 600;
}

// for payments page (teacher)
.main1 {
  padding-top: 30px;

  .main2 {
    .onlineclassmain {
      background-color: #2e2e2e;
      border-radius: 20px 20px 0px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 30px 20px 30px;
    }

    .onlineHeading {
      color: #fafafa;
      font-family: 'Proxima Nova';
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22px;
      margin-bottom: 0px;
    }

    .onlineTime {
      color: #fafafa;
      font-family: 'Proxima Nova';
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 19px;
      margin-bottom: 0px;
    }

    .subdivmain {
      padding-top: 2%;
      display: flex;
      align-items: center;
    }

    .onlineclasssubject {
      border-radius: 0 0 20px 20px;
      background-color: #fff4e9;
      width: 100%;
      float: left;
      padding: 5px 0px 20px 20px;
      margin-bottom: 30px;
    }

    .onlineSubTitle {
      color: #20282a;
      font-family: 'Proxima Nova';
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 29px;
      margin-bottom: 0px;
    }

    .onlineTopic {
      color: #454c4e;
      font-family: 'Proxima Nova';
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 22px;
      margin-bottom: 0px;
    }

    .onlineTopiclink {
      color: #43aa8b;
      font-family: 'Proxima Nova';
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 17px;
    }

    .subimgdiv {
      // padding-top: 20px;
      img {
        max-width: 50px;
      }
    }
    .subtopicsec {
      padding-left: 10px;
    }

    .testicon {
      padding-right: 10px;
    }

    .modelsecdiv {
      padding-left: 20px;
    }

    .middlediv {
      border-radius: 8px;
      background-color: #ffdfbe;
      margin-top: 8%;
      padding: 5px;
      text-align: center;
      margin-left: 10px;
    }

    // .floatleft {
    //     float: left;
    // }

    .tabheadtext {
      color: #20282a;
      font-family: 'Proxima Nova';
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 29px;
    }

    .tabsubhead {
      color: #454c4e;
      font-family: 'Proxima Nova';
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 22px;
    }

    .tabcontent {
      color: #20282a;
      font-family: 'Proxima Nova';
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      padding-top: 30px;
    }

    .Clockicon {
      padding-right: 5px;
    }

    .rawnimg {
      padding-right: 5px;
      height: 30px;
      width: 40px;
    }

    .jionlink {
      text-align: center;
      padding-top: 9%;
    }

    .jionlink button {
      width: 190px;
      padding: 15px;
      border-radius: 10px;
      background-color: #f3722c;
      border: 0;
      color: #fff;
      margin: 0;
      font-family: 'proxima_nova_rgbold';
      font-size: 19px;
    }

    .jionlink button a {
      font-size: 16px;
      color: #fff;
      text-decoration: none;
    }

    .audioLinkdiv {
      text-align: center;
      padding-top: 4%;
      padding-bottom: 2%;
    }

    .audioLink {
      color: #f3722c;
      font-family: 'Proxima Nova';
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 17px;
      text-decoration: underline;
    }

    .sliderBox {
      border-radius: 20px;
      background-color: #ffffff !important;
      box-shadow: 0 12px 34px -10px rgba(0, 0, 0, 0.05) !important;
    }
  }
}

//My Students

// .toggle-maths-div {
//   width: 50% !important;
// }
.mysujecthead {
  color: #20282a;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: 'Proxima Nova';
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 31px;
}
.totalstudents {
  color: #7d93f2;
  font-family: 'Proxima Nova';
  font-size: 20px;
  padding-top: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 31px;
  text-align: right;
}
.innerdiv {
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 6px 24px 0 rgba(181, 181, 181, 0.24);
}
.studenticon {
  height: 60px;
  width: 60px;
}
.stdname {
  color: #20282a;
  font-family: 'Proxima Nova';
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  padding-top: 15px;
}
.stdgrade {
  color: #454c4e;
  font-family: 'Proxima Nova';
  font-size: 15px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

.label-main {
  display: flex;
  justify-content: space-between;
}

.atteddiv .attendance {
  color: #43aa8b;
  font-family: 'Proxima Nova';
  width: 97px;
  border: 1px solid #43aa8b;
  border-radius: 9.5px;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
  padding: 5px 15px 5px 15px;
}
.atteddiv .attendanceAbsent {
  color: #fd2a5b;
  font-family: 'Proxima Nova';
  width: 97px;
  border-radius: 9.5px;
  border: 1px solid #eb2f6a;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
  padding: 5px 15px 5px 15px;
}
.stdloopdiv {
  padding-top: 20px;
}
.dtoggle-bar {
  // width: fit-content !important;
  width: 100%;
}

.examHeading {
  color: #20282a;
  font-family: 'Proxima Nova';
  font-size: 30px;
  letter-spacing: 0;
  line-height: 34px;
}
.examsidediv {
  border-radius: 0 100px 0 100px;
  background: linear-gradient(143.67deg, #39c399 0%, #00a070 100%);
}
.cupicon {
  width: 80%;
}
.aasigngradediv p {
  margin-bottom: 5px;
}
.assinon {
  color: #ffffff;
  font-family: 'Proxima Nova';
  font-size: 20px;
  letter-spacing: 0;
  line-height: 22px;
}
.gradename {
  color: #ffffff;
  font-family: 'Proxima Nova';
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}
.cupicondiv {
  text-align: right;
}
.rowpadding {
  padding-top: 30px;
  padding-bottom: 25px;
}
// .dashboard-main
// {
//     .row-main {
//         .dashboard-right {
//             .myexam-main{
//                 .myexam-title{
//                     margin: 0px;
//                 } }}}}

.completeddiv {
  border-radius: 10px;
  background-color: #f4f7fc;
  width: 100%;
  float: left;
  padding: 30px;
  margin-top: 20px;
  .row {
    padding-bottom: 20px;
  }
}

.chapttitle {
  color: #6f797b;
  font-family: 'Proxima Nova';
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 5px;
}
.subtitle {
  color: #454c4e;
  font-family: 'Proxima Nova';
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 5px;
}

.myexam-answer-right {
  h3 {
    font-family: 'proxima_nova_rgregular';
    font-size: 24px;
    color: #20282a;
    margin-bottom: 20px;
  }
  .exam-right-common {
    background-color: #f4f7fc;
    border-radius: 20px;
    padding: 15px;
    margin-bottom: 20px;
    .exam-right-left {
      img {
        width: 28px;
        height: 19px;
      }
    }
    .exam-right-center {
      h5 {
        font-family: 'proxima_nova_rgregular';
        color: #20282a;
        font-size: 20px;
      }
      h5.answer {
        color: #43aa8b;
        font-family: 'proxima_nova_rgbold';
        margin: 15px 0px;
      }
      .option-main {
        height: 0px;
      }
      .option-main.show {
        height: 100px;
      }
      .option-top {
        display: flex;
        div {
          width: 100px;
          height: auto;

          img {
            width: 56px;
            height: 56px;
          }
          span {
            width: 34px;
            height: 34px;
            border-radius: 50%;
            background-color: #a0a4a6;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-family: 'proxima_nova_rgbold';
          }
        }
      }
      span.index {
        font-family: 'proxima_nova_rgbold';
      }
    }
    .exam-right-right {
      button {
        cursor: pointer;
        background-color: transparent;
        border: 0;
      }
      img {
        height: 20px;
        width: 20px;
      }
    }
  }
}

// My Payments

.divbg {
  border-radius: 10px;
  background: linear-gradient(270deg, #1c1c1c 0%, #373737 100%);
  box-shadow: 0 1px 34px -6px rgba(154, 154, 154, 0.1);
  padding: 25px 20px;
  height: 120px;
}
.paymentid {
  color: #ffffff;
  font-family: 'Proxima Nova';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 15px;
  margin-bottom: 10px;
  padding-top: 10px;
}
.payeename {
  color: #ffffff;
  font-family: 'Proxima Nova';
  font-size: 26px;
  margin-bottom: 0px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 31px;
}

table {
  border-collapse: separate;
  border-spacing: 20px 0;
  width: 100%;
}
th {
  // background-color: #4287f5;
  color: white;
  text-align: center !important;
  color: #6f797b;
  font-family: 'Proxima Nova';
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
}
th,
td {
  // width: 150px;
  text-align: center;
  // border: 1px solid black;
  padding: 5px;
  color: #6f797b;
  font-family: 'Proxima Nova';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
}
.tablerow {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 22px 24px -10px rgba(189, 189, 189, 0.07);
  border-collapse: separate;
  border-spacing: 0 1em;
}
.gfg {
  border-collapse: separate;
  border-spacing: 0 15px;
}
.pending {
  color: #fca834;
  font-family: 'Proxima Nova';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
}
.processed {
  color: #43aa8b;
  font-family: 'Proxima Nova';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
}
.dot {
  height: 10px;
  width: 10px;
  background-color: #fca834;
  border-radius: 50%;
}

.dotGreen {
  height: 10px;
  width: 10px;
  background-color: rgb(144, 238, 144);
  border-radius: 50%;
}
.viewdetails {
  color: #f3722c;
  font-family: 'Proxima Nova';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-decoration: underline;
}
.paymentsection {
  padding-top: 40px;
  h1 {
    color: #20282a;
    font-family: 'Proxima Nova';
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 31px;
  }
}
.tabledatastyle {
  color: #20282a;
  font-family: 'Proxima Nova';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
}
.topdivpad {
  padding-top: 30px;
}
.tableheadings {
  color: #6f797b;
  font-family: 'Proxima Nova';
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
}

// My Students - Assignments

.dashboard-main {
  .fixed-assign-bar {
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: #ffffff;
    box-shadow: -40px -18px 34px -10px rgba(0, 0, 0, 0.12);
    left: 0;
    .container-fixed-assign-bar {
      .heading-and-selected-topics {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 30px 0px 30px 50px;
        p.selected-topics {
          margin-bottom: 0;
        }
      }
    }
    .student-name-and-assign-button {
      background-color: #e3fff6;
      // box-shadow: 0 -18px 34px -10px rgba(0,0,0,0.12);
      display: flex;
      align-items: center;
      width: 50%;
      padding: 30px 50px;
      position: relative;
      img {
        height: 70px;
        width: 70px;
        margin-right: 20px;
      }
      .student-name-and-grades {
        h4.grades {
          margin-bottom: 0;
        }
      }
    }
    .student-name-and-assign-button.mobile-view {
      display: none;
    }
    .student-name-and-assign-button.desktop-view {
      display: flex;
    }

    .heading-assigned {
      color: #42494b;
      font-family: 'Proxima Nova';
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
    }

    .selected-topics {
      color: #20282a;
      font-family: 'Proxima Nova';
      font-size: 18px;
      letter-spacing: 0;
      line-height: 20px;
    }

    .student-name {
      color: #20282a;
      font-family: 'Proxima Nova';
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 29px;
    }

    .grades {
      color: #454c4e;
      font-family: 'Proxima Nova';
      font-size: 18px;
      letter-spacing: 0;
      line-height: 20px;
    }

    .assign-button {
      height: 60px;
      width: 250px;
      border-radius: 33.75px;
      background-color: #43aa8b;
      box-shadow: 0 12px 10px 0 rgba(67, 170, 139, 0.25);
      border: none;
      color: #ffffff;
      font-family: 'Proxima Nova';
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
      padding-left: 20px;
      position: absolute;
      right: 50px;
      margin-top: 0;
      // opacity: 1;
    }
  }

  .progress-and-percentage {
    width: 80%;
    margin-top: 15px;
    margin-bottom: 5px;
    .progress {
      width: 85%;
      height: 100%;
      background-color: #d0e2ff;
      .progress-bar {
        background-color: #79a2e7;
      }
    }
    .percentage {
      padding-left: 10px;
      color: #79a2e7;
      font-family: 'Proxima Nova';
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 17px;
      text-align: right;
      float: right;
      margin-top: -10px;
    }
  }
  .container-body {
    .heading-and-icon {
      display: flex;
      margin-bottom: 20px;
      img {
        float: left;
        margin-right: 25px;
      }
      h3 {
        margin-top: 20px;
        color: #20282a;
        font-family: 'proxima_nova_rgregular';
        font-size: 22px;
        line-height: 32px;
      }
      .text-right {
        text-align: right;
        button {
          background-color: transparent;
          border: 0;
          height: 20px;
          width: 20px;
          padding: 0;
          margin-top: 5px;
          position: relative;
          left: 20px;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
  .heading {
    color: #20282a;
    font-family: 'proxima_nova_rgbold';
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0;
    margin-top: 10px;
  }

  .container-lists-and-sublists-main {
    border-radius: 20px;
    background-color: #f4f7fc;
    margin-bottom: 125px;
  }
  .label-main {
    display: flex;
    justify-content: space-between;
  }

  .lists-and-sublists {
    padding: 40px;
    column-count: 3;
    padding-right: 0;
    .container-lists-and-sublists {
      border: 1px solid transparent;
      margin-bottom: 20px;
    }
  }

  .lists-and-sublists-assignment {
    padding: 40px;
    padding-right: 0;
    .container-lists-and-sublists {
      border: 1px solid transparent;
      margin-bottom: 20px;
    }
  }

  .list-name {
    color: #20282a;
    font-family: 'Proxima Nova';
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 26px;
    margin-top: 10px;
  }

  .sub-topic {
    color: #20282a;
    font-family: 'proxima_nova_rgregular';
    font-size: 20px;
    letter-spacing: 0;
    line-height: 1.3;
    margin-top: 10px;
  }

  .progress-bar {
    height: 6px;
    width: 253px;
    color: #79a2e7;
  }

  .label-and-checkbox {
    display: flex;
    flex-direction: column;
    .data-desc {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }
    .checkbox {
      margin-top: 5px;
      margin-right: 5px;
      height: 24px;
      width: 24px;
    }

    .subtopic-name-selected {
      color: #4fb697;
      font-family: 'Proxima Nova';
      font-size: 18px;
      letter-spacing: 0;
      line-height: 20px;
      margin-top: 10px;
      margin-right: 5px;
    }

    .subtopic-name {
      color: #42494b;
      font-family: 'proxima_nova_rgregular';
      font-size: 18px;
      letter-spacing: 0;
      line-height: 20px;
      margin-top: 5px;
      margin-right: 5px;
      margin-bottom: 0;
      text-decoration: underline;
    }
  }
}

// My students -main page

.dashboard-main .row-main .dashboard-right .myexam-answer-main-top {
  padding: 15px !important;
  // .myexam-answer-left {
  //   // margin-top: 65px;
  // }
}
.dashboard-main
  .row-main
  .dashboard-right
  .renewsub-main
  .renew-right-main
  button.payment-process {
  max-width: 100%;
  font-size: 14px;
}

.myexam-answer-main-top {
  position: fixed;
  width: 100%;
  background: #fff;
  top: 0;
  left: 0;
  padding: 35px;
  height: 100vh;
  z-index: 9999;
  padding-top: 70px;
  padding-right: 20px;
  padding-bottom: 0;
}
.close-top {
  height: 70px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: #fff;
  text-align: right;
  .close-btn {
    background-color: transparent;
    border: 0;
    position: absolute;
    right: 4%;
    top: 25%;
    img {
      height: 20px;
      width: 20px;
    }
  }
}

.myexam-answer-main {
  width: 100%;
  margin: auto;
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 30px;
  padding-right: 20px;
  .myexam-answer-left {
    padding: 50px 30px;
    background: linear-gradient(#39c399, #00a070);
    border-radius: 0 100px 0 100px;
    color: #ffffff;
    .row {
      margin-bottom: 40px;
    }
    .row:last-child {
      margin-bottom: 0px;
    }
    p {
      font-family: 'proxima_nova_rgregular';
      font-size: 20px;
      margin-bottom: 5px;
    }
    h5 {
      font-size: 19px;
      font-family: 'proxima_nova_rgbold';
    }
    .Olympiad {
      img {
        width: 48px;
        height: 50px;
      }
    }
    .Total {
      img {
        width: 50px;
        height: 50px;
      }
    }
    .Date {
      img {
        width: 45px;
        height: 50px;
      }
    }
    .Time {
      img {
        width: 43px;
        height: 51px;
      }
    }
    .Accuracy {
      img {
        width: 47px;
        height: 50px;
      }
    }
    .Right {
      img {
        width: 36px;
        height: 50px;
      }
    }
  }
  .myexam-answer-right {
    h3 {
      font-family: 'proxima_nova_rgregular';
      font-size: 24px;
      color: #20282a;
      margin-bottom: 20px;
    }
    .exam-right-common {
      background-color: #f4f7fc;
      border-radius: 20px;
      padding: 15px;
      margin-bottom: 20px;
      .exam-right-left {
        img {
          width: 28px;
          height: 19px;
        }
      }
      .exam-right-center {
        h5 {
          font-family: 'proxima_nova_rgregular';
          color: #20282a;
          font-size: 20px;
        }
        h5.answer {
          color: #43aa8b;
          font-family: 'proxima_nova_rgbold';
          margin: 15px 0px;
        }
        .option-main {
          height: 0px;
        }
        .option-main.show {
          height: 100px;
        }
        .option-top {
          display: flex;
          div {
            width: 100px;
            height: auto;

            img {
              width: 56px;
              height: 56px;
            }
            span {
              width: 34px;
              height: 34px;
              border-radius: 50%;
              background-color: #a0a4a6;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #fff;
              font-family: 'proxima_nova_rgbold';
            }
          }
        }
        span.index {
          font-family: 'proxima_nova_rgbold';
        }
      }
      .exam-right-right {
        button {
          cursor: pointer;
          background-color: transparent;
          border: 0;
        }
        img {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}

.assignbutdiv {
  text-align: center;
}
.classcompleted {
  color: #43aa8b;
  font-family: 'Proxima Nova';
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 29px;
}
.sendassignment {
  color: #454c4e;
  font-family: 'Proxima Nova';
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
}
.teacher-assign-main {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 999;
  height: 100vh;
  overflow-y: scroll;
  padding: 30px 40px;
}

@media only screen and (max-width: 1250px) {
  .fixed-assign-bar {
    .col-md-6 {
      width: 100% !important;
    }
    .heading-and-selected-topics {
      padding: 20px !important;
      padding-left: 50px !important;
      h2 {
        font-size: 18px !important;
      }
      p {
        font-size: 15px !important;
      }
    }
    .student-name-and-assign-button {
      padding: 20px !important;
      padding-left: 50px !important;
      img {
        width: 50px !important;
        height: 50px !important;
      }
      .student-name-and-grades {
        .student-name {
          font-size: 20px;
          margin-bottom: 3px;
        }
        .grades {
          font-size: 15px;
        }
      }
      button.assign-button {
        height: 50px;
        width: 200px;
        font-size: 18px;
      }
    }
  }
  .container-lists-and-sublists-main {
    margin-bottom: 200px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .teacher-assign-main {
    .heading-and-icon {
      img {
        height: 40px;
        width: 40px;
      }
      h2.heading {
        font-size: 30px;
        margin-top: 5px;
      }
      h3 {
        font-size: 18px !important;
      }
    }
    .lists-and-sublists {
      column-count: 2;
      .container-lists-and-sublists {
        .list-name {
          font-size: 20px;
        }
        .sub-topic {
          font-size: 17px;
        }
        p.subtopic-name {
          font-size: 15px;
        }
        .checkbox {
          height: 18px;
          width: 18px;
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .teacher-assign-main {
    .heading-and-icon {
      .col-md-7 {
        width: 80%;
      }
      .col-md-5 {
        width: 19%;
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .teacher-assign-main {
    .heading-and-icon {
      .col-md-7 {
        width: 100%;
        order: 2;
        img {
          height: 30px;
          width: 30px;
          float: left;
        }
        h2.heading {
          font-size: 25px;
        }
        h3 {
          font-size: 15px !important;
        }
      }
      .col-md-5 {
        width: 100%;
        order: 1;
        text-align: right;
      }
    }
    .container-lists-and-sublists-main {
      margin-bottom: 250px !important;
      .lists-and-sublists {
        column-count: 1;
        .container-lists-and-sublists {
          h3.list-name {
            font-size: 17px;
          }
          .progress-and-percentage {
            max-width: 300px;
          }
          .label-and-checkbox {
            h3.sub-topic {
              font-size: 16px;
            }
            .data-desc {
              p.subtopic-name {
                font-size: 14px;
              }
              .checkbox {
                height: 12px;
                width: 12px;
              }
            }
          }
        }
      }
    }
    .fixed-assign-bar {
      .heading-and-selected-topics {
        h2.heading-assigned {
          font-size: 16px !important;
        }
        p.selected-topics {
          font-size: 14px !important;
        }
      }
      .student-name-and-assign-button.mobile-view {
        display: block;
        text-align: center;
        padding-left: 0 !important;
        padding-right: 0 !important;
        .stu-common {
          display: flex;
          justify-content: center;
          margin-bottom: 20px;
        }
        button.assign-button {
          position: unset !important;
          height: 45px;
          width: 185px;
          font-size: 16px;
        }
      }
      .student-name-and-assign-button.desktop-view {
        display: none;
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .teacher-assign-main {
    padding: 20px;
    .heading-and-icon {
      .col-md-7 {
        text-align: center;
        img {
          float: none;
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
      button {
        left: 0 !important;
      }
    }
  }
}

body {
  overflow: auto !important;
}

#zmmtg-root {
  display: none;
}

.assignmentQueBackground {
  background-color: #fff4e9 !important;
}

.assignQueBtn {
  background-color: #f3722c !important;
  text-align: center !important;
}

.studentNameAssQue {
  color: #f3722c;
  text-decoration: none;
}

.questions-list-assignment-main {
  column-count: 1;
}

.lists-and-sublists-assign {
  padding: 40px;
}

.assignQueBack {
  color: #454c4e;
  font-family: 'proxima_nova_rgbold';
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 20px;
}
.updatedassignQueBack {
  position: absolute;
  top: 194px;
  left: 76px;
  color: #454c4e;
  font-family: 'proxima_nova_rgbold';
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 20px;
}
.assignQueTitle {
  font-family: 'proxima_nova_rgbold';
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 31px;
}

.assignTopicSubTopic {
  color: #20282a;
  font-family: 'proxima_nova_rgregular';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
}

.assignmentQueLayoutMain {
  // border: 1pt solid #79A2E7;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 25px;
  margin-bottom: 25px;
}

.assignQuestion {
  color: #20282a;
  font-family: 'proxima_nova_rgregular';
  font-size: 22px;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0;
  display: flex;
}

.desc-img-cont {
  display: flex;
  flex-wrap: wrap;
}

.desc-img-cont .img-cont {
  height: 75px;
  width: 75px;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}

.img-cont img {
  height: 100%;
  width: 100%;
}

.checkboxAlign {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkboxAlign {
  label {
    color: #20282a;
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      position: absolute;
      top: -5px;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #eee;
      border-radius: 2px;
    }
    .checkmark:after {
      left: 9px;
      top: 4px;
      width: 7px;
      height: 13px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }
  }
  label:hover input ~ .checkmark {
    background-color: #ccc;
  }
  label input:checked ~ .checkmark {
    background-color: #43aa8b;
  }
  label input:checked ~ .checkmark:after {
    display: block !important;
  }
  .text-right {
    text-align: right;
  }
}

.assignQueSearch {
  width: 100%;
  background: #fafafa;
}

.custom-dashboard-right-search {
  background: #fafafa !important;
}

.questionNumber {
  font-family: 'proxima_nova_rgbold';
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.assignQuestion p {
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}

.toggleBootstrap {
  font-family: 'proxima_nova_rgregular';
  font-size: 18px;
  text-align: center;
}

.toggleBootstrap:focus {
  color: #fca834 !important;
}

.toggleBootstrap:hover {
  color: #fca834 !important;
}

// .checkedSemicolon:after{
//   content: '; ';
// }

// .checkedSemicolon:last-child{
//   content:'';
// }

.text-right {
  text-align: right;
  button.leave {
    background-color: #f3722c;
    color: #ffe7d9;
    border-radius: 10px;
    height: 50px;
    width: 220px;
    color: #fff;
    font-family: 'proxima_nova_rgbold';
    font-size: 16px;
    border: 0;
    padding: 0px 20px;
    // margin-top: 50px;
  }
}
.assignmentConQue {
  position: relative;
}
.assignmentBtnClose {
  position: absolute;
  right: 27px;
  top: 24px;
  z-index: 999;
  svg {
    height: 40px;
    width: 40px;
  }
}
.assignmentConQue .modal-content {
  height: auto !important;
  overflow: auto;
  min-height: 150px !important;
}

.modal-backdrop.show {
  display: block !important;
}

.modal-backdrop {
  z-index: 1000 !important;
}

// .preview-popup {
//   top: 0%;
//   right: 15%;
// }

.preview-popup .modal-footer {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.preview-popup .modal-footer button {
  font-weight: 900;
}

.preview-popup .modal-footer button.btn-secondary {
  background-color: #1c1c1c !important;
}

.test-container {
  padding: 24px 0;
}

.background-on-success {
  background-color: #f4f7fc;
}

.background-on-error {
  background-color: #fef4e9;
}

.description-image {
  width: 240px;
  height: auto;
}

.test-container .sub-links-normal {
  color: #4a5052;
  font-family: 'proxima_nova_rgregular';
  font-size: 20px;
}

.test-container .sub-links-normal.bold {
  font-size: 16px;
  color: #454c4e;
}

.test-container .sub-links-active {
  color: #f3722c;
  font-family: 'proxima_nova_rgregular';
  font-size: 19px;
}

// .preview-popup .modal-content {
//   min-height: 150px;
//   min-width: 1000px;
//   border-radius: 10px;
// }

.question-preview-main * {
  font-family: 'proxima_nova' !important;
}

.question-preview-main
  .question-options-wrapper.selectTileType
  .option-wrapper-tile-type {
  height: 70px;
}

.question-preview-main
  .question-options-wrapper.selectTileType
  .option-wrapper-tile-type
  .questionHtlm-left {
  width: 80%;
}

.question-preview-main
  .question-options-wrapper.selectTileType
  .option-wrapper-tile-type
  .questionHtlm-left
  img {
  height: 60px;
  width: 60px;
}

.question-preview-main .test-header-left h3.test-header {
  font-size: 30px;
  font-weight: bold;
}

.question-preview-main .test-header-left span.sub-links-normal {
  font-size: 14px;
}

.question-preview-main .test-header-left .test-header-difficulty {
  text-align: right;
}

.question-preview-main .test-header-left .test-header-difficulty span.bold {
  font-weight: bold;
}

.question-preview-main .question-options-wrapper {
  margin-top: 0;
}

.question-preview-main .explanation-box {
  -webkit-box-shadow: 0px 0px 15px #f5f5f5;
  box-shadow: 0px 0px 15px #f5f5f5;
}

.question-preview-main .explanation-box h4 {
  margin-bottom: 0;
}

.question-preview-main .explanation-box img.description-image {
  max-height: 100px;
  max-width: 100px;
  margin-left: 20px;
}

.question-preview-main .col-12 {
  padding-left: 0;
}

.question-preview-main .question-box {
  border-radius: 15px;
  margin-bottom: 24px;
  -webkit-box-shadow: 0px 0px 10px #f5f5f5;
  box-shadow: 0px 0px 10px #f5f5f5;
  margin-top: 20px;
}

.question-preview-main .question-box h4 {
  font-weight: bold;
  color: #20282a;
}

.question-preview-main .question-box h2 {
  font-size: 16px;
  font-weight: normal;
}

.question-preview-main .questiontype-box {
  padding-left: 0;
  margin-bottom: 0;
}

.question-preview-main .questiontype-box h4 {
  font-weight: bold;
}

.question-preview-main .questiontype-box h4 span {
  font-weight: normal;
  font-size: 20px;
  margin: 0;
  margin-left: 5px;
}

.preViewAssQue {
  padding-right: 10px;
  font-family: 'proxima_nova_rgbold';
  font-size: 18px;
}

.assignDatesandtime .label {
  font-family: 'proxima_nova_rgbold';
  font-size: 18px;
  margin-bottom: 5px;
  margin-top: 25px;
}

.rp-manage-school_error-message {
  // margin-top: -15px;
  font-family: 'proxima_nova_rgregular';
  font-size: 13px;
  color: red;
  margin-bottom: 20px;
}

.button-common-assign {
  height: 50px;
  width: 250px;
  border-radius: 33.75px;
  background-color: #43aa8b;
  border: 0;
  text-align: center;
  padding: 0px 20px;
  color: #fff;
  font-size: 20px;
  font-family: 'proxima_nova_rgbold';
  line-height: 24px;
  margin-top: 68px;
  position: relative;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0.8;
}

.button-common-assign span {
  float: right;
  height: 24px;
  width: 24px;
  background-color: #fca834;
  border-radius: 50%;
  padding: 0px 7px;
  margin-top: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-shadow: none;
}

.button-common-assign span svg {
  margin-top: 0 !important;
}

.button-common-assign:hover {
  -webkit-box-shadow: 5px 5px 5px rgba(67, 170, 139, 0.25) !important;
  box-shadow: 5px 5px 5px rgba(67, 170, 139, 0.25) !important;
  opacity: 1 !important;
}

.button-common-assign:hover span {
  -webkit-box-shadow: 5px 5px 5px rgba(56, 38, 38, 0.24) !important;
  box-shadow: 5px 5px 5px rgba(56, 38, 38, 0.24) !important;
}

.assignStatus {
  width: 130px !important;
}

.modal-overlay {
  background: rgba(0, 0, 0, 0.4) !important;
}

// .submit-btn{
//   width: 250px !important;
//   height: 60px !important;
//   border-radius: 3rem !important;
//   background-color: #43aa8b !important;
//   border: none !important;
//   text-align: center !important;

// }

.font-color {
  color: grey !important;
  font-size: 17px !important;
  font-weight: 500;
}

.font-styles {
  color: #7d8283;
  font-family: 'Proxima Nova';
  font-size: 17px;
  font-weight: 500;
}

.border-color {
  border: 1px solid #e0e0e0;
  border-radius: 10px !important;
}
.border-color-none {
  border: none !important;
  border-radius: 10px !important;
}

button.button-common2 {
  width: 303px;
  background-color: #43aa8b;
  border-radius: 33.75px;
  color: #fff;
  border: 0;
  height: 66px;
  font-weight: bold;
  font-size: 20px;
  font-family: 'proxima_nova_rgbold';
  text-align: center;
  padding: 0px 25px;
  box-shadow: 5px 5px 5px rgb(67 170 139 / 25%) !important;
  margin: 60px 0px 30px 0px;
  position: relative;
  // z-index: 9;
  display: block;
  span {
    float: right;
    height: 24px;
    width: 24px;
    background-color: #fca834;
    border-radius: 50%;
    padding: 0px 7px;
    margin-top: 4px;
    box-shadow: none;
    display: flex;
    align-items: center;
    svg {
      margin-top: 0 !important;
    }
  }
  &::after,
  &::before {
    content: none;
  }
  &:disabled {
    background-color: #dddddd !important;
    color: #ffffff !important;
    box-shadow: none !important;
    span {
      background-color: #c3c3c3 !important;
      color: #ffffff !important;
      box-shadow: none !important;
    }
    &:hover {
      box-shadow: none !important;
      opacity: 1;
      span {
        box-shadow: none !important;
        svg {
          margin-left: 0px;
        }
      }
    }
  }

  // .submit-btn.btndis {
  //   background-color: #dddddd !important;
  //   color: #ffffff !important;
  //   span {
  //     background-color: #c3c3c3 !important;
  //     color: #ffffff !important;
  //   }
  // }
  // .submit-btn.btndis:hover {
  //   box-shadow: none !important;
  //   opacity: 1;
  //   span {
  //     box-shadow: none !important;
  //     svg {
  //       margin-left: 0px;
  //     }
  //   }
  // }
}

.new-toggle-maths-btn {
  min-width: 300px !important;
  width: auto !important;
}
