@import './views/components/auth/register/CustDropDes.css';

@import './views/components/newRegistration/customdropdown/style.css';
@font-face {
  font-family: 'proxima_nova_rgregular';
  src: url('./assets/font/proximanova-regular-webfont.woff');
  /* src: url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot');
  src: url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.woff')
      format('woff'),
    url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.ttf')
      format('truetype'),
    url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.svg#proxima_nova_rgregular')
      format('svg'); */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'proxima_nova_rgbold';
  src: url('./assets/font/proximanova-bold-webfont.woff');
  /* src: url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot');
  src: url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.woff')
      format('woff'),
    url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.ttf')
      format('truetype'),
    url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.svg#proxima_nova_rgbold')
      format('svg'); */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'adelle_rgregular';
  src: url('https://litmus.com/fonts/Emails/adelle_reg-webfont.eot');
  src: url('https://litmus.com/fonts/Emails/adelle_reg-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('https://litmus.com/fonts/Emails/adelle_reg-webfont.woff')
      format('woff'),
    url('https://litmus.com/fonts/Emails/adelle_reg-webfont.ttf')
      format('truetype'),
    url('https://litmus.com/fonts/Emails/adelle_reg-webfont.svg#adelle_rgregular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets//font/f14eee643541cf03a10f26c944cc29f5.woff2');
  /* src: url('//db.onlinewebfonts.com/t/f14eee643541cf03a10f26c944cc29f5.eot');
  src: url('//db.onlinewebfonts.com/t/f14eee643541cf03a10f26c944cc29f5.eot?#iefix')
      format('embedded-opentype'),
    url('//db.onlinewebfonts.com/t/f14eee643541cf03a10f26c944cc29f5.woff2')
      format('woff2'),
    url('//db.onlinewebfonts.com/t/f14eee643541cf03a10f26c944cc29f5.woff')
      format('woff'),
    url('//db.onlinewebfonts.com/t/f14eee643541cf03a10f26c944cc29f5.ttf')
      format('truetype'),
    url('//db.onlinewebfonts.com/t/f14eee643541cf03a10f26c944cc29f5.svg#Proxima Nova')
      format('svg'); */
}
/* iframe[style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;"] {
  pointer-events: none;
} */

iframe[style='position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;'] {
  pointer-events: none;
}

html,
body {
  min-width: 100%;
  overflow-x: hidden !important;
  /* overflow: scroll !important; */
  -webkit-user-select: none; /* Disable text selection for Chrome, Safari, and Opera */
  -moz-user-select: none; /* Disable text selection for Firefox */
  -ms-user-select: none; /* Disable text selection for Internet Explorer and Edge */
  user-select: none; /* Disable text selection for all browsers */
}
button {
  border: none;
  background: none;
}
body::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: transparent; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  /* creates padding around scroll thumb */
}
