.change-name-pop-up,
.change-course-details-pop-up {
  // height: 95% !important;

  .btn-radius {
    border-radius: 30px !important;
  }

  h3 {
    margin: 0px auto;
  }

  .container-phone-number {
    width: 100%;

    .phone-input {
      margin-left: 69px;
    }
  }

  .update-username {
    font-size: 20px;
  }
}

.change-name-pop-up::-webkit-scrollbar {
  display: none;
  overflow: -moz-hidden-unscrollable;
}

.change-course-details-pop-up {
  .save-button {
    border-radius: 33.75px;
    background-color: #43aa8b;
    box-shadow: 0 12px 10px 0 rgba(67, 170, 139, 0.25);
    border: none;
    color: white;
    height: 36px;
    width: 133px;
    margin-top: 50px;
  }

  .select-modal {
    border: red;
  }
}

//sailendra dash

.modal-content {
  box-shadow: 3px 4px 28px rgba(0, 0, 0, 0.4);
}

.modalSize {
  // width: 30vw !important; //linked with titlestyles width
}
.change-phone-body {
  min-height: 300px;

  .change-phone-wrapper {
    .error-msg {
      color: red;
    }
    .phone-input-custom {
      display: flex;
      .phone-input-box {
        max-width: 299px;
        input {
          border-radius: 5px 0 0 5px;
        }
      }
      .send-otp-button {
        background: rgb(67, 170, 139);
        border-radius: 0px 5px 5px 0px;
        min-width: 135px;
        color: rgb(255, 255, 255);
        font-size: 17px;
      }
      .send-otp-button:disabled {
        background: #8c8e8e;
      }
    }
    .otp-block {
      input {
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        background-color: #fff;
        margin: 5px;
        height: 55px;
        min-width: 60px;
      }
    }
  }
}

//mobile
@media only screen and (max-width: 415px) {
  .modalSize {
    width: 30vh !important;
  }
  .modalDialog {
    margin-left: 17rem !important;
  }
}
@media only screen and (max-width: 600px) {
  .modalSize {
    width: 100vh !important;
  }
  .modalDialog {
    margin-left: 17rem !important;
  }
}

//ipad size tab
@media only screen and (max-width: 768px) {
  .modalSize {
    width: 100vh !important;
  }
  .modalDialog {
    margin-left: 17rem !important;
  }
}
.success-modal-popup {
  height: 50% !important;
  //  margin-top: 50px !important;
  //  text-align: center !important;
  //  color : green !important;
}
