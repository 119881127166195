.calendar-main {
  .calendar-head {
    margin-top: 30px;
    h3 {
      font-family: 'proxima_nova_rgbold';
      font-size: 32px;
      color: #20282a;
      display: inline-block;
      margin-right: 30px;
    }
    p.desc {
      font-family: 'Proxima Nova';
      font-size: 16px;
      color: #6f797b;
      line-height: 19px;
      margin-top: 55px;
      margin-bottom: 0;
      text-align: left;
    }
    .text-right {
      text-align: right;
      button.leave {
        background-color: #f3722c;
        color: #ffe7d9;
        border-radius: 10px;
        height: 50px;
        width: 190px;
        color: #fff;
        font-family: 'proxima_nova_rgbold';
        font-size: 16px;
        border: 0;
      }
    }
    .left-right-arrow {
      display: inline-block;
      .arrow-common {
        height: 34px;
        width: 34px;
        background-color: #eaeaea;
        color: #20282a;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
    }
    .view-part {
      margin-bottom: 10px;
      margin-top: 10px;
      p {
        font-family: 'Proxima Nova';
        font-size: 16px;
        color: #6f797b;
        line-height: 19px;
        margin-bottom: 0;
        display: inline-block;
        margin-right: 20px;
        vertical-align: top;
        margin-top: 4px;
      }
      span {
        margin-right: 20px;
      }
      button {
        background-size: cover;
        background-repeat: no-repeat;
        height: 24px;
        width: 24px;
        background-color: transparent;
        border: 0;
        font-size: 0px;
        vertical-align: top;
      }
      button.menu {
        margin-right: 10px;
        background-image: url('../../../../../../src/assets/images/grid.svg');
      }
      button.grid {
        background-image: url('../../../../../../src/assets/images/menu.svg');
      }
    }
  }
  .calendar-table {
    background-color: #fff;
    padding: 20px;
    text-align: center;
    box-shadow: rgba(189, 189, 189, 0.07);
    border-radius: 10px;
    table {
      border-collapse: collapse;
      width: 100%;
      display: flex;
      justify-content: space-around;
      tr {
        display: inline-block;
      }
      td {
        display: block;
        text-align: left;
        padding-bottom: 20px;
      }
      td.border-bottom {
        margin-bottom: 15px;
        color: #6f797b;
        font-size: 14px;
        font-family: 'Proxima Nova';
        border-color: #6f797b;
      }
    }
    .rbc-calendar {
      .rbc-today {
        background-color: #fff4e9;
      }
      .rbc-events-container .rbc-event {
        background-color: #f3722c;
        height: auto !important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 300px !important;
        margin: 0px;
        padding: 0px;
      }

      .rbc-toolbar {
        position: absolute;
        top: 120px;
        left: 52px;
        .rbc-toolbar-label {
          order: 1;
          font-family: 'proxima_nova_rgbold';
          font-size: 32px;
          color: #20282a;
          display: inline-block;
          margin-right: 30px;
          width: 320px;
          text-align: left;
        }
        span.rbc-btn-group:nth-child(1) {
          order: 2;
          button:first-child {
            display: none;
          }
          button {
            height: 34px;
            width: 34px;
            background-color: #eaeaea;
            // background-color: red;
            color: #20282a;
            border-radius: 50%;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 0px;
            border: 0;
          }
          button:nth-child(2) {
            margin-right: 40px;
            position: relative;
            &::before {
              content: '';
              background-image: url('../../../../../../src/assets/images/chevron-left.svg');
              position: absolute;
              height: 15px;
              width: 15px;
              background-size: cover;
              // left: 145%;
            }
          }
          button:nth-child(3)::before {
            content: '';
            background-image: url('../../../../../../src/assets/images/chevron-right.svg');
            position: absolute;
            height: 15px;
            width: 15px;
            background-size: cover;
            // left: 145%;
          }
          button:nth-child(2)::after {
            content: '';
            background-image: url('../../../../../../src/assets/images/rectangle.svg');
            position: absolute;
            height: 10px;
            width: 10px;
            background-size: cover;
            left: 145%;
          }
        }
        span.rbc-btn-group:nth-child(3) {
          order: 3;
          display: none;
        }
      }
      .rbc-month-view {
        border: 0;
        display: block;
        .rbc-date-cell.rbc-now.rbc-current {
          color: #f3722c;
        }
        .rbc-event-allday {
          background-color: transparent;
          .event-common {
            background-image: url('../../../../../../src/assets/images/rectangle.svg');
            height: 10px;
            width: 10px;
            background-size: cover;
            background-repeat: no-repeat;
            margin: auto;
          }
        }
        .rbc-date-cell a:hover {
          color: #20282a;
        }
        .rbc-header {
          border: 0;
        }
        .rbc-month-row {
          .rbc-day-bg + .rbc-day-bg {
            border: 0;
          }
          .rbc-day-bg.rbc-today {
            background-color: transparent;
          }
          .rbc-event {
            width: 100%;
            //background-color: #FFF4E9;
            border-radius: 0;
            text-align: left;
            padding-left: 0px;
            background-color: #fff;
            .rbc-event-content {
              color: #20282a;
              font-family: 'proxima_nova_rgbold';
              font-size: 14px;
              line-height: 1;
              margin-bottom: 10px;
            }
            .rbc-event-content[title='On Leave'] {
              color: #e72d0d;
              border-bottom: 1px solid;
              width: 95%;
              padding: 5px;
              text-align: center;
            }
          }
          .rbc-show-more {
            font-size: 14px;
            font-family: 'Proxima Nova';
            width: 84%;
            //background-color: #FFF4E9;
            text-align: left;
            //padding-left: 10px;
            padding-bottom: 10px;
            color: #6f797b;
            text-decoration: underline;
            // display: none;
          }
        }
        .rbc-month-row + .rbc-month-row {
          border: 0;
        }
        .rbc-month-header {
          border-bottom: 1px solid #6f797b;
          margin-bottom: 20px;
          padding-bottom: 25px;
          .rbc-header {
            span {
              color: #6f797b;
              font-size: 14px;
              font-family: 'Proxima Nova';
              text-transform: uppercase;
            }
          }
        }
        .rbc-month-row {
          .rbc-date-cell {
            text-align: center;
            font-family: 'proxima_nova_rgbold';
            color: #20282a;
            font-size: 20px;
            padding: 15px 0px;
          }
          .rbc-off-range-bg {
            background: transparent;
          }
          .rbc-date-cell.rbc-off-range {
            color: #6f797b;
          }
        }
        .rbc-event.rbc-event-allday {
        }
      }
    }
  }
  .calendar-row.part-view .rbc-month-view .rbc-event.rbc-event-allday {
    padding: 0;
    margin: 0;
    text-align: center;
    width: 100%;
  }
  .calendar-row.part-view
    .rbc-month-view
    .rbc-event.rbc-event-allday
    .rbc-event-content {
    margin-bottom: 0;
    font-family: 'Proxima Nova';
    color: black;
    font-size: 12px;
    position: relative;
    margin: 0px auto;
    top: -13px;
    width: 89px;
  }
  .calendar-row.part-view
    .rbc-month-view
    .rbc-event.rbc-event-allday
    .rbc-event-content[title='Leave'] {
    color: #e72d0d;
    border-bottom: 1px solid #e72d0d;
    width: 80%;
    padding: 5px;
    text-align: center;
    margin: auto;
    margin-bottom: 5px;
  }

  width: 95%;
  padding: 5px;
  text-align: center;
  margin: auto;
  margin-bottom: 5px;

  .calendar-row.full-view {
    .rbc-events-container {
      .rbc-event-label {
        display: inline-block !important;
        width: auto !important;
        margin: 5px;
        font-family: 'Proxima Nova';
        font-size: 16px;
      }

      .rbc-event-content {
        display: inline-block;
        width: 50%;
        text-align: left;
        margin: 5px;
        font-size: 18px;
        font-family: 'Proxima Nova';
        padding-top: 2px;
      }
    }
    .rbc-date-cell,
    .rbc-header {
      text-align: left !important;
    }
    .rbc-calendar {
      min-height: auto !important;
      display: block;
      .rbc-month-view {
        display: block;
        .rbc-month-row {
          .rbc-date-cell {
            padding-bottom: 15px;
          }
        }
      }
    }
    .rbc-month-row {
      .rbc-day-bg {
        border-top: 1px solid #6f797b !important;
        margin-right: 25px;
        //z-index: 9;
      }
      .rbc-date-cell {
        padding-top: 15px;
      }
    }
    div.rbc-month-row:first-child {
      .rbc-day-bg {
        border: 0 !important;
      }
    }
    .rbc-month-header {
      border: 0 !important;
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
      .rbc-header {
        border-bottom: 1px solid #6f797b !important;
        margin-right: 25px;
        padding-bottom: 20px;
      }
    }
    .rbc-row-segment {
      padding: 0;
    }
    .rbc-date-cell.rbc-now.rbc-current {
      padding: 0 !important;
      a {
        //padding-left: 10px;
        width: 84%;
        //background-color: #FFF4E9;
        height: 100%;
        display: block;
        padding-top: 20px;
      }
      .rbc-event {
        //padding-left: 10px;
        width: 84%;
        background-color: #fff4e9 !important;
        text-align: left;
      }
    }
    .rbc-event.rbc-event-allday {
      background-color: #f3722c;
      border-radius: 0;
      width: 100%;
      padding-left: 10px;
    }
    .event-main {
      color: #000;
      text-align: left;
      p.subject {
        margin-bottom: 0;
        color: #20282a;
        font-family: 'proxima_nova_rgbold';
        font-size: 14px;
      }
      p.time {
        color: #6f797b;
        font-size: 14px;
        font-family: 'Proxima Nova';
        margin-bottom: 0;
      }
    }
  }
  .time-table {
    background-color: #fff4e9;
    box-shadow: rgba(189, 189, 189, 0.07);
    padding: 20px;
    border-radius: 10px;
    text-align: left;
    .time-table-head {
      border-bottom: 1px solid #f3722c;
      margin-bottom: 20px;
      color: #f3722c;
      font-size: 16px;
      font-family: 'proxima_nova_rgbold';
    }
    .time-table-common {
      margin-bottom: 25px;
      h5 {
        margin-bottom: 0;
        font-family: 'proxima_nova_rgbold';
        color: #20282a;
        font-size: 22px;
      }
      p {
        margin: 5px 0;
        color: #20282a;
        font-family: 'proxima_nova_rgregular';
      }
      a {
        color: #f3722c;
        font-family: 'proxima_nova_rgregular';
      }
    }
  }
  .leave-main {
    margin-top: 30px;

    .css-1s2u09g-control {
      border-radius: 15px;
      // height:45px;
      // margin-bottom:20px;
    }
    h3 {
      font-family: 'proxima_nova_rgbold';
      font-size: 32px;
      color: #20282a;
      text-align: left;
    }
    .leave-table {
      width: 100%;
      overflow-x: none;
      height: auto;
      min-height: 400px;
      td.comments {
        width: 320px;
      }
    }
    table {
      border-collapse: separate;
      border-spacing: 0px 20px;
      width: 100%;
      tbody tr {
        background-color: #fff;
        box-shadow: rgba(189, 189, 189, 0.07) !important;
        td {
          font-size: 16px;
          color: #20282a;
          font-family: 'proxima_nova_rgbold';
          span.dot {
            width: 10px;
            height: 10px;
            display: inline-block;
            border-radius: 50%;
            margin-right: 10px;
          }
        }
        td.Pending {
          color: #fca834;
          span.dot {
            background-color: #fca834;
          }
        }
        td.Approved {
          color: #43aa8b;
          span.dot {
            background-color: #43aa8b;
          }
        }
        td.Denied {
          color: #e72d0d;
          span.dot {
            background-color: #e72d0d;
          }
        }
        td.Cancelled {
          color: #787c7e;
          span.dot {
            background-color: #787c7e;
          }
        }
        td.edit {
          color: #f3722c;
          text-decoration: underline;
          font-size: 16px;
          font-family: 'proxima_nova_rgbold';
          text-transform: uppercase;

          button {
            background-color: transparent;
            border: 0;
            color: #f3722c;
            padding-left: 20px;
          }
        }
      }
      td {
        padding: 15px;
      }
      thead {
        td {
          padding: 0px 15px;
          font-size: 14px;
          color: #6f797b;
          font-family: 'proxima_nova_rgbold';
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .calendar-head {
      .text-right {
        text-align: left;
        button {
          height: 45px;
          width: 150px;
          font-size: 15px;
        }
      }
      .view-part {
        text-align: center;
        p {
          margin: 1rem 0;
        }
      }
      h3 {
        font-size: 28px;
      }
      p {
        font-size: 14px;
      }
    }
    .time-table {
      margin: 30px 0px;
      .time-table-common {
        h5 {
          font-size: 20px;
        }
        p {
          font-size: 14px;
        }
      }
    }
    .leave-main {
      h3 {
        font-size: 28px;
      }
      .leave-table {
        tbody tr td {
          font-size: 13px;
        }
      }
    }
  }
}
.calendar-leave-popup {
  margin-top: -5px;
  .modal-dialog {
    max-width: 620px;
  }
  .modal-content {
    height: auto !important;
    overflow: auto !important;
    .close {
      top: 30px;
    }
    .modal-header {
      h3 {
        font-family: 'proxima_nova_rgbold';
        font-size: 32px;
        color: #20282a;
      }
      p {
        color: #7d8283;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 0;
        max-width: 80%;
        margin-top: 10px;
      }
    }
    .modal-body {
      padding: 30px;
      padding-top: 15px;
      div.label {
        color: #7d8283;
        font-family: 'Proxima Nova';
        font-size: 18px;
      }
      input,
      button.dropdown-toggle {
        border-radius: 10px;
        width: 100%;
        border: 1px solid #e0e0e0;
        height: 66px;
        padding: 15px;
        color: #7d8283;
        font-family: 'Proxima Nova';
        font-size: 18px;
      }
      input[type='textarea'] {
        height: 100px;
        font-size: 23px;
      }
      textarea {
        height: 100px;
        font-size: 23px;
        resize: none;
        border-radius: 10px;
        width: 100%;
        border: 1px solid #e0e0e0;
        padding: 15px;
        color: #7d8283;
        font-family: 'Proxima Nova';
        font-size: 18px;
      }
      textarea:focus {
        border-color: #43aa8b;
        outline: none;
      }
      input:focus {
        border-color: #43aa8b;
        outline: none;
      }
      button.button-common {
        margin: 50px;
      }
      .mt-25.ml-25.rp-manage-school_error-message {
        margin-top: -15px;
        font-size: 13px;
        color: red;
        margin-bottom: 20px;
        font-style: normal;
      }
      p.day-leave {
        text-align: center;
        color: #f3722c;
        font-family: 'proxima_nova_rgbold';
        font-size: 18px;
        margin-bottom: 0;
        margin-top: 10px;
      }
    }
  }
  .leave-applied {
    text-align: center;
    h3 {
      font-family: 'proxima_nova_rgbold';
      font-size: 32px;
      color: #20282a;
    }
    p {
      font-family: 'Proxima Nova';
      font-size: 18px;
      color: #7d8283;
    }
  }
  .react-datepicker-popper {
    width: 90%;
    .react-datepicker {
      width: 100%;
      padding: 25px;
      border-color: #43aa8b;
      padding-top: 10px;
      .react-datepicker__month-container {
        width: 100%;
        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
          width: 14%;
          text-align: left;
          padding: 12px 5px;
          text-transform: uppercase;
        }
      }
      .react-datepicker__month {
        margin: 0 !important;
      }
      .react-datepicker__triangle::after {
        content: none !important;
      }
      .react-datepicker__triangle::before {
        content: none !important;
      }
      .react-datepicker__header {
        background-color: transparent;
        border-color: #6f797b;
        margin-bottom: 10px;
        padding-bottom: 15px;
        .react-datepicker__current-month {
          // display: none;
          display: inline-block;
          position: relative;
          top: -10px;
          color: #f3722c;
        }
      }
      .react-datepicker__navigation {
        top: 2%;
      }
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--selected,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--selected,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--selected,
      .react-datepicker__year-text--in-selecting-range,
      .react-datepicker__year-text--in-range {
        border-radius: 0;
        background-color: #fff4e9;
        color: #020202 !important;
      }
      .react-datepicker__day--selected {
        color: #f3722c !important;
        border-radius: 0;
        background-color: #fff4e9;
      }
      .react-datepicker__day:hover,
      .react-datepicker__month-text:hover,
      .react-datepicker__quarter-text:hover,
      .react-datepicker__year-text:hover {
        border-radius: 0;
        background-color: #fff4e9;
        color: #f3722c !important;
      }
      .react-datepicker__day--keyboard-selected,
      .react-datepicker__month-text--keyboard-selected,
      .react-datepicker__quarter-text--keyboard-selected,
      .react-datepicker__year-text--keyboard-selected {
        border-radius: 0;
        background-color: #fff4e9;
        color: #f3722c !important;
      }
      .react-datepicker__day-names {
        .react-datepicker__day-name {
          color: #6f797b;
          font-family: 'Proxima Nova';
          font-size: 14px;
        }
      }
      .react-datepicker__week {
        .react-datepicker__day {
          font-family: 'proxima_nova_rgbold';
          color: #20282a;
          font-size: 20px;
        }
        .react-datepicker__day--disabled {
          color: grey !important;
          font-family: 'proxima_nova_rgregular';
          cursor: not-allowed;
          pointer-events: all !important;
        }
        .react-datepicker__day--disabled:hover {
          background-color: transparent !important;
        }
      }
    }
  }
}
.reschedule-popup {
  .time-list-main {
    margin-bottom: 10px;
    label.label {
      display: block;
      color: #7d8283;
      font-family: 'Proxima Nova';
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 5px;
      margin-top: 10px;
    }
    .time-list {
      border: 1px solid #e0e0e0;
      display: inline-flex;
      align-items: center;
      padding: 10px 15px;
      width: auto;
      border-radius: 33px;
      margin-right: 10px;
      margin-bottom: 15px;
      color: #20282a;
      font-family: 'Proxima Nova';
      background-color: #f4f7fc;
      font-size: 18px;
      font-weight: 600;
      padding-right: 25px;
      min-width: 175px;
      min-height: 55px;
      position: relative;
      .round {
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: inline-block;
        vertical-align: top;
        margin-right: 35px;
        input {
          border-radius: 50%;
          margin-right: 10px;
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }
        input:checked ~ .checkmark {
          background-color: #43aa8b;
        }
        .checkmark {
          position: absolute;
          top: 28%;
          left: 5%;
          height: 25px;
          width: 25px;
          background-color: #fff;
          border: 1px solid #e0e0e0;
          border-radius: 50%;
        }
        .checkmark:after {
          content: '';
          position: absolute;
          display: none;
        }
        input:checked ~ .checkmark:after {
          display: block;
        }
        .checkmark:after {
          left: 9px;
          top: 5px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
    .time-list.active {
      border: 1px solid #43aa8b;
      background-color: #fff;
      color: #43aa8b;
    }
  }
  .timer-loader {
    margin-bottom: 10px;
    text-align: center;
  }
  .time-dropdown {
    width: 100%;
    border: 0;
    button.dropdown-toggle {
      color: #7d8283;
      font-family: 'Proxima Nova';
      background-color: transparent;
      text-align: left;
    }
    button.dropdown-toggle::after {
      float: right;
      margin-top: 10px;
    }
    button.dropdown-toggle:focus {
      outline: none;
      box-shadow: none;
    }
    .dropdown-menu {
      width: 100%;
      padding: 20px;
      border: 1px solid #43aa8b;
      border-radius: 10px;
      h5 {
        color: #f3722c;
        font-family: 'Proxima Nova';
        font-size: 18px;
        border-bottom: 1px solid #6f797b;
        padding-bottom: 15px;
      }
      .time-main {
        margin-top: 20px;
        .time-common {
          width: 30%;
          display: inline-block;
          input {
            height: 100px;
            color: #f3722c;
            font-size: 40px;
            font-family: 'Proxima Nova';
            text-align: center;
          }
          input:focus {
            border: 2px solid #f3722c;
          }
          span {
            color: #7d8283;
            font-family: 'Proxima Nova';
            margin-top: 10px;
            display: inline-block;
          }
        }
        .time-center {
          display: inline-block;
          width: 12%;
          text-align: center;
          vertical-align: top;
          span {
            font-size: 65px;
            margin-top: -5px;
            display: inline-block;
          }
        }
        .time-am-pm {
          display: inline-block;
          width: 20%;
          float: right;
          div {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            width: 80px;
            float: right;
            border: 1px solid rgba(125, 130, 131, 0.3);
            border-radius: 10px;
            overflow: hidden;
            button {
              border: 0;
              background-color: #fff;
              height: 50px;
              color: #7d8283;
              font-family: 'Proxima Nova';
            }
            button.active {
              background-color: rgba(243, 114, 44, 0.2) !important;
              color: #f3722c;
            }
            button:first-child {
              border-bottom: 1px solid rgba(125, 130, 131, 0.3);
            }
          }
        }
      }
      .time-bottom {
        border: 1px solid transparent;
        text-align: right;
        margin-top: 50px;
        button {
          background-color: transparent;
          border: 0;
          color: #f3722c;
          font-family: 'proxima_nova_rgbold';
          text-transform: uppercase;
          font-size: 18px;
        }
        button:first-child {
          margin-right: 20px;
        }
      }
    }
  }
  .reschedule-success {
    text-align: center;
    h3 {
      font-family: 'proxima_nova_rgbold';
      font-size: 32px;
      color: #20282a;
      margin-top: 30px;
    }
  }
}
.confirm-leave-popup {
  margin-top: 15%;

  .modal-content {
    height: auto !important;
    overflow: auto !important;
    .modal-body {
      text-align: center;
      font-family: 'Proxima Nova';
      h3 {
        font-size: 29px;
        font-family: 'proxima_nova_rgbold';
        color: #20282a;
      }
      p {
        margin-bottom: 5px;
        color: #20282a;
        font-family: 'proxima_nova_rgbold';
        font-size: 20px;
        span {
          color: #7d8283;
          font-family: 'Proxima Nova';
        }
      }
      .leave-cancel {
        h3 {
          font-size: 32px;
        }
        p {
          color: #7d8283;
        }
      }
    }
    .modal-footer {
      justify-content: center;
      align-items: center;
      button {
        width: 150px;
        padding: 15px;
        height: 60px;
      }

      .red {
        background-color: #fca834;
        opacity: 0.6;
      }
    }
  }
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #fca834;
  border-radius: 50%;
}

.dotGreen {
  height: 10px;
  width: 10px;
  background-color: rgb(144, 238, 144);
  border-radius: 50%;
}

.time-table-body {
  overflow-y: auto;
  height: 320px;
}
