.width-size {
  width: 100% !important;
  min-height: 90px;
}
.register-main .coursedetails-main .course-detail-select .css-12jo7m5 {
  font-size: 13px !important;
  padding: 2px !important;
}
.book-a-demo-main{
  background: none;
  box-shadow: none;
  padding-bottom: 0;
  min-height: 50vh;
}
.book-a-demo-main label{
  text-align: left;

}
.book-a-demo-main .container{
  padding: 0;
  margin: 0;
}
.book-a-demo-main .step-body{
  padding: 0;
  margin:0;
  background: none;
  box-shadow: none;
}