.toggle-custom-maths-div {
  display: flex !important;
}
.fill-in-the-blanks {
  p {
    display: inline-block;
  }
}
.dashboard-main {
  background-color: #f7f7f7;
  .dashboard-main-row.active {
    .dashboard-left {
      display: none !important;
    }
  }
  .row-main {
    margin-left: 0;
    margin-right: 0;
    .dashboard-left {
      font-family: 'proxima_nova_rgregular';
      background-color: #02052e;
      padding: 0px;
      color: #fff;
      border-top-right-radius: 60px;
      border-bottom-right-radius: 60px;
      width: 260px;
      display: inline-block;
      vertical-align: top;
      width: 20%;
      position: fixed;
      z-index: 999;

      .logo-profile {
        padding: 20px;
        height: 70px;
      }
      .dashboard-logo {
        width: 151px;
        height: 39px;
      }
      .dashboard-profile {
        background-color: #01031d;
        padding: 10px;
        margin: 20px 0px;
        cursor: pointer;
        .dashboard-profile-left {
          img {
            height: 44px;
            width: 44px;
            border-radius: 50%;
            margin-left: 3px;
            margin-top: -2px;
          }
        }

        .dashboard-profile-center {
          padding: 0;
          padding-left: 2px;
          h5 {
            font-size: 16px;
            margin-bottom: 3px;
            font-family: 'Proxima Nova';
            color: ivory;
          }
          p {
            font-size: 12px;
            margin-bottom: 0;
            color: #d9d9d9;
            overflow-wrap: break-word;
          }
          a {
            text-decoration: none;
            color: #fff;
          }
        }
        .dashboard-profile-right {
          svg {
            margin-top: 10px;
            cursor: pointer;
            margin-top: 7px;
            cursor: pointer;
            height: 24px;
            width: 24px;
          }
        }
      }
      .dash-list-common {
        padding: 20px;
        padding-left: 30px;
        padding-top: 13px;
        overflow-y: scroll;
        position: relative;
        padding-bottom: 200px !important;
        height: 100vh;
        width: 99%;
        div:nth-child(2) {
          margin-top: 40px;
        }
        h5 {
          font-size: 12px;
          margin-bottom: 20px;
          font-family: 'proxima_nova_rgbold';
          text-transform: uppercase;
          color: ivory;
        }
        ul {
          padding: 0;
          margin-left: -6px;
          li {
            list-style-type: none;
            margin-top: 10px;
            font-size: 16px;
            padding: 10px;
            cursor: pointer;
            font-family: 'Proxima Nova';

            .li-span {
              width: 26px;
              height: 22px;
              background: #fca834;
              display: inline-block;
              border-radius: 6px;
              text-align: center;
              margin-top: 4px;
            }
            svg {
              margin-right: 10px;
              height: 24px;
              width: 24px;
              position: relative;
              top: 5px;
            }
            img {
              margin-right: 10px;
              height: 30px;
              width: 30px;
            }
            a {
              text-decoration: none;
              color: #fff;
              display: flex;
              align-items: center;
              // display: block;
            }
          }
          li.active {
            background: #0d135f;
            border-radius: 5px;
          }
        }
      }
      .dash-list-common::-webkit-scrollbar {
        width: 3px;
        border-radius: 10px;
      }
      .dash-list-common::-webkit-scrollbar-track {
        background: #02052e;
        border-radius: 10px;
      }
      .dash-list-common::-webkit-scrollbar-thumb {
        background: #fff;
        border-radius: 10px;
      }
      .dash-list-common::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

    .dashboard-right {
      width: auto;
      display: inline-block;
      vertical-align: top;
      padding-left: 25px;
      width: 79%;
      padding-top: 25px;
      position: relative;
      padding-bottom: 50px;
      left: 20%;
      min-height: 800px;
      .dashboard-right-search {
        background: #ffffff;
        height: 60px;
        box-shadow: 0px 0px 5px rgba(154, 154, 154, 0.1);
        border-radius: 10px;
        padding: 0px 15px;
        margin: auto;
        .dash-right-Dropdown-common {
          display: flex;
          // align-items: center;
          // justify-content: center;
          img {
            width: 38px;
            height: 38px;
            border-radius: 38px;
          }
          .dropdownSection {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 60px;

            .dropdown {
              height: 55px;
              width: 155px;
              border: none;
              text-align: center;
              color: #454c4e;
              font-family: 'Proxima Nova';
              font-size: 16px;
              font-weight: 600;
              letter-spacing: 0;
              line-height: 19px;

              .dropdown-toggle {
                background: transparent;
                border: 0;
                cursor: pointer;
                font-size: 14px;
                font-family: 'Proxima Nova';
                color: #454c4e;
                .arrow {
                  border: solid black;
                  border-width: 0 1px 1px 0;
                  display: inline-block;
                  padding: 3px;
                  // top: 6px;
                }
                .down {
                  transform: rotate(45deg);
                  -webkit-transform: rotate(45deg);
                }
                // padding: 0;
                &::after {
                  position: relative;
                  top: 2px;
                  left: 3px;
                  height: 6px;
                  width: 10px;
                  border: none;
                  content: '';
                  vertical-align: 0;
                }
                &:focus {
                  box-shadow: none !important;
                }
              }
            }
          }
        }
        .dash-right-search-common {
          display: flex;
          align-items: center;
          img {
            width: 20px;
            height: 20px;
            margin-top: -3px;
            margin-right: 5px;
          }
          p {
            margin: 0;
            font-size: 16px;
            color: #6f797b;
            font-family: 'proxima_nova_rgregular';
            svg {
              margin-right: 15px;
              margin-top: -3px;
            }
            span.xp {
              height: 24px;
              width: 24px;
              background-color: #fca834;
              border-radius: 50%;
              position: relative;
              top: 8px;
              margin-right: 10px;
              display: inline-block;
              box-shadow: 0px 0px 2px #fe9402;
            }
          }
          input::-webkit-input-placeholder {
            /* Edge */
            color: #6f797b;
          }

          input:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #6f797b;
          }

          input::placeholder {
            color: #6f797b;
          }
          input {
            font-size: 16px;
            border: 0;
          }
          input:focus {
            outline: none;
          }
        }
        .dash-right-search-common-one {
          justify-content: flex-end;
          button {
            padding: 0;
            border: 0;
            background: transparent;
          }
          img {
            width: 17px;
            height: 20px;
          }
        }
        .dash-right-search-common-two {
          padding-left: 0;
          padding-right: 0;
        }
        .dash-common-calendar {
          img {
            width: 20px;
            height: 18px;
            margin-right: 7px;
            margin-top: -2px;
          }
        }
      }
      .next-class {
        margin-top: 30px;
        .next-class-common {
          background-color: #fff;
          border-radius: 10px;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
          padding: 30px 20px;
          position: relative;
          height: 263px;
        }
        .next-chapter {
          .nex-chapter-span {
            width: 140px;
            height: 125px;
            border-radius: 40.4px 10px 0px 50.4px;
            position: absolute;
            right: 0;
            top: 0;
            background: #e3fff6;
            text-align: center;
            color: #43aa8b;
            padding-top: 18px;
            svg {
              width: 50px;
              height: 50px;
            }
            span {
              display: block;
              font-family: 'proxima_nova_rgbold';
              text-transform: uppercase;
              font-size: 16px;
              margin-top: 5px;
            }
          }
          h4 {
            color: #454c4e;
            font-size: 24px;
            font-family: 'proxima_nova_rgbold';
          }
          p {
            font-size: 18px;
            color: #454c4e;
            font-family: 'Proxima Nova';
          }
          li {
            list-style-type: none;
            font-size: 20px;
            color: #43aa8b;
            text-transform: uppercase;
            font-family: 'proxima_nova_rgbold';
          }
          p.center-p {
            margin-top: 30px;
            svg {
              margin-top: -3px;
              margin-right: 3px;
            }
          }
          .performance {
            margin-top: 15px;
            p {
              margin: 0;
              svg {
                margin-right: 2px;
              }
            }
          }
        }
        .next-perfomance {
          h3 {
            font-family: 'proxima_nova_rgbold';
            color: #454c4e;
            font-size: 28px;
            text-transform: uppercase;
          }
          .per-left {
            padding-right: 35px;
            .progress-bar-one {
              color: #f3722c;
              margin-top: 30px;
              .progress {
                background-color: #ffe7d9;
                border-radius: 10px;
                height: 5px;
                .progress-bar {
                  background-color: #f3722c;
                  border-radius: 10px;
                  overflow: visible;
                  position: relative;

                  &:after {
                    content: '';
                    position: absolute;
                    background: #fff;
                    height: 1px;
                    width: 85.7%;
                    top: 2.3px;
                    left: 13px;
                  }
                  &:before {
                    content: '';
                    position: absolute;
                    background: #fff;
                    height: 5px;
                    width: 5px;
                    left: 4px;
                    border-radius: 50%;
                    top: 2.4px;
                  }
                }
              }
            }
            .updated-progress-bar-one {
              color: #f3722c;
              margin-top: 30px;
              .progress {
                background-color: #ffe7d9;
                border-radius: 10px;
                height: 5px !important;
                --bs-progress-height: 0px !important;
                .progress-bar {
                  background-color: #f3722c;
                  border-radius: 10px;
                  overflow: visible;
                  position: relative;

                  &:after {
                    content: '';
                    position: absolute;
                    background: #fff;
                    height: 1px;
                    width: 85.7%;
                    top: 2.3px;
                    left: 13px;
                  }
                  &:before {
                    content: '';
                    position: absolute;
                    background: #fff;
                    height: 5px;
                    width: 5px;
                    left: 4px;
                    border-radius: 50%;
                    top: 2.4px;
                  }
                }
              }
            }
            .progress-bar-two {
              color: #f9c74f;
              margin-top: 35px;
              .progress {
                background-color: #fff6e2;
                border-radius: 10px;
                height: 10px;
                .progress-bar {
                  background-color: #f9c74f;
                  border-radius: 10px;
                  overflow: visible;
                  position: relative;
                  &:after {
                    content: '';
                    position: absolute;
                    background: #fff;
                    height: 1px;
                    width: 85.7%;
                    top: 2.3px;
                    left: 13px;
                  }
                  &:before {
                    content: '';
                    position: absolute;
                    background: #fff;
                    height: 5px;
                    width: 5px;
                    left: 4px;
                    border-radius: 50%;
                    top: 2.4px;
                  }
                }
              }
            }

            .label-main {
              margin-bottom: 7px;
              .label-left {
                float: left;
                color: #454c4e;
              }
              .label-right {
                float: right;
                font-size: 14px;
                font-family: 'proxima_nova_rgbold';
              }
              &:after {
                content: '';
                display: block;
                clear: both;
              }
            }
          }
          .per-right {
            .select-top {
              &::after {
                content: '';
                display: block;
                clear: both;
              }
              select {
                float: right;
                border: 0;
                font-size: 14px;
                color: #454c4e;
                font-family: 'Proxima Nova';
                background-color: transparent;
                cursor: pointer;
              }
            }
            select:focus {
              outline: none;
            }
          }
          .icon-part {
            margin-top: 15px;
            .icon {
              width: 100%;
              height: 80%;
              background-color: #e3fff6;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 10px;
              svg {
                width: 30px;
                height: 30px;
                color: #43aa8b;
              }
            }
            h5 {
              font-size: 24px;
              color: #43aa8b;
              font-family: 'proxima_nova_rgbold';
              margin-bottom: 3px;
            }
            p {
              font-family: 'Proxima Nova';
              color: #454c4e;
              font-size: 13px;
            }
          }
          .icon-part.one {
            margin-top: 40px;
          }
          .right {
            padding-right: 0;
          }
        }
      }
      .brain-gym-main {
        background-color: #fff;
        padding: 30px 20px;
        border-radius: 10px;
        box-shadow: 0px 0px 5px rgb(0 0 0 / 5%);
        margin-top: 30px;
        position: relative;
        img {
          // width: 100%;
        }
        button.button-common {
          width: 303px;
          background-color: #43aa8b;
          border-radius: 33.75px;
          color: #fff;
          border: 0;
          height: 66px;
          font-weight: bold;
          font-size: 20px;
          font-family: 'proxima_nova_rgbold';
          text-align: left;
          padding: 0px 25px;
          box-shadow: 5px 5px 5px rgb(67 170 139 / 25%) !important;
          margin: 30px 0px;
          position: relative;
          z-index: 9;
          display: block;
          span {
            float: right;
            height: 24px;
            width: 24px;
            background-color: #fca834;
            border-radius: 50%;
            padding: 0px 7px;
            margin-top: 4px;
            box-shadow: none;
            display: flex;
            align-items: center;
            svg {
              margin-top: 0 !important;
            }
          }
          &::after,
          &::before {
            content: none;
          }
        }
        h3 {
          font-family: 'proxima_nova_rgbold';
          color: #454c4e;
          font-size: 28px;
          text-transform: uppercase;
          span {
            font-family: 'proxima_nova_rgbold';
            color: #43aa8b;
            font-size: 18px;
            text-decoration: underline;
            margin-left: 10px;
          }
        }
        p {
          font-family: 'proxima_nova_rgregular';
          color: #454c4e;
          font-size: 18px;
        }
        li {
          font-family: 'Proxima Nova';
          font-size: 16px;
          color: #454c4e;
          list-style: none;
          display: inline-block;
        }
        li.li-one {
          margin-right: 23px;
          position: relative;
        }
        li.li-one:after {
          content: '';
          position: absolute;
          right: -11px;
          height: 24px;
          width: 1px;
          background: #454c4e;
          top: 0px;
        }
        li.li-two {
          color: #fca834;
          span {
            height: 24px;
            width: 24px;
            background-color: #fca834;
            border-radius: 50%;
            position: relative;
            top: 8px;
            margin-right: 10px;
            display: inline-block;
            box-shadow: 0px 0px 2px #fe9402;
          }
        }
        span.span-last {
          display: block;
          text-align: center;
          color: #f9844a;
          font-family: 'Proxima Nova';
          font-size: 16px;
          position: relative;
          z-index: 9;
          svg {
            margin-top: -2px;
            margin-right: 10px;
          }
        }
        span.span-background {
          width: 130px;
          height: 130px;
          display: inline-block;
          position: absolute;
          background: #e3fff6;
          bottom: 0;
          left: 0;
          border-top-right-radius: 100px;
        }
      }
      .activity-main {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 0px 5px rgb(0 0 0 / 5%);
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        .act-left {
          background-color: #fca834;
          padding: 30px 0px 30px 20px;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          color: #fff;
          display: inline-block;
          width: 40%;
          vertical-align: top;
          .activity-icons {
            margin-top: -41.4px;
          }
          h3 {
            font-family: 'proxima_nova_rgbold';
            font-size: 28px;
            text-transform: uppercase;
            margin-bottom: 15px;
            color: #ffffff;
          }
          p {
            margin: 0;
            font-family: 'proxima_nova_rgregular';
            font-size: 18px;
            line-height: 28px;
          }
          li {
            color: #ab680b;
            font-size: 20px;
            font-family: 'proxima_nova_rgbold';
            list-style: none;
            text-transform: uppercase;
            margin-top: 15px;
          }
          .singing {
            margin-right: 15px;
          }
        }
        .act-right {
          flex: 0 0 auto;
          display: inline-block;
          width: 60%;
          background-image: url('../../../assets/images/activities.png');
          height: auto;
          background-size: cover;
          background-color: #fca834;
          vertical-align: top;
          color: #ffffff;
          .row {
            height: 100%;
          }
          h5 {
            color: #ffffff;
          }
          .act-right-common {
            height: 100%;
            align-items: center;
            display: flex;
            justify-content: center;
            color: #fff;
          }
        }
      }
      .notification-menu {
        position: absolute;
        background: #fff;
        box-shadow: 0px 5px 5px rgb(0 0 0 / 31%);
        top: 88px;
        right: 11px;
        border-radius: 10px;
        // padding: 20px;
        max-width: 480px;
        z-index: 999;
        display: none;
        h4 {
          font-family: 'proxima_nova_rgbold';
          font-size: 20px;
          color: #20282a;
          border-bottom: 1px solid #eaeaea;
          padding-bottom: 15px;
          margin-bottom: 20px;
        }
        .icon {
          height: 55px;
          width: 55px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #f3722c;
        }
        h5 {
          font-size: 16px;
          color: #2e2e2e;
          font-family: 'proxima_nova_rgbold';
          margin-bottom: 5px;
        }
        p {
          font-family: 'proxima_nova_rgregular';
          color: #2e2e2e;
          font-size: 14px;
          line-height: 18px;
        }
        span {
          font-family: 'Proxima Nova';
          font-size: 12px;
          color: #6f797b;
        }
        .notification-menu-right {
          text-align: right;
        }
        .row {
          margin-bottom: 15px;
        }
      }
      .notification-menu-wrapper {
        height: 400px;
        overflow-x: hidden;
        overflow-y: auto;
      }
      .notificationMenuInside {
        padding: 20px;
        .row {
          align-items: center;

          padding-top: 10px;
          padding-bottom: 10px;

          p {
            margin-bottom: 0px !important;
          }
        }
        .notificationActive {
          background: #f7f7f7;
        }
        .notificationInactive {
          background: #fff;
        }

        .no-notification {
          color: #454c4e;
          font-family: 'proxima_nova_rgregular';
          letter-spacing: 0;
          font-size: 24px;
          line-height: 28px;
          text-align: center;
        }
      }
      .notification-menu.true {
        display: block !important;
      }
      .brain-gym-popup {
        position: fixed;
        z-index: 9999;
        background: #e3fff6;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        padding: 40px;
        display: none;
        max-width: 700px;
        h3 {
          font-size: 40px;
        }
        p {
          font-family: 'Proxima Nova';
          color: #454c4e;
          font-size: 22px;
        }
        h4 {
          margin-top: 15px;
          color: #fca834;
          font-family: 'proxima_nova_rgbold';
          font-size: 36px;
        }
        span {
          font-size: 18px;
          font-family: 'proxima_nova_rgregular';
          color: #454c4e;
        }
        div.text-center {
          button {
            margin: 40px auto 20px auto !important;
            svg {
              color: #fff !important;
            }
            &::before {
              content: none !important;
            }
            &::after {
              content: none !important;
            }
          }
          p {
            margin-bottom: 0;
          }
          a {
            text-decoration: none;
          }
        }
        .btn-close {
          position: absolute;
          top: -15px;
          right: -10px;
          width: 40px;
          height: 40px;
          background: #fff !important;
          border: 0;
          padding: 0;
          border-radius: 5px;
          opacity: 1;
          svg {
            height: 30px;
            width: 30px;
          }
        }
        .btn-close:focus {
          outline: none;
          box-shadow: none;
        }
      }
      .brain-gym-popup.true {
        display: block !important;
      }
      .overlay {
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: #000000;
        opacity: 60%;
        z-index: 999;
        display: none;
      }
      .overlay.true {
        display: block !important;
      }
      .brain-gym-timer-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 35px;
        .timerValues {
          height: 43px;
          // width: 166px;
          color: #fca834;
          text-align: center;
          line-height: 43px;
          font-size: 36px;
          font-weight: bold;
          letter-spacing: 0;
          .values {
            color: #fca834;
            font-family: 'Proxima Nova';
            padding-inline: 8px;
            border-radius: 8px;
          }
          .active {
            background: #212529;
          }
        }
        button {
          background: none;
          border: none;
          padding: 0px;
        }
        .arrowButons {
          display: flex;
          flex-direction: column;
          margin-left: 15px;
          // height: 33px;
          // width: 24px;
          padding: 0px;
          .filled-up-arrow {
            height: 14px;
            width: 24px;
            margin-bottom: -10px;
          }
          .filled-Down-arrow {
            height: 14px;
            width: 24px;
          }
        }
      }
      .brain-gym-settimepopup {
        position: fixed;
        z-index: 9999;
        background: #e3fff6;
        top: 50%;
        left: 58%;
        transform: translate(-50%, -50%);
        width: 50%;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        padding: 40px;
        display: none;
        max-width: 700px;
        .brain-gym-settimepopup-mid-section {
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: center;
          .chestopen-img {
            width: 150px;
            height: 132px;
          }
          .brain-gym-settimepopup-mid-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 388px;
            height: 121px;
            h3 {
              font-size: 40px;
            }
            p {
              font-family: 'proxima_nova_rgregular';
              color: #454c4e;
              font-size: 22px;
              text-align: center;
            }
          }
        }
        h4 {
          margin-top: 15px;
          color: #fca834;
          font-family: 'proxima_nova_rgbold';
          font-size: 36px;
        }
        span {
          font-size: 18px;
          font-family: 'proxima_nova_rgregular';
          color: #454c4e;
        }
        div.text-center {
          button {
            margin: 40px auto 20px auto !important;
            svg {
              color: #fff !important;
            }
            &::before {
              content: none !important;
            }
            &::after {
              content: none !important;
            }
          }
          p {
            height: 20px;
            width: auto;
            color: #454c4e;
            font-family: 'proxima_nova_rgregular';
            font-size: 18px;
            letter-spacing: 0;
            line-height: 20px;
            padding: 0;
          }
          a {
            text-decoration: none;
          }
        }
        .btn-close {
          position: absolute;
          top: 5px;
          right: 5px;
          width: 40px;
          height: 40px;
          background: #fff !important;
          border: 0;
          padding: 0;
          border-radius: 5px;
          opacity: 1;
          svg {
            height: 30px;
            width: 30px;
          }
        }
        .btn-close:focus {
          outline: none;
          box-shadow: none;
        }
      }
      .brain-gym-settimepopup.true {
        display: block !important;
      }

      .reportContainer {
        width: 100%;
        // border: 1px solid red;
        border-radius: 10px;
        background-color: #ffffff;
        box-shadow: 0 12px 34px -10px rgba(0, 0, 0, 0.05);
        padding: 1.875rem 1.875rem 3.125rem 1.875rem;
        margin-top: 30px;
        position: relative;
        .ContainerSection1 {
          width: 100%;
          // border: 1px solid rgb(4, 0, 255);
          display: flex;
          justify-content: space-between;
          .reportSection {
            width: 25%;
            color: #454c4e;
            font-family: 'Proxima Nova';
            font-size: 28px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 34px;
            text-transform: uppercase;
          }
          .dropDownSection {
            display: flex;
            justify-content: space-between;
            width: 65%;
            // border: 1px solid rgb(255, 0, 0);
            .dropdown {
              height: 33px;
              width: 126px;
              border-radius: 5px;
              border: none;
              background-color: #fbfbfb;
              text-align: center;
              .dropdown-toggle {
                background: transparent;
                border: 0;
                cursor: pointer;
                font-size: 14px;
                font-family: 'Proxima Nova';
                color: #454c4e;
                // padding: 0;
                &::after {
                  position: relative;
                  top: 2px;
                  left: 3px;
                }
                &:focus {
                  box-shadow: none !important;
                }
              }
            }
            li {
              list-style: none;
              text-align: right;
              color: #43aa8b;
              font-family: 'proxima_nova_rgbold';
              text-transform: uppercase;
              text-decoration: underline 1px;
              font-size: 15px;
              text-align: center;
            }
          }
        }
        .containerSection2 {
          width: 100%;
          // border: 1px solid rgb(255, 0, 8);
          display: flex;
          justify-content: space-between;
          .ProgressbarSection {
            width: 52%;
            display: flex;
            flex-direction: column;
            align-items: center;
            // justify-content: space-evenly;
            .progress-bar-one {
              color: #f3722c;
              margin-top: 30px;
              width: 100%;
              .label-left {
                color: #454c4e;
                font-family: 'Proxima Nova';
                font-size: 20px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 20px;
                margin-bottom: 10px;
              }
              .label-right {
                color: #f3722c;
                font-family: 'Proxima Nova';
                font-size: 20px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 24px;
                text-align: right;
              }
              .progress {
                background-color: #ffe7d9;
                border-radius: 10px;
                height: 10px;
                .progress-bar {
                  background-color: #f3722c;
                  border-radius: 10px;
                  overflow: visible;
                  position: relative;
                  height: 10px;
                  // &:after {
                  //   content: '';
                  //   position: absolute;
                  //   background: #fff;
                  //   height: 1px;
                  //   width: 85.7%;
                  //   top: 2.3px;
                  //   left: 13px;
                  // }
                  // &:before {
                  //   content: '';
                  //   position: absolute;
                  //   background: #fff;
                  //   height: 5px;
                  //   width: 5px;
                  //   left: 4px;
                  //   border-radius: 50%;
                  //   top: 2.4px;
                  // }
                }
              }
            }
            .progress-bar-two {
              // color: #f9c74f;
              margin-top: 35px;
              width: 100%;
              .label-left {
                color: #454c4e;
                font-family: 'Proxima Nova';
                font-size: 20px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 20px;
                margin-bottom: 10px;
              }
              .label-right {
                color: #f9c74f;
                font-family: 'Proxima Nova';
                font-size: 20px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 24px;
                text-align: right;
              }
              .progress {
                background-color: #fff6e2;
                border-radius: 10px;
                height: 10px;
                .progress-bar {
                  background-color: #f9c74f;
                  border-radius: 10px;
                  overflow: visible;
                  position: relative;
                  height: 10px;
                  // &:after {
                  //   content: '';
                  //   position: absolute;
                  //   background: #fff;
                  //   height: 1px;
                  //   width: 85.7%;
                  //   top: 2.3px;
                  //   left: 13px;
                  // }
                  // &:before {
                  //   content: '';
                  //   position: absolute;
                  //   background: #fff;
                  //   height: 5px;
                  //   width: 5px;
                  //   left: 4px;
                  //   border-radius: 50%;
                  //   top: 2.4px;
                  // }
                }
              }
            }
          }
          .ReportChildSection {
            display: flex;
            width: 45%;
            // border: 1px solid rgb(0, 34, 255);
            align-items: center;
            justify-content: space-around;
            margin-top: 15px;
            .report-child {
              border-radius: 10px;
              text-align: center;
              padding: 15px 0px;
              height: 9.125rem;
              width: 11.438rem;
              border-radius: 20px;
              background-color: #f7f9ff;
              .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #e2e8ff;
                height: 50px;
                width: 50px;
                border-radius: 10px;
                margin: auto;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              h5 {
                font-size: 24px;
                font-family: 'proxima_nova_rgbold';
                color: #7d93f2;
                margin: 5px 0px;
                margin-top: 15px;
              }
              p {
                font-family: 'Proxima Nova';
                color: #454c4e;
                font-size: 16px;
                margin: 0;
              }
            }
          }
        }
      }

      .detailed-Report-popup {
        position: fixed;
        z-index: 9999;
        background: #fafafa;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        min-height: 100%;
        height: auto;
        // height: 90vh;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
        padding: 30px;
        display: none;

        .detailed-Report-container {
          display: flex;
          flex-direction: column;
          margin: 0 3.125rem;
          // overflow-y: scroll;
          .noDataFound {
            width: 100%;
            height: 80vh;
            color: #6f797b;
            font-family: 'Proxima Nova';
            background-color: #ffffff;
            box-shadow: 0 12px 34px -10px rgba(0, 0, 0, 0.05);
            border-radius: 5px;
            // border: 2px solid;
            // padding: 0px 5rem 0px 0px;
            margin-top: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .detailed-section1 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap-reverse;
            .last-weeks-report {
              height: 28px;
              width: 16.625rem;
              color: #20282a;
              font-family: 'proxima_nova_rgregular';
              font-size: 2rem;
              font-weight: bold;
              letter-spacing: 0;
              text-align: center;
            }
            .detailed-section1-child {
              display: flex;
              align-items: center;
              .rectangle {
                box-sizing: border-box;
                height: 50px;
                width: 11rem;
                border: 1px solid #454c4e;
                border-radius: 10px;
                background-color: #ffffff;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                text-decoration: none;
                margin-right: 30px;
                .dropdown {
                  height: 33px;
                  width: 126px;
                  // border-radius: 5px;
                  border: none;
                  background: transparent;
                  .dropdown-toggle {
                    background: transparent;
                    border: 0;
                    cursor: pointer;
                    font-size: 14px;
                    font-family: 'Proxima Nova';
                    color: #454c4e;
                    // padding: 0;
                    &::after {
                      position: relative;
                      top: 2px;
                      left: 3px;
                    }
                    &:focus {
                      box-shadow: none !important;
                    }
                  }
                }
              }
            }
            .close {
              width: 1.25rem;
              height: 1.25rem;
              position: absolute;
              top: 1.25rem;
              right: 1.25rem;
              // z-index: 99999;
            }
          }
          .detailed-section2 {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-around;
            min-height: 127px;
            width: 100%;
            border-radius: 20px;
            margin-top: 30px;
            background: linear-gradient(162.9deg, #39c399 0%, #00a070 100%);
            .noDataFound {
              color: rgb(255, 255, 255);
              width: 100%;
              height: 127px;
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              background: linear-gradient(162.9deg, #39c399 0%, #00a070 100%);
            }
            .detailed-section2-child1 {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: auto;
              height: 4.625rem;
              .logo {
                width: 2.813rem;
                height: 2.5rem;
              }
              .subSection {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 15px;
                .para1,
                .para2 {
                  margin-bottom: 0;
                  height: 20px;
                  color: #ffffff;
                  font-family: 'proxima_nova_rgregular';
                  font-size: 18px;
                  letter-spacing: 0;
                  line-height: 20px;
                }
              }
            }
          }
          .detailed-section3 {
            min-height: 127px;
            width: 100%;
            border-radius: 20px;
            background-color: #ffffff;
            margin-top: 30px;
            padding: 20px 30px 0px 30px;
            box-shadow: 0 12px 34px -10px rgba(0, 0, 0, 0.05);
            display: flex;
            flex-direction: column;
            align-items: center;
            .detailed-section3-box {
              width: 100%;
              display: flex;
              align-items: center;
              // flex-wrap: wrap;
              flex-flow: row wrap;
              justify-content: space-between;
              .Box1 {
                display: flex;
                align-items: center;
                flex-wrap: initial;
                // width: 15%;
                justify-content: space-around;
              }
              // .noDataFound {
              //   color: rgb(253, 145, 91);
              //   width: 100%;
              //   height: 127px;
              //   display: flex;
              //   align-items: center;
              //   flex-wrap: wrap;
              // }
              .detailed-section3-child {
                display: flex;
                align-items: center;
                justify-content: space-between;
                // width: 300px;
                border-radius: 20px;
                background-color: #e3fff6;
                padding: 18px 30px 16px 30px;
                height: 4.625rem;
                margin-bottom: 10px;
                // border: 2px solid;
                .logo {
                  max-width: 543px;
                  width: 2.813rem;
                  height: 2.5rem;
                }
                .subsection {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  margin-left: 15px;
                  // height: 50px;
                  .para1 {
                    margin-bottom: 0;
                    color: #20282a;
                    font-family: 'proxima_nova_rgregular';
                    font-size: 18px;
                    letter-spacing: 0;
                    line-height: 20px;
                    margin-top: 18pxs;
                  }
                  .para2 {
                    color: #43aa8b;
                    font-family: 'proxima_nova_rgregular';
                    font-size: 18px;
                    font-weight: 600;
                    letter-spacing: 0;
                    margin-block-end: -10px !important;
                  }
                }
              }
              .detailed-section3-child2 {
                height: 55px;
                width: 55%;
                max-width: 534px;
                display: flex;
                flex-direction: column;
                align-items: baseline;
                justify-content: space-around;
                margin-top: -10px;
                .noData {
                  font-size: 1.5rem;
                  color: #6f797b;
                  font-family: 'Proxima Nova';
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                .chapter {
                  height: 50px;
                  width: 400px;
                  color: #454c4e;
                  font-family: 'proxima_nova_rgregular';
                  font-size: 18px;
                  letter-spacing: 0;
                }
                .subSection {
                  display: flex;
                  justify-content: space-between;
                  flex-wrap: wrap;
                  .subTopic {
                    height: 28px;
                    border-radius: 14px;
                    background-color: #eef2ff;
                    text-align: center;
                    color: #7d93f2;
                    font-family: 'proxima_nova_rgregular';
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0;
                    padding: 3px 15px 6px 15px;
                    margin-bottom: 5px;
                  }
                }
              }
              .Box2 {
                display: flex;
                // align-items: center;
                // flex-wrap: wrap;
                width: 55%;
                max-width: 534px;
                align-items: baseline;
                // justify-content: space-around;
                .detailed-section3-child2 {
                  height: 55px;
                  width: 355px;
                  max-width: 534px;
                  display: flex;
                  flex-direction: column;
                  align-items: baseline;
                  margin-top: -10px;
                  .chapter {
                    height: 50px;
                    width: 400px;
                    color: #454c4e;
                    font-family: 'proxima_nova_rgregular';
                    font-size: 18px;
                    letter-spacing: 0;
                  }
                  .subSection {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    .subTopic {
                      height: 28px;
                      border-radius: 14px;
                      background-color: #eef2ff;
                      text-align: center;
                      color: #7d93f2;
                      font-family: 'proxima_nova_rgregular';
                      font-size: 16px;
                      font-weight: 600;
                      letter-spacing: 0;
                      padding: 3px 15px 6px 15px;
                      margin-bottom: 5px;
                    }
                  }
                }
              }

              .detailed-section3-child3 {
                height: 55px;
                width: 352px;
                display: flex;
                flex-direction: column;
                .chapter {
                  height: 20px;
                  // width: 148px;
                  color: #454c4e;
                  font-family: 'proxima_nova_rgregular';
                  font-size: 18px;
                  letter-spacing: 0;
                }
                .subSection {
                  display: flex;
                  align-items: center;
                  width: 100%;
                  flex-flow: row wrap;
                  .subTopic {
                    // width: 109px;
                    height: 28px;
                    border-radius: 14px;
                    background-color: #eef2ff;
                    text-align: center;
                    color: #7d93f2;
                    font-family: 'proxima_nova_rgregular';
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0;
                    padding: 3px 15px 6px 15px;
                    margin-right: 5px;
                    margin-bottom: 5px;
                  }
                }
              }
            }
            .detailed-section3-child4 {
              height: 1px;
              width: 98%;
              background-color: #d8d8d8;
              margin-top: 20px;
            }
            .next {
              height: 25px;
              width: 25px;
              margin: 12px;
            }
            .detailed-section5-child {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: auto;
              border-radius: 20px;
              background-color: #fff5e6;
              padding: 18px 30px 16px 30px;
              height: 4.625rem;
              margin-bottom: 10px;
              // border: 2px solid;
              .logo {
                max-width: 543px;
                width: 2.813rem;
                height: 2.5rem;
              }
              .subsection {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 15px;
                .para1 {
                  margin-bottom: 0;
                  color: #20282a;
                  font-family: 'proxima_nova_rgregular';
                  font-size: 18px;
                  letter-spacing: 0;
                  line-height: 20px;
                  margin-top: 18pxs;
                }
                .para2 {
                  color: #43aa8b;
                  font-family: 'proxima_nova_rgregular';
                  font-size: 18px;
                  font-weight: 600;
                  letter-spacing: 0;
                  margin-block-end: -10px !important;
                }
              }
            }
          }
        }
      }
      .detailed-Report-popup.true {
        display: block !important;
        overflow-y: scroll;
        height: 300px;
      }
      .error-part {
        background-color: #ffe7d9;
        border-radius: 10px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        margin-top: 30px;
        h4 {
          color: #f3722c;
          font-family: 'proxima_nova_rgbold';
          font-size: 20px;
          margin-bottom: 5px;
          line-height: 24px;
        }
        p {
          color: #454c4e;
          line-height: 19px;
          font-size: 16px;
          font-family: 'Proxima Nova';
          margin: 0;
          span {
            color: #f3722c;
          }
        }
        svg {
          float: right;
          font-size: 35px;
          color: #ffbc33;
        }
        button {
          width: 190px;
          height: 50px;
          border-radius: 10px;
          background-color: #f3722c;
          border: 0;
          color: #fff;
          margin: 0;
          font-family: 'proxima_nova_rgbold';
          font-size: 19px;
          float: right;
          span {
            text-decoration: underline 1px;
            font-size: 16px;
            color: #fff;
          }
        }
        .error-left {
          text-align: right;
          img {
            width: 44px;
            height: 40px;
          }
        }
      }
      .upcoming-class {
        margin-top: 30px;
        .upcoming-common {
          background-color: #ffffff;
          border-radius: 10px;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
          margin: auto;
          padding: 15px;
          height: 265px;
          h3 {
            font-size: 28px;
            color: #454c4e;
            font-family: 'proxima_nova_rgbold';
            text-transform: uppercase;
            margin-bottom: 0;
          }
        }
        .left {
          .noData-error {
            color: #6f797b;
            font-family: 'Proxima Nova';
            font-size: 1.375rem;
            align-items: center;
            margin-left: 43%;
          }
          .second {
            text-align: right;
            li {
              list-style: none;
              color: #43aa8b;
              font-family: 'proxima_nova_rgbold';
              text-transform: uppercase;
              text-decoration: underline;
            }
          }
          .icon {
            height: 60px;
            width: 60px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: -6px;
            svg {
              height: 30px;
              width: 30px;
            }
          }
          h5 {
            color: #454c4e;
            font-size: 20px;
            font-family: 'proxima_nova_rgbold';
            margin-bottom: 5px;
          }
          p {
            font-family: 'proxima_nova_rgregular';
            font-size: 15px;
            color: #454c4e;
            margin-bottom: 0;
          }
          span {
            font-size: 14px;
            font-family: 'Proxima Nova';
            color: #454c4e;
            margin-top: 15px;
            width: 180px;
            display: inline-block;
            img {
              margin-right: 5px;
              width: 21px;
              height: 24px;
            }
          }
          button {
            border: 0;
            width: 100%;
            height: 40px;
            border-radius: 5px;
            text-transform: uppercase;
            font-size: 14px;
            font-family: 'proxima_nova_rgbold';
            color: #b0b0b0;
            background-color: transparent;
          }
          button:hover {
            background-color: #e3fff6;
            color: #43aa8b;
          }
          .upcoming-shedule {
            margin-top: 30px;
            padding-right: 0;
            .icon {
              img {
                width: 100%;
                height: 100%;
              }
            }
            .upcoming-shedule-last {
              padding-right: 0;
            }
            .shedule-clock {
              padding-right: 0;
              img {
                margin-top: -5px;
              }
            }
          }
        }
        .right {
          h3 {
            margin-bottom: 15px;
          }
          .icon {
            height: 60px;
            width: 60px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              height: 100%;
              width: 100%;
            }
          }
          h5 {
            font-size: 24px;
            font-family: 'proxima_nova_rgbold';
          }
          p {
            font-family: 'proxima_nova_rgbold';
            font-size: 15px;
            color: 15px;
            color: #454c4e;
          }
          .upcoming-progress {
            margin-top: 10px;
          }
        }
      }
      .report {
        background-color: #fff;
        padding: 30px 20px;
        border-radius: 10px;
        box-shadow: 0px 0px 5px rgb(0 0 0 / 5%);
        margin: auto;
        margin-top: 30px;
        .report-left {
          h3 {
            font-family: 'proxima_nova_rgbold';
            color: #454c4e;
            font-size: 28px;
            text-transform: uppercase;
          }
          .progress-bar-one {
            color: #f3722c;
            margin-top: 30px;
            .progress {
              background-color: #ffe7d9;
              border-radius: 10px;
              height: 10px;
              .progress-bar {
                background-color: #f3722c;
                border-radius: 10px;
                overflow: visible;
                position: relative;
                &:after {
                  content: '';
                  position: absolute;
                  background: #fff;
                  height: 1px;
                  width: 85.7%;
                  top: 2.3px;
                  left: 13px;
                }
                &:before {
                  content: '';
                  position: absolute;
                  background: #fff;
                  height: 5px;
                  width: 5px;
                  left: 4px;
                  border-radius: 50%;
                  top: 2.4px;
                }
              }
            }
          }

          .progress-bar-two {
            color: #f9c74f;
            margin-top: 35px;
            .progress {
              background-color: #fff6e2;
              border-radius: 10px;
              height: 10px;
              .progress-bar {
                background-color: #f9c74f;
                border-radius: 10px;
                overflow: visible;
                position: relative;
                &:after {
                  content: '';
                  position: absolute;
                  background: #fff;
                  height: 1px;
                  width: 85.7%;
                  top: 2.3px;
                  left: 13px;
                }
                &:before {
                  content: '';
                  position: absolute;
                  background: #fff;
                  height: 5px;
                  width: 5px;
                  left: 4px;
                  border-radius: 50%;
                  top: 2.4px;
                }
              }
            }
          }

          .label-main {
            margin-bottom: 7px;
            .label-left {
              float: left;
              color: #454c4e;
            }
            .label-right {
              float: right;
              font-size: 14px;
              font-family: 'proxima_nova_rgbold';
            }
            &:after {
              content: '';
              display: block;
              clear: both;
            }
          }
        }
        .report-right {
          .dropdown-toggle {
            background: transparent;
            border: 0;
            cursor: pointer;
            font-size: 14px;
            font-family: 'Proxima Nova';
            color: #454c4e;
            // padding: 0;
            &::after {
              position: relative;
              top: 2px;
              left: 3px;
            }
            &:focus {
              box-shadow: none !important;
            }
          }

          .dropdown {
            position: relative;
            color: #333;
            cursor: default;
            height: 34px;
            width: auto;
            border: 0px solid #e0e0e0;
            border-radius: 0;
            background-color: #fbfbfb;
            a.dropdown-item {
              font-size: 14px;
              &:hover {
                background-color: #43aa8b;
                color: #fff;
              }
            }
          }
          li {
            list-style: none;
            text-align: right;
            color: #43aa8b;
            font-family: 'proxima_nova_rgbold';
            text-transform: uppercase;
            text-decoration: underline;
            font-size: 15px;
            text-align: center;
          }
          .report-child {
            background-color: #f7f9ff;
            border-radius: 10px;
            text-align: center;
            padding: 15px 0px;
            .icon {
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #e2e8ff;
              height: 50px;
              width: 50px;
              border-radius: 10px;
              margin: auto;
              img {
                width: 100%;
                height: 100%;
              }
            }
            h5 {
              font-size: 24px;
              font-family: 'proxima_nova_rgbold';
              color: #7d93f2;
              margin: 5px 0px;
              margin-top: 15px;
            }
            p {
              font-family: 'Proxima Nova';
              color: #454c4e;
              font-size: 16px;
              margin: 0;
            }
          }
          .report-bottom {
            padding-left: 25px;
            padding-top: 25px;
          }
        }
      }
      .myanswer {
        background-color: #fff;
        padding: 30px 20px;
        border-radius: 10px;
        box-shadow: 0px 0px 5px rgb(0 0 0 / 5%);
        margin: auto;
        margin-top: 30px;
        h3 {
          font-family: 'proxima_nova_rgbold';
          color: #454c4e;
          font-size: 28px;
          text-transform: uppercase;
        }
        .dropdown-toggle {
          background: transparent;
          border: 0;
          cursor: pointer;
          font-size: 14px;
          font-family: 'Proxima Nova';
          color: #454c4e;
          padding: 0;
          &::after {
            position: relative;
            top: 2px;
            left: 3px;
          }
          &:focus {
            box-shadow: none !important;
          }
        }

        .dropdown {
          a.dropdown-item {
            font-size: 14px;
            &:hover {
              background-color: #43aa8b;
              color: #fff;
            }
          }
        }
        .recharts-legend-wrapper {
          bottom: -35px !important;
        }
        .recharts-responsive-container {
          height: 330px !important;
          .recharts-bar-rectangle {
            border: 2px solid red;
          }
        }
        .progress-chart-top {
          margin-bottom: 25px;
          .dropdown {
            height: 33px;
            width: 126px;
            border-radius: 5px;
            border: none;
            background-color: #fbfbfb;
            text-align: center;
            .dropdown-toggle {
              background: transparent;
              border: 0;
              cursor: pointer;
              font-size: 14px;
              font-family: 'Proxima Nova';
              color: #454c4e;
              // padding: 0;
              &::after {
                position: relative;
                top: 2px;
                left: 3px;
              }
              &:focus {
                box-shadow: none !important;
              }
            }
          }
        }
      }

      // dashboardtable start
      .dashtable-main {
        box-sizing: border-box;
        margin-top: 30px;
        padding: 30px 20px;
        width: 100%;
        border-radius: 10px;
        background-color: #ffffff;
        box-shadow: 0 0.75rem 2.125rem -0.625rem rgba(0, 0, 0, 0.05);
        .dropdown-toggle {
          background: transparent;
          border: 0;
          cursor: pointer;
          font-size: 14px;
          font-family: 'Proxima Nova';
          color: #454c4e;
          // padding: 0;
          &::after {
            position: relative;
            top: 2px;
            left: 3px;
          }
          &:focus {
            box-shadow: none !important;
          }
        }

        .dropdown {
          a.dropdown-item {
            font-size: 14px;
            &:hover {
              background-color: #43aa8b;
              color: #fff;
            }
          }
        }
        .dashtable-heading {
          display: flex;
          justify-content: space-between;
          align-items: center;
          h3 {
            font-family: 'proxima_nova_rgbold';
            color: #454c4e;
            font-size: 28px;
            text-transform: uppercase;
          }
          div {
            color: #454c4e;
            font-family: 'Proxima Nova';
            font-size: 1.75rem;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 2.125rem;
          }
          .dropdown {
            height: 33px;
            width: 126px;
            border-radius: 5px;
            border: none;
            background-color: #fbfbfb;
            text-align: center;
            .dropdown-toggle {
              background: transparent;
              border: 0;
              cursor: pointer;
              font-size: 14px;
              font-family: 'Proxima Nova';
              color: #454c4e;
              // padding: 0;
              &::after {
                position: relative;
                top: 2px;
                left: 3px;
              }
              &:focus {
                box-shadow: none !important;
              }
            }
          }
        }
        .dtoggle-bar {
          display: flex;
          padding: 2px;
          // width: 38.5vw !important;
          width: fit-content !important;
          background-color: #fff5e6;
          margin-top: 10px;
          margin-bottom: 30px;
          border-radius: 1.5625rem;
          font-weight: bold;
        }

        .toggle-maths-btn {
          border: none;
          border-radius: 1.5625rem;
          color: #fca834;
          width: 19.25vw;
          height: 3.125rem;
        }
        .mathbtn-act {
          border-radius: 1.5625rem;
          background: white;
          box-shadow: 0px 5px 5px rgb(170 114 67 / 24%);
        }
        .mathbtn-un {
          border-top-left-radius: 1.5625rem;
          border-bottom-left-radius: 1.5625rem;
          border-top-right-radius: none;
          border-bottom-right-radius: none;
          background-color: #fff5e6;
        }

        .toggle-science-btn {
          border: none;
          color: #fca834;
          height: 3.125rem;
          width: 19.25vw;
        }
        .sciencebtn-act {
          border-radius: 1.5625rem;
          background: white;
          box-shadow: 0px 5px 5px rgb(170 114 67 / 24%);
        }
        .sciencebtn-un {
          border-top-right-radius: 1.5625rem;
          border-bottom-right-radius: 1.5625rem;
          border-top-left-radius: none;
          border-bottom-left-radius: none;
          background-color: #fff5e6;
        }
        .dtable-select {
          background-color: #fbfbfb;
          color: #454c4e;
          font-family: 'proxima_nova_rgregular';
          font-size: 0.875rem;
          font-weight: 600;
          letter-spacing: 0;
          text-align: center;
          border: none;
          cursor: pointer;
        }
        .noData-error {
          color: #6f797b;
          font-family: 'Proxima Nova';
          font-size: 1.375rem;
          align-items: center;
          margin-left: 43%;
        }
        .dtable-select:focus {
          outline: none;
        }
        .dtable {
          overflow-x: auto;
          overflow-y: auto;
          height: 50vh;
          background-color: white;
          table {
            width: 100%;
            tr th {
              color: #6f797b;
              font-family: 'Proxima Nova';
              font-size: 0.875rem;
              font-weight: 600;
              letter-spacing: 0;
              // text-align: left;
              text-align: center;
            }
            th:nth-child(1),
            th:nth-child(2) {
              text-align: center;
            }
            th,
            td {
              padding: 0.5rem;
              // text-align: left;
              text-align: center;
            }

            .rank {
              div {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 1.6875rem;
                width: 1.6875rem;
                background-color: #e2e2e2;
                color: white;
                border-radius: 50%;
                margin: auto;
              }
            }
            .name {
              color: #454c4e;
              font-family: 'proxima_nova_rgregular';
              font-size: 1rem;
              letter-spacing: 0;
              line-height: 1.125rem;
              text-align: center;
              // text-align: left;
            }
            .name-main {
              align-items: center;
              display: flex;
              justify-content: center;
              img.medal {
                width: 17px;
                height: 35px;
                margin-left: 25px;
              }
              .top-img {
                width: 27px;
                height: 17px;
                position: relative;
                display: block;
                left: 11px;
                top: 5px;
              }
            }
            .name-image {
              height: 3.125rem;
              width: 3.125rem;
              border-radius: 50%;
              margin-right: 0.7rem;
              border: 0.125rem solid #43aa8b;
            }
            .accuracy {
              color: #454c4e;
              font-family: 'proxima_nova_rgregular';
              font-size: 1rem;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 1.1875rem;
            }
            .overalltime {
              color: #454c4e;
              font-family: 'proxima_nova_rgregular';
              font-size: 1rem;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 1.1875rem;
              // text-align: left;
              text-align: center;
            }
            .answer {
              color: #454c4e;
              font-family: 'proxima_nova_rgregular';
              font-size: 1rem;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 1.1875rem;
              text-align: center;
              // text-align: left;
            }
            .coins {
              color: #454c4e;
              font-family: 'proxima_nova_rgregular';
              font-size: 1rem;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 1.1875rem;
              text-align: center;
              // text-align: left;
            }
            .coins-main {
              align-items: center;
              display: flex;
              justify-content: center;
            }
            .coins-medal {
              height: 1.5rem;
              width: 1.5rem;
              border-radius: 50%;
              background-color: #fca834;
              box-shadow: inset -0.125rem -0.0625rem 0.1875rem 0 #fe9402;
              margin-right: 7px;
            }
            .coins-text {
              height: 1.1875rem;
              width: 1.875rem;
              color: #454c4e;
              font-family: 'proxima_nova_rgregular';
              font-size: 1rem;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 1.1875rem;
            }
            .smartscore {
              color: #454c4e;
              font-family: 'proxima_nova_rgregular';
              font-size: 1rem;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 1.1875rem;
              text-align: center;
              // text-align: left;
            }
          }
        }
        .dtable::-webkit-scrollbar {
          width: 6px;
        }
        .dtable::-webkit-scrollbar-thumb {
          background: #cbcdcd;
          border-radius: 3px;
        }
        .dtable::-webkit-scrollbar-thumb:hover {
          background: #cbcdcd;
        }
      }

      // dashboardtable end

      .mysubject-top {
        background-color: #fff;
        border-radius: 80px 0 80px 80px;
        margin: auto;
        // margin-top: 30px;
        padding: 20px;
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.07);
        align-items: center;
        .mysubject-top-left {
          display: flex;
          align-items: center;
          h4 {
            font-size: 30px;
            color: #20282a;
            font-family: 'proxima_nova_rgbold';
            margin-bottom: 0;
          }
          img {
            width: 50px;
            height: 50px;
            margin-right: 10px;
          }
        }
        .mysub-progressbar-round {
          .mysub-progressbar-round-left-top {
            display: flex;
            justify-content: flex-end;
            .mysub-progressbar-round-left {
              height: 75px;
              width: 75px;
              display: flex;
              align-items: center;
            }
          }
          h5 {
            font-size: 18px;
            font-family: 'proxima_nova_rgbold';
            margin-bottom: 0;
          }
          .mysub-progressbar-round-right {
            display: flex;
            align-items: center;
          }
        }
      }
      .mysubject-list {
        margin-top: 30px;
        .dtoggle-bar {
          display: flex;
          padding: 2px;
          width: 75%;
          background-color: #fff5e6;
          margin-top: 10px;
          margin-bottom: 30px;
          border-radius: 1.5625rem;
          font-weight: bold;
          .toggle-maths-div {
            width: 33%;
          }
        }

        .toggle-maths-btn {
          border: none;
          color: #fca834;
          width: 100%;
          padding: 12px 20px;
          font-family: 'proxima_nova_rgregular';
        }
        .mathbtn-act {
          border-radius: 1.5625rem;
          background: white;
          box-shadow: 0px 5px 5px rgb(170 114 67 / 24%);
          font-family: 'Proxima Nova';
        }
        .mathbtn-un {
          border-top-left-radius: 1.5625rem;
          border-bottom-left-radius: 1.5625rem;
          border-top-right-radius: none;
          border-bottom-right-radius: none;
          background-color: #fff5e6;
        }
        .toggle-science-btn {
          border: none;
          color: #fca834;
          height: 3.125rem;
          width: 19.25vw;
        }
      }
      .mysubject-count-top {
        background-color: #ffffff;
        border-radius: 10px;
        margin: auto;
        height: 70px;
        align-items: center;
        font-family: 'proxima_nova_rgbold';
        padding: 0px 10px;
        .icon {
          background-color: #eaeaea;
          height: 34px;
          width: 34px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        span {
          text-transform: uppercase;
          display: block;
          font-size: 12px;
          color: #cfcfcf;
        }
        .icon {
          background-color: #454c4e;
          color: #fff;
        }
        select {
          border: 0;
          background: transparent;
          cursor: pointer;
        }
        select:focus {
          outline: none;
        }
        .right-part {
          text-align: right;
          .icon {
            float: right;
          }
        }
      }
      .mysubject-count {
        background-color: #ffffff;
        margin-top: 10px;
        border-radius: 10px;
        padding: 30px;
        .mysubject-count-common {
          padding-bottom: 10px;
          border-bottom: 1px solid #eaeaea;
          margin-bottom: 25px;
          .progress-main {
            align-items: center;
            .col-md-2 {
              padding-left: 0;
            }
            span {
              font-size: 12px;
              font-family: 'proxima_nova_rgbold';
            }
            span.green {
              color: #43aa8b;
            }
            span.orange {
              color: #f3722c;
            }
          }
          p {
            font-family: 'Proxima Nova';
            color: #454c4e;
            font-size: 16px;
            span {
              font-family: 'proxima_nova_rgregular';
            }
          }
          .sub-list {
            margin-left: -15px;
            li {
              list-style: none;
              color: #43aa8b;
              display: inline-block;
              font-family: 'proxima_nova_rgregular';
              font-size: 16px;
              border-right: 1px solid #43aa8b;
              padding: 0px 15px;
              margin-bottom: 15px;
              text-decoration: underline;
              cursor: pointer;
            }
            li:last-child {
              border-right: 0;
            }
          }

          h5 {
            color: #454c4e;
            font-family: 'Proxima Nova';
            font-size: 20px;
            button {
              padding: 10px;
              border: 1px solid;
              border-radius: 5px;
              /* background-color: #43aa8b; */
              color: #f3722c;
              transition: 0.3s ease;
            }
            button:hover {
              background: #f3722c;
              color: #ffff;
            }
          }
          select {
            font-size: 20px;
            color: #454c4e;
            font-family: 'proxima_nova_rgbold';
          }
          .progress {
            background-color: #ffe7d9;
            border-radius: 10px;
            height: 10px;

            .progress-bar {
              border-radius: 10px;
              position: relative;
              height: 100%;
              // &::before {
              //   content: '';
              //   position: absolute;
              //   background: #fff;
              //   height: 5px;
              //   width: 5px;
              //   left: 4px;
              //   border-radius: 50%;
              //   top: 2.4px;
              // }
              // &::after {
              //   content: '';
              //   position: absolute;
              //   background: #fff;
              //   height: 1px;
              //   width: 80%;
              //   top: 2.3px;
              //   left: 35px;
              // }
            }
          }
          .progress.green {
            background-color: #ffe7d9;
            .progress-bar {
              background-color: #43aa8b;
            }
          }
          .progress.orange {
            background-color: #ffe7d9;
            .progress-bar {
              background-color: #f3722c;
            }
          }
          .mysub-progress {
            .progress {
              background: none;
              .progress-bar {
                // &::before {
                //   content: '';
                //   position: unset;
                //   background: none;
                //   height: 0;
                //   width: 0;
                //   left: 0;
                //   border-radius: 0;
                //   top: 0;
                // }
                // &::after {
                //   content: '';
                //   position: unset;
                //   background: none;
                //   height: 0;
                //   width: 0;
                //   left: 0;
                //   border-radius: 0;
                //   top: 0;
                // }
              }
            }
            .progress.green {
              background: none;
            }
          }
        }
        div.mysubject-count-common:last-child {
          border-bottom: 0;
          margin-bottom: 0;
        }
      }
      .mysub-upcoming {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
        .mysub-upcoming-head {
          background-color: #7d93f2;
          color: #fff;
          display: flex;
          align-items: center;
          height: 60px;
          font-family: 'proxima_nova_rgbold';
          padding: 0px 15px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          cursor: pointer;
          h4 {
            font-size: 20px;
            width: 100%;
            margin: 0;
            img {
              width: 23px;
              height: 21px;
              margin-top: -7px;
              margin-left: 10px;
            }
            span {
              float: right;
              img {
                width: 10px;
                height: 18px;
                margin: 0;
              }
            }
          }
        }
        .mysub-upcoming-sub {
          background-color: #fff;
          padding: 30px 40px;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          display: none;
          h5 {
            color: #454c4e;
            font-family: 'proxima_nova_rgbold';
            font-size: 24px;
            margin-top: 10px;
          }
          p {
            margin: 0;
          }
          p.chapter-p {
            margin-bottom: 25px;
          }
          p.img-p {
            color: #454c4e;
            font-size: 16px;
            font-family: 'Proxima Nova';
            img {
              width: 21px;
              height: 24px;
              margin-right: 10px;
              margin-top: -5px;
            }
          }
          p.audio-video {
            img {
              width: 24px;
              height: 24px;
              margin-top: -2px;
            }
          }
          button {
            width: 275px;
            background-color: #dddddd;
            border-radius: 33.75px;
            color: #fff;
            border: 0;
            height: 55px;
            font-weight: bold;
            font-size: 20px;
            font-family: 'proxima_nova_rgbold';
            text-align: left;
            padding: 0px 20px;
            box-shadow: none;
            margin: 15px 0px;
            position: relative;
            z-index: 9;
            transition: 0.3s;
            opacity: 0.8;
            span {
              background-color: #c3c3c3;
              float: right;
              height: 24px;
              width: 24px;
              border-radius: 50%;
              padding: 0px 7px;
              margin-top: 4px;
              box-shadow: none;
              display: flex;
              align-items: center;
              margin: 0;
            }
          }
          button:hover {
            box-shadow: 0px 10px 10px rgba(125, 147, 242, 0.25) !important;
            opacity: 1;
            span {
              box-shadow: 5px 5px 5px rgb(0 0 0 / 24%) !important;
            }
          }
        }
      }
      .mysub-upcoming.true {
        .mysub-upcoming-sub {
          display: block;
        }
        h4 span {
          img {
            width: 18px !important;
            height: 10px !important;
          }
        }
      }
      .practice-test {
        margin-top: 20px;
        .mysub-upcoming-head {
          background-color: #43aa8b;
        }
        p {
          font-family: 'Proxima Nova';
          color: #20282a;
          font-size: 18px;
          line-height: 29px;
          text-align: left;
        }
        .mysub-upcoming-sub {
          padding-left: 15px;
          padding-right: 15px;
          select {
            width: 100%;
            margin: 20px 0px;
            color: #7d8283;
            height: 50px;
            border-radius: 10px;
            background-color: transparent;
            padding: 15px;
            border: 1px solid #e0e0e0;
            cursor: pointer;
          }
          select:focus {
            outline: none;
          }
        }
        button {
          background-color: #43aa8b !important;
          margin-top: 5px !important;
          margin-bottom: 0px !important;
          box-shadow: none;
          span {
            background-color: #fca834 !important;
          }
        }
      }
      .myconcept-main {
        margin-top: 30px;
        .myconcept-left {
          .myconcept-head-main {
            .myconcept-head-top {
              padding: 15px 20px;
              background-color: #f5a833;
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              .breadcrumb {
                margin: 0;
                a {
                  text-decoration: none;
                  color: #4a5052;
                  font-family: 'proxima_nova_rgregular';
                  font-size: 18px;
                }
                li.active {
                  color: #fff;
                  font-family: 'proxima_nova_rgregular';
                  font-size: 18px;
                }
                li.breadcrumb-item + .breadcrumb-item {
                  &::before {
                    content: url('../../../assets/images/pathrightsvg.svg');
                    top: 2px;
                    position: relative;
                  }
                }
              }
            }
            .myconcept-head-bottom {
              border-top: 1px solid #fff;
              padding: 20px;
              background-color: #f5a833;
              h4 {
                font-size: 26px;
                margin-bottom: 20px;
                color: #20282a;
                font-family: 'proxima_nova_rgbold';
              }
              li {
                list-style: none;
                display: inline-block;
                font-size: 16px;
                font-family: 'Proxima Nova';
                background: #fff;
                margin-right: 15px;
                border-radius: 5px;
                padding: 5px 10px;
                margin-bottom: 13px;
                cursor: pointer;
                color: #454c4e;
              }
              .head-li-active,
              li:hover {
                background-color: #43aa8b;
                color: #fff;
              }
            }
          }
        }
        .myconcept-right {
          background-color: #fff;
          border-radius: 10px;
          .video-top {
            background-color: #2e2e2e;
            color: #fff;
            padding: 20px 15px;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            h5 {
              margin-bottom: 7px;
              text-transform: uppercase;
              font-size: 18px;
              font-family: 'proxima_nova_rgbold';
            }
            p {
              margin-bottom: 0px;
              font-size: 16px;
              font-family: 'Proxima Nova';
            }
          }
          .video-bottom {
            padding: 20px 15px;
            .video-bottom-left {
              position: relative;
            }
            .row {
              margin-bottom: 20px;
              align-items: center;
            }
            h5 {
              font-size: 16px;
              margin-bottom: 5px;
              font-family: 'proxima_nova_rgbold';
              color: #2e2e2e;
            }
            p {
              color: #20282a;
              font-family: 'proxima_nova_rgregular';
              font-size: 15px;
              margin-bottom: 0;
            }
            img {
              width: 100%;
              height: auto;
            }
            span.play-img {
              position: absolute;
              top: 50%;
              left: 50%;
              -webkit-transform: translateX(-50%) translateY(-50%);
              transform: translateX(-50%) translateY(-50%);
              img {
                width: 18px;
                height: 24px;
              }
            }
          }
        }
      }

      .myconcept-desc {
        padding: 20px;
        background-color: #fff;
        .desc-top {
          .col-md-5 {
            text-align: right;
          }
          button {
            width: 100%;
            max-width: 243px;
            background-color: #43aa8b;
            border-radius: 33.75px;
            color: #fff;
            border: 0;
            height: 50px;
            font-weight: bold;
            font-size: 18px;
            font-family: 'proxima_nova_rgbold';
            text-align: left;
            padding: 0px 20px;
            box-shadow: none;
            position: relative;
            z-index: 9;
            display: block;
            float: right;
            margin-top: 15px;
            transition: 0.3s;
            opacity: 0.8;
            span {
              float: right;
              height: 22px;
              width: 22px;
              background-color: #fca834;
              border-radius: 50%;
              padding: 0px 7px;
              box-shadow: none;
              display: flex;
              align-items: center;
              margin-top: 2px;
            }
          }
          button:hover {
            box-shadow: 5px 5px 5px rgba(67, 170, 139, 0.25) !important;
            opacity: 1 !important;
            span {
              box-shadow: 5px 5px 5px rgba(56, 38, 38, 0.24) !important;
            }
          }
          p {
            margin-top: 20px;
            color: #20282a;
            font-size: 18px;
            font-family: 'proxima_nova_rgregular';
            line-height: 24px;
          }
          h5 {
            font-size: 20px;
            color: #20282a;
            font-family: 'proxima_nova_rgbold';
          }
          li {
            color: #43aa8b;
            list-style: none;
            font-size: 18px;
            text-decoration: underline;
            font-family: 'proxima_nova_rgbold';
            a {
              color: #43aa8b !important;
            }
            span.span-icon {
              height: 24px;
              width: 24px;
              background-color: #43aa8b;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              margin-right: 10px;
              position: relative;
              top: 8px;
              svg {
                color: #fff;
                height: 19px;
                width: 19px;
              }
            }
          }
        }
      }
      .myexam-main {
        .myExam-noData {
          width: 100%;
          text-align: center;
          color: #20282a;
          font-size: 20px;
          font-weight: 500;
        }
        .myexam-title {
          padding: 30px 0px 15px 0px;
          align-items: center;
          .custom-show-unattempted {
            color: #20282a;
            font-size: 28px;
            //font-family: 'Proxima Nova';
            // margin-top: 12px;
            // margin-left: 43px;
            margin-left: 72px;
            margin-right: -30px;
            .form-check-input {
              // background-color: #e3fff6;
              border-color: #43aa8b;
              box-shadow: none;
              margin-top: 7px;
            }
            .form-check-input:checked {
              background-color: #43aa8b;
              border-color: #43aa8b;
            }
            // .form-check-input:focus {
            //   background-image: inherit;
            // }
            label {
              font-size: 14px;
              font-family: 'Proxima Nova';
              line-height: 1.3;
              font-weight: 500;
            }
          }
          .myexam-title-left {
            float: left;
            margin-right: 15px;
          }
          img {
            width: 40px;
            height: 50px;
          }
          h3 {
            font-size: 25px;
            color: #20282a;
            font-family: 'proxima_nova_rgbold';
            margin-bottom: 0;
            // margin-top: 10px;
          }
          .dropdown {
            position: relative;
            //  color: #333;
            //  cursor: default;
            //  box-sizing: border-box;
            //  height: 66px;
            margin: 20px 0 0 0;
            width: 200px;
            border: 0px solid #e0e0e0;
            // border-radius: 10px;
            background: none !important;
          }
          .myexam-title-last {
            text-align: right;
            display: flex;
            justify-content: space-around;
            .dropdown-toggle {
              height: 45px;
              border: 1px solid #454c4e;
              border-radius: 10px;
              background-color: #fff;
              width: 175px;
              padding: 0px 10px;
              color: #000;
              cursor: pointer;
              text-align: left;
              position: relative;
              &::after {
                position: absolute;
                top: 50%;
                right: 10px;
              }
              &:focus {
                box-shadow: none !important;
              }
            }
            .dropdown {
              a.dropdown-item {
                font-size: 14px;
                &:hover {
                  background-color: #43aa8b;
                  color: #fff;
                }
              }
            }
          }
          .olymp-info_text {
            img {
              width: 19px;
              height: 19px;
            }
            .info_text {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 14px;
              color: #3a4145;
            }
          }
        }
        .subscribe-button {
          text-align: center;
          margin-top: 30px;
          button {
            // background-color: #43aa8b;
            // box-shadow: 0 12px 10px 0 rgba(67, 170, 139, 0.25);
            // border-radius: 33.75px;
            // color: #fff;
            // border: 0;
            // font-weight: bold;
            // font-size: 20px;
            // font-family: 'proxima_nova_rgbold';
            // display: inline-flex;
            // align-items: center;
            // text-align: center;
            // padding: 21px 20px;
            // width: 343px;
            // line-height: 24px;
            // justify-content: space-between;

            width: 363px;
            background-color: #43aa8b;
            border-radius: 33.75px;
            color: #fff;
            border: 0;
            height: 66px;
            font-weight: bold;
            font-size: 20px;
            font-family: 'proxima_nova_rgbold';
            text-align: center;
            padding: 0px 25px;
            box-shadow: none;
            transition: 0.3s;
            opacity: 0.8;
            span {
              float: right;
              height: 24px;
              width: 24px;
              background-color: #fca834;
              border-radius: 50%;
              padding: 0px 2px;
              box-shadow: 5px 5px 5px rgb(0 0 0 / 24%) !important;
              margin-top: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
        .myexam-main-common-left {
          // display: contents;
          // max-width: 95% !important;
        }
        .myexam-common-top {
          // width: 100%;
          // margin: auto;
          .myexam-common {
            .test-audio-video {
              color: #454c4e;
              font-family: 'proxima_nova_rgregular';
              font-size: 14px;
              text-align: center;
              margin-top: 7px;
            }
            background-color: #fff;
            padding: 20px;
            border-radius: 10px;
            margin-top: 30px;
            //margin-right: 30px;
            .myassignment-common-row {
              align-items: baseline;
            }
            button {
              background-color: #e3fff6;
              border: 0;
              height: 50px;
              display: flex;
              align-items: center;
              text-decoration: underline;
              text-align: center;
              color: #43aa8b;
              justify-content: center;
              border-radius: 10px;
              width: 100%;
              text-transform: uppercase;
              font-size: 16px;
              font-family: 'proxima_nova_rgbold';
            }
            .active-button {
              background-color: #e3fff6;
              border: 0;
              height: 50px;
              display: flex;
              align-items: center;
              text-decoration: underline;
              text-align: center;
              color: #43aa8b;
              justify-content: center;
              border-radius: 10px;
              width: 100%;
              text-transform: uppercase;
              font-size: 16px;
              font-family: 'proxima_nova_rgbold';
            }
            // .inactive-button {
            //   background-color: grey;
            //   border: 0;
            //   height: 50px;
            //   display: flex;
            //   align-items: center;
            //   text-decoration: underline;
            //   text-align: center;
            //   color: black;
            //   justify-content: center;
            //   border-radius: 10px;
            //   width: 100%;
            //   text-transform: uppercase;
            //   font-size: 16px;
            //   font-family: 'proxima_nova_rgbold';
            // }
            .myexam-common-left {
              display: contents;
              h4 {
                font-family: 'proxima_nova_rgbold';
                color: #454c4e;
                margin-bottom: 9px;
                padding-left: 15px;
                width: 38%;
              }
              h5 {
                font-size: 16px;
                color: #454c4e;
                padding-left: 15px;
              }
            }

            .myassignment-common-subject-left {
              h5 {
                //  height: 17px;
                // width: 205px;
                color: #454c4e;
                font-family: 'Proxima Nova';
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 17px;
              }
            }

            .myassignment-common-left {
              h5 {
                height: 19px;
                width: auto;
                color: #000000;
                font-family: 'Proxima Nova';
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 19px;
              }
            }
            .upcoming-classes {
              display: flex;
              .up-class {
                height: 19px;
                width: 120px;
                color: #000000;
                font-family: 'Proxima Nova';
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 19px;
                background-color: #e3fff6;
                margin-left: 10px;
                padding: 1px;
              }
            }

            .myassignment-common-right {
              text-align: right;
              p.p-one {
                height: 19px;
                width: 76px;
                padding: 5px 10px;
                display: inline-flex;
                margin-bottom: 3px;
                border-radius: 9.5px;
                background-color: #ffeaf1;
                display: inline-flex;
              }
              span.span-one {
                height: 14px;
                width: 52px;
                color: #fd2a5b;
                font-family: 'Proxima Nova';
                font-size: 12px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 14px;
              }
            }
            .myassignment-assigned-left {
              p.p-one {
                color: #454c4e;
                font-family: 'proxima_nova_rgregular';
                font-size: 14px;
              }
            }

            .myassignment-common-day-left {
              p.p-one {
                height: 29px;
                width: 139px;
                border-radius: 14.5px;
                background-color: #fff4e9;
              }
              span {
                color: #fca834;
                font-family: 'Proxima Nova';
                font-size: 16px;
                font-weight: 600;
                padding-left: 25px;
              }
              img {
                width: 10px;
              }
            }
            .myexam-common-right {
              text-align: right;
              p.p-one {
                background-color: #fff4e9;
                border-radius: 20px;
                padding: 5px 10px;
                display: inline-flex;
                margin-bottom: 10px;
                width: 100%;
                justify-content: space-around;
              }
              span {
                font-size: 14px;
                font-family: 'proxima_nova_rgregular';
                color: #454c4e;
              }
              span.span-one {
                color: #fca834;
                font-family: 'proxima_nova_rgbold';
              }
              span.span-two {
                margin: 0px 15px;
              }
              span.span-three {
                img {
                  margin-bottom: 4px;
                }
              }
              span.span-student {
                color: #43aa8b;
                font-family: 'proxima_nova_rgbold';
                font-size: 16px;
              }
            }
          }
        }
        .my-olympiad-exam-card-wrapper {
          width: 49.2%;
          font-family: 'Proxima Nova';
          font-style: normal;
          padding: 20px;
          margin-top: 30px;
          background: #ffffff;
          border: 2px solid #43aa8b;
          box-shadow: 0px 22px 24px -10px rgba(0, 0, 0, 0.03);
          border-radius: 10px;
          .exam-title {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            color: #454c4e;
            margin-left: 10px;
          }
          .subject-title {
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 20px;
            color: #20282a;
            margin-left: 10px;
          }
          .exam-type {
            background: #f2f3ff;
            border-radius: 20px;
            color: #20282a;
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 30px;
            width: auto;
            padding: 0 60px;
            height: 34px;
            white-space: nowrap;
          }
          .exam-date-wrapper {
            background: #f9f9f9;
            border-radius: 4px;
            padding: 10px 0;
            margin-right: 10px;
            .date {
              font-family: 'Proxima Nova';
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 14px;
              color: #454c4e;
              text-align: center;
              margin: 0;
            }
          }
          button {
            width: 100%;
            height: 50px;
            background: #43aa8b;
            border-radius: 30px;
            font-size: 16px;
            font-weight: 700;
            color: #ffffff;
            letter-spacing: 0;
            line-height: 18px;
          }
          .disabled-btn {
            background: #d6d6d6;
          }
        }
        .my-olympiad-exam-card-wrapper-yet {
          border: 2px solid #c2c2c2;
          box-shadow: 0px 22px 24px -10px rgba(0, 0, 0, 0.03);
        }

        .subscribe-for-more-exam {
          text-align: center;
          button {
            width: 343px;
            background-color: #43aa8b;
            border-radius: 33.75px;
            color: #fff;
            border: 0;
            height: 66px;
            font-weight: bold;
            font-size: 20px;
            font-family: 'proxima_nova_rgbold';
            text-align: left;
            padding: 0px 25px;
            box-shadow: none;
            transition: 0.3s;
            opacity: 0.8;
            margin: 50px 0px;
            position: relative;
            z-index: 9;
            margin-bottom: 20px;
            span {
              float: right;
              height: 24px;
              width: 24px;
              background-color: #fca834;
              border-radius: 50%;
              padding: 0px 7px;
              margin-top: 4px;
              box-shadow: 5px 5px 5px rgb(0 0 0 / 24%) !important;
              display: flex;
              align-items: center;
            }
          }
          button:hover {
            box-shadow: 5px 5px 5px rgb(67 170 139 / 25%) !important;
            opacity: 1;
          }
        }
        .myexam-tab {
          align-items: center;
          margin-top: 10px;
          margin-bottom: 30px;
          .dropdown {
            position: relative;
            //  color: #333;
            //  cursor: default;
            //  box-sizing: border-box;
            //  height: 66px;
            //  width: 540px;
            border: 0px solid #e0e0e0;
            // border-radius: 10px;
            background: none !important;
          }
          .myexam-tab-right {
            text-align: right;
            .dropdown-toggle {
              height: 45px;
              border: 1px solid #454c4e;
              border-radius: 10px;
              background-color: #fff;
              width: 175px;
              padding: 0px 10px;
              color: #000;
              cursor: pointer;
              text-align: left;
              position: relative;
              &::after {
                position: absolute;
                top: 50%;
                right: 10px;
              }
              &:focus {
                box-shadow: none !important;
              }
            }
            .dropdown {
              a.dropdown-item {
                font-size: 14px;
                &:hover {
                  background-color: #43aa8b;
                  color: #fff;
                }
              }
            }
          }
          .dtoggle-bar {
            display: flex;
            padding: 2px;
            width: 100%;
            background-color: #fff5e6;

            border-radius: 1.5625rem;
            font-weight: bold;
            .toggle-maths-div {
              width: 33%;
            }
          }

          .toggle-maths-btn {
            border: none;
            color: #fca834;
            width: 100%;
            padding: 12px 20px;
            font-family: 'proxima_nova_rgregular';
            font-size: 18px;
          }
          .mathbtn-act {
            border-radius: 1.5625rem;
            background: white;
            box-shadow: 0px 5px 5px rgb(170 114 67 / 24%);
            font-family: 'Proxima Nova';
          }
          .mathbtn-un {
            border-top-left-radius: 1.5625rem;
            border-bottom-left-radius: 1.5625rem;
            border-top-right-radius: none;
            border-bottom-right-radius: none;
            background-color: #fff5e6;
          }

          .toggle-science-btn {
            border: none;
            color: #fca834;
            height: 3.125rem;
            width: 19.25vw;
          }
        }
        .myexam-totalscore-top {
          border-radius: 20px !important;
          .total-score-left {
            background-color: #7d93f2;
            height: 95px;
            border-radius: 50px;
            padding: 0px 25px;
            color: #fff;
            width: 90%;
            align-items: center;
            max-width: 300px;
            img {
              width: 50px;
              height: 50px;
            }
            p {
              margin-bottom: 4px;
              font-family: 'proxima_nova_rgregular';
              font-size: 20px;
            }
            h5 {
              margin-bottom: 0;
              font-family: 'proxima_nova_rgbold';
              font-size: 20px;
              color: #ffffff;
            }
          }
        }
        .myexam-examlist {
          margin-top: 50px;
          .examlist-title {
            h4 {
              color: #20282a;
              font-size: 28px;
              font-family: 'Proxima Nova';
              margin-bottom: 15px;
            }
          }
          .examlist-common {
            border-radius: 21px;
            margin-bottom: 30px;
            padding: 25px 20px;
            cursor: pointer;
            background-color: #ffffff;
            &.active {
              background: linear-gradient(#39c399, #00a070) !important;
              p,
              span,
              h5,
              li {
                color: #fff !important;
              }
            }
            .examlist-common-bottom {
              display: flex;
              align-items: center;
            }
            .left-icon {
              margin-right: 15px;
            }
            .left-icon,
            .right-content {
              float: left;
            }
            .right-content {
              h5 {
                margin-bottom: 0;
              }
            }
            .examlist-common-one,
            .examlist-common-two {
              margin: auto;
              --bs-gutter-x: 0;
            }
            .examlist-common-one {
              border-bottom: 1px solid #ebebeb;
              margin-bottom: 20px;

              p {
                margin-bottom: 10px;
                span {
                  font-size: 15px;
                  color: #454c4e;
                  font-family: 'proxima_nova_rgregular';
                }
                span.span-bold {
                  font-family: 'proxima_nova_rgbold';
                }
              }

              .myexam-answer-right {
                h3 {
                  font-family: 'proxima_nova_rgregular';
                  font-size: 24px;
                  color: #20282a;
                  margin-bottom: 20px;
                }
                .exam-right-common {
                  background-color: #f4f7fc;
                  border-radius: 20px;
                  padding: 15px;
                  margin-bottom: 20px;

                  .exam-right-left {
                    img {
                      width: 35px;
                      height: 25px;
                      margin-top: 15px;
                    }
                  }
                  .exam-right-center {
                    .question {
                      display: flex;
                    }
                    h5 {
                      font-family: 'proxima_nova_rgregular';
                      color: #20282a;
                      font-size: 20px;
                    }
                    h5.answerRight {
                      color: #43aa8b;
                      font-family: 'proxima_nova_rgbold';
                      margin: 10px 0px 0px 0px;
                      display: flex;

                      span.text {
                        height: auto;
                        width: 227px;
                        color: #20282a;
                        font-family: 'proxima_nova_rgregular';
                        font-size: 20px;
                        letter-spacing: 0;
                        line-height: 22px;
                        margin-left: 10px;

                        img {
                          height: 80px;
                          width: 80px;
                          margin-left: 20px;
                        }
                      }
                      span.image {
                        height: 85px;
                        width: 227px;
                        color: #20282a;
                        font-family: 'proxima_nova_rgregular';
                        font-size: 20px;
                        letter-spacing: 0;
                        line-height: 22px;
                        margin: -10px 0px 20px 15px;

                        img {
                          height: 80px;
                          width: 80px;
                          margin-left: 20px;
                        }
                      }
                    }
                    h5.answerWrong {
                      color: #fd2b5c;
                      font-family: 'proxima_nova_rgbold';
                      margin: 10px 0px 0px 0px;
                      display: flex;

                      span.text {
                        height: auto;
                        width: 227px;
                        color: #20282a;
                        font-family: 'proxima_nova_rgregular';
                        font-size: 20px;
                        letter-spacing: 0;
                        line-height: 22px;
                        margin-left: 10px;

                        img {
                          height: 80px;
                          width: 80px;
                          margin-left: 20px;
                        }
                      }
                      span.image {
                        height: 85px;
                        width: 227px;
                        color: #20282a;
                        font-family: 'proxima_nova_rgregular';
                        font-size: 20px;
                        letter-spacing: 0;
                        line-height: 22px;
                        margin-left: 10px;

                        img {
                          height: 80px;
                          width: 80px;
                          margin-left: 20px;
                        }
                      }
                    }
                    .option-main {
                      // height: 0px;
                      .show {
                        height: auto !important;
                      }
                      .question-options-wrapper.selectTyleType {
                        display: grid;
                        grid-template-columns: 2fr 2fr;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: 1fr 1fr;
                        grid-column-gap: 20px;
                        width: 70%;
                        width: 100%;
                      }
                      .option-wrapper-tile-type {
                        margin-bottom: 30px;
                        position: relative;
                        width: 40%;
                        height: auto !important;
                        min-height: 70px;
                        padding: 0 !important;
                      }
                      .option-wrapper-tile-type .optionsbtn {
                        height: 100%;
                        width: 80%;
                        left: 0px;
                        border: 0;
                        border-radius: 20px;
                        margin: 0 0 0 40px;
                        background: transparent;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      }
                      .questionHtlm-right {
                        padding: 20px 20px 20px 0px;
                        img {
                          height: 80px;
                          width: 80px;
                          object-fit: cover;
                        }
                        p {
                          margin-bottom: 0;
                        }
                      }
                      span.opti {
                        margin-right: 10px;
                      }
                      span {
                        display: inline-block !important;
                        //width: 85% !important;
                        text-align: left;
                        font-family: 'proxima_nova_rgregular';
                      }
                      div.icon--check-wrapper {
                        display: inline-block !important;
                        svg {
                          height: 25px;
                          width: 25px;
                          // margin-top: -5px;
                        }
                      }
                    }
                  }
                  .questionHtlm-left {
                    width: 80%;
                    display: inline-block;
                    img {
                      height: 60px;
                      width: 60px;
                    }
                  }
                  .questionHtlm-right {
                    padding: 20px 20px 20px 0px;
                    width: 80%;
                    text-align: left;
                    display: inline-block;
                  }
                }
                .question-options-wrapper {
                  margin-top: 15px;
                  display: block;
                  margin-top: 24px;
                }
                // .question-options-wrapper {
                .option-wrapper-tile-type.true {
                  background-color: #00a070 !important;
                  .optionsbtn {
                    color: #fff;
                  }
                  span {
                    color: #fff;
                  }
                  .icon--check-wrapper {
                    background-color: #fff !important;
                    svg {
                      fill: #00a070 !important;
                    }
                  }
                }
                // }
                .question-box .image {
                  height: auto;
                  width: auto;
                  cursor: pointer;
                }
                .drag-detail-table {
                  border: 1px solid #dddddd;
                  border-collapse: collapse;
                  max-width: 100%;
                  background-color: #fff;
                  width: 100%;
                  margin-bottom: 20px;
                  th,
                  td {
                    border: 1px solid #dddddd;
                    padding: 10px;
                    font-size: 15px;
                  }
                  th {
                    font-weight: bold;
                  }
                  img {
                    height: 80px !important;
                    width: 80px !important;
                  }
                }
              }

              .option-main.show {
                height: auto !important;
              }
              .option-top {
                display: flex;
                div {
                  width: 100px;
                  height: auto;

                  img {
                    width: 56px;
                    height: 56px;
                  }
                  span {
                    width: 34px;
                    height: 34px;
                    border-radius: 50%;
                    background-color: #a0a4a6;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    font-family: 'proxima_nova_rgbold';
                  }
                }
              }
              span.index {
                font-family: 'proxima_nova_rgbold';
              }
            }
            .exam-right-right {
              button {
                cursor: pointer;
                background-color: transparent;
                border: 0;
              }
              img {
                height: 20px;
                width: 20px;
              }
            }
            .examlist-last {
              text-align: right;
              li {
                font-family: 'proxima_nova_rgbold';
                font-size: 16px;
                color: #43aa8b;
                text-transform: uppercase;
                list-style: none;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
          .examlist-common-two {
            .examlisthand img {
              height: 40px;
              width: 40px;
            }
            .examlistidea img {
              height: 40px;
              width: 40px;
            }
            .examlistclipboard img {
              width: 32px;
              height: 40px;
            }
            .examlistglass img {
              width: 34px;
              height: 40px;
            }
            p {
              margin-bottom: 3px;
              font-family: 'proxima_nova_rgregular';
              font-size: 18px;
              color: #454c4e;
            }
            h5 {
              color: #20282a;
              font-family: 'Proxima Nova';
              font-size: 18px;
            }
          }
        }
        .examlist-common:hover,
        .first-record {
          background: linear-gradient(#39c399, #00a070) !important;
          p,
          span,
          h5,
          li {
            color: #fff !important;
          }
        }
      }
    }
    .myclasses-main {
      .myexam-tab {
        .dtoggle-bar {
          width: 100%;
        }
      }
    }
    .myexam-answer-main-top {
      position: fixed;
      width: 100%;
      background: #fff;
      top: 0;
      left: 0;
      padding: 35px;
      height: 100vh;
      z-index: 9999;
      padding-top: 70px;
      padding-right: 20px;
      padding-bottom: 0;
      .close-top {
        height: 70px;
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        background: #fff;
        text-align: right;
        .close-btn {
          background-color: transparent;
          border: 0;
          position: absolute;
          right: 4%;
          top: 25%;
          img {
            height: 20px;
            width: 20px;
          }
        }
      }
    }
    .myexam-answer-main {
      width: 100%;
      margin: auto;
      overflow-y: scroll;
      height: 100%;
      padding-bottom: 30px;
      padding-right: 20px;
      .myexam-answer-left {
        padding: 50px 30px;
        background: linear-gradient(#39c399, #00a070);
        border-radius: 0 100px 0 100px;
        color: #ffffff;
        .row {
          margin-bottom: 40px;
        }
        .row:last-child {
          margin-bottom: 0px;
        }
        p {
          font-family: 'proxima_nova_rgregular';
          font-size: 20px;
          margin-bottom: 5px;
        }
        h5 {
          font-size: 19px;
          font-family: 'proxima_nova_rgbold';
          color: #ffffff;
          overflow-wrap: break-word;
        }
        .Olympiad {
          img {
            width: 48px;
            height: 50px;
          }
        }
        .Total {
          img {
            width: 50px;
            height: 50px;
          }
        }
        .Date {
          img {
            width: 45px;
            height: 50px;
          }
        }
        .Time {
          img {
            width: 43px;
            height: 51px;
          }
        }
        .Accuracy {
          img {
            width: 47px;
            height: 50px;
          }
        }
        .Right {
          img {
            width: 36px;
            height: 50px;
          }
        }
      }

      .myexam-answer-right {
        h3 {
          font-family: 'proxima_nova_rgregular';
          font-size: 24px;
          color: #20282a;
          margin-bottom: 20px;
        }
        .exam-right-common {
          background-color: #f4f7fc;
          border-radius: 20px;
          padding: 15px;
          margin-bottom: 20px;

          .exam-right-left {
            img {
              width: 35px;
              height: 25px;
              margin-top: 15px;
            }
          }
          .exam-right-center {
            .question {
              display: flex;
            }
            h5 {
              font-family: 'proxima_nova_rgregular';
              color: #20282a;
              font-size: 20px;
            }
            h5.answerRight {
              color: #43aa8b;
              font-family: 'proxima_nova_rgbold';
              margin: 10px 0px 0px 0px;
              display: flex;

              span.text {
                // height: 50px;
                width: 227px;
                color: #20282a;
                font-family: 'proxima_nova_rgregular';
                font-size: 20px;
                letter-spacing: 0;
                line-height: 22px;
                margin-left: 10px;

                img {
                  height: 80px;
                  width: 80px;
                  margin-left: 20px;
                }
              }
              span.image {
                height: 85px;
                width: 227px;
                color: #20282a;
                font-family: 'proxima_nova_rgregular';
                font-size: 20px;
                letter-spacing: 0;
                line-height: 22px;
                margin-left: 10px;

                img {
                  height: 80px;
                  width: 80px;
                  margin-left: 20px;
                }
              }
            }
            h5.answerWrong {
              color: #fd2b5c;
              font-family: 'proxima_nova_rgbold';
              margin: 10px 0px 0px 0px;
              display: flex;

              span.text {
                height: auto;
                //width: 227px;
                color: #20282a;
                font-family: 'proxima_nova_rgregular';
                font-size: 20px;
                letter-spacing: 0;
                line-height: 22px;
                margin-left: 10px;

                .fillInAnswers {
                  color: #20282a;
                  font-family: 'proxima_nova_rgregular';
                  font-size: 20px;
                  letter-spacing: 0;
                  line-height: 22px;
                  margin-right: 20px;
                }

                img {
                  height: 80px;
                  width: 80px;
                  margin-left: 20px;
                }
              }
              span.image {
                height: 85px;
                width: 227px;
                color: #20282a;
                font-family: 'proxima_nova_rgregular';
                font-size: 20px;
                letter-spacing: 0;
                line-height: 22px;
                margin-left: 10px;

                img {
                  height: 80px;
                  width: 80px;
                  margin-left: 20px;
                }
              }
            }
            .option-main {
              // height: 0px;
              .show {
                height: auto !important;
              }
              .question-options-wrapper.selectTyleType {
                display: grid;
                grid-template-columns: 2fr 2fr;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr 1fr;
                grid-column-gap: 20px;
                width: 70%;
                width: 100%;
              }
              .option-wrapper-tile-type {
                margin-bottom: 30px;
                position: relative;
                width: 40%;
                height: auto !important;
                min-height: 70px;
                padding: 0 !important;
              }
              .option-wrapper-tile-type .optionsbtn {
                height: 100%;
                width: 80%;
                left: 0px;
                border: 0;
                border-radius: 20px;
                margin: 0 0 0 40px;
                background: transparent;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .questionHtlm-right {
                padding: 20px 20px 20px 0px;
                img {
                  height: 80px;
                  width: 80px;
                  object-fit: cover;
                }
                p {
                  margin-bottom: 0;
                }
              }
              span.opti {
                margin-right: 10px;
              }
              span {
                display: inline-block !important;
                //width: 85% !important;
                text-align: left;
                font-family: 'proxima_nova_rgregular';
              }

              span.fillInAnswers {
                color: #20282a;
                font-family: 'proxima_nova_rgregular';
                font-size: 20px;
                letter-spacing: 0;
                line-height: 22px;
                margin-right: 20px;
              }
              div.icon--check-wrapper {
                display: inline-block !important;
                svg {
                  height: 25px;
                  width: 25px;
                  // margin-top: -5px;
                }
              }
            }
          }
          .questionHtlm-left {
            width: 80%;
            display: inline-block;
            img {
              height: 60px;
              width: 60px;
            }
          }
          .questionHtlm-right {
            padding: 20px 20px 20px 0px;
            width: 80%;
            text-align: left;
            display: inline-block;
          }
        }
        .question-options-wrapper {
          margin-top: 15px;
          display: block;
          margin-top: 24px;
          .option-wrapper-solution {
            padding: 20px 20px 20px 10px;
            border: 3px #01a171 solid;
            border-radius: 20px;
            background-color: #e3fff6;
            margin-top: 10px;
            font-family: 'proxima_nova_rgregular';
            color: #20282a;
            font-size: 20px;
            h4 {
              font-weight: 600;
            }
          }
        }
        // .question-options-wrapper {
        .option-wrapper-tile-type.true {
          background-color: #00a070 !important;
          .optionsbtn {
            color: #fff;
          }
          span {
            color: #fff;
          }
          .icon--check-wrapper {
            background-color: #fff !important;
            svg {
              fill: #00a070 !important;
            }
          }
        }
        // }
        .question-box .image {
          height: auto;
          width: auto;
          cursor: pointer;
        }
        .drag-detail-table {
          border: 1px solid #dddddd;
          border-collapse: collapse;
          max-width: 100%;
          background-color: #fff;
          width: 100%;
          margin-bottom: 20px;
          th,
          td {
            border: 1px solid #dddddd;
            padding: 10px;
            font-size: 15px;
          }
          th {
            font-weight: bold;
          }
          img {
            height: 80px !important;
            width: 80px !important;
          }
        }
      }

      .option-main.show {
        height: auto !important;
      }
      .option-top {
        display: flex;
        div {
          width: 100px;
          height: auto;

          img {
            width: 56px;
            height: 56px;
          }
          span {
            width: 34px;
            height: 34px;
            border-radius: 50%;
            background-color: #a0a4a6;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-family: 'proxima_nova_rgbold';
          }
        }
      }
      span.index {
        font-family: 'proxima_nova_rgbold';
      }
    }
    .exam-right-right {
      button {
        cursor: pointer;
        background-color: transparent;
        border: 0;
      }
      img {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.myexam-answer-main::-webkit-scrollbar {
  width: 6px;
}
.myexam-answer-main::-webkit-scrollbar-thumb {
  background: #cbcdcd;
  border-radius: 3px;
}
.myexam-answer-main::-webkit-scrollbar-thumb:hover {
  background: #cbcdcd;
}
.myclass-reschedule-rectanle {
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 12px 34px -10px rgba(0, 0, 0, 0.05);
  margin-top: 20px;
  padding: 30px;

  .myclass-bell {
    margin-left: 30px;
    margin-top: 60px;
  }
  .myclass-inside-rectangle {
    .bell-part {
      height: 100%;
      display: flex;
      align-items: center;
      img {
        float: left;
        margin-top: -15px;
        margin-right: 20px;
      }
    }
    .myclass-reschedule {
      color: #28b98d;
      font-family: 'proxima_nova_rgbold';
      font-size: 32px;
      font-weight: bold;
    }
    .myclass-subject {
      border-radius: 20px;
      background-color: #e3fff6;
      box-shadow: 0 1px 34px -6px rgba(154, 154, 154, 0.1);
      padding: 30px;
      .myclass-inside-rectangle {
        .shape {
          height: 82px;
          width: 82px;
          border-radius: 15px;
          background-color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          float: left;
          margin-right: 20px;
        }
        .subject {
          float: left;

          .algebra {
            color: #000000;
            font-family: 'Proxima Nova';

            margin-bottom: 0;
          }
          .math {
            color: #000000;
            font-family: 'Proxima Nova';
            font-size: 14px;
            margin-bottom: 8px;
          }
          .previous {
            border-radius: 8px;
            background-color: #fff5e6;
            .span-one {
              color: #454c4e;
              font-family: 'proxima_nova_rgregular';
              margin-left: 15px;
              font-size: 14px;
            }
            .span-two {
              color: #fca834;
              font-family: 'proxima_nova_rgbold';
              font-size: 14px;
              font-weight: 600;
              margin-left: 16px;
              margin-right: 16px;
            }
          }
          .reschedule {
            border-radius: 8px;
            background-color: #ffffff;
            margin-bottom: 0;
            .span-one {
              color: #454c4e;
              font-family: 'proxima_nova_rgregular';
              margin-left: 15px;
              font-size: 14px;
            }
            .span-two {
              color: #28b98d;
              font-family: 'proxima_nova_rgbold';
              font-size: 14px;
              margin-left: 16px;
            }
          }
        }
      }
      .myclass-inside-rectangle::after {
        content: '';
        display: block;
        clear: both;
      }
    }
  }
}
.renewsub-main {
  .renewsub-head {
    margin: 35px 0px;
    h2 {
      color: #20282a;
      font-family: 'proxima_nova_rgbold';
      font-size: 32px;
    }
    .breadcrumb {
      .breadcrumb-item {
        font-family: 'proxima_nova_rgregular';
        font-size: 14px;
        a {
          color: #4a5052;
          text-decoration: none;
          font-size: 14px;
        }
      }
      .breadcrumb-item.active {
        color: #fca834;
        position: relative;
      }
    }
    .breadcrumb-item + .breadcrumb-item {
      &::before {
        content: url('../../../assets/images/rightpathsvg.svg');
        position: relative;
        top: 2px;
      }
    }
  }
  .renew-left-main {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    padding: 25px;
    h3 {
      color: #20282a;
      font-size: 24px;
      font-family: 'Proxima Nova';
    }
    h3.curricular-title {
      margin-top: 35px;
      margin-bottom: 15px;
    }
    .dropdown-common {
      label {
        font-family: 'proxima_nova_rgregular';
        color: #7d8283;
        font-size: 14px;
        line-height: 15px;
        margin-bottom: 10px;
      }
      .dropdown-main {
        position: relative;
        .select {
          width: 100%;
          border: 1px solid #e0e0e0;
          border-radius: 10px;
          height: 66px;
          background-color: #fff;
          padding: 0px 20px;
          cursor: pointer;
          display: flex;
          align-items: center;
          position: relative;
          margin-bottom: 20px;
          font-family: 'Proxima Nova';
          svg {
            position: absolute;
            right: 20px;
          }
          img {
            width: 30px;
            height: 20px;
            margin-right: 20px;
          }
        }
        .dropdown {
          position: absolute;
          width: 100%;
          background-color: #fff;
          z-index: 9;
          img {
            width: 30px;
            height: 20px;
            margin-right: 20px;
          }
        }
        .dropdown.false {
          display: none;
        }
        .dropdown.true {
          display: block;
          border: 1px solid #e0e0e0;
          border-radius: 10px;
          margin-top: 5px;
          div {
            cursor: pointer;
            height: 80px;
            display: flex;
            align-items: center;
            padding: 20px;
            font-family: 'Proxima Nova';
          }
          div:hover {
            background-color: #e3fff6;
            color: #43aa8b;
            border-radius: 10px 10px 0 0;
          }
        }
      }
    }
    .subject-list-main {
      label {
        font-family: 'proxima_nova_rgregular';
        color: #7d8283;
        font-size: 14px;
        line-height: 15px;
        margin-bottom: 10px;
      }
      .subject-list {
        border: 1px solid #7d8283;
        display: inline-block;
        padding: 15px;
        width: 175px;
        border-radius: 33px;
        margin-right: 10px;
        margin-bottom: 15px;
        color: #000;
        background-color: #fafafa;
        .round {
          position: relative;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          display: inline-block;
          vertical-align: top;
          margin-right: 35px;
          input {
            border-radius: 50%;
            margin-right: 10px;
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }
          input:checked ~ .checkmark {
            background-color: #43aa8b;
          }
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: #efefef;
            border-radius: 50%;
          }
          .checkmark:after {
            content: '';
            position: absolute;
          }
          .checkmark:after {
            left: 10px;
            top: 5px;
            width: 6px;
            height: 12px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }
        }
      }
      .subject-list.active {
        border: 1px solid #43aa8b;
        background-color: #fff;
        color: #43aa8b;
      }
    }
  }
  .renew-right-main {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    padding: 25px;
    h3 {
      color: #20282a;
      font-size: 24px;
      margin-bottom: 20px;
    }
    .payment-data {
      margin-bottom: 25px;
      label {
        margin-top: 0;
        font-size: 16px;
        display: block;
        color: #7d8283;
        font-family: 'Proxima Nova';
        line-height: 22px;
        margin-bottom: 5px;
      }
      h4 {
        font-size: 18px;
        color: #454c4e;
      }
      p {
        color: #454c4e;
        font-size: 18px;
        margin-bottom: 0;
        margin-top: 25px;
        text-align: right;
      }
    }
    .total-data {
      background-color: #e3fff6;
      border-radius: 0 0 10px 10px;
      height: 70px;
      display: flex;
      align-items: center;
      padding: 0px 30px;
      margin: 0 -25px;
      font-family: 'Proxima Nova';
      h4 {
        color: #43aa8b;
        font-size: 24px;
        margin-bottom: 0;
      }
      h3 {
        font-size: 30px;
        margin-bottom: 0;
        color: #43aa8b;
        text-align: right;
      }
      div {
        padding: 0;
      }
    }
    button.payment-process {
      background-color: #43aa8b;
      box-shadow: none;
      border-radius: 33.75px;
      color: #fff;
      border: 0;
      width: 273px;
      height: 66px;
      font-size: 20px;
      font-family: 'proxima_nova_rgbold';
      text-align: left;
      padding: 0px 25px;
      margin-top: 25px;
      margin-bottom: 20px;
      transition: 0.3s;
      opacity: 0.8;
      span {
        float: right;
        height: 24px;
        width: 24px;
        background-color: #fca834;
        border-radius: 50%;
        padding: 0px 7px;
        margin-top: 4px;
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    button.payment-process:hover {
      box-shadow: 5px 5px 5px rgb(67 170 139 / 25%) !important;
      opacity: 1;
      span {
        box-shadow: 5px 5px 5px rgb(0 0 0 / 24%) !important;
      }
    }
  }
}
.dashboard-main.open {
  .dashboard-left {
    width: 6%;
    .logo-profile {
      display: none;
      img {
        display: none;
      }
    }
    .dashboard-profile {
      margin-top: 55px;
      margin-bottom: 0;
      .dashboard-profile-left {
        width: 100%;
      }
      .dashboard-profile-center {
        h5 {
          display: none;
        }
        p {
          display: none;
        }
      }
      .dashboard-profile-right {
        width: 100%;
        svg {
          margin-top: 15px;
        }
      }
    }
    .dash-list-common {
      padding: 10px;
      h5 {
        display: none;
      }
      ul {
        margin-left: 0;
        text-align: -webkit-center;
        li {
          font-size: 0 !important;
          img {
            display: block;
            margin-right: 0;
          }
        }
      }
    }
  }
  .dashboard-right {
    width: 93%;
    left: 6%;
  }
}

@media only screen and (max-width: 1285px) {
  .dashboard-right {
    .report {
      .report-right {
        .report-one,
        .report-two {
          width: 50%;
          text-align: center;
        }
        .report-three {
          width: 100%;
          text-align: center;
          .report-three-bth {
            width: 200px;
          }
          li {
            margin: 20px 0px;
            width: 200px;
          }
        }
        .report-bottom {
          h5 {
            font-size: 20px;
          }
          p {
            font-size: 14px !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1270px) {
  .dashboard-main
    .row-main
    .dashboard-right
    .dashboard-right-search
    .dash-right-search-common
    p {
    font-size: 13px;
    span.xp {
      height: 15px;
      width: 15px;
      top: 4px;
    }
  }
  .dashboard-main .row-main .dashboard-right .brain-gym-main li {
    font-size: 13px;
  }
  .dashboard-main .row-main .dashboard-right .brain-gym-main li.li-one {
    margin-right: 15px;
    &::after {
      right: -8px;
      height: 20px;
    }
  }
  .dashboard-main .row-main .dashboard-right .brain-gym-main li.li-two span {
    height: 10px;
    width: 10px;
    top: 2px;
  }
}

@media only screen and (max-width: 1235px) {
  .myclasses-main {
    .reshedule-left,
    .reshedule-right {
      width: 100%;
    }
    .reshedule-left {
      margin-bottom: 30px;
      img {
        margin-top: 0 !important;
      }
    }
    .reshedule-right {
      .myclass-subject {
        max-width: 550px;
        margin: auto;
      }
    }
    .myexam-tab {
      .myexam-tab-left {
        width: 100%;
      }
      .myexam-tab-right {
        width: 100%;
        text-align: center;
        margin-top: 30px;
      }
      .dropdown {
        position: relative;
        //  color: #333;
        //  cursor: default;
        //  box-sizing: border-box;
        //  height: 66px;
        //  width: 540px;
        border: 0px solid #e0e0e0;
        // border-radius: 10px;
        background: none !important;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .dashboard-main
    .row-main
    .dashboard-right
    .next-class
    .next-chapter
    .nex-chapter-span {
    padding-top: 35px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .next-class
    .next-chapter
    .nex-chapter-span
    svg {
    width: 30px;
    height: 30px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .next-class
    .next-chapter
    .nex-chapter-span
    span {
    font-size: 13px;
    margin-top: 5px;
  }
  .dashboard-main .row-main .dashboard-right .next-class .next-chapter p {
    font-size: 14px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .mysubject-top
    .mysub-progressbar-round
    .mysub-progressbar-round-left {
    height: 50px;
    width: 50px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .mysubject-top
    .mysub-progressbar-round
    h5 {
    font-size: 16px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .mysubject-top
    .mysubject-top-left
    img {
    width: 40px;
    height: 40px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .mysub-upcoming
    .mysub-upcoming-head
    h4 {
    font-size: 16px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .mysub-upcoming
    .mysub-upcoming-sub
    button {
    width: 220px;
    font-size: 17px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .myconcept-main
    .myconcept-left
    .myconcept-desc
    .desc-top {
    .col-md-7,
    .col-md-5 {
      width: 100%;
    }
    .col-md-5 {
      text-align: center;
      button {
        float: none !important;
        margin: auto !important;
        margin-top: 30px !important;
      }
    }
  }
  .myexam-main {
    .myexam-main-common-left,
    .myexam-main-common-right {
      max-width: 95% !important;
      width: 100%;
      margin-top: 30px;
    }
    .myexam-common {
      button {
        width: 300px !important;
        margin: auto;
      }
      h5,
      p.p-two {
        margin-top: 20px;
      }
      .profile {
        float: none;
      }
    }
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .myexam-main
    .myexam-examlist
    .examlist-common {
    li,
    h5,
    p {
      font-size: 15px !important;
    }
    span {
      font-size: 14px !important;
    }
  }
  .mysubscription-dashboard {
    .grand-total {
      .subs-end {
        text-align: center;
        width: 100%;
        h6 {
          img {
            display: block;
            margin: auto;
            margin-bottom: 10px;
            padding-right: 0;
          }
        }
      }
      .renew-button {
        button {
          width: 100% !important;
          font-size: 15px !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 1185px) {
  .dashboard-main .row-main .dashboard-right .upcoming-class .left span,
  .dashboard-main .row-main .dashboard-right .upcoming-class .left p,
  .dashboard-main .row-main .dashboard-right .upcoming-class .right p {
    font-size: 13px;
  }
  .dashboard-main .row-main .dashboard-right .upcoming-class .left span img {
    margin-right: 3px;
    width: 14px;
    height: 16px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .upcoming-class
    .left
    .upcoming-shedule
    .shedule-clock {
    padding-left: 0;
  }
  .shedule-title-desc {
    padding-left: 0 !important;
  }
  .dashboard-main .row-main .dashboard-right .upcoming-class .left .icon,
  .dashboard-main .row-main .dashboard-right .upcoming-class .right .icon {
    height: 50px;
    width: 50px;
    margin-top: 0px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .upcoming-class
    .upcoming-common
    h3,
  .dashboard-main .row-main .dashboard-right .upcoming-class .right h3,
  .dashboard-main .row-main .dashboard-right .brain-gym-main h3,
  .dashboard-main .row-main .dashboard-right .report .report-left h3,
  .dashboard-main .row-main .dashboard-right .myanswer h3,
  .dashboard-main
    .row-main
    .dashboard-right
    .dashtable-main
    .dashtable-heading
    h3 {
    font-size: 24px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .upcoming-class
    .right
    .upcoming-progress {
    padding-right: 0;
    .col-md-8 {
      padding-left: 5px;
      padding-right: 0;
      h5 {
        font-size: 18px;
      }
    }
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .myexam-main
    .myexam-tab
    .dtoggle-bar {
    width: 100%;
  }
}

@media only screen and (max-width: 1165px) {
  .dashboard-main
    .row-main
    .dashboard-right
    .myexam-main
    .myexam-totalscore-top {
    .total-score-left-part,
    .total-score-right-part {
      width: 100%;
      padding: 0;
    }
    .total-score-left-part {
      margin-bottom: 30px;
      .total-score-left {
        height: 75px;
        width: 300px;
        margin: auto;
      }
    }
  }
}

@media only screen and (max-width: 1150px) {
  .dashboard-main .row-main .dashboard-right .progress-chart-top select {
    font-size: 11px;
  }
  .dashboard-main .row-main .dashboard-left .dash-list-common ul li {
    font-size: 13px;
  }
  .dashboard-main .row-main .dashboard-left .dash-list-common {
    padding-left: 15px;
  }
  .dashboard-main .row-main .dashboard-left .dashboard-profile {
    img {
      height: 35px !important;
      width: 35px !important;
    }
    h5 {
      font-size: 13px !important;
    }
    p {
      font-size: 11px;
    }
    svg {
      height: 20px !important;
      width: 20px !important;
    }
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .myexam-main.myassignment-main
    .myexam-examlist
    .examlist-common
    span {
    font-size: 12px !important;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .myexam-main.myassignment-main
    .myexam-examlist
    .examlist-common
    .examlist-common-one
    p {
    span.full-span {
      display: block;
    }
  }
  .dashboard-right
    .myassignment-main
    .examlist-common-one
    .examlist-common-date-time
    .shape {
    margin-left: 0;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .myexam-main.myassignment-main
    .myexam-examlist
    .examlist-common {
    p,
    h5 {
      font-size: 14px !important;
    }
  }
}

@media only screen and (max-width: 1120px) {
  .renewsub-main {
    .renew-right-main {
      .total-data {
        height: auto !important;
        text-align: center;
        div {
          flex: 100%;
          max-width: 100%;
          margin: 10px 0px;
        }
        h3 {
          text-align: center !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .myprofile {
    .account-details {
      .pass-left,
      .pass-right {
        width: 100%;
        text-align: center !important;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .dashboard-main
    .row-main
    .dashboard-right
    .dashboard-right-search
    .dash-right-search-common-two {
    padding-left: 0;
    padding-right: 0;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .dashboard-right-search
    .dash-right-search-common
    p
    span.xp {
    display: none;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .next-class
    .next-chapter
    .nex-chapter-span {
    width: 80px;
    height: 80px;
    padding-top: 15px;
    border-radius: 10px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .next-class
    .next-chapter
    .nex-chapter-span
    svg {
    width: 18px;
    height: 18px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .next-class
    .next-chapter
    .nex-chapter-span
    span,
  .dashboard-main
    .row-main
    .dashboard-right
    .next-class
    .next-perfomance
    .icon-part
    p {
    font-size: 11px;
  }
  .dashboard-main .row-main .dashboard-right .next-class .next-chapter h4 {
    font-size: 22px;
  }
  .dashboard-main .row-main .dashboard-right .next-class .next-chapter p,
  .dashboard-main
    .row-main
    .dashboard-right
    .next-class
    .next-perfomance
    .per-left
    .label-main
    .label-left,
  .dashboard-main
    .row-main
    .dashboard-right
    .next-class
    .next-perfomance
    .per-left
    .label-main
    .label-right {
    font-size: 13px;
  }
  .dashboard-main .row-main .dashboard-right .next-class .next-chapter li {
    font-size: 15px;
  }
  .dashboard-main .row-main .dashboard-right .next-class .next-perfomance h3,
  .dashboard-main .row-main .dashboard-right .brain-gym-main h3,
  .dashboard-main .row-main .dashboard-right .activity-main .act-left h3,
  .dashboard-main
    .row-main
    .dashboard-right
    .upcoming-class
    .upcoming-common
    h3,
  .dashboard-main .row-main .dashboard-right .upcoming-class .right h3 {
    font-size: 21px !important;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .next-class
    .next-perfomance
    .icon-part
    .icon
    svg {
    height: 15px;
    width: 15px;
  }
  .icon-part {
    .left {
      padding-left: 0;
    }
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .next-class
    .next-perfomance
    .icon-part
    h5 {
    font-size: 20px;
  }
  .dashboard-main .row-main .dashboard-right .brain-gym-main p,
  .dashboard-main .row-main .dashboard-right .activity-main .act-left p,
  .dashboard-main .row-main .dashboard-right .activity-main .act-left li {
    font-size: 14px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .brain-gym-main
    button.button-common {
    width: 200px !important;
    height: 49px !important;
    font-size: 16px !important;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .brain-gym-main
    button.button-common
    span {
    margin-top: 0;
  }
  .dashboard-main .row-main .dashboard-right .brain-gym-main li {
    display: block;
    margin-bottom: 10px;
  }
  .dashboard-main .row-main .dashboard-right .brain-gym-main li.li-one::after {
    content: none !important;
  }
  .act-right h5 {
    font-size: 16px;
  }
  .dashboard-main .row-main .dashboard-right .upcoming-class .left .second li {
    font-size: 15px;
  }
  .dashboard-right {
    .error-part {
      button {
        width: 120px !important;
        height: 40px !important;

        a {
          font-size: 14px !important;
        }
      }
      h4 {
        font-size: 17px !important;
      }
      p {
        font-size: 12px !important;
      }
    }
    .upcoming-class .upcoming-common {
      height: auto !important;
    }
    .upcoming-left {
      width: 100% !important;
    }
    .upcoming-class .icon {
      height: 60px !important;
      width: 60px !important;
    }
    .upcoming-right {
      width: 100% !important;
      margin-top: 30px;
      .upcoming-progress {
        margin-bottom: 15px;
        .col-md-4 {
          width: 15%;
        }
      }
    }
    .report {
      .report-left,
      .report-right {
        width: 100%;
      }
      .report-right {
        margin-top: 50px;
      }
    }
    .mysubject-top {
      .mysubject-top-left {
        width: 100%;
        text-align: center;
        display: block !important;
        .heading {
          margin: 40px 10px;
        }
      }
      .mysubject-top-right {
        width: 100%;
      }
    }
    .mysubject-list {
      .dtoggle-bar {
        width: 100% !important;
      }
    }
    .mysubject-count-left,
    .mysubject-count-right {
      width: 100% !important;
    }
    .mysubject-count-right {
      margin-top: 30px;
    }
    .myexam-main {
      .myexam-title {
        .myexam-title-last {
          select {
            width: auto !important;
          }
        }
      }
    }
    .myexam-tab {
      .myexam-tab-right {
        select {
          width: auto;
        }
      }
    }
    .myexam-main {
      .myexam-title {
        h3 {
          font-size: 28px !important;
        }
      }
    }
    .subscribe-for-more-exam {
      button {
        height: 50px !important;
        width: auto !important;
        font-size: 17px !important;
        span {
          margin-top: 0;
          margin-left: 10px;
        }
      }
    }
    .myexam-main
      .myexam-totalscore-top
      .total-score-left-part
      .total-score-left {
      height: 70px;
      width: 300px;
      p,
      h5 {
        font-size: 17px !important;
      }
    }
    .myexam-main .myexam-tab .dtoggle-bar {
      width: 100% !important;
    }
    .myconcept-main
      .myconcept-left
      .myconcept-head-main
      .myconcept-head-top
      .breadcrumb
      a,
    .myconcept-main
      .myconcept-left
      .myconcept-head-main
      .myconcept-head-top
      .breadcrumb
      li.active,
    .myconcept-main .myconcept-right .video-top h5 {
      font-size: 16px !important;
    }
    .myconcept-main
      .myconcept-left
      .myconcept-head-main
      .myconcept-head-bottom
      h4 {
      font-size: 23px !important;
    }
    .myconcept-main
      .myconcept-left
      .myconcept-head-main
      .myconcept-head-bottom
      li,
    .myconcept-desc .desc-top p,
    .myconcept-main .myconcept-right .video-top p {
      font-size: 14px !important;
    }
    .myconcept-desc .desc-top button {
      height: 45px !important;
      font-size: 17px !important;
    }
    .myconcept-main .myconcept-right .video-bottom h5,
    .myconcept-main .myconcept-right .video-bottom p {
      font-size: 13px !important;
    }
    .myexam-answer-main .myexam-answer-left {
      padding-left: 15px !important;
      padding-right: 15px !important;
      text-align: center;
      p {
        margin: 10px 0px !important;
        font-size: 16px !important;
      }
      h5 {
        font-size: 16px !important;
      }
      .row {
        margin-bottom: 30px !important;
      }
    }
    .myexam-answer-main .myexam-answer-right {
      h3 {
        font-size: 22px !important;
      }
      h5 {
        font-size: 16px !important;
      }
    }
    .myexam-answer-main .myexam-answer-left .myexam-answer-sub-left,
    .myexam-answer-main .myexam-answer-left .myexam-answer-sub-right {
      width: 100%;
      padding: 0;
    }
  }
  .dashboard-main .row-main .dashboard-right .renewsub-main .renewsub-head h2 {
    font-size: 26px;
  }
  .dashboard-main .row-main .dashboard-right .renewsub-main .renew-left-main h3,
  .dashboard-main
    .row-main
    .dashboard-right
    .renewsub-main
    .renew-right-main
    h3,
  .dashboard-main
    .row-main
    .dashboard-right
    .renewsub-main
    .renew-right-main
    .total-data
    h4 {
    font-size: 20px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .renewsub-main
    .renew-left-main
    .subject-list-main
    .subject-list,
  .dashboard-main
    .row-main
    .dashboard-right
    .renewsub-main
    .renew-left-main
    .dropdown-common
    .dropdown-main
    .select,
  .dashboard-main
    .row-main
    .dashboard-right
    .renewsub-main
    .renew-left-main
    .dropdown-common
    .dropdown-main
    .dropdown.true
    div,
  .dashboard-main
    .row-main
    .dashboard-right
    .renewsub-main
    .renew-right-main
    .payment-data
    label {
    font-size: 14px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .renewsub-main
    .renew-right-main
    .payment-data
    h4,
  .dashboard-main
    .row-main
    .dashboard-right
    .renewsub-main
    .renew-right-main
    .payment-data
    p {
    font-size: 16px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .renewsub-main
    .renew-right-main
    .total-data
    h3 {
    font-size: 24px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .renewsub-main
    .renew-right-main
    button.payment-process {
    height: 50px;
    font-size: 16px;
    span {
      margin-top: 0;
    }
  }
  .myprofile {
    .myprofile-subscription {
      .subscription-left,
      .subscription-right {
        width: 100%;
      }
      .subscription-right {
        margin-top: 30px;
      }
    }
    .myprofile-subscription-parent {
      .subscription-left,
      .subscription-right {
        width: 100%;
      }
      .subscription-right {
        margin-top: 30px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .dashboard-main .row-main .dashboard-right .myexam-main .myexam-common-top {
    width: 100% !important;
    margin: auto;
  }
  .dashboard-left {
    width: 6% !important;
    .logo-profile {
      display: none;
      img {
        display: none;
      }
    }
    .dashboard-profile {
      margin-top: 55px !important;
      margin-bottom: 0 !important;
      .dashboard-profile-left {
        width: 100%;
      }
      .dashboard-profile-center {
        h5 {
          display: none;
        }
        p {
          display: none;
        }
      }
      .dashboard-profile-right {
        width: 100%;
        svg {
          margin-top: 15px !important;
        }
      }
    }
    .dash-list-common {
      padding: 10px !important;
      h5 {
        display: none;
      }
      ul {
        margin-left: 0 !important;
        text-align: -webkit-center;
        li {
          font-size: 0 !important;
          padding: 10px 0px !important;
          img {
            margin-right: 0 !important;
          }
        }
      }
    }
  }
  .dashboard-main .row-main .dashboard-left .dashboard-profile img {
    height: 25px !important;
    width: 25px !important;
  }
  .dashboard-right {
    width: 93% !important;
    left: 6% !important;
    .next-class {
      .performance {
        .col-md-6 {
          display: block;
          width: 100%;
          p {
            margin-left: 14px !important;
            margin-top: 10px !important;
          }
        }
      }
    }
    .brain-gym-main span.span-last {
      font-size: 14px !important;
    }
    .brain-gym-main .span.span-background {
      width: 80px !important;
      height: 80px !important;
    }
    .mysubscription-dashboard {
      .col-7,
      .col-5 {
        width: 100%;
      }
      .col-5 {
        margin-top: 30px;
        .grand-total {
          .renew {
            .subs-end {
              width: auto;
            }
            .renew-button {
              button {
                width: 250px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .subscription-history {
    .subscription-details {
      width: 100%;
    }
    .subscription-end {
      float: none !important;
      margin-top: 15px;
      display: inline-block;
    }
  }
}

@media only screen and (max-width: 767px) {
  .dashboard-left {
    display: none !important;
  }
  .dashboard-main .row-main .dashboard-right .activity-main .act-right {
    border: 0 !important;
  }
  .dashboard-right {
    width: 100% !important;
    padding: 30px !important;
    left: auto !important;
  }
  .dashboard-main .row-main .dashboard-right .dashboard-right-search {
    padding: 0px 10px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .dashboard-right-search
    .dash-right-search-common
    input {
    width: 100px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .next-class
    .next-chapter
    .nex-chapter-span {
    width: 120px;
    height: 120px;
    padding-top: 30px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .next-class
    .next-chapter
    .nex-chapter-span
    svg {
    width: 30px;
    height: 30px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .next-class
    .next-chapter
    .nex-chapter-span
    span,
  .dashboard-main
    .row-main
    .dashboard-right
    .next-class
    .next-perfomance
    .icon-part
    p {
    font-size: 14px;
  }
  .dashboard-right .next-class .performance .col-md-6 {
    width: auto;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .next-class
    .next-chapter
    .performance
    p {
    margin-top: 0 !important;
  }
  .next-class-common {
    height: auto !important;
  }
  .next-perfomance {
    margin-top: 30px !important;
  }
  .dashboard-main .row-main .dashboard-right .brain-gym-main span.span-last {
    text-align: left;
  }
  .next-class-common .select-top {
    text-align: center;
    margin-top: 30px;
    select {
      float: none !important;
      font-size: 16px !important;
    }
  }
  .dashboard-main .row-main .dashboard-right .notification-menu {
    top: 95px;
    right: 26px;
  }
  .error-left {
    img {
      margin-left: -10px;
    }
  }
  .dashboard-right .upcoming-class .icon {
    height: 50px !important;
    width: 50px !important;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .brain-gym-main
    span.span-background {
    display: none;
  }
  .dashboard-main .row-main .dashboard-right .brain-gym-main img {
    margin-top: 20px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .report
    .report-right
    .report-bottom {
    padding-left: 0;
  }
  .dtable {
    td,
    th {
      font-size: 12px !important;
    }
    .name-image {
      height: 30px !important;
      width: 30px !important;
    }
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .mysubject-top
    .mysub-progressbar-round
    .mysub-progressbar-round-left {
    margin: auto;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .mysubject-top
    .mysub-progressbar-round
    .mysub-progressbar-round-right {
    justify-content: center;
    margin-top: 10px;
  }
  .dashboard-main .row-main .dashboard-right .mysubject-top {
    border-radius: 20px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .mysubject-count
    .mysubject-count-common
    p {
    text-align: left;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .mysubject-count
    .mysubject-count-common
    .progress-main {
    margin-bottom: 10px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .mysubject-count
    .mysubject-count-common
    .sub-video {
    width: auto !important;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .mysubject-top
    .mysubject-top-left
    h4 {
    font-size: 25px;
  }
  .dashboard-main .row-main .dashboard-right .practice-test p {
    font-size: 16px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .mysubject-top
    .mysub-progressbar-round
    h5 {
    font-size: 14px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .dashtable-main
    .dtable
    table
    .name-main
    .top-img {
    left: 1px !important;
    top: 4px !important;
  }
  .dashboard-main .row-main .dashboard-right .myconcept-main .myconcept-right {
    margin-top: 30px;
  }
  .dashboard-main .row-main .dashboard-right .myexam-main .myexam-title h3 {
    font-size: 25px !important;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .myexam-main
    .myexam-tab
    .myexam-tab-right {
    text-align: center !important;
    margin-top: 30px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .myexam-main
    .myexam-examlist
    .examlist-common {
    margin-bottom: 0;
    .examlist-common-bottom {
      margin-bottom: 20px;
    }
    .examlist-common-date-time {
      text-align: right;
    }
    .examlist-last {
      text-align: left !important;
      margin: 15px 0px !important;
    }
  }

  .dashboard-right .myconcept-main .myconcept-right .video-bottom h5,
  .dashboard-right .myconcept-main .myconcept-right .video-bottom p {
    font-size: 16px !important;
  }
  .dashboard-right .myexam-answer-main .myexam-answer-right {
    margin-top: 50px;
  }
  .dashboard-main .row-main .dashboard-right .myexam-answer-main {
    padding-right: 0px !important;
  }
  .dashboard-main .row-main .dashboard-right .renewsub-main .renew-right-main {
    margin-top: 40px;
    .dashboard-main
      .dashboard-right
      .myassignment-main
      .examlist-common
      .examlist-common-one {
      .exam-common-three {
        text-align: left !important;
      }
      .exam-common-two,
      .exam-common-four.examlist-last {
        text-align: right !important;
      }
      .exam-common-four.examlist-last {
        margin: 0 !important;
        li {
          text-align: right !important;
        }
      }
    }
    .dashboard-right
      .myexam-main.myassignment-main
      .myexam-examlist
      .examlist-common
      .examlist-common-one
      p
      span.full-span {
      display: inline-block !important;
    }
    .dashboard-right
      .myexam-main.myassignment-main
      .myexam-examlist
      .examlist-common
      .examlist-common-one
      .examlist-common-date-time
      .shape {
      margin-left: 10px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .myprofile {
    .subscription-left {
      .row {
        background-color: #f5f5f5;
        margin: auto;
        padding: 15px;
        .text-right {
          text-align: left !important;
          h5 {
            margin-top: 15px;
          }
        }
      }
    }
    .parent-details {
      .row {
        margin-bottom: 0;
        .col-md-6 {
          background-color: #f5f5f5;
          padding: 15px;
          margin-bottom: 20px;
        }
      }
    }
    .myaccount-parent-details {
      .row {
        margin-bottom: 0;
        .col-md-6 {
          background-color: #f5f5f5;
          padding: 15px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) and (min-width: 576px) {
  .dashboard-main
    .row-main
    .dashboard-right
    .myexam-main
    .myexam-examlist
    .examlist-common
    .examlist-common-one {
    .exam-common-two {
      text-align: right !important;
    }
    .exam-common-three {
      text-align: left !important;
    }
    .exam-common-four {
      text-align: right !important;
      margin: 0 !important;
    }
    span.full-span {
      display: inline-block !important;
      img.shape {
        margin-left: 10px;
      }
    }
  }
}

@media only screen and (max-width: 630px) {
  .myclasses-main {
    .myclass-inside-rectangle {
      .reshedule-right {
        .shape {
          float: none !important;
          margin: auto !important;
          margin-bottom: 20px !important;
        }
        .subject {
          float: none !important;
          display: block;
          width: 100%;
          text-align: center;
        }
      }
      .reshedule-left {
        .bell-part {
          img {
            width: 55px;
          }
          .myclass-reschedule {
            font-size: 24px !important;
          }
        }
      }
    }
    .myexam-tab-left {
      .dtoggle-bar {
        display: block !important;
        padding: 15px !important;
        .toggle-maths-div {
          width: 100% !important;
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .brain-gym-popup {
    width: 80% !important;
    h4 {
      font-size: 25px !important;
      margin: 0 !important;
    }
    span {
      font-size: 14px !important;
    }
  }
  .error-left {
    img {
      margin-left: 0px;
    }
  }
  .dashboard-right .myconcept-main .myconcept-right .video-bottom h5,
  .dashboard-right .myconcept-main .myconcept-right .video-bottom p {
    font-size: 14px !important;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .brain-gym-main
    .text-center
    button.button-common {
    margin-top: 20px !important;
  }
  .dashboard-main .row-main .dashboard-right .dashboard-right-search {
    height: auto;
    background: transparent;
    box-shadow: none;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .dashboard-right-search
    .dash-right-search-common {
    border-bottom: 1px solid #dfdfdf;
    padding: 10px 0px !important;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    border: 0;
    height: 50px;
    align-items: center;
    margin-bottom: 15px;
    padding-left: 30px !important;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .dashboard-right-search
    .dash-right-search-common-one {
    justify-content: center !important;
    padding-left: 0px !important;
    button {
      width: 100%;
    }
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .dashboard-right-search
    .dash-right-search-common
    input {
    width: auto;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .next-class
    .next-chapter
    .nex-chapter-span {
    width: 80px;
    height: 80px;
    padding-top: 15px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .next-class
    .next-chapter
    .nex-chapter-span
    svg {
    width: 20px;
    height: 20px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .next-class
    .next-chapter
    .nex-chapter-span
    span,
  .dashboard-main
    .row-main
    .dashboard-right
    .next-class
    .next-perfomance
    .icon-part
    p {
    font-size: 11px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .brain-gym-main
    button.button-common {
    width: 170px !important;

    height: 45px !important;
  }
  .dashboard-main .row-main .dashboard-right .next-class .next-perfomance h3,
  .dashboard-main .row-main .dashboard-right .brain-gym-main h3,
  .dashboard-main .row-main .dashboard-right .activity-main .act-left h3 {
    font-size: 17px;
    line-height: 1.5;
  }
  .dashboard-main .row-main .dashboard-right .notification-menu {
    top: 283px;
    right: 9.5%;
    max-width: 80.5%;
  }
  .notification-menu {
    .notification-menu-right {
      text-align: center !important;
    }
    .row {
      background: #f5f5f5;
      padding: 15px;
      border-radius: 5px;
      text-align: center;
    }
    h5 {
      margin-top: 20px;
    }
    .icon {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .dashboard-right {
    padding-left: 15px !important;
    padding-right: 15px !important;
    .error-part {
      height: auto !important;
      .col-3 {
        text-align: center !important;
        svg {
          float: none !important;
        }
      }
      .col-12 {
        text-align: center;
      }
      button {
        float: none !important;
        margin: 15px 0px !important;
        width: 110px !important;
        height: 35px !important;
      }
      h4 {
        margin-top: 15px;
      }
    }
    .upcoming-class {
      .upcoming-left {
        .second {
          text-align: left !important;
          margin-top: 15px;
        }
        .upcoming-shedule {
          margin: 30px auto 0px auto;
          background: #f5f5f5;
          border-radius: 10px;
          text-align: center;
          padding: 20px !important;
          .col-12 {
            padding: 0;
            .icon {
              margin: auto;
              height: 60px !important;
              width: 60px !important;
              margin-bottom: 15px;
            }
            button {
              margin-top: 10px;
              width: 150px;
            }
          }
        }
      }
      .upcoming-progress {
        text-align: center;
        background: #f5f5f5;
        border-radius: 10px;
        padding: 20px !important;
        margin: 30px auto 0px auto;
        .col-md-4 {
          width: 100%;
          .icon {
            margin: auto;
            margin-bottom: 20px;
          }
        }
      }
    }
    .report .report-right .report-two,
    .report .report-right .report-one {
      width: 100% !important;
      margin-top: 15px;
    }
    .report .report-right li {
      font-size: 14px !important;
    }
    .report .report-right .report-bottom .report-child {
      margin-bottom: 20px;
    }
    .dashtable-main {
      .dashtable-heading {
        display: block !important;
        span {
          margin: 15px 0px;
          display: block;
        }
      }
      .dtoggle-bar {
        display: block !important;
        width: 100% !important;
        border-radius: 10px !important;
        padding: 20px 10px 0px 10px !important;
        button {
          width: 100% !important;
          margin-bottom: 20px;
        }
      }
    }
    .myanswer {
      .progress-chart-top {
        .select-two {
          text-align: right;
        }
      }
    }
    .mysubject-top {
      .mysubject-top-left {
        margin-bottom: 15px;
      }
      .mysub-progressbar-round {
        margin-top: 20px;
        h5 {
          font-size: 16px !important;
        }
      }
    }
    .mysubject-list .dtoggle-bar {
      display: block !important;
      padding: 10px !important;
      .toggle-maths-div {
        width: 100% !important;
        margin-bottom: 15px;
      }
    }
    .mysubject-count-top {
      padding: 0px !important;
    }
    .mysubject-count .mysubject-count-common .sub-list li {
      display: block !important;
      border: 0 !important;
    }
    .mysubject-count .mysubject-count-common h5 {
      font-size: 18px !important;
    }
    .mysubject-count .mysubject-count-common p,
    .mysubject-count-common .sub-list li {
      font-size: 14px !important;
    }
    .mysub-upcoming .mysub-upcoming-head h4 {
      font-size: 14px;
    }
    .mysub-upcoming .mysub-upcoming-sub h5 {
      font-size: 20px !important;
    }
    .mysub-upcoming .mysub-upcoming-sub p {
      font-size: 14px !important;
    }
    .mysub-upcoming .mysub-upcoming-sub button {
      width: 200px !important;
      font-size: 15px !important;
      height: 50px !important;
    }
    .practice-test .mysub-upcoming-sub select {
      font-size: 14px;
    }
    .myexam-main .myexam-title h3 {
      margin: 0 !important;
    }
    .myexam-main .myexam-title .myexam-title-last {
      text-align: center !important;
      margin-top: 30px;
    }
    .myexam-main .myexam-title {
      .myexam-title-left {
        display: none;
      }
      .myexam-title-center {
        width: 100%;
        h3 {
          font-size: 25px !important;
        }
      }
    }
    .myexam-answer-main
      .myexam-answer-right
      .exam-right-common
      .exam-right-left {
      text-align: center;
      margin-bottom: 20px;
    }
    .myexam-answer-main
      .myexam-answer-right
      .exam-right-common
      .exam-right-right {
      text-align: right;
    }
    .myexam-answer-main .myexam-answer-right {
      h3 {
        font-size: 18px !important;
        line-height: 1.3;
      }
      h5 {
        font-size: 15px !important;
        line-height: 1.5;
      }
    }
  }
  .dashboard-main .row-main .dashboard-right .myexam-main .myexam-title h3 {
    font-size: 25px !important;
  }
  .dashboard-main .row-main .dashboard-right .myexam-main .myexam-common-top {
    width: 100% !important;
    margin: auto;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .myexam-main
    .myexam-common-top
    .myexam-common
    .myexam-common-right {
    text-align: left !important;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .myexam-main
    .myexam-common-top
    .myexam-common
    button {
    width: 100% !important;
    max-width: 100% !important;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .myexam-main
    .myexam-common-top
    .myexam-common
    .myexam-common-right
    p.p-one {
    display: block !important;
    span {
      display: block;
      margin: 15px 0px;
      font-size: 16px;
    }
  }
  .dashboard-right .subscribe-for-more-exam button {
    font-size: 15px !important;
    span {
      margin-top: 0px !important;
    }
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .myexam-main
    .myexam-tab
    .dtoggle-bar
    .toggle-maths-div {
    width: 100% !important;
    margin-bottom: 20px;
  }
  .dashboard-right .myexam-main .myexam-tab .dtoggle-bar {
    display: block !important;
    padding: 20px !important;
    padding-bottom: 0 !important;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .myexam-main
    .myexam-totalscore-top
    .total-score-left-part
    .total-score-left {
    height: 65px !important;
    padding: 0px 10px !important;
    width: 100%;
  }
  .dashboard-right
    .mysubject-top.myexam-totalscore-top
    .mysub-progressbar-round {
    margin-bottom: 20px;
  }

  .dashboard-main
    .row-main
    .dashboard-right
    .myexam-main
    .myexam-examlist
    .examlist-common
    .examlist-common-date-time {
    text-align: left !important;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .myexam-main
    .myexam-examlist
    .examlist-common
    .examlist-common-bottom {
    text-align: center !important;
    display: block;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .myexam-main
    .myexam-examlist
    .examlist-common
    .examlist-common-two
    p {
    margin: 10px 0px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .myexam-main
    .myexam-title
    .myexam-title-last
    select,
  .dashboard-main
    .row-main
    .dashboard-right
    .myexam-main
    .myexam-tab
    .myexam-tab-right
    select {
    width: 100% !important;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .myexam-main
    .myexam-examlist
    .examlist-common
    .examlist-common-one
    p
    span.span-bold {
    display: block;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .myexam-main
    .myexam-examlist
    .examlist-common
    .examlist-common-date-time,
  .dashboard-main
    .row-main
    .dashboard-right
    .myexam-main
    .myexam-examlist
    .examlist-common
    .examlist-last,
  .dashboard-main
    .row-main
    .dashboard-right
    .myexam-main
    .myexam-examlist
    .examlist-common {
    text-align: center !important;
  }
  .dashboard-main .row-main .dashboard-right .myexam-answer-main-top {
    padding: 15px !important;
    .myexam-answer-left {
      margin-top: 65px;
    }
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .renewsub-main
    .renew-right-main
    button.payment-process {
    max-width: 100%;
    font-size: 14px;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .renewsub-main
    .renew-left-main
    .subject-list-main
    .subject-list {
    padding: 10px 15px;
  }
  .dashboard-right {
    .renewsub-main {
      .renew-right-main {
        .payment-main {
          .payment-data {
            background-color: #f5f5f5;
            margin: auto;
            padding: 9px;
            border-radius: 10px;
            p {
              margin-top: 15px !important;
              text-align: left !important;
            }
          }
        }
      }
    }
    .dashboard-main
      .dashboard-right
      .myassignment-main
      .examlist-common
      .examlist-common-one
      .exam-common-four.examlist-last
      li {
      text-align: center !important;
      margin-bottom: 20px;
    }
    .dashboard-right
      .myexam-main.myassignment-main
      .myexam-examlist
      .examlist-common
      .examlist-common-one
      p
      span.full-span {
      display: block !important;
    }
    .mysubscription-dashboard {
      .subscription-details-left {
        .common-left-subject {
          display: block !important;
        }
      }
      .amount,
      .left-olympiad,
      .olympiad,
      .classes {
        display: block !important;
        background-color: #f5f5f5;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 20px;
        p {
          font-size: 15px !important;
          margin-bottom: 5px !important;
          padding: 0 !important;
        }
      }
      .class-payment {
        background-color: #f5f5f5;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 20px;
        p {
          font-size: 15px !important;
          margin-bottom: 5px !important;
        }
      }
      .amount {
        margin-top: 15px;
      }
      .subscription-for-class,
      .subscription-details-for-rectangle,
      .subscription-for-exam,
      .subscription-details-for-cocurri {
        padding: 0 !important;
        background-color: transparent;
        font-size: 15px !important;
      }
      .subscription-details {
        font-size: 21px !important;
      }
      .subscription-details-left,
      .grand-total,
      .subscription-history {
        padding: 20px 15px !important;
      }
      .col-5 .grand-total {
        .renew .renew-button button {
          max-width: 250px !important;
          width: 100% !important;
          font-size: 13px !important;
        }
      }
      .subscription-history .subscription-end {
        text-align: center !important;
        img {
          display: block;
          margin: auto;
          margin-bottom: 10px;
          padding: 0 !important;
        }
      }
      .subscription-history {
        .subscription-details {
          float: none;
        }
        .subscription-end {
          display: block;
        }
      }
    }
    .myclasses-main {
      .myclass-reschedule-rectanle {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
      .examlist-common {
        .examlist-common-bottom {
          display: block !important;
          .left-icon,
          .right-content {
            float: none !important;
          }
          .left-icon {
            margin-right: 0 !important;
          }
        }
      }
      .myexam-common {
        .profile {
          float: none;
          text-align: center;
        }

        .myclasses-common-day-left {
          height: auto;
          text-align: center;
          padding: 10px;
          p {
            margin-bottom: 0;
            span {
              margin: 0;
              display: block;
            }
          }
        }
      }
      .reshedule-left {
        .bell-part {
          display: block !important;
          text-align: center;
          img {
            float: none !important;
            margin-right: 0;
            margin-bottom: 15px;
          }
        }
      }
      .reshedule-right {
        .myclass-subject {
          padding-left: 10px !important;
          padding-right: 10px !important;
          .subject {
            .previous,
            .reschedule {
              padding: 10px;
              span {
                display: block;
                margin: 0;
              }
            }
          }
        }
      }
    }
    .myprofile {
      .profile {
        .common-name .name {
          font-size: 19px;
        }
        .taut-id {
          font-size: 15px;
          .id-name {
            font-size: 15px;
          }
        }
        .pro-icon {
          height: 70px !important;
          width: 70px !important;
        }
        .profilepic .edit {
          bottom: -10px;
        }
      }
      .myprofile-subscription {
        padding: 15px;
        .subscription-left {
          h5 {
            font-size: 15px;
          }
        }
        .subscription-right {
          .completed-rectangle {
            h3 {
              font-size: 12px;
            }
            padding: 15px;
            .text-right {
              text-align: left !important;
              margin: 15px 0px;
            }
          }
        }
      }
      .myprofile-subscription-parent {
        padding: 15px;
        .subscription-left {
          h5 {
            font-size: 15px;
          }
        }
        .subscription-right {
          .completed-rectangle {
            h3 {
              font-size: 12px;
            }
            padding: 15px;
            .text-right {
              text-align: left !important;
              margin: 15px 0px;
            }
          }
        }
      }
      .account-details {
        padding: 15px;
        .details {
          text-align: center;
          margin-bottom: 20px;
        }
      }
      .parent-details {
        padding: 15px;
        h5 {
          font-size: 16px;
        }
        .row {
          margin: auto;
        }
      }
      .myaccount-parent-details {
        padding: 15px;
        h5 {
          font-size: 16px;
        }
        .row {
          margin: auto;
        }
      }
      .mysubscription-name,
      .account-details-name,
      .parent-details-name {
        font-size: 19px;
      }
    }
  }
}

@media only screen and (max-width: 335px) {
  .dashboard-right .next-class .performance .col-md-6 {
    width: 100% !important;
  }
  .dashboard-main
    .row-main
    .dashboard-right
    .next-class
    .next-chapter
    .performance
    p {
    margin-top: 15px !important;
  }
}

.examlist-common-one .examlist-common-date-time .shape {
  box-sizing: border-box;
  margin-left: 20px;
  margin-right: 10px;
}

.myclasses-common-day-left {
  height: 29px;
  width: 314px;
  border-radius: 14.5px;
  background-color: #fff4e9;
  margin-top: 20px;

  p .span-one {
    font-family: 'proxima_nova_rgregular';
    margin-left: 35px;
    margin-top: 3px;
  }
  p .span-two {
    color: #fca834;
    font-family: 'Proxima Nova';
    font-weight: bold;
  }
}

.myexam-common-top .myclass-common-shift {
  margin-left: 20px;
}
.myexam-common-top .profile {
  float: left;
  img {
    max-width: 46px;
  }
}

.myexam-title .status1 {
  color: #7d93f2;
  font-family: 'proxima_nova_rgbold';
  font-size: 20px;
  font-weight: bold;
}
.myexam-title .status2 {
  color: #fca834;
  font-family: 'proxima_nova_rgbold';
  font-size: 20px;
  font-weight: bold;
  margin-left: 35px;
}
.myexam-title .status {
  text-align: right;
}

.mysubscription-dashboard {
  .subscription-details {
    color: #20282a;
    font-family: 'proxima_nova_rgbold';
    font-weight: bold;
    margin: 20px 0px;
    font-size: 26px;
  }

  .subscription-history {
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 12px 34px -10px rgba(0, 0, 0, 0.05);
    margin-top: 30px;
    padding: 30px;
    .subscription-details {
      font-family: 'proxima_nova_rgbold';
      font-size: 26px;
      font-weight: bold;
      margin: 0;
      float: left;
    }
    .subscription-end {
      text-align: right;
      color: #f3332c;
      font-family: 'proxima_nova_rgregular';
      font-size: 15px;
      float: right;
      img {
        padding-right: 10px;
        margin-top: -4px;
      }
    }
    .subscription-table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      overflow-x: auto;
    }
    table {
      margin-top: 50px;
      margin-bottom: 0;

      tr {
        text-align: left;
      }
      td {
        //cursor: pointer;
        text-align: left;
      }
      th {
        border: 0;
        font-family: 'Proxima Nova';
      }
      p {
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 700;
      }
      h5 {
        font-size: 14px;
      }
    }
  }
  .subscription-details-for-cocurri-rectangle {
    border-radius: 10px;
    background-color: #f9f9f9;
    margin-left: 4px;
    margin-top: 3px;
    .subscription-details-for-cocurri {
      color: #20282a;
      font-family: 'proxima_nova_rgregular';
      font-size: 17px;
      font-weight: 600;
      letter-spacing: 0;
      padding: 15px 0px 15px 20px;
    }
  }
  .line {
    box-sizing: border-box;
    height: 1px;
    width: calc(100% + 7px);
    border: 1px solid #20282a;
    margin: 15px 0px;
  }
  .amount {
    color: #20282a;
    font-family: 'Proxima Nova';
    font-size: 18px;
    font-weight: 600;
    line-height: 29px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    p {
      font-size: 19px;
    }
    .total-amount {
      display: flex;
      justify-content: space-between;
    }
  }

  .classes {
    font-family: 'proxima_nova_rgregular';
    color: #20282a;
    font-size: 16px;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 20px;
    p {
      font-size: 17px;
      margin-bottom: 0;
    }
    .claasses-month {
      display: flex;
      justify-content: space-between;
    }
    .dollar {
      p {
        font-size: 19px;
        font-family: 'Proxima Nova';
      }
    }
  }

  .grand-total-right {
    color: #20282a;
    font-family: 'proxima_nova_rgbold';
    font-size: 32px;
    font-weight: bold;
  }
  .left-olympiad {
    font-family: 'proxima_nova_rgregular';
    color: #20282a;
    font-size: 16px;
    margin-top: 19px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .half-yearly-olympiad {
      display: flex;
      justify-content: space-between;
      margin-top: 1px;
    }
    p {
      font-size: 17px;
      font-family: 'proxima_nova_rgregular';
      margin: 0;
    }
  }
  .olympiad {
    font-family: 'proxima_nova_rgregular';
    color: #20282a;
    font-size: 16px;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 20px;
    height: auto;
    p {
      font-size: 17px;
      font-family: 'proxima_nova_rgregular';
      font-weight: normal;
    }
  }
  .grand-total {
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0 12px 34px -10px rgba(0, 0, 0, 0.05);
    padding: 30px;
    .renew {
      border-radius: 20px;
      background-color: #e3fff6;
      text-align: center;
      padding: 20px;
      .left-days {
        color: #f3332c;
        font-family: 'proxima_nova_rgbold';
        font-size: 24px;
        font-weight: 600;
        margin-top: 20px;
      }
      .subs-end {
        border-radius: 10px;
        background-color: #fff4e9;
        margin: 15px 0px;
        display: inline-flex;
        align-items: center;
        .subscription {
          color: #f3332c;
          font-family: 'proxima_nova_rgregular';
          font-size: 14px;
          padding: 10px 20px;
          margin: 0;
          img {
            padding-right: 10px;
          }
        }
      }
      .renew-button {
        button {
          // background-color: #43aa8b;
          // box-shadow: 0 12px 10px 0 rgba(67, 170, 139, 0.25);
          // border-radius: 33.75px;
          // color: #fff;
          // border: 0;
          // font-weight: bold;
          // font-size: 17px;
          // font-family: 'proxima_nova_rgbold';
          // display: inline-flex;
          // align-items: center;
          // padding: 12px 20px;
          // width: 250px;
          // justify-content: space-between;

          width: 300px;
          background-color: #43aa8b;
          border-radius: 33.75px;
          color: #fff;
          border: 0;
          height: 66px;
          font-weight: bold;
          font-size: 20px;
          font-family: 'proxima_nova_rgbold';
          text-align: center;
          padding: 0px 25px;
          box-shadow: none;
          transition: 0.3s;
          opacity: 0.8;

          span {
            float: right;
            height: 24px;
            width: 24px;
            background-color: #fca834;
            border-radius: 50%;
            padding: 0px 4px;
            box-shadow: 5px 5px 5px rgb(0 0 0 / 24%) !important;
            margin-top: 2px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .subscription-details-for-rectangle {
    border-radius: 10px;
    background-color: #f9f9f9;

    .subscription-for-exam {
      color: #20282a;
      font-family: 'proxima_nova_rgregular';
      font-size: 17px;
      font-weight: 600;
      letter-spacing: 0;
      padding: 15px 0px 15px 20px;
      margin: 0;
    }
  }
  .subscription-for-class {
    color: #20282a;
    font-family: 'proxima_nova_rgregular';
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0;
    padding: 15px 0px 15px 20px;
    margin: 0;
  }
  .subscription-details-left {
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0 12px 34px -10px rgba(0, 0, 0, 0.05);
    margin-top: 0px;
    padding: 20px 30px;
    //  margin-right: 40px;
  }
  .parent-class {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-inline: 20px;
    font-weight: 600;

    .line {
      box-sizing: border-box;
      height: 1px;
      width: calc(100% + 7px);
      border: 1px solid #20282a;
    }

    .amount {
      color: #20282a;
      font-family: 'Proxima Nova';
      font-size: 18px;
      font-weight: 600;

      line-height: 29px;
      display: flex;
      justify-content: space-between;

      width: 100%;

      .total-amount {
        display: flex;
        justify-content: space-between;
      }
    }

    .class-payment {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      flex-direction: column;
      margin-top: 10px;
      width: 100%;
      .subject {
        color: #454c4e;
        font-family: 'proxima_nova_rgregular';
        margin: 0;
        font-size: 14px;
      }
      .subscribed-for {
        color: #454c4e;
        font-family: 'proxima_nova_rgregular';
        font-size: 16px;
        margin-top: 20px;
        p {
          margin-bottom: 0;
          font-size: 14px;
        }
      }

      .common-left-subject {
        display: flex;
        justify-content: space-between;
        margin-top: 1px;
        //  align-items: center;

        .english-math {
          color: #20282a;
          font-family: 'Proxima Nova';
          font-size: 20px;
          letter-spacing: 0;
          line-height: 22px;
          p {
            margin-bottom: 0;
            font-family: 'proxima_nova_rgregular';
            font-size: 17px;
          }
        }
        .prekindergarten {
          // color: #20282A;
          font-family: 'proxima_nova_rgregular';
          font-size: 20px;

          p {
            margin-bottom: 0px;
            font-size: 17px;
          }
        }
      }
    }
  }
}
.table {
  //padding: 20px 20px;
  border-collapse: collapse;
  .table-hover th,
  td {
    border: 0px;

    p {
      margin-bottom: 0px;
      color: #454c4e;
      font-family: 'proxima_nova_rgregular';
      font-size: 14px;
    }
    h5 {
      color: #20282a;
      font-family: 'Proxima Nova';
      font-size: 16px;
      font-weight: 600;
    }
  }
}
.myprofile {
  margin-top: 50px;
  .profile {
    justify-content: center;
    display: flex;
    align-items: center;
    .common-name {
      padding-left: 20px;
      .name {
        color: #20282a;
        font-family: 'Proxima Nova';
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 0px;
      }
      .taut-id {
        color: #43aa8b;
        font-family: 'proxima_nova_rgregular';
        font-size: 17px;
        letter-spacing: 0;
        margin-bottom: 4px;
      }
    }
  }
}
.myprofile .profilepic {
  position: relative;
  .pro-icon {
    height: 85px;
    width: 85px;
    border-radius: 50%;
  }
  .edit {
    position: absolute;
    bottom: -6px;
    right: -2%;
  }
  .uploadcontrol::file-selector-button {
    display: none;
  }
  .uploadcontrol {
    cursor: pointer !important;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 40px 40px 0px 40px;
  }
}
.id-name {
  color: #454c4e;
  font-family: 'proxima_nova_rgregular';
  font-size: 18px;
  padding-left: 10px;
}
.myprofile-subscription {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 12px 34px -10px rgba(0, 0, 0, 0.05);
  margin-top: 30px;
  padding: 30px;
  .mysubscription-name {
    color: #454c4e;
    font-family: 'Proxima Nova';
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: left;
  }
  .subscription-left {
    h5 {
      font-size: 18px;
    }
    .row {
      align-items: flex-end;
      margin-bottom: 25px;
      .text-right {
        text-align: right;
      }
    }
    .row:last-child {
      margin-bottom: 0;
    }
    p {
      margin-bottom: 5px;
      color: #7d8283;
    }
    h5 {
      margin-bottom: 0;
    }
  }
  .subscribed {
    p {
      color: #7d8283;
      font-family: 'proxima_nova_rgregular';
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 5px;
    }
    h5 {
      color: #20282a;
      font-family: 'Proxima Nova';
      font-size: 20px;
      font-weight: 600;
    }
    .pre-kind {
      margin-bottom: 25px;
    }
  }

  .usd {
    margin-top: 60px;
    h5 {
      color: #20282a;
      font-family: 'Proxima Nova';
      font-size: 24px;
      font-weight: 600;
    }
    .usd-inside {
      margin-top: 60px;
    }
  }
}
.myprofile-subscription-parent {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 12px 34px -10px rgba(0, 0, 0, 0.05);
  // margin-top: 30px;
  padding: 30px;
  // width: 50%;
  margin: 30px auto 30px auto;
  .mysubscription-name {
    color: #454c4e;
    font-family: 'Proxima Nova';
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
  }
  .subscription-left {
    h5 {
      font-size: 18px;
    }
    .row {
      align-items: flex-end;
      margin-bottom: 25px;
      .text-right {
        text-align: right;
      }
    }
    .row:last-child {
      margin-bottom: 0;
    }
    p {
      margin-bottom: 5px;
      color: #7d8283;
    }
    h5 {
      margin-bottom: 0;
    }
  }
  .subscribed {
    p {
      color: #7d8283;
      font-family: 'proxima_nova_rgregular';
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 5px;
    }
    h5 {
      color: #20282a;
      font-family: 'Proxima Nova';
      font-size: 20px;
      font-weight: 600;
    }
    .pre-kind {
      margin-bottom: 25px;
    }
  }

  .usd {
    margin-top: 60px;
    h5 {
      color: #20282a;
      font-family: 'Proxima Nova';
      font-size: 24px;
      font-weight: 600;
    }
    .usd-inside {
      margin-top: 60px;
    }
  }
}
.mysubscription-name {
}
.account-details {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 12px 34px -10px rgba(0, 0, 0, 0.05);
  margin: 30px 0px;
  padding: 30px;
  .text-right {
    text-align: right;
  }
  .account-details-name {
    text-transform: uppercase;
    color: #454c4e;
    font-family: 'Proxima Nova';
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 30px;
  }
  .tautid {
    color: #7d8283;
    font-family: 'proxima_nova_rgregular';
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 0px;
    margin-bottom: 5px;
  }
  .span-one {
    color: #7d8283;
    font-family: 'proxima_nova_rgregular';
    font-size: 18px;
    font-weight: 600;
  }
}
.myprofile .cred {
  p {
    color: #7d8283;
    font-family: 'proxima_nova_rgregular';
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  h5 {
    color: #20282a;
    font-family: 'Proxima Nova';
    font-size: 30px;
    font-weight: 600;
  }
}
.pass-myProfile {
  height: 43px;
  width: 100%;
  border: 1.13px solid #43aa8b;
  border-radius: 10px;
  background-color: #ffffff;
  max-width: 175px;
  span {
    color: #43aa8b;
    font-family: 'Proxima Nova';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
  }
}
.pass {
  height: 43px;
  width: 100%;
  border-radius: 10px;
  background-color: #43aa8b;
  border: 0;
  max-width: 175px;
  span {
    color: #ffffff;
    font-family: 'proxima_nova_rgbold';
    font-size: 14px;
    font-weight: bold;
    text-align: center;
  }
}
.pass:disabled {
  background-color: #43aa8b;
  opacity: 0.4;
}
.parent-details {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 12px 34px -10px rgba(0, 0, 0, 0.05);
  padding: 30px;
  .row {
    margin-bottom: 20px;
  }
  .row:last-child {
    margin-bottom: 0;
  }
  .parent-details-name {
    color: #454c4e;
    font-family: 'proxima_nova_rgregular';
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: left;
  }
  p {
    color: #7d8283;
    font-family: 'proxima_nova_rgregular';
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  h5 {
    color: #20282a;
    font-family: 'Proxima Nova';
    font-size: 20px;
    font-weight: 600;
  }
}
.myaccount-parent-details {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 12px 34px -10px rgba(0, 0, 0, 0.05);
  padding: 30px;
  // width: 50%;
  margin: 30px auto 30px auto;
  .stu-details {
    display: flex;
  }
  .row {
    margin-bottom: 20px;
  }
  .row:last-child {
    margin-bottom: 0;
  }
  .parent-details-name {
    color: #454c4e;
    font-family: 'proxima_nova_rgregular';
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: left;
  }
  p {
    color: #7d8283;
    font-family: 'proxima_nova_rgregular';
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  h5 {
    color: #20282a;
    font-family: 'Proxima Nova';
    font-size: 20px;
    font-weight: 600;
  }
}
.parent-details-block {
  .parent p {
    color: #7d8283;
    font-family: 'proxima_nova_rgregular';
    font-size: 18px;
    font-weight: 600;
  }
  .number p {
    color: #7d8283;
    font-family: 'proxima_nova_rgregular';
    font-size: 18px;
    font-weight: 600;
  }
  .state p {
    color: #7d8283;
    font-family: 'proxima_nova_rgregular';
    font-size: 18px;
    font-weight: 600;
  }

  h5 {
    color: #20282a;
    font-family: 'Proxima Nova';
    font-size: 20px;
    font-weight: 600;
  }
  .number {
    margin-top: 20px;
  }
  .state {
    margin-top: 10px;
  }
}
.email-right {
  padding-top: 24px;
  .email p {
    color: #7d8283;
    font-family: 'proxima_nova_rgregular';
    font-size: 18px;
    font-weight: 600;
  }

  h5 {
    color: #20282a;
    font-family: 'Proxima Nova';
    font-size: 20px;
    font-weight: 600;
  }
  .country {
    margin-top: 30px;
    p {
      color: #7d8283;
      font-family: 'proxima_nova_rgregular';
      font-size: 18px;
      font-weight: 600;
    }
  }
}
.myprofile-subscription {
  .completed-rectangle {
    border-radius: 20px;
    background-color: #fffcf5;
    padding: 25px;
    .rect-inside {
      .progress {
        background-color: #ffe7d9;
        border-radius: 10px;
        height: 10px;
        .progress-bar {
          background-color: #f3722c;
          border-radius: 10px;
          overflow: visible;
          position: relative;
          height: 8px;
          // &::before {
          //   content: '';
          //   position: absolute;
          //   background: #fff;
          //   height: 5px;
          //   width: 5px;
          //   left: 4px;
          //   border-radius: 50%;
          //   top: 2.4px;
          // }
          // &::after {
          //   content: '';
          //   position: absolute;
          //   background: #fff;
          //   height: 1px;
          //   width: 60%;
          //   top: 2.3px;
          //   left: 13px;
          // }
        }
      }
      .text-right {
        text-align: right;
        p {
          color: #f3722c;
          font-size: 15px;
        }
      }
      p {
        margin-bottom: 0;
        font-size: 18px;
      }
      h3 {
        margin-top: 20px;
        display: flex;
        align-items: center;
      }
      .progressBar {
        color: #f3722c;
        .progress {
          background-color: #ffe7d9;
          border-radius: 10px;
          height: 10px;
          .progress-bar {
            background-color: #f3722c;
            border-radius: 10px;
            overflow: visible;
            position: relative;

            &:after {
              content: '';
              position: absolute;
              background: #fff;
              height: 1px;
              width: 85.7%;
              top: 2.3px;
              left: 13px;
            }
            &:before {
              content: '';
              position: absolute;
              background: #fff;
              height: 5px;
              width: 5px;
              left: 4px;
              border-radius: 50%;
              top: 2.4px;
            }
          }
        }
      }
    }
  }

  p {
    color: #454c4e;
    font-family: 'proxima_nova_rgregular';
    font-size: 16px;
    font-weight: 600;
  }
  h3 {
    color: #f3332c;
    font-family: 'proxima_nova_rgbold';
    font-size: 16px;
    font-weight: bold;

    .span-one {
      padding-right: 12px;
    }
  }
}
.myprofile-subscription-parent {
  .completed-rectangle {
    border-radius: 20px;
    background-color: #fffcf5;
    padding: 25px;
    .rect-inside {
      .progress {
        background-color: #ffe7d9;
        border-radius: 10px;
        height: 10px;
        .progress-bar {
          background-color: #f3722c;
          border-radius: 10px;
          overflow: visible;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            background: #fff;
            height: 5px;
            width: 5px;
            left: 4px;
            border-radius: 50%;
            top: 2.4px;
          }
          &::after {
            content: '';
            position: absolute;
            background: #fff;
            height: 1px;
            width: 60%;
            top: 2.3px;
            left: 13px;
          }
        }
      }
      .text-right {
        text-align: right;
        p {
          color: #f3722c;
          font-size: 15px;
        }
      }
      p {
        margin-bottom: 0;
        font-size: 18px;
      }
      h3 {
        margin-top: 20px;
        display: flex;
        align-items: center;
      }
      .progressBar {
        color: #f3722c;
        .progress {
          background-color: #ffe7d9;
          border-radius: 10px;
          height: 10px;
          .progress-bar {
            background-color: #f3722c;
            border-radius: 10px;
            overflow: visible;
            position: relative;

            &:after {
              content: '';
              position: absolute;
              background: #fff;
              height: 1px;
              width: 85.7%;
              top: 2.3px;
              left: 13px;
            }
            &:before {
              content: '';
              position: absolute;
              background: #fff;
              height: 5px;
              width: 5px;
              left: 4px;
              border-radius: 50%;
              top: 2.4px;
            }
          }
        }
      }
    }
  }

  p {
    color: #454c4e;
    font-family: 'proxima_nova_rgregular';
    font-size: 16px;
    font-weight: 600;
  }
  h3 {
    color: #f3332c;
    font-family: 'proxima_nova_rgbold';
    font-size: 16px;
    font-weight: bold;

    .span-one {
      padding-right: 12px;
    }
  }
}

.noClassAva-text {
  display: flex;
  justify-content: center;
  align-items: center;
  // color: #fca834;
  font-family: 'proxima_nova_rgbold';
  font-size: 18px;
  font-weight: bold;
}
.carousel-inner {
  > .innerItem {
    position: relative;
    -webkit-transition: 0.1s ease-in-out left;
    -moz-transition: 0.1s ease-in-out left;
    -o-transition: 0.1s ease-in-out left;
    transition: 0.1s ease-in-out left;
  }
}

.dashboard-main .row-main .dashboard-right .noData-errorNew {
  color: #6f797b;
  font-family: 'Proxima Nova';
  font-size: 1.375rem;
  align-items: center;
  text-align: center;
}

.reschedule-popupp {
  .time-list-main {
    margin-bottom: 10px;
    label.label {
      display: block;
      color: #7d8283;
      font-family: 'Proxima Nova';
      font-weight: 600;
      font-size: 18px !important;
      line-height: 22px;
      margin-bottom: 5px;
      margin-top: 10px;
    }
    .time-list {
      border: 1px solid #e0e0e0;
      display: inline-flex;
      align-items: center;
      padding: 10px 10px !important;
      width: auto;
      border-radius: 33px;
      margin-right: 10px;
      // margin-bottom: 15px;
      color: #20282a;
      font-family: 'Proxima Nova';
      background-color: #f4f7fc;
      font-size: 18px !important;
      font-weight: 600;
      padding-right: 25px;
      min-width: 175px;
      height: 45px !important;
      position: relative;
      .round {
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: inline-block;
        vertical-align: top;
        margin-right: 35px;
        input {
          border-radius: 50%;
          margin-right: 10px;
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }
        input:checked ~ .checkmark {
          background-color: #43aa8b;
        }
        .checkmark {
          position: absolute;
          top: 27%;
          left: 5%;
          height: 20px;
          width: 20px;
          background-color: #fff;
          border: 1px solid #e0e0e0;
          border-radius: 50%;
        }
        .checkmark:after {
          content: '';
          position: absolute;
          display: none;
        }
        input:checked ~ .checkmark:after {
          display: block !important;
        }
        .checkmark:after {
          left: 7px;
          top: 4px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
    .time-list.active {
      border: 1px solid #43aa8b;
      background-color: #fff;
      color: #43aa8b;
    }
  }
}

.reschedule-popuppp {
  .time-list-main {
    margin-bottom: 10px;
    label.label {
      display: block;
      color: #7d8283;
      font-family: 'Proxima Nova';
      font-weight: 600;
      font-size: 18px !important;
      line-height: 22px;
      margin-bottom: 5px;
      margin-top: 10px;
    }
    .time-list {
      border: 1px solid #e0e0e0;
      display: inline-flex;
      align-items: center;
      padding: 10px 10px !important;
      width: auto;
      border-radius: 33px;
      margin-right: 10px;
      // margin-bottom: 15px;
      color: #20282a;
      font-family: 'Proxima Nova';
      background-color: #f4f7fc;
      font-size: 18px !important;
      font-weight: 600;
      padding-right: 25px;
      min-width: 175px;
      height: 45px !important;
      position: relative;
      .round {
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: inline-block;
        vertical-align: top;
        margin-right: 35px;
        input {
          border-radius: 50%;
          margin-right: 10px;
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }
        input:checked ~ .checkmark {
          background-color: #43aa8b;
        }
        .checkmark {
          position: absolute;
          top: 27%;
          left: 5%;
          height: 20px;
          width: 20px;
          background-color: #fff;
          border: 1px solid #e0e0e0;
          border-radius: 50%;
        }
        .checkmark:after {
          content: '';
          position: absolute;
          display: none;
        }
        input:checked ~ .checkmark:after {
          display: block !important;
        }
        .checkmark:after {
          left: 7px;
          top: 4px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
    .time-list.active {
      border: 1px solid #43aa8b;
      background-color: #fff;
      color: #43aa8b;
    }
  }
}

.dashtable-heading .dropdown-menu.show {
  left: -50px !important;
}

.reportSection {
  font-family: 'proxima_nova_rgbold' !important;
}

.activity-main .button-common {
  height: 45px !important;
  width: 235px !important;
  border-radius: 33.75px;
  background-color: #43aa8b;
  border: 0;
  text-align: center;
  padding: 0px 20px;
  color: #fff;
  font-size: 20px;
  font-family: 'proxima_nova_rgbold';
  line-height: 24px;
  margin-top: 15px !important;
  position: relative;
  transition: 0.3s;
  opacity: 0.8;
}

.braingym-para {
  font-family: 'proxima_nova_rgregular';
  color: #454c4e;
  font-size: 18px;
}

.braingymList li {
  font-family: 'Proxima Nova';
  font-size: 16px;
  color: #454c4e;
  list-style: none;
  display: inline-block;
}

.braingymList .li-one {
  margin-right: 23px;
  position: relative;
}

.braingymList .li-two {
  color: #fca834;
}

.braingymList .li-one:after {
  content: '';
  position: absolute;
  right: -11px;
  height: 24px;
  width: 1px;
  background: #454c4e;
  top: 0px;
}

.braingymList .li-two span {
  height: 24px;
  width: 24px;
  background-color: #fca834;
  border-radius: 50%;
  position: relative;
  top: 8px;
  margin-right: 10px;
  display: inline-block;
  box-shadow: 0px 0px 2px #fe9402;
}

.braingymList span.span-background {
  width: 130px;
  height: 130px;
  display: inline-block;
  position: absolute;
  background: #e3fff6;
  bottom: 0;
  left: 0;
  border-top-right-radius: 100px;
}

.braingymList button.button-common {
  width: 303px;
  background-color: #43aa8b;
  border-radius: 33.75px;
  color: #fff;
  border: 0;
  height: 66px;
  font-weight: bold;
  font-size: 20px;
  font-family: 'proxima_nova_rgbold';
  text-align: center;
  padding: 0px 25px;
  box-shadow: 5px 5px 5px rgb(67 170 139 / 25%) !important;
  margin: 30px 0px;
  position: relative;
  z-index: 9;
  display: block;
}

.add_new_stu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.expired-sub-txt {
  width: 100%;
  text-align: center;
  a {
    color: red;
    font-size: 20px;
    font-weight: 500;
  }
}

.add_new_title {
  color: #454c4e;
  font-family: 'proxima_nova_rgregular';
  font-size: 20px;
  font-weight: bold;

  text-align: left;
}
.book-a-demo-sticky {
  display: block !important;
  height: 60px;
  width: 260px;
  font-size: 18px;
  cursor: pointer;
  position: fixed;
  bottom: 70px;
  right: 10px;
  z-index: 1000;
  transition: all 0.3s ease 0s;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  opacity: 1 !important;
}
#book-a-demo-sticky-id {
  display: block !important;
}
.book-demo-success {
  .modal-dialog {
    min-width: 59%;
  }
  .register-main {
    min-height: 0;
    background: none;
    box-shadow: none;
    padding-bottom: 0;
    .success_width {
      margin: 0;
      width: 100%;
      .step-body {
        padding: 0;
        margin: 0;
        .success-part {
          p {
            margin: 0;
            font-size: 22px;
            padding-bottom: 0;
            line-height: 28px;
            width: 100%;
          }
          p:nth-child(1) {
            font-weight: bold;
          }
          .flip {
            width: 130px;
            height: 130px;
          }
          h1 {
            margin: 15px 0;
          }
          .margin_top_success {
            margin-top: 3px;
          }
        }
      }
    }
    .success-button {
      button {
        padding: 10px;
        margin-top: 10px;
        width: 20%;
      }
    }
  }
  .success {
    padding-top: 0;
  }
}

//Ninganagouda media query changes//
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #book-a-demo-sticky-id {
    bottom: 65px;
    width: 204px;
  }
}
@media only screen and (max-width: 767px) {
  #book-a-demo-sticky-id {
    bottom: 65px;
    height: 46px;
    width: 157px;
    font-size: 14px;
  }
  .book-demo-success .register-main .success_width .step-body .success-part p {
    font-size: 17px;
    padding-bottom: 0;
    line-height: 20px;
  }
  .book-a-demo-popup .modal-dialog .register-main .success-button button {
    width: 31%;
    padding: 6px;
  }
}
@media only screen and (max-width: 991px) {
  .book-demo-success .register-main .success-button button {
    width: 50%;
  }
  .book-demo-success .register-main .success_width .step-body {
    max-width: 100%;
  }
  .book-demo-success {
    padding-right: 0;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

//Ninganagouda media query changes//
