.footer-main {
  .footer-top {
    padding: 60px 50px 20px 50px;
    background-color: #f0f4fa;
    p {
      color: #20282a;
      font-family: 'proxima_nova_rgregular';
      font-size: 18px;
      letter-spacing: 0;
      line-height: 25px;
      // text-align: justify;
    }
    .footer-top-left {
      img {
        width: 248px;
        height: 63px;
        margin-bottom: 30px;
      }
      ul {
        padding: 0;
        margin: 0;
        margin-top: 40px;
        li {
          list-style-type: none;
          display: inline-block;
          margin-right: 33px;
          cursor: pointer;
          img {
            height: 35px;
            width: 35px;
          }
        }
      }
    }
    .footer-top-right {
      h4 {
        font-size: 18px;
        font-family: 'Proxima Nova';
        color: #20282a;
        border-bottom: 1px solid #cecece;
        padding-bottom: 20px;
        margin-bottom: 10px;
      }
      ul {
        margin: 0;
        padding: 0;
        li {
          font-family: 'proxima_nova_rgregular';
          font-size: 16px;
          line-height: 40px;
          color: #4e5d61;
          list-style: none;
          cursor: pointer;
        }
      }
    }
  }
  .footer-bottom {
    height: 66px;
    background-color: #34373b;
    padding: 25px 40px;
    color: #fff;
    font-family: 'proxima_nova_rgregular';
    .text-right {
      text-align: right;
      .fotter_text {
        color: white;
        text-decoration: none;
      }
    }
  }
}

.footer_style {
  text-decoration: none;
  color: #4e5d61 !important;
}

@media only screen and (max-width: 1070px) {
  .footer-main {
    .footer-top {
      .footer-top-left {
        img {
          width: 200px;
          height: 50px;
        }
        p {
          font-size: 19px;
        }
        li {
          margin-bottom: 30px;
        }
      }
      .footer-top-right {
        li {
          font-size: 15px !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 815px) {
  .footer-main .footer-bottom {
    height: auto;
    padding: 15px;
    p {
      margin-bottom: 0;
      font-size: 13px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .footer-top-right {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .footer-top {
    padding: 60px 20px !important;
    p {
      font-size: 18px !important;
    }
  }
  .footer-bottom {
    .text-right,
    .text-left {
      text-align: center !important;
    }
  }
}

.stickyFooterMobile {
  display: none;
}

@media only screen and (min-width: 320px) and (max-width: 375px) {
  .stickyFooterMobile {
    height: 100px;
    background-color: #fff;
    position: sticky;
    bottom: 0px;
    text-align: center;
    width: 100%;
    z-index: 999;
    box-shadow: rgb(0 0 0 / 10%) 0px -2px 12px 1px;
    display: none;
    justify-content: space-evenly;
    align-items: center;
  }

  .stickyFooterMobile .stickyFooterInside {
    display: flex;
    justify-content: center;
  }

  .stickyFooterMobile img {
    width: 140px;
    padding: 0 5px;
  }
}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .stickyFooterMobile {
    height: 100px;
    background-color: #fff;
    position: sticky;
    bottom: 0px;
    text-align: center;
    width: 100%;
    z-index: 999;
    box-shadow: rgb(0 0 0 / 10%) 0px -2px 12px 1px;
    display: none;
    justify-content: space-evenly;
    align-items: center;
  }

  .stickyFooterMobile .stickyFooterInside {
    display: flex;
    justify-content: center;
  }

  .stickyFooterMobile img {
    width: 160px;
    padding: 0 10px;
  }
}

@media only screen and (min-width: 426px) and (max-width: 1025px) {
  .stickyFooterMobile {
    height: 100px;
    background-color: #fff;
    position: sticky;
    bottom: 0px;
    text-align: center;
    width: 100%;
    z-index: 999;
    box-shadow: rgb(0 0 0 / 10%) 0px -2px 12px 1px;
    display: none;
    justify-content: space-evenly;
    align-items: center;
  }

  .stickyFooterMobile .stickyFooterInside {
    display: flex;
    justify-content: center;
  }

  .stickyFooterMobile img {
    // width: 160px;
    padding: 0 10px;
  }
}

@media only screen and (min-width: 700px) and (max-width: 900px) {
  .footer-main .footer-top .footer-top-left {
    ul {
      li {
        margin-right: 23px !important;
      }
    }
  }

  .footer-main .footer-top .footer-top-left p {
    font-size: 17px;
  }

  .size_changes {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .stickyFooterMobile {
    height: 100px;
    background-color: #fff;
    position: sticky;
    bottom: 0px;
    text-align: center;
    width: 100%;
    z-index: 999;
    box-shadow: rgb(0 0 0 / 10%) 0px -2px 12px 1px;
    display: none;
    justify-content: space-evenly;
    align-items: center;
  }
  .book-demo-stickyFooterMobile {
    box-shadow: none;
    background: transparent;
  }

  .stickyFooterMobile .stickyFooterInside {
    display: flex;
    justify-content: center;
  }

  .stickyFooterMobile img {
    // width: 160px;
    width: 160px;
    padding: 0 10px;
  }
}
