.width-size {
  width: 100% !important;
  border-color: rgb(207, 207, 207) !important;
  border-radius: 7px !important;
}
.css-1okebmr-indicatorSeparator {
  width: 0px !important;
}
// .css-1s2u09g-control{
//   border-color: rgb(207, 207, 207) !important;
//   border-radius: 7px !important;
// }
.font-font {
  font-family: 'Proxima Nova' !important;
  padding-top: 60px !important;
}
.row-position {
  position: relative;
  height: 50% !important;
}
.leave-reason {
  white-space: nowrap;
  max-width: 75px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.approved {
  display: flex;
  align-items: center;
}
.denied {
  display: flex;
  align-items: center;
}
.pending {
  display: flex;
  align-items: center;
}
.calander-customeTd {
  display: flex;
  align-items: center;
}

.calander-divwrap {
  display: flex;
  align-items: center;
  // flex-direction: column;
  p {
    margin: 0px;
  }
}
