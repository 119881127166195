.right-answer-modal-new {
  border-radius: 0 !important;
  height: 645px !important;
}

.right-answer-container-new {
  width: 60% !important;
}

.floatLetft {
  float: left;
}

.progressWithContent {
  background-color: #7d93f2;
  // height: 180px;
  border-radius: 25px;
  padding: 15px 0px;
}

.progressBarAssign {
  height: 100px;
  width: 100px;
  /* padding: 10px; */
  margin: auto;
}

.weeklyOlympiad {
  background: linear-gradient(#a9fce3 0%, #e3fff6 100%);
  border-radius: 25px;
  width: 98%;
  float: left;
  padding: 12px 12px 0px 15px;
}

.halfYearlyOlym {
  background: linear-gradient(#ffdfab 0%, #fff5e6 100%);
  border-radius: 25px;
  width: 98%;
  float: right;
  padding: 12px 12px 0px 15px;
}

.assignLayout {
  display: flex;
  justify-content: space-between;
  border-top: 1pt solid #e5e5e5;
}

.seperateBorder {
  border-bottom: 1pt solid #e5e5e5;
}

.rightAnsAvgSpeed {
  background: #f8f8fa;
  padding: 25px;
  margin-top: 20px;
  border-radius: 25px;
}

.intelliScoreHead {
  color: #ffffff;
  font-family: 'proxima_nova_rgbold';
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 15px;
}

.intelliScoreSub {
  color: #ffffff;
  font-family: 'proxima_nova_rgregular';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}

.glimpseTake {
  color: #20282a;
  font-family: 'proxima_nova_rgbold';
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 31px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.percentageAssign {
  color: #43aa8b;
  font-family: 'proxima_nova_rgbold';
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 7px;
}

.weekly-olympiad {
  color: #444b4d;
  font-family: 'proxima_nova_rgbold';
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
}

.ready-for-the-exam {
  color: #697b81;
  font-family: 'proxima_nova_rgregular';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}

.percentageSecond {
  color: #fca834;
  font-family: 'proxima_nova_rgbold';
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 7px;
}

.half-yearly-olympiad {
  color: #444b4d;
  font-family: 'proxima_nova_rgbold';
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
}

.need-5-more {
  color: #697b81;
  font-family: 'proxima_nova_rgregular';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}

.total-number-of-ques {
  color: #fca834;
  font-family: 'proxima_nova_rgregular';
  font-size: 20px;
  letter-spacing: 0;
  line-height: 45px;
  margin: 0;
}

.total-number-of-ques-numbers {
  color: #fca834;
  font-family: 'proxima_nova_rgbold';
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 45px;
  margin: 0;
}

.right-answers {
  color: #20282a;
  font-family: 'proxima_nova_rgregular';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
  margin: 0;
  text-align: center;
}

.right-ans-marks {
  color: #20282a;
  font-family: 'proxima_nova_rgbold';
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  margin: 0;
  text-align: center;
}

.borderRight {
  border-right: 1pt solid #dbdbdb;
}

.assignViewReport {
  padding-top: 10px;
  font-family: 18px;
  font-family: 'proxima_nova_rgbold';
  color: #43aa8b;
}
