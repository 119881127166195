.sub-title {
  width: 50%;
  display: flex;
  margin-top: 22px;
  margin-bottom: 35px;
  h6 {
    color: #1b1b1b;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
    margin-left: 50px;
  }
  span {
    color: #43aa8b;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
    margin-left: 72px;
  }
}
.nxt-video {
  height: 35px;
  width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #e3fff6;
  color: #43aa8b;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  margin-left: 60px;
  margin-bottom: 20px;
}

.tree {
  margin:auto;
  box-sizing: border-box;
  max-height: 314px;
  max-width: 617px;
  // border: 0.5px solid #979797;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 23px 0px 0px 23px;
  overflow: scroll;
  position: relative;

  .view {
    position: sticky;
    bottom: 10px;
    left: 10px;
    color: #43aa8b;
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
    width: 60px;
  }
}
.video-tree{
  width: 82% !important;
  max-width: 82% !important;
  max-height: 400px !important;
}
.video-tree-concept{
  width: 82% !important;
  max-width: 82% !important;
  max-height: auto !important;
  height: auto !important;
  overflow: unset !important;
  margin: auto;
}


.full-tree {
  margin: 0 auto;
  box-sizing: border-box;
  border: 0.5px solid #979797;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 23px 0px 0px 23px;
  overflow: scroll;
  position: relative;

  .view {
    position: sticky;
    bottom: 10px;
    left: 10px;
    color: #43aa8b;
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
    width: 60px;
  }
}

.tree::-webkit-scrollbar {
  width: 6px;
}

.tree::-webkit-scrollbar:horizontal {
  height: 6px;
}

.tree::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border-radius: 3px;
  background-color: #cbcdcd;
}

.videos-tree {
  position: relative;

  .side-line {
    width: 1px;
    border-left: 2.5px dashed rgba(27, 27, 27, 0.2);
    height: 94%;
    padding: 0.5px;
    position: absolute;
    left: 0px;
    top: 10px;
  }

  .branch {
    display: flex;
    align-items: center;
    margin-bottom: 26px;

    .branch-line {
      height: 1px;
      margin-left: 4px;
      margin-right: 9px;
      border-bottom: 2.5px dashed rgba(27, 27, 27, 0.2);
    }
    .video-card{
      background: #FAFAFA;
      padding: 1rem;
      width: 100%;
      }
    .type {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 5px 10px;
      border: 0.5px solid #43aa8b;
      border-radius: 2px;
      img {
        margin-right: 5px;
      }
      p {
        color: #43aa8b;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 12px;
        margin: 0;
        padding: 0;
      }
    }
    a {
      text-decoration: none;
      h4 {
        color: #1b1b1b;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 20px;
        white-space: nowrap;
        margin: 0;
        margin-left: 20px;
        margin-right: 10px;
        padding: 0;
      }
    }
  }
}

.btn-wrap {
  margin-top: 20px;
  text-align: center;
}

.btn-normal {
  height: 50px;
  width: 305px;
  border-radius: 33.75px;
  background-color: #43aa8b;
  color: #ffffff;
  font-family: 'Proxima Nova';
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}

.topic-wrap {
  padding: 50px;
  .single-topic {
    h4 {
      color: #43aa8b;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22px;
    }
    p {
      color: #20282a;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 23px;
      margin-bottom: 15px 0px;
    }

    .markdown {
      strong {
        color: #20282a;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 17px;
      }

      h6 {
        color: #43aa8b;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 17px;
      }

      p {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        img {
          margin: 10px 7.5px;
          // width: 155px;
          // height: 100%;
          // object-fit: contain;
        }
      }
    }
  }
}

.dashboard-main .row-main .dashboard-right .myconcept-desc .desc-top li a {
  color: #43aa8b !important;
  margin-left: 10px;
}

.ReactModal__Overlay {
  z-index: 99999 !important;
}

.dashboard-main
  .row-main
  .dashboard-right
  .mysubject-count-top
  .carousel-control-prev
  span {
  display: none;
}

.dashboard-main
  .row-main
  .dashboard-right
  .mysubject-count-top
  .carousel-control-next
  span {
  display: none;
  width: 5%;
}

.dashboard-main
  .row-main
  .dashboard-right
  .mysubject-count-top
  .carousel-control-prev {
  width: 0%;
  top: -15px;
}

.dashboard-main
  .row-main
  .dashboard-right
  .mysubject-count-top
  .carousel-control-next {
  width: 8%;
  top: -15px;
}

.dashboard-main
  .row-main
  .dashboard-right
  .mysubject-count-top
  .carousel-inner
  span {
  font-size: 18px;
  color: #000;
  font-family: 'proxima_nova_rgbold';
}

.dashboard-main .row-main .dashboard-right .myconcept-desc {
  padding: 20px;
  background-color: #fff;
}

.dashboard-main .row-main .dashboard-right .myconcept-desc .desc-top h5 {
  font-size: 20px;
  color: #20282a;
  font-family: 'proxima_nova_rgbold';
}

.dashboard-main .row-main .dashboard-right .myconcept-desc .desc-top li {
  color: #43aa8b;
  list-style: none;
  font-size: 18px;
  text-decoration: underline;
  font-family: 'proxima_nova_rgbold';
  display: flex;
  align-items: center;
}

.dashboard-main .row-main .dashboard-right .myconcept-desc .desc-top button {
  width: 100%;
  max-width: 243px;
  background-color: #43aa8b;
  border-radius: 33.75px;
  color: #fff;
  border: 0;
  height: 50px;
  font-weight: 700;
  font-size: 18px;
  font-family: 'proxima_nova_rgbold';
  text-align: left;
  padding: 0 20px;
  box-shadow: none;
  position: relative;
  z-index: 9;
  display: block;
  float: right;
  margin-top: 15px;
  transition: 0.3s;
  opacity: 0.8;
}

.dashboard-main
  .row-main
  .dashboard-right
  .myconcept-desc
  .desc-top
  button:hover {
  box-shadow: 5px 5px 5px rgba(67, 170, 139, 0.25) !important;
  opacity: 1 !important;
}

.dashboard-main
  .row-main
  .dashboard-right
  .myconcept-desc
  .desc-top
  button
  span {
  float: right;
  height: 22px;
  width: 22px;
  background-color: #fca834;
  border-radius: 50%;
  padding: 0 7px;
  box-shadow: none;
  display: flex;
  align-items: center;
  margin-top: 2px;
}
.sub-topic-wrapper{
  position:unset !important;
  padding: 0 !important;
  overflow: unset !important;
  // height: auto !important;
}
.sub-dashboard-main-row{
  background: white;
}