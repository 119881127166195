.unselectable {
  body {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.ndfHFb-c4YZDc-Wrql6b {
  display: none !important;
}

.model-close-button {
  position: absolute;
  right: 0;
  top: 0;
}
.videoBox {
  min-width: 720px;
  min-height: 480px;
}

.pg-viewer-wrapper .document-container {
  width: 1200px !important;
}

.modalContentScroll {
  height: 650px;
  overflow: scroll;
}
