.dropdown {
  position: relative;
  color: #333;
  cursor: default;
  box-sizing: border-box;
  height: 66px;
  width: 540px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: #ffffff;
}

.dropdown .selected-value {
  display: flex;
}

.dropdown .selected-value .country-flag-and-selected-value {
  display: flex;
}

.dropdown .selected-value .country-flag {
  width: 35px;
  height: 21px;
  margin-right: 10px;
}

.dropdown .arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  display: block;
  height: 0;
  margin-top: 0.3rem;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.dropdown .arrow.open {
  border-color: transparent transparent #999;
  border-width: 0 5px 10px;
}

.dropdown .selected-value input {
  margin: -1px;
  line-height: 2.5;
  font-size: 1.2rem;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
  width: 100%;
  box-sizing: border-box;
  height: 52px;
  width: 540px;
  border: 1px solid #e0e0e0;
  border-radius: 0px 10px 10px 0px;
  background-color: #ffffff;
}

.dropdown .options {
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.dropdown .options .country-flag {
  width: 35px;
  height: 21px;
  margin-right: 10px;
}

.dropdown .options.open {
  display: block;
}

.dropdown .option {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 1);
  cursor: pointer;
  display: block;
  padding: 20px 12px;
}

.dropdown .option.selected,
.dropdown .option:hover {
  /* background-color: #f2f9fc; */
  background-color: #e3fff6;
  color: #43aa8b;
}

.chevDown {
  position: absolute;
  top: 0;
  right: 0;
}

svg.bi.bi-chevron-down {
  stroke-width: 2.3px !important;
  stroke: gray;
}

input[type='text']::placeholder {
  line-height: 3;
  font-size: 20px;
}

/* .dropdown {
      position: relative;
      color: #333;
      cursor: default;
      box-sizing: border-box;
      height: 66px;
      width: 540px;
      border: 1px solid #E0E0E0;
      border-radius: 10px;
      background-color: #FFFFFF;
      
    }
  
    .dropdown .selected-value{
      display: flex;
    }
  
    .dropdown .selected-value .country-flag-and-selected-value{
  display: flex;
    }
  
     .dropdown .selected-value .country-flag{
      width:35px;
      height:21px;
      margin-right:10px
  
    }
  
    .dropdown .arrow {
      border-color: #999 transparent transparent;
      border-style: solid;
      border-width: 5px 5px 0;
      content: " ";
      display: block;
      height: 0;
      margin-top: 0.3rem;
      position: absolute;
      right: 10px;
      top: 14px;
      width: 0;
    }
    
    .dropdown .arrow.open {
      border-color: transparent transparent #999;
      border-width: 0 5px 10px;
    }
    
    .dropdown .selected-value input {
        margin:-1px;
      line-height: 1.5;
      font-size: 1.2rem;
      border-radius: 2px;
      box-sizing: border-box;
      cursor: default;
      outline: none;
      padding: 8px 52px 8px 10px;
      transition: all 200ms ease;
      width: 100%;
      box-sizing: border-box;
      height: 66px;
      width: 540px;
      border: 1px solid #E0E0E0;
      border-radius: 10px;
      background-color: #FFFFFF;
    }
    
    .dropdown .options {
      display: none;
      background-color: #fff;
      border: 1px solid #ccc;
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
      box-sizing: border-box;
      margin-top: -1px;
      max-height: 200px;
      overflow-y: auto;
      position: absolute;
      top: 100%;
      width: 100%;
      z-index: 1000;     
      -webkit-overflow-scrolling: touch;
    }
  
    .dropdown .options .country-flag{
        width:35px;
        height:21px;
        margin-right:10px
  
    }
    
    .dropdown .options.open {
      display: block;
    }
    
    .dropdown .option {
      box-sizing: border-box;
      color: rgba(51, 51, 51, 0.8);
      cursor: pointer;
      display: block;
      padding: 8px 10px;
    }
    
    .dropdown .option.selected,
    .dropdown .option:hover {
      background-color: #f2f9fc;
      color: #333;
    }
     */
