.modal-content {
  .modal-header {
    background-color: none !important;
  }
}

.title-orange-color-text {
  color: #f3722c;
}
.title-orange-color-text::after {
  display: none;
}
// .vp-center {
//   display: none !important;
//   align-items: none !important;
// }

.modal__wrapper__vimeo {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1000;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.65);
  // filter: blur(4px);
  .modal__container {
    z-index: 1000;
    .modal__container-body {
      position: relative;
      width: 500px;
      height: 500px;
      @media only screen and (min-width: 320px) and (max-width: 600px) {
        width: 300px;
        height: 300px;
      }

      .modal__conatiner-header {
        text-align: center;
        button {
          position: absolute;
          top: 90px;
          right: -20px;
          width: 40px;
          height: 40px;
          font-size: 20px;
          font-weight: 700;
          border: none;
          border-color: transparent;
          background-color: #ffffff;
          // padding: 25px;
          border-radius: 50%;
          @media only screen and (min-width: 320px) and (max-width: 600px) {
            right: -20px;
            top: 40px;
          }
        }
      }
    }
  }
}
// .video__width {
//   width: 50vw;
// }

// .modal_header_homepage_video {
//   display: flex;
//   justify-content: end;
// }

// .modal__wrapper--vimeo {
//   .modal {
//     background-color: red !important;
//   }
//   .modal-dialog {
//     .modal-content {
//       background-color: transparent !important;
//       border: none !important;
//       box-shadow: none !important;
//     }
//   }
// }

.banner-left::before {
  content: '';
  top: -60px;
  z-index: -1;
  left: 170px;
  position: absolute;
  height: 230px;
  width: 280px;
  border-radius: 90px 0 90px 90px;
  background-color: #fff6e9;
}

.custom-container {
  max-width: 1920px;
}

// html {
//   scroll-behavior: smooth;
//   transition-duration: 2ms;
// }

.banner-left::after {
  content: '';
  top: -40px;
  z-index: -1;
  left: 20px;
  position: absolute;
  height: 30px;
  width: 35px;
  border-radius: 10px 0 10px 10px;
  background-color: #e3f1ff;
}

.tautmore_content {
  padding-bottom: 80px;
  padding-top: 15px;
  // padding: 15px 70px 80px 70px !important;
  .tcontent_heading {
    margin-bottom: 50px;
    color: #20282a;
    font-family: 'proxima_nova_rgbold';
    font-size: 50px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 64px;
    margin: 0 30px;
  }
}

.tautmore_info {
  border-radius: 35px;
  background-color: #ffffff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
  padding: 15px 25px 15px 25px;
  // margin-top: 20px;
  margin: 20px 30px 20px 30px;
  .tautmore_text {
    color: #20282a;
    font-family: 'proxima_nova_rgregular';
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 24px;
  }

  .tautmore_text2 {
    // color: #20282A;
    font-family: 'proxima_nova_rgregular';
    font-size: 20px;
    letter-spacing: 0;
    line-height: 27px;
  }
  .tautmore_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //  .tautmore_text2{
    //   color: #20282A;
    //   font-family: "Proxima Nova";
    //   font-size: 21px;
    //   letter-spacing: 0;
    //   line-height: 32px;
    //  }
  }
}
.list-style-accordian {
  display: inline-block;
  height: 14px;
  width: 14px;
  background-color: #fca834;
  border-radius: 50%;
  margin-right: 10px;
}

.sub-list-main {
  padding: 10px 40px;
  margin: 0 auto 0 auto !important;
  display: flex;
  .sub-list-each {
    display: inline-block;
    transition: 0.4s ease;
    width: 14.4%;
    margin: auto;
    text-align: center;
    // margin-right: 30px;
    /* border: 1px solid; */

    // background: #f9f9f9;
    border-radius: 10px;
    cursor: pointer;
    a {
      display: block;
      padding: 10px 15px;
    }
  }
  .sub-list-each:hover {
    background: #e3fff6;
  }
  div.active {
    background: #e3fff6;
    position: relative;
    // padding: 10px 15px;
    border-radius: 10px 10px 0px 0px;
    border-bottom: 3px solid #43aa8b;
    // margin-top: -15px;
    a {
      display: block;
      padding: 10px 15px;
    }
  }

  div:last-child {
    margin-right: 0;
    background: transparent;
    // text-align: right;
    color: #43aa8b;
    padding-right: 0;
    font-size: 20px;
    font-family: 'proxima_nova_rgbold';
    line-height: 24px;
    span {
      cursor: pointer;
    }
    svg {
      margin-right: 0;
      position: relative;
      top: -1px;
    }
  }
  img {
    // height: 40px;
    width: 35px !important;
    margin-right: 10px;
    display: inline-block;
    // margin-top: -8px;
  }
  h5 {
    margin-bottom: 0;
    color: #454c4e;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    display: inline-block;
    vertical-align: middle;
    // margin-top: -7px;
  }
}
.sub-list-main.mainactive {
  div {
    text-align: center;
  }
  // div:last-child {
  //   text-align: right;
  // }
}

.banner-main {
  padding: 90px;
  padding-top: 90px;
  .banner-left {
    color: #20282a;
    position: relative;

    h4 {
      font-family: 'proxima_nova_rgbold';
      font-size: 50px;
      line-height: 62px;
      margin: 0;
      font-weight: bold;
      letter-spacing: 0;
      position: relative;
      z-index: 9;
      span {
        position: relative;
        color: #f3722c;
        z-index: 9;
      }
      // span:after {
      //   content: '';
      //   position: absolute;
      //   width: 300px;
      //   height: 260px;
      //   border-radius: 90px 0px 90px 90px;
      //   background-color: #fff6e9;
      //   top: -57px;
      //   z-index: -1;
      //   right: -15%;
      // }
    }
    p {
      color: #20282a;
      font-family: 'proxima_nova_rgregular';
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0;
      margin: 45px 40px 15px 0px;
      position: relative;
      z-index: 9;
    }
    button {
      width: 303px;
      background-color: #43aa8b;
      border-radius: 33.75px;
      color: #fff;
      border: 0;
      height: 66px;
      font-weight: bold;
      font-size: 18px;
      font-family: 'proxima_nova_rgbold';
      text-align: center;
      padding: 0px 25px;
      box-shadow: none;
      transition: 0.3s;
      opacity: 0.8;
      span {
        float: right;
        height: 24px;
        width: 24px;
        background-color: #fca834;
        border-radius: 50%;
        padding: 0px 7px;
        margin-top: 4px;
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .banner-left-bottom-icon {
      width: 65px;
      height: 56px;
      border-radius: 17px 0px 17px 17px;
      background-color: #e3fff6;
      display: inline-block;
      margin-left: 60px;
      position: relative;
      top: 25px;
    }
  }
  .banner-right {
    img {
      margin-top: -50px;
      width: 100%;
    }
  }
}

.learningprogram-main {
  background-color: #e3fff6;
  border-radius: 90px 0px 90px 0px;
  margin-top: 50px;
  padding: 120px 60px 50px 50px;
  .learning-progress-left {
    h4 {
      margin: 0;
      color: #20282a;
      font-family: 'proxima_nova_rgbold';
      font-size: 36px;
      line-height: 48px;
      letter-spacing: 0;
      font-weight: bold;
      position: relative;
      z-index: 9;
      span.our {
        position: relative;
        z-index: 9;
      }
      span.our:after {
        content: '';
        position: absolute;
        width: 91px;
        height: 78px;
        border-radius: 0px 31px 31px 31px;
        background-color: #fff6e9;
        top: -18px;
        z-index: -1;
        right: -15%;
      }
      span.learn {
        position: relative;
        z-index: 9;
      }
      span.learn:after {
        content: '';
        position: absolute;
        width: 35px;
        height: 30px;
        border-radius: 10px 0px 10px 10px;
        background-color: #e3e5ff;
        top: -45px;
        z-index: -1;
        right: 5%;
      }
    }
    p {
      margin-top: 25px;
      font-family: 'proxima_nova_rgregular';
      font-size: 18px;
      line-height: 24px;
      color: #20282a;
    }
  }
  .learning-progress-right {
    position: relative;
    z-index: 9;
    .secondtop {
      position: relative;
    }
    .learning-block-main {
      background-color: #fff;
      margin-bottom: 20px;
      padding: 10px 10px;
      border-radius: 80px 0 80px 80px;
      min-height: 210px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.07) !important;
      transition: 0.5s;
      cursor: pointer;
      .icon {
        height: 70px;
        width: 70px;
        background-color: #d3fff1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        margin-left: 20px;
        font-size: 30px;
      }
      h5 {
        margin: 0;
        color: #277da1;
        font-family: 'proxima_nova_rgbold';
        font-size: 18px;
        line-height: 24px;
      }
      p {
        font-family: 'proxima_nova_rgregular';
        font-size: 16px;
        line-height: 24px;
        color: #20282a;
        margin-top: 10px;
        margin-bottom: 0;
      }
      .learning-right-icon svg {
        color: #d9d9d9;
        width: 19px;
        height: 16px;
      }
    }
    .learning-block-main:hover {
      background: linear-gradient(#1ee9ba, #43aa8b);
      h5,
      p {
        color: #fff !important;
      }
      .icon {
        background-color: #358e74 !important;
        color: #fff !important;
      }
      .learning-right-icon svg {
        color: #fff !important;
        width: 39px !important;
        height: 16px !important;
      }
    }
    .learning-block-main.first {
      position: relative;
      .icon {
        background-color: #d3fff1;
        color: #52b194;
      }
    }
    .learning-block-main.first:after {
      content: '';
      position: absolute;
      width: 62px;
      height: 53px;
      border-radius: 22px 0 22px 22px;
      background-color: #c9fcbc;
      top: -75px;
      right: -3%;
    }
    .learning-six-second {
      position: relative;
      z-index: 9;
      .learning-block-main.second {
        .icon {
          background-color: #358e74;
          color: #fff;
        }
      }
    }
    .learning-block-main.second::after {
      content: '';
      position: absolute;
      width: 143px;
      height: 123px;
      border-radius: 47px 0 47px 47px;
      background-color: #c5f7e7;
      z-index: -1;
      top: -47px;
      right: -6%;
    }
    .learning-block-main.third {
      position: relative;
      .icon {
        background-color: #fff9da;
        color: #fdca79;
      }
    }
    .learning-block-main.third:after {
      content: '';
      position: absolute;
      width: 35px;
      height: 30px;
      border-radius: 10px 0 10px 10px;
      background-color: #ffe6c1;
      bottom: -15px;
      right: -3%;
    }
    .learning-block-main.fourth {
      .icon {
        background-color: #f9e7fd;
        color: #dc50fa;
      }
    }
  }
}
.counter-main {
  padding: 100px 80px;
  .counter-common {
    height: 152px;
    border-radius: 0 76px 76px 76px;
    background: linear-gradient(to right, #f1f1ff, #ddddff);
    display: flex;
    align-items: center;
    padding: 0px 45px;
    h4 {
      color: #fca834;
      font-size: 30px;
      line-height: 50px;
      font-family: 'proxima_nova_rgbold';
      margin: 0;
    }
    p {
      font-family: 'proxima_nova_rgregular';
      color: #20282a;
      font-size: 20px;
      line-height: 22px;
      margin: 0;
    }
  }
}
.classroom-main {
  padding: 0px 40px;
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
    }
  }
  .classroom-left {
    img {
      margin-top: 40px;
      width: 100%;
    }
  }
  .classroom-right {
    color: #20282a;
    // font-family: 'proxima_nova_rgregular';
    display: flex;
    padding: 50px;
    h4 {
      color: #20282a;
      font-family: 'proxima_nova_rgbold';
      font-size: 48px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 64px;
      span {
        position: relative;
        z-index: -1;
      }
      span:after {
        content: '';
        position: absolute;
        height: 66px;
        width: 85px;
        border-radius: 25px 0 25px 25px;
        background-color: #fff6e9;
        top: -10px;
        z-index: -1;
        right: -15%;
      }
    }
    p {
      color: #20282a;
      font-family: 'proxima_nova_rgregular';
      font-size: 20px;
      letter-spacing: 0;
      line-height: 32px;
      margin: 30px 0px;
    }
    li {
      color: #20282a;
      font-family: 'proxima_nova_rgregular';
      font-size: 18px;
      letter-spacing: 0;
      line-height: 36px;
      svg {
        height: 14px;
        width: 14px;
        margin-right: 5px;
        color: #fca834;
      }
    }
    button {
      height: 66px;
      width: 303px;
      border-radius: 33.75px;
      background-color: #43aa8b;
      box-shadow: 0 12px 10px 0 rgba(67, 170, 139, 0.25);
      border: 0;
      text-align: center;
      padding: 0px 20px;
      color: #fff;
      font-size: 18px;
      font-family: 'proxima_nova_rgbold';
      line-height: 24px;
      margin-top: 40px;
      transition: 0.3s;
      opacity: 0.8;
      span {
        float: right;
        height: 24px;
        width: 24px;
        background-color: #fca834;
        border-radius: 50%;
        padding: 0px 7px;
        margin-top: 0px;
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          margin-top: 2px;
        }
      }
    }
  }
}

.activities-main {
  padding: 50px 40px 0px 40px;
  .activity-left {
    margin-top: 50px;
    display: flex;
    // align-items: center;
  }
  .activity-right {
    img {
      margin-top: -60px;
      width: 100%;
    }
  }

  h4 {
    color: #20282a;
    font-family: 'proxima_nova_rgbold';
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 54px;
    position: relative;
    z-index: 9;
    span {
      position: relative;
    }
    span:after {
      content: '';
      position: absolute;
      height: 76px;
      width: 85px;
      border-radius: 25px 0 25px 25px;
      background-color: #e3fff6;
      top: -10px;
      z-index: -1;
      right: -60%;
    }
  }
  p {
    color: #20282a;
    font-family: 'proxima_nova_rgregular';
    font-size: 20px;
    letter-spacing: 0;
    line-height: 32px;
    margin: 45px 0px;
    margin-bottom: 35px;
  }
  li {
    color: #20282a;
    font-family: 'proxima_nova_rgregular';
    font-size: 18px;
    letter-spacing: 0;
    line-height: 36px;
    svg {
      height: 14px;
      width: 14px;
      margin-right: 5px;
      color: #fca834;
    }
  }
  button {
    height: 66px;
    width: 303px;
    border-radius: 33.75px;
    background-color: #43aa8b;
    box-shadow: none;
    border: 0;
    text-align: center;
    padding: 0px 20px;
    color: #fff;
    font-size: 20px;
    font-family: 'proxima_nova_rgbold';
    line-height: 24px;
    margin-top: 40px;
    span {
      float: right;
      height: 24px;
      width: 24px;
      background-color: #fca834;
      border-radius: 50%;
      padding: 0px 7px;
      margin-top: 0px;
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-top: 2px;
      }
    }
  }
}

.olympiad-main {
  padding: 0px 40px 0px 40px;
  // padding-top: 0;

  .olympiad-right {
    img {
      width: 100%;
    }
  }
  .olympiad-left {
    display: flex;
    // align-items: center;
  }

  h4 {
    margin-top: 50px;
    color: #20282a;
    font-family: 'proxima_nova_rgbold';
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 54px;
    position: relative;
    z-index: 9;
    span {
      position: relative;
    }
    span:after {
      content: '';
      position: absolute;
      height: 76px;
      width: 85px;
      border-radius: 25px 0 25px 25px;
      background-color: #fff6e9;
      top: -10px;
      z-index: -1;
      right: -16%;
    }
  }
  p {
    color: #20282a;
    font-family: 'proxima_nova_rgregular';
    font-size: 20px;
    letter-spacing: 0;
    line-height: 32px;
    margin: 45px 0px;
    margin-bottom: 35px;
  }
  li {
    color: #20282a;
    font-family: 'proxima_nova_rgregular';
    font-size: 18px;
    letter-spacing: 0;
    line-height: 36px;
    svg {
      height: 14px;
      width: 14px;
      margin-right: 5px;
      color: #fca834;
    }
  }
  button {
    height: 66px;
    width: 303px;
    border-radius: 33.75px;
    background-color: #43aa8b;
    box-shadow: none;
    border: 0;
    text-align: center;
    padding: 0px 20px;
    color: #fff;
    font-size: 18px;
    font-family: 'proxima_nova_rgbold';
    line-height: 24px;
    margin-top: 40px;
    span {
      float: right;
      height: 24px;
      width: 24px;
      background-color: #fca834;
      border-radius: 50%;
      padding: 0px 7px;
      margin-top: 0px;
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-top: 2px;
      }
    }
  }
}

.brain-main {
  padding: 0px 40px;
  .brain-right {
    // display: flex;
    // align-items: center;
    div {
      width: 100%;
    }
  }
  .brain-left {
    cursor: pointer;
    img {
      width: 100%;
    }
  }

  h4 {
    padding: 0px 18% 0 0;
    color: #20282a;
    font-family: 'proxima_nova_rgbold';
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 0;
    position: relative;
    z-index: 9;
    span {
      position: relative;
    }
    span:after {
      content: '';
      position: absolute;
      height: 76px;
      width: 85px;
      border-radius: 25px 0 25px 25px;
      background-color: #fff6e9;
      top: -10px;
      z-index: -1;
      right: 20px;
    }
  }
  p {
    color: #20282a;
    font-family: 'proxima_nova_rgregular';
    font-size: 20px;
    letter-spacing: 0;
    line-height: 32px;
    padding-right: 140px;
    margin: 30px 0px;
    margin-bottom: 15px;
  }
  li {
    color: #20282a;
    font-family: 'proxima_nova_rgregular';
    font-size: 18px;
    letter-spacing: 0;
    line-height: 36px;

    svg {
      height: 14px;
      width: 14px;
      margin-right: 5px;
      color: #fca834;
    }
  }
  button {
    height: 66px;
    width: 353px;
    border-radius: 33.75px;
    background-color: #43aa8b;
    box-shadow: none;
    border: 0;
    text-align: center;
    padding: 0px 20px;
    color: #fff;
    font-size: 18px;
    font-family: 'proxima_nova_rgbold';
    line-height: 24px;
    margin-top: 40px;
    span {
      float: right;
      height: 24px;
      width: 24px;
      background-color: #fca834;
      border-radius: 50%;
      padding: 0px 7px;
      margin-top: 0px;
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-top: 2px;
      }
    }
  }
}

.learningjoyful-main {
  padding: 0px 40px 0px 40px;
  .learningjoyful-left {
    img {
      width: 100%;
      margin-top: -30px;
    }
  }
  .learningjoyful-right {
    // display: flex;
    // align-items: center;
    div {
      width: 100%;
    }
  }

  h4 {
    color: #20282a;
    font-family: 'proxima_nova_rgbold';
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 0;
    position: relative;
    z-index: 9;
  }
  p {
    color: #20282a;
    font-family: 'proxima_nova_rgregular';
    font-size: 20px;
    letter-spacing: 0;
    line-height: 32px;
    margin: 50px 0px;
    margin-bottom: 40px;
  }

  li {
    color: #20282a;
    font-family: 'proxima_nova_rgregular';
    font-size: 18px;
    letter-spacing: 0;
    line-height: 36px;

    svg {
      height: 14px;
      width: 14px;
      margin-right: 5px;
      color: #fca834;
    }
  }
  .displaynone_circle {
    display: none;
  }
  button {
    height: 66px;
    width: 303px;
    border-radius: 33.75px;
    background-color: #43aa8b;
    box-shadow: none;
    border: 0;
    text-align: center;
    padding: 0px 20px;
    color: #fff;
    font-size: 18px;
    font-family: 'proxima_nova_rgbold';
    line-height: 24px;
    margin-top: 40px;
    span {
      float: right;
      height: 24px;
      width: 24px;
      background-color: #fca834;
      border-radius: 50%;
      padding: 0px 7px;
      margin-top: 0px;
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-top: 2px;
      }
    }
  }
}
.physical-classroom-main {
  padding: 50px 40px;

  h4 {
    text-align: center;
    font-size: 48px;
    font-family: 'proxima_nova_rgbold';
    line-height: 44px;
    position: relative;
    z-index: 9;
    text-align: center;
    span {
      position: relative;
    }
    span:after {
      content: '';
      position: absolute;
      height: 56px;
      width: 65px;
      border-radius: 17px 0 17px 17px;
      background-color: #fae3ff;
      top: -4px;
      z-index: -1;
      right: 34px;
    }
  }
  p.main-p {
    font-size: 20px;
    line-height: 32px;
    width: 70%;
    margin: 30px auto;
    margin-bottom: 15px;
    text-align: center;
    color: #20282a;
    font-family: 'proxima_nova_rgregular';
    margin-bottom: 40px;
  }
  .common-div {
    border-radius: 80px 0 80px 80px;
    padding: 45px 40px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom right;
    transition: ease 0.4s;
    &:hover {
      background: #ffffff !important;
      box-shadow: 0px 12px 74px rgb(0 0 0 / 10%);
    }
    // min-height: 346px;

    svg {
      height: 70px;
      width: 70px;
      color: #f9b53d;
    }
    h5 {
      margin: 30px 0px 20px;
      color: #277da1;
      font-size: 20px;
      line-height: 24px;
      font-family: 'proxima_nova_rgbold';
    }
    p {
      color: #20282a;
      font-family: 'Proxima Nova';
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      // padding-bottom: 20px;
    }
  }

  .common-div-2 {
    border-radius: 80px 0 80px 80px;
    padding: 38px 20px 20px 38px;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 256px;

    svg {
      height: 70px;
      width: 70px;
      color: #f9b53d;
    }
    h5 {
      margin: 30px 0px 20px;
      color: #277da1;
      font-size: 20px;
      line-height: 24px;
      font-family: 'proxima_nova_rgbold';
    }
    // p {
    //   color: #20282a;
    //   font-family: 'Proxima Nova';
    //   font-size: 16px;
    //   letter-spacing: 0;
    //   line-height: 24px;
    //   padding-bottom: 0px;
    // }
  }
  .common-div-1 {
    //background: linear-gradient(#fffbf6, #fff0da);
    position: relative;
  }

  .common-div-1:after {
    content: '';
    position: absolute;
  }
  // .common-div-2 {
  //   background: #fff;
  //   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
  // }
  // .common-div-3 {
  //   background: linear-gradient(#fdf5ff, #f7d4ff);
  // }
  // .common-div-4 {
  //   background: linear-gradient(#f6fff8, #d5ffe1);
  // }
}

.learning-journey-main {
  padding: 80px 60px 70px 80px;
  background: #e9ffef;
  border-radius: 180px 0 180px 0;

  .learning-journey-left {
    display: flex;
    align-items: center;
  }

  .learning-journey-right {
    img {
      width: 100%;
    }
  }

  h4 {
    font-size: 45px;
    font-family: 'proxima_nova_rgbold';
    line-height: 64px;
    position: relative;
    z-index: 9;
    span {
      position: relative;
    }
    span:after {
      content: '';
      position: absolute;
      height: 78px;
      width: 91px;
      border-radius: 0px 31px 31px 31px;
      background-color: #fff6e9;
      top: -15px;
      z-index: -1;
      right: -52px;
    }
  }

  button {
    height: 66px;
    width: 303px;
    border-radius: 33.75px;
    background-color: #43aa8b;
    box-shadow: none;
    border: 0;
    text-align: center;
    padding: 0px 20px;
    color: #fff;
    font-size: 20px;
    font-family: 'proxima_nova_rgbold';
    line-height: 24px;
    margin-top: 40px;
    span {
      float: right;
      height: 24px;
      width: 24px;
      background-color: #fca834;
      border-radius: 50%;
      padding: 0px 7px;
      margin-top: 0px;
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-top: 2px;
      }
    }
  }
}
.give-gift-main {
  padding: 120px 40px;

  .border-common {
    position: relative;
    height: 50px;
    display: flex;
    max-width: 587px;
    width: 100%;
    border-radius: 0 100px 68px 68px;
    margin-left: -13px;
    span {
      border-left: 2px dotted #f9844a;
      text-align: center;
      display: inline-block;
      position: relative;
      width: 1px;
      height: 100%;
      left: 50%;
    }
    span:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #f9844a;
      left: -11px;
      top: 40%;
    }
  }
  .border-common-2 {
    span {
      border-left: 2px dotted #f9c74f;
    }
    span:after {
      border-top: 10px solid #f9c74f;
    }
  }
  .gift-common {
    display: flex;
    align-items: center;
    border-radius: 0 65px 68px 68px;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 12px 74px 0 rgba(0, 0, 0, 0.1);
    height: 106px;
    max-width: 587px;
    // width: 100%;
    //margin-bottom: 50px;
    // padding-left: 30px;
    font-family: 'proxima_nova_rgbold';

    .icon {
      height: 43px;
      width: 43px;
      border-radius: 50%;
      background: #f9844a;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 24px;
    }
    h5 {
      margin-bottom: 0;
      padding-left: 25px;
      font-family: 'Proxima Nova';
      font-size: 28px;
      line-height: 36px;
      color: #f9844a;
    }
  }

  .gift-common-2 {
    .icon {
      background: #f9c74f;
    }
    h5 {
      color: #f9c74f;
    }
  }
  .gift-common-3 {
    .icon {
      background: #43aa8b;
    }
    h5 {
      color: #43aa8b;
    }
  }
  .gift-right {
    display: flex;
    align-items: center;
    h4 {
      font-size: 48px;
      font-family: 'proxima_nova_rgbold';
      line-height: 64px;
      position: relative;
      z-index: 9;
      margin-bottom: 25px;
      margin-top: 50px;
      span.span-1 {
        position: relative;
      }
      span.span-1:after {
        content: '';
        position: absolute;
        height: 30px;
        width: 35px;
        border-radius: 10px 0 10px 10px;
        background-color: #e3e5ff;
        top: -45px;
        z-index: -1;
        right: 8px;
      }
      span.span-2 {
        position: relative;
      }
      span.span-2:after {
        content: '';
        position: absolute;
        height: 56px;
        width: 65px;
        border-radius: 17px 0 17px 17px;
        background-color: #e9ffef;
        top: -5px;
        z-index: -1;
        right: -5px;
      }
      span.span-3 {
        position: relative;
      }
      span.span-3:after {
        content: '';
        position: absolute;
        height: 30px;
        width: 35px;
        border-radius: 10px 0 10px 10px;
        background-color: #fbe8ff;
        top: 10px;
        z-index: -1;
        right: -65px;
      }
      span.span-4 {
        position: relative;
      }
      span.span-4:after {
        content: '';
        position: absolute;
        height: 91px;
        width: 106px;
        border-radius: 0px 31px 31px 31px;
        background-color: #fff6e9;
        top: 2px;
        z-index: -1;
        right: -178px;
      }
      span.span-4:before {
        content: '';
        position: absolute;
        height: 53px;
        width: 62px;
        border-radius: 22px 0 22px 22px;
        background-color: #e9ffef;
        top: 100px;
        z-index: -1;
        right: -320px;
      }
    }

    button {
      height: 66px;
      width: 303px;
      border-radius: 33.75px;
      background-color: #43aa8b;
      border: 0;
      text-align: center;
      padding: 0px 20px;
      color: #fff;
      font-size: 18px;
      font-family: 'proxima_nova_rgbold';
      line-height: 24px;
      margin-top: 40px;
      position: relative;
      transition: 0.3s;
      opacity: 0.8;
      span {
        float: right;
        height: 24px;
        width: 24px;
        background-color: #fca834;
        border-radius: 50%;
        padding: 0px 7px;
        margin-top: 0px;
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          margin-top: 2px;
        }
      }
    }
    button:before {
      content: '';
      position: absolute;
      height: 25px;
      width: 27px;
      border-radius: 10px 0 10px 10px;
      background-color: #e3e5ff;
      top: 40px;
      z-index: -1;
      right: -50px;
    }
    button:after {
      content: '';
      position: absolute;
      height: 30px;
      width: 35px;
      border-radius: 10px 0 10px 10px;
      background-color: #fbe8ff;
      top: 115px;
      z-index: -1;
      left: 20%;
    }
  }
}

.button-common span svg {
  margin-top: 0 !important;
}
.button-common:hover {
  box-shadow: 5px 5px 5px rgba(67, 170, 139, 0.25) !important;
  opacity: 1;
  span {
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.24) !important;
  }
}

.learningjoyful-main .learningjoyful-right div,
.olympiad-right div,
.classroom-right div {
  width: 85%;
}

@media only screen and (max-width: 1200px) {
  .give-gift-main .gift-right h4 span.span-4:before {
    right: -230px;
  }
}

@media only screen and (max-width: 1200px) {
  .give-gift-main .gift-right h4 span.span-4:before {
    display: none;
  }
  .give-gift-main .gift-right h4 span.span-4:after {
    display: none;
  }
  .give-gift-main .gift-right h4 span.span-3:after {
    display: none;
  }
}

@media only screen and (max-width: 1185px) {
  .sub-list-main {
    display: block;
    padding: 15px 20px;
    div {
      margin-right: 15px;
      img {
        height: 20px;
        width: 20px !important;
      }
      h5 {
        font-size: 14px;
      }
    }
  }
  .learningprogram-main {
    .learning-progress-right {
      .icon {
        width: 50px !important;
        height: 50px !important;
      }
    }
  }
}

// @media only screen and (max-width: 600px) {
//   .tautmore_info {
//     border-radius: 35px;
//     background-color: #ffffff;
//     box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
//     padding: 15px 10px 15px 0px !important;
//     margin: 0 !important;
//     margin-top: 20px !important;
//     .tautmore_text {
//       color: #20282a;
//       font-family: 'proxima_nova_rgregular';
//       font-size: 18px;
//       font-weight: 600;
//       letter-spacing: 0;
//       line-height: 24px;
//       padding: 0px 10px 0px 10px;
//     }

//     .tautmore_text2 {
//       font-size: 18px;
//       padding: 10px 20px 0px 20px;
//       line-height: 26px;
//       // text-align: justify;
//     }
//     .tautmore_flex {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       padding: 0px 10px;
//       img {
//         width: 27px;
//       }
//     }
//   }

//   .tautmore_content {
//     padding-bottom: 80px;
//     padding-top: 15px;
//     .px-5 {
//       padding-left: 0px !important;
//       padding-right: 0px !important;
//     }
//     .tcontent_heading {
//       margin-left: 10px;
//       margin-bottom: 50px;
//       color: #20282a;
//       font-family: 'proxima_nova_rgbold';
//       font-size: 30px;
//       font-weight: bold;
//       letter-spacing: 0;
//       line-height: 40px;
//     }
//   }
// }

// @media only screen and (max-width: 1100px) {
//   .give-gift-main .border-common::before {
//     left: 49.5%;
//   }
// }
// @media only screen and (max-width: 1023px) {
//   .sub-list-main {
//     div {
//       width: auto;
//     }
//     div:last-child {
//       float: right;
//     }
//   }
//   .banner-main {
//     position: relative;
//     padding-top: 80px;
//     .banner-left {
//       h4 {
//         font-size: 45px;
//         span {
//           &:after {
//             content: none !important;
//           }
//         }
//       }
//       p {
//         font-size: 16px;
//         margin: 25px 0px 25px;
//       }
//       .banner-left-bottom-icon {
//         display: none;
//       }
//     }
//     .banner-right {
//       img {
//         margin-top: -35px;
//       }
//     }
//   }
//   .learningprogram-main {
//     .learning-progress-left {
//       h4 {
//         font-size: 25px;
//       }
//       span {
//         &:after {
//           content: none !important;
//         }
//       }
//       p {
//         font-size: 16px;
//       }
//     }
//     .learning-progress-right {
//       .learning-block-main {
//         padding: 15px 20px;
//         height: auto;
//         border-radius: 20px !important;
//         .row {
//           margin: 0;
//         }
//         .learning-block-left {
//           display: flex;
//           width: 100%;
//           justify-content: center;
//           // align-items: center;
//           margin-bottom: 20px;
//           padding: 0;

//           .icon {
//             margin-top: 0px;
//             // img{
//             //   display: flex;
//             //   align-items: center;
//             // }
//           }
//         }
//         .learning-block-right {
//           display: block;
//           width: 100%;
//           text-align: center;
//           padding: 0;
//           h5 {
//             font-size: 18px;
//           }
//           p {
//             font-size: 13px;
//           }
//         }
//         .learning-right-icon {
//           display: block;
//           width: 100%;
//           text-align: center;
//         }
//         &:after {
//           content: none !important;
//         }
//       }
//     }
//   }
//   .counter-main {
//     padding: 50px 40px;
//     .counter-common {
//       text-align: center;
//       h4 {
//         font-size: 22px;
//       }
//       p {
//         font-size: 14px;
//       }
//     }
//   }
//   .classroom-main {
//     .classroom-right {
//       h4 {
//         font-size: 35px;
//       }
//       span {
//         &:after {
//           content: none !important;
//         }
//       }
//       p {
//         font-size: 16px;
//       }
//       li {
//         font-size: 15px;
//       }
//     }
//   }
//   .activities-main {
//     h4 {
//       font-size: 35px;
//       line-height: 45px;
//       span {
//         &::after {
//           content: none !important;
//         }
//       }
//     }
//     p {
//       font-size: 16px;
//     }
//   }

//   .olympiad-main {
//     h4 {
//       font-size: 35px;
//       line-height: 45px;
//       span {
//         &::after {
//           content: none !important;
//         }
//       }
//     }
//     p {
//       font-size: 16px;
//     }
//   }

//   .brain-main {
//     h4 {
//       font-size: 35px;
//       line-height: 45px;
//       span {
//         &::after {
//           content: none !important;
//         }
//       }
//     }
//     p {
//       font-size: 16px;
//     }
//   }

//   .learningjoyful-main {
//     h4 {
//       font-size: 35px;
//       line-height: 45px;
//       span {
//         &::after {
//           content: none !important;
//         }
//       }
//     }
//     p {
//       font-size: 16px;
//     }
//   }
//   .physical-classroom-main {
//     h4 {
//       font-size: 35px;
//       line-height: 45px;
//       span {
//         &::after {
//           content: none !important;
//         }
//       }
//     }
//     p.main-p {
//       font-size: 16px;
//       line-height: 1.8;
//     }
//     .common-div {
//       padding: 40px 25px;
//       height: 340px;

//       h5 {
//         font-size: 17px;
//       }
//       p {
//         font-size: 14px;
//       }
//     }
//   }
//   .learning-journey-main {
//     h4 {
//       font-size: 35px;
//       line-height: 45px;
//       span {
//         &::after {
//           content: none !important;
//         }
//       }
//     }
//   }
//   .give-gift-main {
//     .gift-left {
//       .icon {
//         height: 35px;
//         width: 35px;
//       }
//     }
//     h5 {
//       font-size: 22px !important;
//     }
//   }

//   .gift-right {
//     h4 {
//       font-size: 35px !important;
//       line-height: 45px !important;
//       span {
//         &::after {
//           content: none !important;
//         }
//         &::before {
//           content: none !important;
//         }
//       }
//     }
//     button {
//       &::after {
//         content: none !important;
//       }
//       &::before {
//         content: none !important;
//       }
//     }
//   }
// }
// @media only screen and (max-width: 830px) {
//   .give-gift-main h5 {
//     font-size: 18px !important;
//   }
//   .give-gift-main .border-common::before {
//     left: 48.8%;
//   }
// }
// @media only screen and (max-width: 805px) {
//   .learn_w_interactive {
//     border-radius: 180px 0 180px 0;
//     background-color: #e9ffef;
//     padding: 80px;
//     h3 {
//       padding: 0 30px !important;
//     }
//     div {
//       text-align: center;
//     }
//   }
//   .physical-classroom-main {
//     h4 {
//       font-size: 40px !important;
//       line-height: 45px;
//     }
//     p.main-p {
//       font-size: 19px;
//       line-height: 1.8;
//     }
//   }
//   .sub-list-main {
//     text-align: center;
//     border-bottom: 1px solid #dfdfdf;
//     div:last-child {
//       float: none;
//       display: block;
//       width: 100%;
//       text-align: center !important;
//       margin-top: 25px;
//     }
//   }
//   .banner-main {
//     .banner-left {
//       h4 {
//         font-size: 35px;
//         line-height: 45px;
//       }
//     }
//   }
//   .button-common {
//     width: 250px !important;
//     height: 50px !important;
//     font-size: 17px !important;
//     span {
//       margin-top: 0 !important;
//     }
//   }
// }

// @media only screen and (max-width: 767px) {
//   .banner-main {
//     padding-top: 30px;
//     .banner-right {
//       text-align: center;
//       img {
//         margin-top: 50px;
//         width: 100%;
//         max-width: 400px;
//       }
//     }
//   }
//   .learningprogram-main {
//     padding-top: 50px;
//     .learning-progress-right {
//       margin-top: 30px;
//     }
//   }
//   .counter-main {
//     .counter-common {
//       margin-bottom: 30px;
//       .row {
//         width: 100%;
//       }
//     }
//   }
//   .classroom-main {
//     .classroom-left {
//       text-align: center;
//       img {
//         width: 100%;
//         max-width: 400px;
//       }
//     }
//   }
//   .activities-main {
//     p {
//       margin-bottom: 0;
//     }
//     .activity-right {
//       text-align: center;
//       img {
//         width: 100%;
//         max-width: 400px;
//         margin-top: 50px;
//       }
//     }
//   }

//   .olympiad-main {
//     p {
//       margin-bottom: 0;
//     }
//     .olympiad-left {
//       text-align: center;
//       img {
//         width: 100%;
//         // max-width: 400px;
//       }
//     }
//   }

//   .brain-main {
//     p {
//       margin-bottom: 0;
//     }
//     .brain-right {
//       text-align: center;
//       display: block;
//       img {
//         width: 100%;
//         max-width: 400px;
//         margin-top: 50px;
//       }
//     }
//   }

//   .learningjoyful-main {
//     p {
//       margin-bottom: 0;
//     }
//     .learningjoyful-left {
//       text-align: center;
//       img {
//         width: 100%;
//         max-width: 400px;
//       }
//     }
//     .learningjoyful-right {
//       margin-top: 50px;
//       width: 100%;
//     }
//   }
//   .physical-classroom-main {
//     .common-div {
//       height: auto;
//       text-align: center;
//       margin-bottom: 30px;
//     }
//   }
//   .learning-journey-main {
//     .learning-journey-left {
//       display: block;
//     }
//     .learning-journey-right {
//       margin-top: 50px;
//     }
//   }
//   .give-gift-main {
//     .gift-common {
//       max-width: 100%;
//     }
//     .border-common {
//       max-width: 100%;
//     }
//   }

//   .learningjoyful-main .learningjoyful-right div,
//   .olympiad-right div,
//   .classroom-right div,
//   .brain-left div {
//     width: 100% !important;
//   }
// }

// @media only screen and (max-width: 725px) {
//   .sub-list-main {
//     div {
//       width: 35%;
//       margin-bottom: 20px;
//     }
//   }
// }
// @media only screen and (max-width: 605px) {
//   .activities-main {
//     padding: 0px 40px 0px 0px;
//   }

//   .physical-classroom-main {
//     padding: 50px 30px;

//     h4 {
//       // text-align: center;
//       font-size: 30px !important;
//       font-family: 'proxima_nova_rgbold';
//       line-height: 35px;
//       text-align: left;
//       span {
//         position: relative;
//       }
//       span:after {
//         content: '';
//         position: absolute;
//         height: 56px;
//         width: 65px;
//         border-radius: 17px 0 17px 17px;
//         background-color: #fae3ff;
//         top: -4px;
//         z-index: -1;
//         right: 34px;
//       }
//     }
//     p.main-p {
//       font-size: 18px;
//       line-height: 28px;
//       width: 100%;
//       margin: 30px auto;
//       margin-bottom: 15px;
//       text-align: left;
//     }
//     .common-div {
//       border-radius: 80px 0 80px 80px;
//       padding: 38px;
//       background-repeat: no-repeat;
//       background-size: cover;
//       min-height: 346px;

//       svg {
//         height: 70px;
//         width: 70px;
//         color: #f9b53d;
//       }
//       h5 {
//         margin: 30px 0px 20px;
//         color: #277da1;
//         font-size: 20px;
//         line-height: 24px;
//         font-family: 'proxima_nova_rgbold';
//       }
//       p {
//         color: #20282a;
//         font-family: 'Proxima Nova';
//         font-size: 16px;
//         letter-spacing: 0;
//         line-height: 24px;
//         padding-bottom: 20px;
//       }
//     }

//     .common-div-2 {
//       border-radius: 80px 0 80px 80px;
//       padding: 38px 20px 20px 38px;
//       background-repeat: no-repeat;
//       background-size: cover;
//       min-height: 256px;

//       svg {
//         height: 70px;
//         width: 70px;
//         color: #f9b53d;
//       }
//       h5 {
//         margin: 30px 0px 20px;
//         color: #277da1;
//         font-size: 20px;
//         line-height: 24px;
//         font-family: 'proxima_nova_rgbold';
//       }
//       // p {
//       //   color: #20282a;
//       //   font-family: 'Proxima Nova';
//       //   font-size: 16px;
//       //   letter-spacing: 0;
//       //   line-height: 24px;
//       //   padding-bottom: 0px;
//       // }
//     }
//     .common-div-1 {
//       //background: linear-gradient(#fffbf6, #fff0da);
//       position: relative;
//     }

//     .common-div-1:after {
//       content: '';
//       position: absolute;
//     }
//     // .common-div-2 {
//     //   background: #fff;
//     //   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
//     // }
//     // .common-div-3 {
//     //   background: linear-gradient(#fdf5ff, #f7d4ff);
//     // }
//     // .common-div-4 {
//     //   background: linear-gradient(#f6fff8, #d5ffe1);
//     // }
//   }
//   .learningprogram-main .learning-progress-right .learning-block-main {
//     height: 225px;
//   }
// }
// @media only screen and (max-width: 575px) {
//   .activities-main {
//     padding: 0px 40px 0px 0px;
//   }

//   .physical-classroom-main {
//     padding: 50px 30px;

//     h4 {
//       // text-align: center;
//       font-size: 30px !important;
//       font-family: 'proxima_nova_rgbold';
//       line-height: 35px;
//       text-align: left;
//       span {
//         position: relative;
//       }
//       span:after {
//         content: '';
//         position: absolute;
//         height: 56px;
//         width: 65px;
//         border-radius: 17px 0 17px 17px;
//         background-color: #fae3ff;
//         top: -4px;
//         z-index: -1;
//         right: 34px;
//       }
//     }
//     p.main-p {
//       font-size: 18px;
//       line-height: 28px;
//       width: 100%;
//       margin: 30px auto;
//       margin-bottom: 15px;
//       text-align: left;
//     }
//     .common-div {
//       border-radius: 80px 0 80px 80px;
//       padding: 38px;
//       background-repeat: no-repeat;
//       background-size: cover;
//       min-height: 346px;

//       svg {
//         height: 70px;
//         width: 70px;
//         color: #f9b53d;
//       }
//       h5 {
//         margin: 30px 0px 20px;
//         color: #277da1;
//         font-size: 20px;
//         line-height: 24px;
//         font-family: 'proxima_nova_rgbold';
//       }
//       p {
//         color: #20282a;
//         font-family: 'Proxima Nova';
//         font-size: 16px;
//         letter-spacing: 0;
//         line-height: 24px;
//         padding-bottom: 20px;
//       }
//     }

//     .common-div-2 {
//       border-radius: 80px 0 80px 80px;
//       padding: 38px 20px 20px 38px;
//       background-repeat: no-repeat;
//       background-size: cover;
//       min-height: 256px;

//       svg {
//         height: 70px;
//         width: 70px;
//         color: #f9b53d;
//       }
//       h5 {
//         margin: 30px 0px 20px;
//         color: #277da1;
//         font-size: 20px;
//         line-height: 24px;
//         font-family: 'proxima_nova_rgbold';
//       }
//       // p {
//       //   color: #20282a;
//       //   font-family: 'Proxima Nova';
//       //   font-size: 16px;
//       //   letter-spacing: 0;
//       //   line-height: 24px;
//       //   padding-bottom: 0px;
//       // }
//     }
//     .common-div-1 {
//       //background: linear-gradient(#fffbf6, #fff0da);
//       position: relative;
//     }

//     .common-div-1:after {
//       content: '';
//       position: absolute;
//     }
//     // .common-div-2 {
//     //   background: #fff;
//     //   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
//     // }
//     // .common-div-3 {
//     //   background: linear-gradient(#fdf5ff, #f7d4ff);
//     // }
//     // .common-div-4 {
//     //   background: linear-gradient(#f6fff8, #d5ffe1);
//     // }
//   }
//   .sub-list-main {
//     div {
//       width: 100%;
//       text-align: left;
//       padding-left: 40%;
//     }
//   }
//   .banner-main,
//   .classroom-main,
//   .activities-main,
//   .olympiad-main,
//   .brain-main,
//   .physical-classroom-main,
//   .learning-journey-main {
//     padding-left: 20px;
//     padding-right: 20px;
//   }
//   .give-gift-main {
//     padding-left: 40px;
//     padding-right: 20px;
//   }
//   .learningprogram-main {
//     padding: 50px 20px 50px 20px;
//   }
//   .learningprogram-main .learning-progress-right .learning-block-main {
//     height: auto;
//     border-radius: 20px !important;
//   }
//   .counter-main {
//     padding: 50px 20px;
//   }
//   .learning-journey-left {
//     padding-left: 50px !important;
//   }
//   .give-gift-main .gift-common {
//     padding-left: 10px;
//   }
//   .give-gift-main .gift-common h5 {
//     line-height: 1.3;
//   }
//   .gift-right h4,
//   .learning-journey-main h4,
//   // .physical-classroom-main h4,
//   .learningjoyful-main h4,
//   .brain-main h4,
//   .olympiad-main h4,
//   .activities-main h4,
//   .classroom-main .classroom-right h4,
//   .learningprogram-main .learning-progress-left h4 {
//     font-size: 25px !important;
//     line-height: 35px !important;
//   }
// }

.learn_w_interactive {
  border-radius: 180px 0 180px 0;
  background-color: #e9ffef;
  padding: 80px;
  h3 {
    padding: 0 120px;
    text-align: center !important;
    color: #20282a;
    font-family: 'proxima_nova_rgbold';
    font-size: 48px !important;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 44px !important;
  }
  img {
    margin-top: 20px;
    width: 100%;
  }
  div {
    text-align: center;
  }
}

@media only screen and (max-width: 900px) {
  .banner-left::before {
    display: none;
  }

  .banner-left::after {
    display: none;
  }
}

@media only screen and (min-width: 850px) and (max-width: 1350px) {
  .brain-main {
    padding-top: 40px;
  }

  .classroom-main {
    padding-top: 40px;
  }

  .activities-main {
    padding-top: 40px;
  }

  .learningjoyful-main {
    padding-top: 40px;
  }

  .olympiad-main {
    padding-top: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1482px) {
  .common-div {
    height: 100%;
  }
}

@media only screen and (min-width: 1398px) {
  .learningprogram-main {
    .learning-block-left {
      display: flex;
      align-items: center;
      // margin-bottom: 20px;
      // padding: 0;

      .icon {
        // margin-top: 0px;

        img {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 1393px) {
  .learningprogram-main {
    .learning-progress-left {
      h4 {
        font-size: 22px;
      }
      span {
        &:after {
          content: none !important;
        }
      }
      p {
        font-size: 14px;
      }
    }
    .learning-progress-right {
      .learning-block-main {
        padding: 15px 20px;
        height: auto;
        border-radius: 20px !important;
        .row {
          margin: 0;
        }
        .learning-block-left {
          display: flex;
          width: 100%;
          justify-content: center;
          // align-items: center;
          margin-bottom: 20px;
          padding: 0;

          .icon {
            margin-top: 0px;
            // img{
            //   display: flex;
            //   align-items: center;
            // }
          }
        }
        .learning-block-right {
          display: block;
          width: 100%;
          text-align: center;
          padding: 0;
          h5 {
            font-size: 18px;
          }
          p {
            font-size: 13px;
          }
        }
        .learning-right-icon {
          display: block;
          width: 100%;
          text-align: center;
        }
        &:after {
          content: none !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 1922px) {
  .banner-main {
    .banner-right {
      img {
        margin-top: -50px;
        width: 75%;
      }
    }
  }

  .brain-main {
    .brain-left {
      img {
        width: 70%;
      }
    }
  }

  .classroom-main {
    .classroom-left {
      img {
        width: 70%;
      }
    }
  }

  .activities-main {
    .activity-right {
      img {
        width: 70%;
      }
    }
  }

  .learningjoyful-main {
    .learningjoyful-left {
      img {
        width: 75%;
      }
    }
  }

  .olympiad-main {
    .olympiad-right {
      img {
        width: 75%;
      }
    }
  }

  .learningjoyful-main {
    .learningjoyful-left {
      img {
        width: 70%;
      }
    }
  }
}

/* SEEE THE BELOW LINK FOR EXAMPLES */
/* DONOT MODIFY - TRY WITH NEW ANIMATIONS */
/* https://css-tricks.com/a-handy-little-system-for-animated-entrances-in-css/ */
.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-pop;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Fade In */
.animate-fade {
  animation: animation-fade 2s ease;
}
@keyframes animation-fade {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

/* Pop In */
.animate-pop {
  animation: animate-pop 1.2s ease;
}
@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Blur In */
.animate-x-direction {
  animation: animate-x-direction 0.8s ease-in-out;
}
@keyframes animate-x-direction {
  0% {
    opacity: 0;
    transform: translateX(-25%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.animate-x-direction-right {
  animation: animate-x-direction-right 12s ease-in-out;
}
@keyframes animate-x-direction-right {
  0% {
    opacity: 0;
    transform: translateX(25%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.animate-y-direction-down {
  animation: animate-y-direction-down 3s ease-in-out;
}
@keyframes animate-y-direction-down {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}
@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}
/* Grow In */
.animate.grow {
  animation-duration: 0.4s;
  animation-delay: 0s;
  animation-name: animate-grow;
}
@keyframes animate-grow {
  0% {
    opacity: 0;
    transform: scale(0, 0);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
/* Splat In */
.animate.splat {
  animation-name: animate-splat;
}
@keyframes animate-splat {
  0% {
    opacity: 0;
    transform: scale(0, 0) rotate(20deg) translate(0, -30px);
  }
  70% {
    opacity: 1;
    transform: scale(1.1, 1.1) rotate(15deg);
  }
  85% {
    opacity: 1;
    transform: scale(1.1, 1.1) rotate(15deg) translate(0, -10px);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1) rotate(0) translate(0, 0);
  }
}
/* Roll In */
.animate.roll {
  animation-name: animate-roll;
}
@keyframes animate-roll {
  0% {
    opacity: 0;
    transform: scale(0, 0) rotate(360deg);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1) rotate(0deg);
  }
}
/* Flip In */
.animate.flip {
  animation-name: animate-flip;
  transform-style: preserve-3d;
  perspective: 1000px;
}
@keyframes animate-flip {
  0% {
    opacity: 0;
    transform: rotateX(-120deg) scale(0.9, 0.9);
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg) scale(1, 1);
  }
}
/* Spin In */
.animate.spin {
  animation-name: animate-spin;
  transform-style: preserve-3d;
  perspective: 1000px;
}
@keyframes animate-spin {
  0% {
    opacity: 0;
    transform: rotateY(-120deg) scale(0.9, 0.9);
  }
  100% {
    opacity: 1;
    transform: rotateY(0deg) scale(1, 1);
  }
}
/* Slide In */
.animate.slide {
  animation-name: animate-slide;
}
@keyframes animate-slide {
  0% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
/* Drop In */
.animate.drop {
  animation-name: animate-drop;
  animation-timing-function: cubic-bezier(0.77, 0.14, 0.91, 1.25);
}
@keyframes animate-drop {
  0% {
    opacity: 0;
    transform: translate(0, -300px) scale(0.9, 1.1);
  }
  95% {
    opacity: 1;
    transform: translate(0, 0) scale(0.9, 1.1);
  }
  96% {
    opacity: 1;
    transform: translate(10px, 0) scale(1.2, 0.9);
  }
  97% {
    opacity: 1;
    transform: translate(-10px, 0) scale(1.2, 0.9);
  }
  98% {
    opacity: 1;
    transform: translate(5px, 0) scale(1.1, 0.9);
  }
  99% {
    opacity: 1;
    transform: translate(-5px, 0) scale(1.1, 0.9);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0) scale(1, 1);
  }
}
/* Animation Delays */
.delay-1 {
  animation-delay: 0.6s;
}
.delay-2 {
  animation-delay: 0.7s;
}
.delay-3 {
  animation-delay: 0.8s;
}
.delay-4 {
  animation-delay: 0.9s;
}
.delay-5 {
  animation-delay: 1s;
}
.delay-6 {
  animation-delay: 1.1s;
}
.delay-7 {
  animation-delay: 1.2s;
}
.delay-8 {
  animation-delay: 1.3s;
}
.delay-9 {
  animation-delay: 1.4s;
}
.delay-10 {
  animation-delay: 1.5s;
}
.delay-11 {
  animation-delay: 1.6s;
}
.delay-12 {
  animation-delay: 1.7s;
}
.delay-13 {
  animation-delay: 1.8s;
}
.delay-14 {
  animation-delay: 1.9s;
}
.delay-15 {
  animation-delay: 2s;
}
@media screen and (prefers-reduced-motion: reduce) {
  .animate {
    animation: none !important;
  }
}

@media only screen and (min-width: 319px) and (max-width: 1026px) {
  .Header .header-right ul li {
    display: none;
  }
  .Header .li button {
    display: block !important;
  }
  .sub-list-main {
    display: none;
  }

  .banner-main {
    padding-bottom: 30px;
  }
}

.learningProgramMainPad {
  padding: 0px 40px 0px 40px;
}

.mobileResponsiveHome {
  padding-left: 3rem;
  padding-right: 3rem;
}

.showDesktop {
  display: block;
}

.showMobile {
  display: none;
}

.customSliderHomeBrain {
  background-color: #f8f8ff;
  margin: 0px 40px 0 40px;
  border-radius: 90px 0 90px 0;
  padding: 80px 0 80px 0;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .banner-main .banner-left h4 {
    font-size: 28px;
    line-height: 40px;
  }

  .learningprogram-main {
    padding: 60px 50px 50px 50px;
  }

  .learningprogram-main .learning-progress-left p {
    font-size: 16px;
    line-height: 28px;
  }

  .banner-main {
    padding: 0;
    padding-top: 45px;
  }

  .banner-main .banner-left button {
    display: none;
  }

  .banner-main {
    padding-left: 20px;
    padding-right: 20px;
  }

  .banner-main .banner-left p {
    margin: 10px 0px;
    font-size: 16px;
  }

  .banner-main .banner-right img {
    margin-top: 15px;
  }

  .learningProgramMainPad {
    padding: 0 20px 0 20px;
  }

  .mobileResponsiveHome {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .physical-classroom-main {
    padding-left: 0px;
    padding-right: 0px;
  }

  .physical-classroom-main p.main-p {
    width: 100%;
  }

  .physical-classroom-main .common-div {
    // margin-left: auto;
    margin-right: auto;
  }

  .learn_w_interactive {
    padding: 0px;
  }

  // .learn_w_interactive img {
  //   display: none;
  // }

  .learn_w_interactive h3 {
    padding: 0px 0px 0px 20px !important;
    font-size: 28px !important;
    text-align: left !important;
  }

  .learn_w_interactive .button-common {
    display: none;
  }

  .give-gift-main {
    padding: 60px 0px;
  }

  .give-gift-main .gift-common {
    margin-left: auto;
    margin-right: auto;
  }

  .give-gift-main .gift-right h4 {
    margin-top: 25px;
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 20px;
  }

  .give-gift-main .gift-common h5 {
    font-size: 16px;
    line-height: 28px;
  }

  .give-gift-main .gift-right button {
    display: none;
  }

  .mobileResponsiveHome .brain-main {
    padding-left: 0px;
    padding-right: 0px;
  }

  .showDesktop {
    display: none;
  }

  .showMobile {
    display: block;
  }

  .mobileResponsiveHome .brain-main h4 {
    font-size: 28px !important;
    margin: 0;
    line-height: 40px !important;
    padding: 0;
    text-align: left;
  }

  .mobileResponsiveHome .brain-main p {
    line-height: 28px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-right: 0px;
    text-align: left;
    font-size: 18px;
  }

  .mobileResponsiveHome .brain-main li {
    font-size: 18px;
    // line-height: 28px;
    text-align: left;
  }

  .learningprogram-main .learning-progress-left h4 {
    font-size: 28px !important;
    margin: 0;
    line-height: 40px !important;
  }

  .mobileResponsiveHome .brain-main button {
    display: none;
  }

  .brain-main .brain-left img {
    margin-top: 25px;
  }

  .mobileResponsiveHome .classroom-main .classroom-right h4 {
    font-size: 33px !important;
    text-align: left;
    margin: 0;
    line-height: 40px;
  }

  .mobileResponsiveHome .classroom-main {
    padding-left: 0px;
    padding-right: 0px;
  }

  .mobileResponsiveHome .classroom-main .classroom-right {
    padding: 50px 10px 0 10px;
  }

  .mobileResponsiveHome .classroom-main .classroom-right p {
    line-height: 28px;
    font-size: 16px;
    margin: 10px 0px;
  }

  .mobileResponsiveHome .classroom-main .classroom-right li {
    font-size: 18px;
    // line-height: 28px;
    text-align: left;
  }

  .classroom-main .classroom-right button {
    display: none;
  }

  .mobileResponsiveHome .activities-main {
    padding-left: 0px;
    padding-right: 0px;
  }

  .mobileResponsiveHome .activities-main .activity-left {
    margin-top: 0px;
  }

  .mobileResponsiveHome .activities-main h4 {
    font-size: 33px !important;
    line-height: 40px;
    margin: 0;
  }

  .mobileResponsiveHome .activities-main p {
    line-height: 28px;
    margin: 10px 0px;
    font-size: 16px;
  }

  .mobileResponsiveHome .activities-main li {
    font-size: 18px;
  }

  .mobileResponsiveHome .activities-main button {
    display: none;
  }

  .activities-main .activity-right img {
    margin-top: 0;
  }

  .learningjoyful-main {
    padding: 0px;
  }

  .learningjoyful-main .learningjoyful-right div {
    width: 100%;
  }

  .mobileResponsiveHome .learningjoyful-main h4 {
    font-size: 33px !important;
    margin: 0;
  }

  .learningjoyful-main .learningjoyful-right {
    margin-top: 20px;
  }

  .learningjoyful-main p {
    margin: 10px 0;
    line-height: 28px;
    font-size: 16px;
  }

  .learningjoyful-main li {
    font-size: 18px;
  }

  .learningjoyful-main button {
    display: none;
  }

  .learningjoyful-main .learningjoyful-left img {
    margin-top: 20px;
  }

  .mobileResponsiveHome .olympiad-main {
    padding: 0;
  }

  .mobileResponsiveHome .olympiad-main .olympiad-left {
    text-align: left;
  }

  .mobileResponsiveHome .olympiad-main h4 {
    font-size: 33px !important;
    margin-top: 25px;
    margin-bottom: 0;
  }

  .mobileResponsiveHome .olympiad-main p {
    line-height: 28px;
    margin: 10px 0;
    font-size: 16px;
  }

  .mobileResponsiveHome .olympiad-main li {
    font-size: 18px;
  }

  .mobileResponsiveHome .olympiad-main button {
    display: none;
  }

  .customSliderHomeBrain {
    margin: 50px 20px 0 20px;
  }

  // .physical-classroom-main h4 {
  //   // font-size: 35px !important;
  // }

  .physical-classroom-main {
    padding: 50px 20px;

    h4 {
      // text-align: center;
      font-size: 24px !important;
      font-family: 'proxima_nova_rgbold';
      line-height: 35px;
      text-align: left;
      span {
        position: relative;
      }
      span:after {
        content: '';
        position: absolute;
        height: 56px;
        width: 65px;
        border-radius: 17px 0 17px 17px;
        background-color: #fae3ff;
        top: -4px;
        z-index: -1;
        right: 34px;
      }
    }
    p.main-p {
      font-size: 16px;
      line-height: 28px;
      width: 100%;
      margin: 30px auto;
      margin-bottom: 15px;
      text-align: left;
    }
    .common-div {
      border-radius: 80px 0 80px 80px;
      padding: 38px;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 250px;
      text-align: center;

      svg {
        height: 70px;
        width: 70px;
        color: #f9b53d;
      }
      h5 {
        margin: 30px 0px 20px;
        color: #277da1;
        font-size: 20px;
        line-height: 24px;
        font-family: 'proxima_nova_rgbold';
      }
      p {
        color: #20282a;
        font-family: 'Proxima Nova';
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        padding-bottom: 20px;
      }
    }

    .common-div-2 {
      border-radius: 80px 0 80px 80px;
      padding: 38px 20px 20px 38px;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 256px;

      svg {
        height: 70px;
        width: 70px;
        color: #f9b53d;
      }
      h5 {
        margin: 30px 0px 20px;
        color: #277da1;
        font-size: 20px;
        line-height: 24px;
        font-family: 'proxima_nova_rgbold';
      }
      // p {
      //   color: #20282a;
      //   font-family: 'Proxima Nova';
      //   font-size: 16px;
      //   letter-spacing: 0;
      //   line-height: 24px;
      //   padding-bottom: 0px;
      // }
    }
    .common-div-1 {
      //background: linear-gradient(#fffbf6, #fff0da);
      position: relative;
    }

    .common-div-1:after {
      content: '';
      position: absolute;
    }
    // .common-div-2 {
    //   background: #fff;
    //   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
    // }
    // .common-div-3 {
    //   background: linear-gradient(#fdf5ff, #f7d4ff);
    // }
    // .common-div-4 {
    //   background: linear-gradient(#f6fff8, #d5ffe1);
    // }
  }

  .physical-classroom-main p.main-p {
    line-height: 28px;
    margin: 15px 0;
  }

  .Header .header-left a {
    margin-bottom: 0px;
  }

  .footer-top p {
    font-size: 16px !important;
    line-height: 28px !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 767px) {
  .physical-classroom-main .row .pt-3 {
    width: 50%;
  }

  .physical-classroom-main .common-div {
    padding: 50px !important;
  }

  .banner-main .banner-right {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .brain-main .brain-left {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .classroom-main .classroom-left {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .activities-main .activity-right {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .learningjoyful-main .learningjoyful-left {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .olympiad-main .olympiad-right {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .banner-main .banner-left button {
    display: none;
  }

  .banner-main {
    padding-left: 20px;
    padding-right: 20px;
  }

  .banner-main .banner-left p {
    margin: 10px 0px;
  }

  .banner-main .banner-right img {
    margin-top: 15px;
  }

  .learningProgramMainPad {
    padding: 0 20px 0 20px;
  }

  .mobileResponsiveHome {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .physical-classroom-main {
    padding-left: 0px;
    padding-right: 0px;
  }

  .physical-classroom-main p.main-p {
    width: 100%;
  }

  .physical-classroom-main .common-div {
    // margin-left: auto;
    margin-right: auto;
  }

  .learn_w_interactive {
    padding: 0px 0 50px 0;
  }

  // .learn_w_interactive img {
  //   display: none;
  // }

  .learn_w_interactive h3 {
    padding: 50px 0px 0px 100px !important;
    font-size: 33px !important;
    text-align: left !important;
  }

  .learn_w_interactive .button-common {
    display: none;
  }

  .give-gift-main {
    padding: 60px 0px;
  }

  .give-gift-main .gift-common {
    margin-left: auto;
    margin-right: auto;
  }

  .give-gift-main .gift-right h4 {
    margin-top: 25px;
  }

  .give-gift-main .gift-right button {
    display: none;
  }

  .mobileResponsiveHome .brain-main {
    padding-left: 0px;
    padding-right: 0px;
  }

  .showDesktop {
    display: none;
  }

  .showMobile {
    display: block;
  }

  .mobileResponsiveHome .brain-main h4 {
    font-size: 38px !important;
    line-height: 35px !important;
    padding: 0;
    text-align: left;
  }

  .mobileResponsiveHome .brain-main p {
    line-height: 28px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-right: 0px;
    text-align: left;
  }

  .mobileResponsiveHome .brain-main li {
    font-size: 18px;
    // line-height: 28px;
    text-align: left;
  }

  .mobileResponsiveHome .brain-main button {
    display: none;
  }

  .brain-main .brain-left img {
    margin-top: 25px;
  }

  .mobileResponsiveHome .classroom-main .classroom-right h4 {
    font-size: 38px !important;
    text-align: left;
  }

  .mobileResponsiveHome .classroom-main {
    padding-left: 0px;
    padding-right: 0px;
  }

  .mobileResponsiveHome .classroom-main .classroom-right {
    padding: 50px 10px 0 10px;
  }

  .mobileResponsiveHome .classroom-main .classroom-right p {
    line-height: 26px;
    margin: 10px 0px;
  }

  .mobileResponsiveHome .classroom-main .classroom-right li {
    font-size: 18px;
    // line-height: 28px;
    text-align: left;
  }

  .classroom-main .classroom-right button {
    display: none;
  }

  .mobileResponsiveHome .activities-main {
    padding-left: 0px;
    padding-right: 0px;
  }

  .mobileResponsiveHome .activities-main .activity-left {
    margin-top: 0px;
  }

  .mobileResponsiveHome .activities-main h4 {
    font-size: 38px !important;
  }

  .mobileResponsiveHome .activities-main p {
    line-height: 28px;
    margin: 10px 0px;
  }

  .mobileResponsiveHome .activities-main li {
    font-size: 18px;
  }

  .mobileResponsiveHome .activities-main button {
    display: none;
  }

  .activities-main .activity-right img {
    margin-top: 0;
  }

  .learningjoyful-main {
    padding: 0px;
  }

  .mobileResponsiveHome .learningjoyful-main h4 {
    font-size: 38px !important;
  }

  .learningjoyful-main .learningjoyful-right {
    margin-top: 20px;
  }

  .learningjoyful-main p {
    margin: 10px 0;
    line-height: 28px;
  }

  .learningjoyful-main li {
    font-size: 18px;
  }

  .learningjoyful-main button {
    display: none;
  }

  .learningjoyful-main .learningjoyful-left img {
    margin-top: 20px;
  }

  .mobileResponsiveHome .olympiad-main {
    padding: 0;
  }

  .mobileResponsiveHome .olympiad-main .olympiad-left {
    text-align: left;
  }

  .mobileResponsiveHome .olympiad-main h4 {
    font-size: 38px !important;
    margin-top: 25px;
  }

  .mobileResponsiveHome .olympiad-main p {
    line-height: 28px;
    margin: 10px 0;
  }

  .mobileResponsiveHome .olympiad-main li {
    font-size: 18px;
  }

  .mobileResponsiveHome .olympiad-main button {
    display: none;
  }

  .customSliderHomeBrain {
    margin: 0px 20px 0 20px;
  }

  // .physical-classroom-main h4 {
  //   // font-size: 35px !important;
  // }

  .physical-classroom-main {
    padding: 50px 30px;

    h4 {
      // text-align: center;
      font-size: 33px !important;
      font-family: 'proxima_nova_rgbold';
      line-height: 35px;
      text-align: left;
      span {
        position: relative;
      }
      span:after {
        content: '';
        position: absolute;
        height: 56px;
        width: 65px;
        border-radius: 17px 0 17px 17px;
        background-color: #fae3ff;
        top: -4px;
        z-index: -1;
        right: 34px;
      }
    }
    p.main-p {
      font-size: 18px;
      line-height: 28px;
      width: 100%;
      margin: 30px auto;
      margin-bottom: 15px;
      text-align: left;
    }
    .common-div {
      border-radius: 80px 0 80px 80px;
      padding: 38px;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 346px;

      svg {
        height: 70px;
        width: 70px;
        color: #f9b53d;
      }
      h5 {
        margin: 30px 0px 20px;
        color: #277da1;
        font-size: 22px;
        line-height: 24px;
        font-family: 'proxima_nova_rgbold';
      }
      p {
        color: #20282a;
        font-family: 'Proxima Nova';
        font-size: 18px;
        letter-spacing: 0;
        line-height: 24px;
        padding-bottom: 20px;
      }
    }

    .common-div-2 {
      border-radius: 80px 0 80px 80px;
      padding: 38px 20px 20px 38px;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 256px;

      svg {
        height: 70px;
        width: 70px;
        color: #f9b53d;
      }
      h5 {
        margin: 30px 0px 20px;
        color: #277da1;
        font-size: 22px;
        line-height: 24px;
        font-family: 'proxima_nova_rgbold';
      }
      // p {
      //   color: #20282a;
      //   font-family: 'Proxima Nova';
      //   font-size: 16px;
      //   letter-spacing: 0;
      //   line-height: 24px;
      //   padding-bottom: 0px;
      // }
    }
    .common-div-1 {
      //background: linear-gradient(#fffbf6, #fff0da);
      position: relative;
    }

    .common-div-1:after {
      content: '';
      position: absolute;
    }
    // .common-div-2 {
    //   background: #fff;
    //   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
    // }
    // .common-div-3 {
    //   background: linear-gradient(#fdf5ff, #f7d4ff);
    // }
    // .common-div-4 {
    //   background: linear-gradient(#f6fff8, #d5ffe1);
    // }
  }

  .physical-classroom-main p.main-p {
    line-height: 28px;
    margin: 15px 0;
  }

  .Header .header-left a {
    margin-bottom: 0px;
  }

  .footer-top p {
    font-size: 18px !important;
    line-height: 28px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  // .showMobile{
  //   display: block;
  // }

  // .showDesktop{
  //   display: none;
  // }

  .give-gift-main {
    padding: 40px 40px;
  }

  .give-gift-main .gift-common {
    // width: 0%;
    h5 {
      margin-left: 10px;
    }
  }

  .banner-main {
    padding: 40px;
    padding-top: 40px;
    .banner-left {
      h4 {
        font-family: 'proxima_nova_rgbold';
        font-size: 36px;
        line-height: 50px;
      }
      p {
        font-size: 20px;
        line-height: 28px;
        margin: 25px 0px 15px 0px;
      }
    }
    .banner-right {
      img {
        margin-top: 0px;
        width: 100%;
        height: 80%;
      }
    }
  }

  .brain-main {
    padding: 0px 0px;
    .brain-right {
      div {
        width: 100%;
      }
    }
    .brain-left {
      display: flex;
      align-items: center;
      img {
        width: 100%;
      }
    }

    h4 {
      padding: 0px 0 0 0;
      font-size: 36px;
      span {
        position: relative;
      }
      // span:after {
      //   content: '';
      //   position: absolute;
      //   height: 76px;
      //   width: 85px;
      //   border-radius: 25px 0 25px 25px;
      //   background-color: #fff6e9;
      //   top: -10px;
      //   z-index: -1;
      //   right: 20px;
      // }
    }
    p {
      font-size: 20px;
      letter-spacing: 0;
      line-height: 32px;
      padding-right: 0px;
      margin: 30px 0px;
      margin-bottom: 15px;
    }
    li {
      color: #20282a;
      font-family: 'proxima_nova_rgregular';
      font-size: 18px;
      letter-spacing: 0;
      line-height: 32px;

      svg {
        height: 14px;
        width: 14px;
        margin-right: 5px;
        color: #fca834;
      }
    }
    button {
      height: 66px;
      width: 353px;
      border-radius: 33.75px;
      background-color: #43aa8b;
      box-shadow: none;
      border: 0;
      text-align: left;
      padding: 0px 20px;
      color: #fff;
      font-size: 18px;
      font-family: 'proxima_nova_rgbold';
      line-height: 24px;
      margin-top: 40px;
      span {
        float: right;
        height: 24px;
        width: 24px;
        background-color: #fca834;
        border-radius: 50%;
        padding: 0px 7px;
        margin-top: 0px;
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          margin-top: 2px;
        }
      }
    }
  }

  // .brain-main h4 {
  //   font-size: 45px;
  //   line-height: 50px;
  // }

  // .brain-main p {
  //   padding-right: 0px;
  //   font-size: 20px;
  //   line-height: 28px;
  // }

  // .brain-main li {
  //   line-height: 30px;
  // }

  .learningprogram-main {
    margin-top: 50px;
    padding: 50px 60px 50px 50px;
    .learning-progress-left {
      h4 {
        // margin: 0;
        font-size: 30px;
        line-height: 44px;
        letter-spacing: 0;
      }
      p {
        margin-top: 25px;
        margin-bottom: 25px;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  .classroom-main {
    padding: 0px 0px;
    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
      }
    }
    .classroom-left {
      display: flex;
      align-items: center;
      img {
        margin-top: 0px;
        width: 100%;
      }
    }
    .classroom-right {
      color: #20282a;
      // font-family: 'proxima_nova_rgregular';
      display: flex;
      padding: 50px 0 0 0;
      h4 {
        font-size: 36px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 44px;
      }
      p {
        color: #20282a;
        font-family: 'proxima_nova_rgregular';
        font-size: 20px;
        letter-spacing: 0;
        line-height: 32px;
        margin: 30px 0px;
      }
      li {
        color: #20282a;
        font-family: 'proxima_nova_rgregular';
        font-size: 18px;
        letter-spacing: 0;
        line-height: 32px;
      }
    }
  }

  // .classroom-main .classroom-right h4 {
  //   font-size: 45px;
  //   line-height: 50px;
  // }

  // .classroom-main .classroom-right {
  //   padding-right: 0px;
  // }

  // .classroom-main .classroom-right p {
  //   font-size: 20px;
  //   line-height: 28px;
  //   margin: 20px 0px;
  // }

  // .classroom-main .classroom-right li {
  //   line-height: 30px;
  // }

  .activities-main {
    padding: 50px 30px 50px 30px;
    .activity-left {
      margin-top: 50px;
    }
    .activity-right {
      display: flex;
      align-items: center;
      img {
        margin-top: 0px;
        width: 100%;
      }
    }

    h4 {
      font-size: 36px;
      line-height: 44px;
    }
    p {
      font-size: 20px;
      line-height: 32px;
      margin: 10px 0px;
      margin-bottom: 30px;
    }
    li {
      font-size: 18px;
      line-height: 32px !important;
    }
  }

  // .activities-main h4 {
  //   font-size: 45px;
  //   line-height: 50px;
  // }

  // .activities-main p {
  //   font-size: 20px;
  //   line-height: 28px;
  //   margin: 20px 0px;
  // }

  // .activities-main li {
  //   line-height: 30px;
  // }

  // .activities-main .activity-right img {
  //   padding-top: 20px;
  // }

  .learningjoyful-main {
    padding: 0px 0px 0px 0px;
    .learningjoyful-left {
      display: flex;
      align-items: center;
      img {
        width: 100%;
        margin-top: 0px;
      }
    }
    .learningjoyful-right {
      // display: flex;
      // align-items: center;
      div {
        width: 100%;
      }
    }

    h4 {
      font-size: 36px;
    }
    p {
      font-size: 20px;
      letter-spacing: 0;
      line-height: 32px;
      margin: 20px 0px;
      margin-bottom: 20px;
    }

    li {
      font-size: 18px;
      letter-spacing: 0;
      line-height: 36px;
    }
    .displaynone_circle {
      display: none;
    }
  }

  // .learningjoyful-main h4 {
  //   font-size: 45px;
  //   line-height: 50px;
  // }

  // .learningjoyful-main p {
  //   font-size: 20px;
  //   line-height: 28px;
  //   margin: 20px 0px;
  // }

  // .learningjoyful-main li {
  //   line-height: 30px;
  // }

  // .learningjoyful-main .learningjoyful-left img {
  //   padding-top: 20px;
  // }

  .olympiad-main {
    padding: 0px 0px 40px 0px;
    // padding-top: 0;

    .olympiad-right {
      display: flex;
      align-items: center;
      img {
        width: 100%;
      }
    }
    .olympiad-left {
      display: flex;
      // align-items: center;
    }

    h4 {
      margin-top: 20px;
      font-size: 36px;
    }
    p {
      font-size: 20px;
      margin: 0px 0px;
      margin-bottom: 25px;
    }
  }

  // .olympiad-main h4 {
  //   font-size: 45px;
  //   line-height: 50px;
  //   margin-top: 35px;
  // }

  // .olympiad-main p {
  //   font-size: 20px;
  //   line-height: 28px;
  //   margin: 20px 0px;
  // }

  // .olympiad-main li {
  //   line-height: 30px;
  // }

  .learningjoyful-main .learningjoyful-right div {
    width: 100%;
  }

  .physical-classroom-main {
    padding: 50px 0;
    h4 {
      font-size: 38px;
      line-height: 50px;
    }
  }

  .physical-classroom-main p.main-p {
    font-size: 18px;
    line-height: 28px;
    width: 100%;
  }

  .learn_w_interactive h3 {
    font-size: 28px !important;
    line-height: 32px !important;
    padding: 0 0px;
  }

  .give-gift-main .gift-common h5 {
    font-size: 23px;
  }

  .give-gift-main .gift-right h4 {
    font-size: 34px;
    line-height: 45px;
  }

  .banner-main .banner-left button {
    display: none;
  }

  .brain-main button {
    display: none;
  }

  .classroom-main .classroom-right button {
    display: none;
  }

  .activities-main button {
    display: none;
  }

  .learningjoyful-main button {
    display: none;
  }

  .olympiad-main button {
    display: none;
  }

  .button-common {
    display: none;
  }
}

.stem_banner_bg {
  background: #fff6e9;
  height: 160px;
  align-items: center;
  border-radius: 0px 67px 0px 67px;
  padding: 34px;
  h2 {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 64px;
    color: #20282a;
    margin: 0;
  }
  p {
    color: #20282a;
    font-family: 'proxima_nova_rgregular';
    font-size: 20px;
    line-height: 28px;
    color: #20282a;
    width: 62%;
  }
  > div:nth-child(2) {
    margin-left: 3rem;
  }
}

// Ninganagouda media query css//
@media only screen and (max-width: 991px) {
  .stem_banner_bg {
    margin-bottom: 100px;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    height: auto;
    h2 {
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 10px;
    }
    > div:nth-child(2) {
      margin-left: 0;
    }
    p {
      width: 100%;
    }
  }
}

// Ninganagouda media query css//

//chandan media query

@media only screen and (min-width: 1027px) {
  .swiper-container {
    display: none;
  }
  .sub-grade-select {
    display: none;
  }
}
@media only screen and (min-width: 320px) and (max-width: 376px) {
  .chapters-details {
    margin-top: 0px;
  }
  .chapters-subject-discription {
    display: none;
  }
  .swiper-container {
    width: 100%;
    height: 42px;
    background: #ffffff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    background-color: #fff;
    a {
      text-decoration: none;
    }
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    h5 {
      margin-bottom: 0;
      color: #454c4e;
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .sub-grade-select {
    text-align: right;
    display: flex;
    justify-content: space-evenly;
    .drop-left {
      .dropdown {
        height: auto;
        width: auto;
        border: none !important;
        img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }
      .dropdown-toggle {
        height: 46px;
        border: 1px solid #454c4e !important;
        border-radius: 10px;
        background-color: #fff;
        width: 150px;
        padding: 0px 10px;
        color: #454c4e;
        font-family: 'Proxima Nova';
        font-weight: 600;
        font-size: 14px !important;
        cursor: pointer;
        text-align: left;
        position: relative;
        &::after {
          position: absolute;
          top: 50%;
          right: 10px;
        }
        &:focus {
          box-shadow: none !important;
          background-color: transparent !important;
          color: #000;
          border: 1px solid #454c4e !important;
        }
        &:hover {
          box-shadow: none !important;
          background-color: transparent !important;
          color: #000;
        }
        &:active {
          box-shadow: none !important;
          background-color: transparent !important;
          color: #000 !important;
        }
      }

      .dropdown {
        a.dropdown-item {
          margin-bottom: 0;
          color: #454c4e;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          display: inline-block;
          vertical-align: middle;
          padding: 10px;
          a {
            text-decoration: none;
            color: #454c4e;
          }
          img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
          &:hover {
            background-color: #43aa8b;
            color: #fff;
          }
        }
      }
    }

    .drop_right {
      .dropdown {
        height: auto;
        width: auto;
        border: none !important;
      }
      .dropdown-toggle {
        height: 46px;
        // border: 1px solid #454c4e;
        border-radius: 10px;
        background-color: #fff;
        width: 150px;
        padding: 0px 10px;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #000;
        cursor: pointer;
        text-align: left;
        position: relative;
        &::after {
          position: absolute;
          top: 50%;
          right: 10px;
        }
        &:focus {
          box-shadow: none !important;
          background-color: transparent !important;
          color: #000;
        }
        &:hover {
          box-shadow: none !important;
          background-color: transparent !important;
          color: #000;
        }
      }

      .dropdown .dropdown-menu {
        // width: 150px;
      }
      .dropdown {
        a.dropdown-item {
          margin-bottom: 0;
          color: #454c4e;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          display: inline-block;
          vertical-align: middle;
          padding: 10px;
          a {
            text-decoration: none;
            color: #454c4e;
          }
          img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
          &:hover {
            background-color: #43aa8b;
            color: #fff;
          }
          &:active {
            box-shadow: none !important;
            background-color: transparent !important;
            // color: #000 !important;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 376px) and (max-width: 1026px) {
  .chapters-subject-discription {
    display: none;
  }
  .swiper-container {
    width: 100%;
    height: 42px;
    background: #ffffff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    background-color: #fff;
    a {
      text-decoration: none;
    }
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    h5 {
      margin-bottom: 0;
      color: #454c4e;
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .sub-grade-select {
    text-align: right;
    display: flex;
    justify-content: space-evenly;
    .drop-left {
      .dropdown {
        height: auto;
        width: auto;
        border: none !important;
        img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }
      .dropdown-toggle {
        height: 46px;
        border: 1px solid #454c4e !important;
        border-radius: 10px;
        background-color: #fff;
        width: 180px;
        padding: 0px 10px;
        color: #454c4e;
        font-family: 'Proxima Nova';
        font-weight: 600;
        font-size: 14px !important;
        cursor: pointer;
        text-align: left;
        position: relative;
        &::after {
          position: absolute;
          top: 50%;
          right: 10px;
        }
        &:focus {
          box-shadow: none !important;
          background-color: transparent !important;
          color: #000;
          border: 1px solid #454c4e !important;
        }
        &:hover {
          box-shadow: none !important;
          background-color: transparent !important;
          color: #000;
        }
        &:active {
          box-shadow: none !important;
          background-color: transparent !important;
          color: #000 !important;
        }
      }

      .dropdown {
        a.dropdown-item {
          margin-bottom: 0;
          color: #454c4e;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          display: inline-block;
          vertical-align: middle;
          padding: 10px;
          a {
            text-decoration: none;
            color: #454c4e;
          }
          img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
          &:hover {
            background-color: #43aa8b;
            color: #fff;
          }
        }
      }
    }

    .drop_right {
      .dropdown {
        height: auto;
        width: auto;
        border: none !important;
      }
      .dropdown-toggle {
        height: 46px;
        // border: 1px solid #454c4e;
        border-radius: 10px;
        background-color: #fff;
        width: 180px;
        padding: 0px 10px;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #000;
        cursor: pointer;
        text-align: left;
        position: relative;
        &::after {
          position: absolute;
          top: 50%;
          right: 10px;
        }
        &:focus {
          box-shadow: none !important;
          background-color: transparent !important;
          color: #000;
        }
        &:hover {
          box-shadow: none !important;
          background-color: transparent !important;
          color: #000;
        }
      }

      .dropdown .dropdown-menu {
        width: 100%;
      }
      .dropdown {
        a.dropdown-item {
          margin-bottom: 0;
          color: #454c4e;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          display: inline-block;
          vertical-align: middle;
          padding: 10px;
          a {
            text-decoration: none;
            color: #454c4e;
          }
          img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
          &:hover {
            background-color: #43aa8b;
            color: #fff;
          }
          &:active {
            box-shadow: none !important;
            background-color: transparent !important;
            // color: #000 !important;
          }
        }
      }
    }
  }
}
