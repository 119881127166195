.PhoneInputCountrySelect {
  display: none;
}

.outermost-div {
  .invisible-part {
    height: 50px;

    margin-top: 40px;
    border-top-left-radius: -9px;
    display: flex;
    justify-content: space-between;
    padding: 10px;

    .plus-icon {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .add-grade {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #43aa8b;
      font-family: 'proxima_nova_rgbold';
      font-size: 24px;
      font-weight: bold;

      line-height: 24px;
    }
  }
}

.register-main .coursedetails-main .course-detail-select .dropdown {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  .selected-value {
    display: block !important;
  }
}

.register-main {
  background-color: #f4f7fc;
  padding-bottom: 50px;
  min-height: 110vh;
  box-shadow: 0 22px 24px -10px rgba(189, 189, 189, 0.07);
  .success_width {
    width: 83%;
    // height: 350px;
    margin-left: 115px;
    // margin-right: 10%;
    @media only screen and (max-width: 1024px) {
      margin: auto;
    }
  }
  .container {
    width: 95%;
  }
  .step-body {
    padding: 50px;
    background-color: #fff;
    max-width: 1210px;
    margin: 30px auto;
    border-radius: 40px;
    box-shadow: 0 22px 24px -10px rgba(189, 189, 189, 0.07);
    .fileUploaderContent {
      cursor: pointer;
    }
    .course-label {
      color: #7d8283;
      font-family: 'Proxima Nova';
      font-size: 18px;
      line-height: 22px;
    }
    .coursedetail-input-part {
      font-family: 'Proxima Nova';
      font-size: 18px;
    }
    .book-demo-grade {
      text-align: left;
    }
    .add-stud {
      text-align: center;
      padding-top: 50px;
    }
    .add-stud-btn {
      background-color: transparent;
      border: 0;
      margin-top: 25px;
      //text-transform: uppercase;
      font-family: 'proxima_nova_rgbold';
      color: #43aa8b;
      font-size: 20px;
      img {
        margin-right: 10px;
        margin-top: -3px;
      }
    }

    .step-btn-part {
      margin-top: 50px;
      margin-bottom: 12px;
    }
    span.error-msg {
      font-size: 14px;
      color: red;
      margin-top: 10px;
      font-family: 'Proxima Nova';
      display: flex;
      text-align: left;
    }
    .css-6kb8m9-menu {
      div {
        cursor: pointer;
      }
      div:hover {
        background-color: #e3fff6;
        color: #43aa8b;
      }
    }
  }
  .register-top {
    padding: 40px 0px 15px;
    .row {
      width: 100%;
      display: flex;
      // justify-content: space-between;
      margin-left: 0px;

      .flex-row {
        display: flex;
        width: 100%;
      }

      .inline-cross {
        width: 30px;
        padding-top: 15px;
        margin-right: -40px;
      }
    }
    .close {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .logo {
      height: 63.32px;
      width: 241px;
    }
    h2 {
      font-size: 40px;
      line-height: 48px;
      color: #20282a;
      font-family: 'Proxima Nova';
      margin-top: 10px;
      margin-bottom: 30px;
      // margin-left: -50px;
      span {
        color: #43aa8b;
      }
    }
  }
  .option-part-head {
    margin-bottom: 10px;
    text-align: center;
    h3 {
      font-size: 50px;
      font-family: 'Proxima Nova';
      margin-top: -58px;
    }
  }
  h5.already-account {
    margin-top: 32px;
    font-size: 30px;
    font-family: 'Proxima Nova';
    color: #20282a;
    a {
      color: #43aa8b;
      text-decoration: none;
    }
  }
  button.next-button {
    max-width: 273px;
    width: 100%;
    height: 55px;
    border-radius: 25px;
    border: 0;
    background-color: #40a486;
    color: #fff;
    text-align: center;
    margin: 10px auto 10px auto;
    font-family: 'Proxima Nova';
    font-size: 20px;
    box-shadow: 0px 12px 10px rgba(67, 170, 139, 0.254371);
    //text-align-last: justify;
    svg {
      //margin-left: 10px;
    }
  }
  .step-btn-part {
    .next-button {
      margin: 10px 20px 10px auto;
    }
  }
  .option-part {
    padding: 50px 40px 15px;
    background-color: #fff;
    // height: 323px;
    // margin:108px 10px;
    // width: 1250px;
    border-radius: 40px;
    box-shadow: 0 22px 24px -10px rgba(189, 189, 189, 0.07);
    max-width: 854px;
    margin-inline: auto;
    p {
      font-family: 'Proxima Nova';
      font-size: 30px;
      width: 100%;
      margin-bottom: 10px;
    }
    .option-part-head {
      margin-bottom: 25px;
      text-align: center;
      h3 {
        font-size: 50px;
        font-family: 'Proxima Nova';
        // margin-top:-267px;
      }
      p {
        font-family: 'Proxima Nova';
        font-size: 30px;
        width: 100%;
        margin-bottom: 68px;
      }
    }

    .option-common {
      display: inline-block;
      padding-top: 14px;
      height: 180px;
      width: 200px;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0 5px 18px 0 rgba(140, 140, 140, 0.1);
      // margin-left:72px;
      margin-bottom: 42px;
      margin-top: 35px;
      // margin-right:53px;
      overflow: hidden;

      cursor: pointer;
      box-shadow: rgba(140, 140, 140, 0.1);
      margin-right: 20px;
      svg {
        margin-top: 30px;
      }
      p {
        margin-top: -205px;
        font-family: 'proxima_nova_rgbold';
        font-size: 25px;
      }
      &:hover {
        background-color: #43aa8b;
        svg {
          fill: #fff !important;
        }
        p {
          color: #fff !important;
        }
      }
      &.active {
        background-color: #43aa8b;
        svg {
          fill: #fff !important;
        }
        p {
          color: #fff !important;
        }
      }
    }
    .option-common.active {
      background-color: #43aa8b;
      svg {
        fill: #fff !important;
      }
      p {
        color: #fff !important;
      }
    }
    div.option-common:last-child {
      // margin-right: 0;
    }
    h5.already-account {
      margin-top: 100px;
      font-size: 30px;
      font-family: 'Proxima Nova';
      color: #20282a;
      a {
        color: #43aa8b;
        text-decoration: none;
      }
    }
    button {
      max-width: 335px;
      width: 100%;
      height: 55px;
      border-radius: 25px;
      border: 0;
      background-color: #40a486;
      color: #fff;
      margin-top: 30px;
      font-family: 'Proxima Nova';
      font-size: 20px;
      svg {
        margin-left: 10px;
      }
    }
  }
  .stepper-above {
    display: inline-block;
    width: 15%;
    position: relative;
    button {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #e8e8e8;
      border: 0;
      z-index: 9;
      position: relative;
      svg {
        font-size: 13px;
        position: relative;
        top: -2px;
        color: #fff;
        left: 0px; //using 0px it will be in center :sailendra dash
      }
    }

    span {
      display: block;
      font-family: 'proxima_nova_rgregular';
      font-size: 18px;
      line-height: 19px;
      font-weight: 400;
      margin-top: 12px;
      color: #4a5052;
    }
  }
  .stepper-above.active {
    button {
      background-color: #f3722c !important;
    }
    span {
      color: #f3722c !important;
    }
  }
  .stepper-above.prev-step {
    button {
      background-color: #43aa8b !important;
    }
    span {
      color: #43aa8b;
    }
    &:after {
      background: #43aa8b;
    }
  }
  .stepper-above:after {
    content: '';
    position: absolute;
    background: #e8e8e8;
    height: 1px;
    width: 100%;
    top: 11px;
    left: 50%;
  }
  div.stepper-above:last-child:after {
    content: none !important;
  }

  // .coursedetails-main2{
  //   background-color: #fff ;
  //   border-radius:20px;

  //   .dropdown-class{
  //     margin-left:20px;
  //   }

  //   .label-div{
  //     margin-left:20px
  //   }

  //   .coursedetails-main{
  //     .course-detail-select{

  //       .div.css-1s2u09g-control,.css-1pahdxg-control{
  //       display:none;
  //     }}
  //   }

  // }

  .coursedetails-main {
    button.add-subject {
      background-color: transparent;
      border: 0;
      margin-top: 0px;
      text-transform: uppercase;
      font-family: 'proxima_nova_rgbold';
      color: #43aa8b;
      font-size: 20px;
      img {
        margin-right: 10px;
        margin-top: -3px;
      }
    }
    .choose-subject {
      .counter,
      .input-box {
        display: inline-block;
      }
      .counter {
        height: 66px;
        width: 66px;
        cursor: pointer;
        display: inline-block;
        vertical-align: top;
        img {
          width: 100%;
        }
      }
      .input-box {
        width: 90px;
        height: 66px;
        display: inline-block;
        margin: 0px 10px;
        vertical-align: top;
        background-color: #f3f3f3;
        border-radius: 10px;
        input {
          width: 100%;
          border: 0;
          height: 100%;
          background-color: #f3f3f3;
          border-radius: 10px;
          text-align: center;
        }
        input:focus {
          outline: none;
        }
      }
      .remove-part {
        display: inline-block;
        // margin-left: 50px;
        // margin-top: 20px;
        cursor: pointer;
        font-family: 'proxima_nova_rgbold';
        color: #fd2a5b;
        img {
          height: 12px;
          width: 12px;
          margin-top: -3px;
        }
      }
      .remove-partOlympiad {
        display: inline-block;
        width: 140px;
        margin-left: 84%;
        margin-top: 0px;
        margin-bottom: 50px;
        cursor: pointer;
        font-family: 'proxima_nova_rgbold';
        color: #fd2a5b;
        img {
          height: 24px;
          width: 24px;
          margin-top: -3px;
        }
      }
      .course-detail-select .label-div .info-icon {
        height: 20px;
        width: 20px;
        background-color: #fca834;
        display: inline-flex;
        border-radius: 50%;
        color: #fff;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        margin-left: 10px;
        position: relative;
        top: -3px;
      }
      .tooltip-innercss {
        max-width: 200px;
        padding: 0.25rem 0.5rem;
        color: #fff;
        text-align: center;
        // font-size: 14px;
        background-color: #fca834;
        border-radius: 0.25rem;
        margin: 0px 0px 0 12px;
      }
    }
    .coursedetail-input-part {
      input {
        width: 100%;
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        height: 66px;
        background-color: #fff;
        padding: 0px 20px;
        //margin-top: 5px;
      }
      input:focus {
        outline: none;
      }
    }

    .upload-container {
      margin-top: 50px;
      font-style: 'proxima_nova_rgregular';
      width: 98%;
      color: #7d8283;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 22px;

      .dashed-border {
        border: dashed 3px #e0e0e0;
        height: 176px;
        width: 100%;
        border-radius: 15px;

        .file-uploader {
          display: block;
          width: 100%;
          height: 100%;
          label {
            display: block;
            width: 100% !important;
            height: 100%;
            max-width: 100%;
            margin-top: 0px !important;
            svg {
              display: none;
            }
            .sc-dkPtRN.ebdQkY {
              display: flex;
              height: 100%;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              span {
                font-family: 'Proxima Nova';
                color: #7e8283;
                font-size: 18px;
                span {
                  display: none;
                }
              }
              span.sc-hKwDye.kCudqJ {
                position: relative;
              }
              span.sc-hKwDye::after {
                content: ' here';
                color: #f3722c;
                font-family: 'Proxima Nova';
                font-size: 18px;
              }
              span.sc-hKwDye.kCudqJ:before {
                content: 'Maximum file size: 15MB';
                position: absolute;
                width: 100%;
                text-align: center;
                bottom: -29px;
                font-size: 15px;
              }
              span.file-types {
                display: none;
              }
            }
          }
          .iciELI {
            border: none;
            width: 500px;
          }

          .kCudqJ span {
            font-size: 20px;
            color: black;
          }

          .kCudqJ {
            font-size: 18px;
          }

          .ebdQkY > span {
            font-size: 15px;
          }

          .iciELI {
            margin-top: -10px;
          }
        }

        .upload-text1 {
          text-align: center;
          padding-top: 50px;
          font-size: 20px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 22px;
          color: #7d8283;

          .file-uploader {
            border: 'red';
          }
        }

        .max-size {
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 17px;
          text-align: center;
          color: #7d8283;
        }

        .orange {
          color: #f3722c;
          margin-left: 7px;
          cursor: pointer;
        }
      }

      .upload-image {
        margin-right: 10px;
      }

      .upload-notif {
        margin-top: 35px;
        border: #e0e0e0 solid 2px;
        width: 100%;
        min-height: 66px;
        display: flex;
        justify-content: space-between;
        color: #20282a;
        font-size: 18px;
        align-items: center;
        font-style: 'proxima_nova_rgregular';
        padding-left: 50px;
        border-radius: 15px;
        @media only screen and (max-width: 480px) {
          font-size: 14px;
          padding-block: 10px;
          padding-left: 10px;
        }

        .remove-file {
          border: none;
          font-style: 'proxima_nova_rgregular';
          background-color: #fff;
          margin-right: 50px;
          @media only screen and (max-width: 480px) {
            margin-right: 0px;
          }
        }

        .preview-image {
          height: 40px;
          width: 40px;
        }

        .close {
          width: 20px;
          height: 20px;
          margin-right: 20px;
        }

        .faded {
          color: #7e8283;
        }
      }
    }

    .heading-and-dropdown {
      .subject-list-main {
        .subject-list {
          .round {
            .checkmark {
              top: -25px;
            }
          }
        }
      }
    }
    .border1 {
      background-color: #ffffff;
      height: 50px;
      width: 99.2%;
      border-bottom-left-radius: 40px;
      border-bottom-right-radius: 40px;
    }

    .border2 {
      background-color: #ffffff;
      height: 50px;
      width: 99.2%;
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
    }

    .invisible-part {
      height: 100px;
      width: 60vw;
      margin-left: -80px;
      margin-top: 40px;
      border-top-left-radius: -9px;
      display: flex;
      justify-content: space-between;
      padding: 10px;

      .plus-icon {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .add-grade {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #43aa8b;
        font-family: 'proxima_nova_rgbold';
        font-size: 24px;
        font-weight: bold;

        line-height: 24px;
      }
    }

    // .row{

    //   .css-1s2u09g-control{
    //     display:none;
    //   }
    //   .inactive{
    //     display:none;
    //   }
    // }

    .heading-and-dropdown {
      width: 100%;
      height: auto;

      .close-icon-container {
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: row-reverse;
        margin-top: -80px;
      }

      .close-grade {
        border: none;
        background-color: transparent;
        width: 20px;
        height: 20px;
        position: relative;
        top: 66px;
        right: -14px;
      }
      .close-icon2 {
        width: 30px;
        height: 30px;
      }

      .heading {
        padding-top: 30px;
        width: 100%;

        h2 {
          color: #454c4e;
          font-family: 'proxima_nova_rgbold';
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 29px;
          text-align: center;
        }

        p {
          color: #7d8283;
          font-family: 'Proxima Nova';
          font-size: 19px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 22px;
          text-align: center;
        }

        h2,
        p {
          text-align: center;
        }
      }

      .dropdowns {
        display: flex;
        justify-content: space-between;

        .mutiple-dropdown-part {
          .flex-column {
            display: flex;
            width: 49vw;
          }
          .subject-list-main {
            width: 88%;
            margin-bottom: 30px;
          }
        }

        .experience-field {
          width: 100%;
          height: 66px;
          padding: 0px 10px;
          border: 1px solid #e0e0e0;
          border-radius: 10px;
        }
        .experience-field.focus-visible {
          border: 1px solid #43aa8b;
          border-radius: 10px;
          box-shadow: none;
          outline: none;
        }

        .label-div {
          color: #7d8283;
          font-family: 'Proxima Nova';
          font-size: 20px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 25px;

          .bold-weekday {
            color: #454c4e;
          }
          .tooltip-innercss {
            max-width: 200px;
            padding: 0.25rem 0.5rem;
            color: #fff;
            text-align: center;
            // font-size: 14px;
            background-color: #fca834;
            border-radius: 0.25rem;
            margin: 0px 0px 0 12px;
          }
        }

        .dropdown-class {
          color: #7d8283;
          font-family: 'Proxima Nova';
          font-size: 18px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 35px;
          height: 80px;
          width: 80%;
        }
      }
    }
    h3.text-center {
      font-family: 'proxima_nova_rgbold';
      color: #454c4e;
      font-size: 24px;
      line-height: 29px;
      //text-transform: uppercase;
      // margin-top: 40px;
      span {
        color: #f3722c;
      }
    }
    .course-detail-select {
      .dropdown {
        position: relative;
        color: #333;
        cursor: default;
        box-sizing: border-box;
        height: 66px;
        width: 450px;
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        background-color: #ffffff;
      }
      .country-flag-and-selected-value {
        img {
          width: 35px;
          height: 21px;
          margin: auto 10px auto 7px;
        }
        input:disabled {
          background-color: #f3f3f3;
        }
        input {
          margin: -1px;
          line-height: 2.5;
          font-size: 1.2rem;
          border-radius: 2px;
          box-sizing: border-box;
          cursor: pointer;
          outline: none;
          padding: 8px 8px 8px 10px;
          transition: all 200ms ease;
          // width: 100%;
          box-sizing: border-box;
          height: 66px;
          width: 450px;
          border: 1px solid #e0e0e0;
          border-radius: 0 10px 10px 0;
          background-color: #ffffff;
        }
      }
      .country-flag-and-selected-novalue {
        img {
          width: 35px;
          height: 21px;
          margin: 22px 10px 0px 7px;
        }
        input {
          margin: -1px;
          line-height: 2.5;
          font-size: 1.2rem;
          border-radius: 2px;
          box-sizing: border-box;
          cursor: pointer;
          outline: none;
          padding: 8px 8px 8px 10px;
          transition: all 200ms ease;
          // width: 100%;
          box-sizing: border-box;
          height: 66px;
          // width: 585px;
          width: 497px;
          border: 1px solid #e0e0e0;
          border-radius: 10px;
          background-color: #ffffff;
        }
      }
      .chevDown {
        position: absolute;
        top: 14px;
        right: 9px;
        color: #c6c8c9;
        display: flex;
        // padding: 8px;
        -webkit-transition: color 150ms;
        transition: color 150ms;
        box-sizing: border-box;
      }
      .chevDown-novalue {
        position: absolute;
        /* width: 30px; */
        /* height: 60px; */
        top: 14px;
        right: 9px;
        color: #c6c8c9;
        display: flex;
        /* padding: 8px; */
        transition: color 150ms;
        box-sizing: border-box;
      }

      .ReactFlagsSelect-module_selectBtn__19wW7 {
        border-radius: 10px;
        height: 66px;
        border: 1px solid #e0e0e0;
        width: 100%;
      }
      .css-6j8wv5-Input input {
        font-size: 20px;
        // display: none
        border: 0px solid #e0e0e0 !important ;
        height: 90%;
      }
      .css-1kixbkd:hover {
        background-color: 'blue';
      }
      .css-12jo7m5 {
        border-radius: 33px;
        color: #7d8283;
        font-size: 18px;
        overflow: hidden;
        padding: 7px;
        margin: 0 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
        background-color: #e3fff6;
        font-style: 'Proxima Nova';
        font-weight: 600;
      }
      .css-xb97g8 {
        border-radius: 50%;
        color: #7d8283;
        font-size: 20px;
        margin-left: -10px;
      }

      .css-xb97g8 :hover {
        background-color: #e3fff6;
        color: black;
      }

      .css-1rhbuit-multiValue {
        border-radius: 33px;
        width: auto;

        background-color: #e3fff6;
        border: 1px solid #e0e0e0;
      }
      label,
      div.label-div {
        display: flex;
        color: #7d8283;
        font-family: 'Proxima Nova';
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 5px;
        margin-top: 30px;
      }
      .exam-style .css-1s2u09g-control {
        height: fit-content;
      }
      .tooltip-innercss {
        max-width: 200px;
        padding: 0.25rem 0.5rem;
        color: #fff;
        text-align: center;
        // font-size: 14px;
        background-color: #fca834 !important ;
        border-radius: 0.25rem;
        margin: 0px 0px 0 12px;
      }
      div.css-1s2u09g-control:focus,
      .css-1pahdxg-control:focus {
        // border-color:blue;
        border-color: #fff;
      }

      // .css-1s2u09g-control,
      // .css-1pahdxg-control {
      //   height: fit-content;
      // }

      div.css-1s2u09g-control,
      .css-1pahdxg-control {
        //height: fit-content;
        width: 100%;
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        // height: 66px;
        min-height: 66px;
        // max-height: 66px;

        background-color: #fff;
        padding: 10px 10px;
        cursor: pointer;
        align-items: center;
        position: relative;
        box-shadow: none;

        .css-ijftu2-ValueContainer {
          max-width: 500px;
          padding: 0;
        }

        .css-319lph-ValueContainer {
        }
        .css-1hb7zxy-IndicatorsContainer {
          span {
            width: 0;
          }
          .css-tlfecz-indicatorContainer {
            padding: 0;
            svg {
              fill: #212529;
            }
          }
        }
      }
      div.select {
        .css-1s2u09g-control {
          margin-top: 10px;
        }
        width: 100%;
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        height: 66px;
        background-color: #fff;
        padding: 0px 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        svg {
          position: absolute;
          right: 20px;
        }
      }
      button {
        width: 100%;
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        height: 66px;
        background-color: #fff;
        padding: 0px 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        box-shadow: none;
      }
      select:focus {
        outline: 0 !important;
      }
      .dropdown.false {
        display: none;
      }
      .dropdown.true {
        display: block;
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        margin-top: 5px;
        div {
          cursor: pointer;
          height: 80px;
          display: flex;
          align-items: center;
          padding: 20px;
        }
        div:hover {
          background-color: #e3fff6;
          color: #43aa8b;
          border-radius: 10px 10px 0 0;
        }
      }
      .dropdown-main {
        position: relative;
        .dropdown {
          position: absolute;
          width: 100%;
          background-color: #fff;
          z-index: 9;
        }
      }
    }
    .mutiple-dropdown-part {
      width: 50%;
      height: auto;

      .qual-and-edu {
        display: flex;
        justify-content: space-between;
      }

      .qual {
        margin-right: 50px;

        .div.css-1s2u09g-control {
          width: 150%;
        }
      }

      .upload-container {
        margin-top: 50px;
        font-style: 'proxima_nova_rgregular';
        width: 100%;
        color: #7d8283;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;

        .dashed-border {
          border: dashed 3px #e0e0e0;
          height: 176px;
          width: 100%;
          border-radius: 15px;

          .upload-text1 {
            text-align: center;
            padding-top: 50px;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 22px;
            color: #7d8283;
          }

          .max-size {
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 17px;
            text-align: center;
            color: #7d8283;
          }

          .orange {
            color: #f3722c;
          }
        }

        .upload-image {
          margin-right: 10px;
        }

        .upload-notif {
          margin-top: 60px;
          border: #e0e0e0 solid 2px;
          width: 100%;
          height: 66px;
          display: flex;
          color: #20282a;
          font-size: 18px;
          align-items: center;
          font-style: 'proxima_nova_rgregular';
          padding-left: 50px;

          .faded {
            color: #7e8283;
          }
        }
      }

      div {
        span.css-1v99tuv {
          color: #7d8283;
          font-family: 'Proxima Nova';
          font-size: 18px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 22px;
        }
        .css-1vr111p-option,
        .css-1qprcsu-option {
          cursor: pointer;
          height: 80px;
          display: flex;
          align-items: center;
          padding: 20px;
          input {
            right: 20px;
            position: absolute;
          }
        }
        .css-1qprcsu-option {
          background-color: #e3fff6 !important;
          color: #43aa8b !important;
        }
        div.css-1vr111p-option:hover {
          background-color: #e3fff6;
          color: #43aa8b;
          border-radius: 10px 10px 0 0;
        }
      }
      .css-1pcexqc-container {
        display: block;
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        margin-top: 5px;
      }
      .css-g1d714-ValueContainer {
        //position: absolute;
        top: 100%;
        margin-top: 25px;
        left: 0;
        .css-1rhbuit-multiValue {
          border-radius: 20px;
          border: 1px solid #40a486;
          // background-color: transparent;
          padding: 5px;
          min-width: 135px;
          margin-right: 10px;
          .css-12jo7m5 {
            width: 85%;
            color: #40a486;
          }
          div.css-xb97g8 {
            svg {
              fill: #40a486 !important;
              height: 25px;
              width: 25px;
            }
          }
          div.css-xb97g8:hover {
            background-color: transparent;
          }
        }
      }
      // .css-g1d714-ValueContainer::after{
      //   content: 'Select';
      //   position: absolute;
      //   top: 50%;
      // }
      .css-1hb7zxy-IndicatorsContainer {
        div:first-child {
          position: absolute;
          right: 50px;
        }
        div:last-child {
          position: absolute;
          right: 20px;
        }
      }
      .selected-top {
        margin-top: 27px;
        visibility: hidden;
        .selected-common {
          height: 38px;
          width: 135px;
          display: inline-block;
        }
      }
    }
    .mutiple-dropdown-part.mutiple-dropdown-part-right {
      .selected-top {
        margin-top: 61px;
        margin-left: -15px;
      }
    }
    .mutiple-dropdown-part.activity-dropdown {
      .css-g1d714-ValueContainer {
        top: 0;
        margin-top: 0;
        // left: 100%;
        width: 100%;
      }
    }
    .mutiple-dropdown-part.olympiad-dropdown {
      .css-g1d714-ValueContainer {
        //top: 70px;
        margin-top: 0;
        // left: 100%;
        width: 115%;
      }
    }
    .subject-list-main {
      label.label {
        display: block;
        color: #7d8283;
        font-family: 'Proxima Nova';
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 10px;
        margin-top: 10px;
      }
      .subject-list {
        border: 1px solid #e0e0e0;
        display: inline-flex;
        align-items: center;
        padding: 10px 15px;
        width: auto;
        border-radius: 33px;
        margin-right: 10px;
        margin-bottom: 15px;
        color: #20282a;
        font-family: 'Proxima Nova';
        background-color: #f4f7fc;
        font-size: 18px;
        font-weight: 600;
        padding-right: 25px;
        min-width: 175px;
        min-height: 55px;
        .round {
          position: relative;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          display: inline-block;
          vertical-align: top;
          margin-right: 35px;
          input {
            border-radius: 50%;
            margin-right: 10px;
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }
          input:checked ~ .checkmark {
            background-color: #43aa8b;
          }
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: #fff;
            border: 1px solid #e0e0e0;
            border-radius: 50%;
          }
          .checkmark:after {
            content: '';
            position: absolute;
            display: none;
          }
          input:checked ~ .checkmark:after {
            display: block;
          }
          .checkmark:after {
            left: 9px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
      .subject-list.active {
        border: 1px solid #43aa8b;
        background-color: #fff;
        color: #43aa8b;
      }
    }
  }
  .mydetails-main {
    .row {
      font-family: 'Proxima Nova';
      font-size: 20px;
      padding-bottom: 10px;
      color: #7d8283;
      .choose-subject {
        margin-bottom: 20px;
      }

      .course-detail-select {
        .disabled {
          background-color: #f3f3f3;
          opacity: 1;
        }
        .dropdown {
          padding: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          .selected-value {
            display: block !important;
          }
          input {
            border: 0;
            width: 100%;
            height: 90%;
          }
          .options {
            left: 0;
          }
          .arrow {
            top: 42%;
          }
          .country-flag-and-selected-value {
            align-items: center;
          }
        }

        .custom-dropdown {
          width: 100%;
        }

        .element-style {
          font-size: 20px;
        }

        .select-button {
          font-size: 40px;
        }

        .menu-flags {
          font-size: 40px;
          .ReactFlagsSelect-module_filterBox__3m8EU {
            padding: 15px;
            padding-bottom: 0;
            input {
              margin: 0;
            }
          }
          ul {
            li:hover {
              background-color: #e3fff6;
              span {
                color: #43aa8b;
              }
            }
          }

          .select-button::after {
            content: '';
            display: block;
            box-sizing: border-box;
            width: 8px;
            height: 8px;
            border-bottom: 2px solid #7d8283;
            // border-right: 2px solid #7d8283;
            transform: rotate(45deg);
            left: 4px;
            top: 2px;
            border-left: 0;
            border-top: 0;
            margin-right: 3px;
          }
        }

        #state {
          .css-1pahdxg-control {
            box-shadow: none;
            border: 1px solid #e0e0e0;
            height: 66px;
            cursor: pointer;
          }
          .css-1okebmr-indicatorSeparator {
            background-color: transparent;
          }
        }

        button.select-button:focus {
          border: 1px solid #43aa8b;
        }
        .ReactFlagsSelect-module_selectBtn__19wW7 {
          border-radius: 10px;
          height: 66px;
          border: 1px solid #e0e0e0;
          width: 100%;
          font-size: 20px;
        }

        .ReactFlagsSelect-module_fullWidthOptions__1XeR6 {
          max-height: 350px;
          border-radius: 10px;
        }

        .ReactFlagsSelect-module_selectOptionValue__vS99- {
          font-size: 20px;
          padding: 20px 10px;
        }
      }

      .ABC {
        input {
          margin-left: -72px;
          padding-left: 80px;
        }

        svg {
          padding-left: 20px;
        }
      }

      .css-13cymwt-control {
        border-radius: 10px;
        height: 66px;
        border: 1px solid #e0e0e0;
        width: 100%;

        .css-2613qy-menu {
          color: black;
        }
      }
      .css-16xfy0z-control {
        border-radius: 10px;
        height: 66px;
        border: 1px solid #e0e0e0;
        width: 100%;

        .css-2613qy-menu {
          color: black;
        }
      }

      .css-t3ipsp-control {
        border-radius: 10px;
        height: 66px;
        border: 1px solid #e0e0e0;
        width: 100%;

        .css-2613qy-menu {
          color: black;
        }
      }
      // .css-1s2u09g-control :hover {
      //   border-radius: 10px;
      //   height: 66px;
      //   border: 1px solid #e0e0e0;
      //   width: 100%;

      //   .css-2613qy-menu {
      //     color: black;
      //   }
      // }

      // .css-1s2u09g-control:focus{
      //   border: 1px solid greenyellow;
      // }
      .css-319lph-ValueContainer {
        margin: -12px;
        padding: 0;
        margin-left: 10px;
      }
    }
    .bottom-border-styling {
      border-bottom: 1px solid #e0e7f3;
      margin-inline: -50px;
      padding-inline: 50px;
      padding-bottom: 50px;
    }

    .otp-block {
      button {
        background-color: #ffffff;
        border: none;
        color: #43aa8b;
      }
      input {
        width: 100% !important;
        height: 66px;
      }
      span {
        margin-right: 10px;
      }
      p {
        color: #43aa8b;
        font-family: 'proxima_nova_rgbold';
        margin-top: 7px;
        text-align: right;

        span {
          margin-right: 0;
          cursor: pointer;
        }
      }
    }

    .mydetail-input {
      .mydetail-input-part {
        position: relative;

        .phone-input-custom {
          input {
            padding: 10px 70px;
            font-size: 18px;

            image {
              height: 20px;
            }
          }
          .search {
            input {
              padding: 10px;
              font-size: 18px;
              height: auto;
            }
          }
        }
        .disable {
          background-color: #e9ecef;
          border-radius: 10px;
          color: #20282a;
          cursor: not-allowed;
          input {
            background-color: #e9ecef;
            border-radius: 10px;
          }
        }
        input {
          height: 66px;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      .gender-wrapper {
        display: flex;
        justify-content: left;
        gap: 10px;
        .gender-button {
          position: initial;
          background: #f4f7fc;
          border-radius: 10px;
          padding: 24px;
          font-weight: 600;
          font-size: 18px;
          line-height: 18px;
          color: #20282a;
        }
        .active {
          background-color: #43aa8b;
          transition: ease 0.4s;
          color: white;
        }
      }

      button {
        display: inline-block;
        position: absolute;
        right: 7%;
        bottom: 15px;
        background: transparent;
        border: 0;
        color: #40a486;
        font-family: 'proxima_nova_rgbold';
      }
      .success-msg {
        color: #40a486;
      }
    }
    h3.text-center {
      font-family: 'proxima_nova_rgbold';
      color: #454c4e;
      font-size: 24px;
      line-height: 27px;
      //text-transform: uppercase;
      margin-bottom: 18px;
      span {
        color: #f3722c;
      }
    }
    h3.bottom-title {
      font-family: 'proxima_nova_rgbold';
      color: #454c4e;
      font-size: 24px;
      line-height: 27px;
      //text-transform: uppercase;
      margin-bottom: 10px;
      margin-top: 50px;
      span {
        color: #f3722c;
      }
    }
    p {
      font-family: 'Proxima Nova';
      font-size: 20px;
      padding-bottom: 10px;
      margin-bottom: 0;
      color: #7d8283;
    }
    label {
      display: block;
      color: #7d8283;
      font-family: 'Proxima Nova';
      font-size: 20px;
      line-height: 22px;
      margin-top: 30px;
      margin-bottom: 8px;
    }
    input {
      width: 100%;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      //height: 66px;
      // min-height: 66px;
      background-color: #fff;
      padding: 0px 20px;
      //margin-top: 5px;
    }
    input:focus {
      outline: 0 !important;
      border: 1px solid #43aa8b;
      box-shadow: none;
    }
    input::placeholder {
      font-family: 'Proxima Nova';
      font-size: 20px;
      //padding-bottom: 10px;
      color: #7d8283;
    }
    input:hover {
      border-color: #e0e0e0;
    }
    input:disabled {
      background-color: #f3f3f3;
    }
  }

  .pay-fees-main {
    .payment-main {
      font-family: 'Proxima Nova';

      label {
        margin-top: 0;
        font-size: 18px;
      }
      h4 {
        font-size: 22px;
        color: #454c4e;
      }
      p {
        color: #454c4e;
        font-size: 22px;
        margin-bottom: 0;
        margin-top: 25px;
      }
      .payment-data {
        margin-bottom: 25px;
      }
    }
    .total-data {
      background-color: #e3fff6;
      border-radius: 0 0 10px 10px;
      height: 70px;
      display: flex;
      align-items: center;
      padding: 0px 30px;
      width: 75%;
      margin: auto;
      font-family: 'Proxima Nova';
      h4 {
        color: #43aa8b;
        font-size: 24px;
        margin-bottom: 0;
      }
      h3 {
        font-size: 30px;
        margin-bottom: 0;
        color: #43aa8b;
      }
    }
  }
  .create-student-main {
    .add-stud {
      text-align: center;
      padding-top: 13px;
      margin-bottom: 64px;
    }
    .add-stud-btn {
      background-color: transparent;
      border: 0;
      margin-top: 25px;
      // text-transform: uppercase;
      font-family: 'proxima_nova_rgbold';
      color: #43aa8b;
      font-size: 20px;
      img {
        margin-right: 10px;
        margin-top: -3px;
      }
    }
    .submit-button {
      background-color: #43aa8b;
      box-shadow: none;
      border-radius: 33.75px;
      color: #fff;
      border: 0;
      width: 273px;
      height: 66px;
      font-size: 20px;
      font-family: 'proxima_nova_rgbold';
      margin: 4% 0% 0% 40%;
      /* text-align: left; */
      /* margin: auto; */
      padding: 0px 25px;
      transition: 0.3s;
    }
    h3.text-center {
      font-family: 'proxima_nova_rgbold';
      color: #454c4e;
      font-size: 24px;
      line-height: 29px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    h4 {
      color: #f3722c;
      font-size: 24px;
      font-family: 'proxima_nova_rgbold';
      line-height: 29px;
      margin-bottom: 10px;
      margin-top: 25px;
    }
    h4.title-last {
      position: relative;
      // top: 32px;
    }
    label {
      display: block;
      color: #7d8283;
      font-family: 'Proxima Nova';
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 5px;
      margin-top: 30px;
    }
    input {
      width: 100%;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      height: 66px;
      background-color: #fff;
      padding: 0px 20px;
      margin-top: 9px;
    }
    input:focus {
      outline: 0 !important;
    }
    // .create-student-main {
    //   .col-md-6 {
    //     margin-bottom: 40px;
    //   }
    // }

    .student-last {
      position: relative;
      top: 32px;
    }
    .addparent-above {
      left: -41px;
      position: relative;
      width: calc(100% + 81px);
      height: 50px;
      background-color: #fff;
      /* margin-bottom: 18px; */
      top: 32px;
      border-bottom-left-radius: 40px;
      border-bottom-right-radius: 40px;
      z-index: 9;
      margin-bottom: 3px;
    }
    .addparent-above-below {
      left: -41px;
      position: relative;
      width: calc(100% + 99px);
      height: 40px;
      background-color: #f4f7fc;
      top: 2px;
    }
    // .addparent-top {
    //   left: -41px;
    //   position: relative;
    //   width: calc(100% + 90px);
    //   height: 50px;
    //   background-color: #f4f7fc;
    //   margin-bottom: 18px;
    // }
    .addparent-top {
      left: 12px;
      position: relative;
      width: calc(100% + 90px);
      height: 10px;
      background-color: #f4f7fc;
      margin-bottom: 18px;
      margin-top: 32px;
    }
    .addparent-bottom {
      left: -40px;
      position: relative;
      width: calc(100% + 80px);
      height: 50px;
      background-color: #fff;
      border-top-left-radius: 40px;
      top: -50px;
      border-top-right-radius: 40px;
    }
    .parent-title {
      // margin-top: -50px;
      margin-top: 40px;
    }
    .inline-cross {
      /* margin-left: 97%; */
      margin-top: -29px;
      cursor: pointer;
      /* text-align: right; */
      position: absolute;
      width: fit-content;
      right: 0;
    }
    .close {
      width: 15px;
      height: 15px;
    }
    // .parent-title {
    //   left: -42px;
    //   position: relative;
    //   width: calc(100% + 90px);
    //   top: 0px;
    //   &::before {
    //     content: "";
    //     position: absolute;
    //     left: 1px;
    //     top: -3px;
    //     width: 30px;
    //     height: 32px;
    //     background: #f4f7fc;
    //   }
    //   &::after {
    //     content: "";
    //     position: absolute;
    //     width: 50px;
    //     height: 50px;
    //     background: #fff;
    //     left: 2px;
    //     border-top-left-radius: 40px;
    //     top: -1px;
    //   }
    // }
  }

  .addnew_success-part {
    h1 {
      color: #43aa8b;
      font-size: 50px;
      font-family: 'proxima_nova_rgbold';
      margin: 30px 0px;
    }
    p {
      font-family: 'proxima_nova_rgregular';
      font-size: 30px;
      color: #4a5052;
      line-height: 34px;
      width: 880px;
      margin-left: -42px;
      padding-bottom: 20px;
    }

    .success-icon {
      width: 180px;
      height: 180px;
    }

    .flip {
      backface-visibility: visible !important;
      animation: flip 2s ease infinite;
    }
    @keyframes flip {
      0% {
        transform: perspective(600px) rotateY(0);
        animation-timing-function: ease-out;
      }
      40% {
        transform: perspective(600px) translateZ(150px) rotateY(170deg);
        animation-timing-function: ease-out;
      }
      50% {
        transform: perspective(600px) translateZ(150px) rotateY(190deg) scale(1);
        animation-timing-function: ease-in;
      }
      80% {
        transform: perspective(600px) rotateY(360deg) scale(0.95);
        animation-timing-function: ease-in;
      }
      100% {
        transform: perspective(600px) scale(1);
        animation-timing-function: ease-in;
      }
    }
  }

  .success-part {
    h1 {
      color: #43aa8b;
      font-size: 50px;
      font-family: 'proxima_nova_rgbold';
      margin: 30px 0px;
    }
    p {
      font-family: 'proxima_nova_rgregular';
      font-size: 30px;
      color: #4a5052;
      line-height: 34px;
      width: 880px;
      // margin-left: 160px;
      margin: auto;
      padding-bottom: 20px;
      @media only screen and (max-width: 1024px) {
        width: auto;
      }
    }

    .success-icon {
      width: 180px;
      height: 180px;
    }

    .flip {
      backface-visibility: visible !important;
      animation: flip 2s ease infinite;
    }
    @keyframes flip {
      0% {
        transform: perspective(600px) rotateY(0);
        animation-timing-function: ease-out;
      }
      40% {
        transform: perspective(600px) translateZ(150px) rotateY(170deg);
        animation-timing-function: ease-out;
      }
      50% {
        transform: perspective(600px) translateZ(150px) rotateY(190deg) scale(1);
        animation-timing-function: ease-in;
      }
      80% {
        transform: perspective(600px) rotateY(360deg) scale(0.95);
        animation-timing-function: ease-in;
      }
      100% {
        transform: perspective(600px) scale(1);
        animation-timing-function: ease-in;
      }
    }
  }
  .teacher-success-part {
    p {
      width: auto;
      margin: 0;
    }
  }
  .success-button button {
    background-color: #43aa8b;
    box-shadow: 0 12px 10px 0 rgba(67, 170, 139, 0.25);
    border-radius: 43.75px;
    color: #fff;
    border: none;
    font-weight: bold;
    font-size: 20px;
    font-family: 'proxima_nova_rgbold';
    // display: inline-flex;
    align-items: center;
    width: 25%;
    // height: 70px;
    margin: auto;
    padding: 25px;
    // justify-content: space-between;
    text-align: center;
    @media only screen and (max-width: 480px) {
      width: auto;
      padding: 10px 20px;
    }

    // span {
    //   float: right;
    //   height: 40px;
    //   width: 40px;
    //   background-color: #fca834;
    //   border-radius: 50%;
    //   padding: 0px 10px;
    //   box-shadow: 5px 5px 5px rgb(0 0 0 / 24%) !important;
    //   display: flex;
    //   align-items: center;
    // }
  }
  .next-button {
    background-color: #43aa8b;
    box-shadow: none;
    border-radius: 33.75px;
    color: #fff;
    border: 0;
    width: 273px;
    height: 66px;
    font-size: 20px;
    font-family: 'proxima_nova_rgbold';
    text-align: center;
    padding: 0px 25px;
    transition: 0.3s;
    opacity: 0.8;
    margin-right: 10px;
    margin-bottom: 10px;
    span {
      float: right;
      height: 24px;
      width: 24px;
      background-color: #fca834;
      border-radius: 50%;
      padding: 0px 7px;
      margin-top: 4px;
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .back-button {
    background-color: #43aa8b;
    box-shadow: none;
    border-radius: 33.75px;
    color: #fff;
    border: 0;
    width: 100px;
    margin-right: 10px;
    height: 66px;
    font-size: 20px;
    font-family: 'proxima_nova_rgbold';
    text-align: left;
    padding: 0px 25px;
    transition: 0.3s;
    opacity: 0.8;
  }
  .next-button:hover {
    box-shadow: 5px 5px 5px rgb(67 170 139 / 25%) !important;
    opacity: 1;
    span {
      box-shadow: 5px 5px 5px rgb(0 0 0 / 24%) !important;
    }
  }
  .next-button:disabled {
    opacity: 0.4;
    span {
      box-shadow: 5px 5px 5px rgb(0 0 0 / 24%) !important;
    }
  }
}
.register-main.success {
  padding-top: 50px;
}
.paymet-summary-main {
  border-radius: 20px;
  background-color: #f4f7fc;
  .stu {
    margin: auto;
    width: 80%;
  }
  // margin-top: 30px;
  // box-shadow: 0 12px 34px -10px rgba(0,0,0,0.05);
  // padding-bottom: 50px;

  .paymet-summary-details {
    margin: auto;
    max-width: 900px;
    border-radius: 20px;
    background-color: #fcfcfc;
    //padding-left: 72px;
    padding-top: 30px;
    box-shadow: 0 12px 34px -10px rgba(0, 0, 0, 0.05);
    padding-inline: 100px;
    margin-bottom: 30px;
    padding-bottom: 10px;
    order: 1;
  }
  .paymet-summary-details-pay {
    margin: auto;
    width: 50%;
    border-radius: 20px;
    background-color: #fcfcfc;
    padding-left: 72px;
    padding-top: 30px;
    box-shadow: 0 12px 34px -10px rgba(0, 0, 0, 0.05);
  }
  .paymet-summary-heading {
    color: #454c4e;
    font-family: 'Proxima Nova';
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    padding-top: 30px;
  }
  // .paymet-summary-heading-color {
  //   color: #43aa8b;
  // }
  .grade-heading {
    opacity: 0.8;
    color: #7d8283;
    font-family: 'Proxima Nova';
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
  }
  .total-section {
    .pay-for {
      opacity: 0.8;
      color: #454c4e;
      font-family: 'Proxima Nova';
      font-size: 24px;
      font-weight: 800;
      letter-spacing: 0;
      line-height: 29px;
    }
  }

  .pay-for {
    opacity: 0.8;
    color: #454c4e;
    // font-family: 'Proxima Nova';
    font-size: 24px;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: 0;
    line-height: 29px;
    width: 60%;
  }
  .grand-total-last {
    opacity: 0.8;
    color: #43aa8b;
    font-family: 'Proxima Nova';
    font-size: 35px;
    font-weight: 600;
    margin-left: 30px;
  }
  .grand-usd-dollar {
    font-family: 'Proxima Nova';
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 37px;
    text-align: right;
    margin-right: 27px;
    color: #43aa8b;
  }
  .each-grand-total {
    border-radius: 0 0 10px 10px;
    background-color: #fff5e6;
    font-family: 'Proxima Nova';
    font-size: 24px;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
    padding: 10px;
    margin-top: 20px;
    h5 {
      color: #454c4e;
      font-weight: 600;
    }
    span {
      color: #fca834;
      font-weight: bold;
    }
  }
  .section-heading {
    background-color: #f4f7fc;
    padding: 9px;
    width: 100%;
    text-align: left;
    color: #454c4e;
    // font-family: 'Proxima Nova';
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    margin-top: 50px;
  }
  .class_name {
    margin-left: 10px;
  }
  .section-1 {
    // max-width: 800px;
    margin-top: 30px;
    // border-top: solid;
  }
  .section-11 {
    // max-width: 800px;
    margin-top: 30px;
  }
  .section-two-grand-total {
    margin-top: 100px;
    height: 80px;
    /* max-width: 900px; */
    width: 76%;
    margin: auto;
    border-radius: 0 0 10px 10px;
    background-color: #e3fff6;
    /* vertical-align: middle; */
    padding-top: 1%;
    padding-bottom: 50px;
  }
  .section-grand-total {
    margin-top: 100px;
    height: 80px;
    /* max-width: 900px; */
    width: 51%;
    margin: auto;
    border-radius: 0 0 10px 10px;
    background-color: #e3fff6;
    /* vertical-align: middle; */
    padding-top: 1%;
    padding-bottom: 50px;
  }

  .below-sction-div-second {
    display: flex;
    justify-content: space-between;
    margin-top: 19px;
    // padding-left: 30px;
  }
  .below-sction-div {
    display: flex;
    justify-content: space-between;
    // margin-right: 12%;
  }

  .proceed-to-payment {
    height: 80px;
    width: 350px;
    cursor: pointer;
    border-radius: 37.75px;
    margin-left: 50%;
    text-align: center;
    font-size: 25px;
    background-color: #43aa8b;
    box-shadow: 0 12px 10px 0 rgba(67, 170, 139, 0.25);
  }
  .proceed-to-payment-image {
    height: 30px;
    border-radius: 50%;
    background-color: orange;
    border: 1px solid blue;
    width: 30px;
    transform: rotate(270deg);
  }

  .proceed-to-payment p {
    font-family: 'Proxima Nova';
    margin-top: 0;
    font-weight: bold;
    // width: 183px;
    color: #ffffff;
    margin-top: 20px;
    padding-top: 20px;
    color: white;
    margin-bottom: 1rem;
  }
  .payment-details1 :last-child {
    margin-left: auto !important;
    text-align: right;
  }

  .grand-total-reactangle {
    height: 70px;
    width: 70%;
    border-radius: 0 0 10px 10px;
    background-color: #e3fff6;
  }

  .grade {
    font-size: 17px;
    color: gray;
  }
  .usd-dollar1 {
    opacity: 0.8;
    color: #454c4e;
    // font-family: 'Proxima Nova';
    // margin-right: 100px;
    line-height: 24px;
    font-size: 24px;
    font-weight: 600;
  }
  #promocode-input {
    width: 80%;
    border: 1px solid #e0e0e0;
    height: 60px;
    border-right: none;
    cursor: pointer;
  }
  input[type='text'],
  select {
    padding: 12px 20px;
    color: #1b1b1b;
    font-family: 'Proxima Nova';
    font-size: 20px;
    letter-spacing: 0;
    font-size: 26px;
    text-transform: uppercase;
  }

  .apply-button {
    border-radius: 0 10px 10px 0;
    background-color: #fca834;
    border: none;
    width: 20%;
    height: 60px;
    color: white;
    cursor: pointer !important;
    border-left: none;
  }
  .remove {
    border-radius: 0 10px 10px 0;
    background-color: white;
    border: none;
    width: 20%;
    height: 60px;
    color: red !important;
    cursor: pointer !important;
    border-left: none;
    border: 1px solid black;
    border-color: rgb(238, 235, 231);
    border-left: none;
  }
  .apply {
    width: 48px;
    // color: #FFFFFF;
    font-family: 'Proxima Nova';
    font-size: 30px;
    font-weight: bold;
  }
  #promocode-input img {
    width: 10px;
    height: 10px;
  }
  .cupon-applied {
    color: #43aa8b;
    opacity: 0.8;
    font-family: 'Proxima Nova';
    font-size: 25px;
    font-weight: 600;
  }
  .show-error {
    color: #fd2a5b;
    opacity: 0.7;
    font-family: 'Proxima Nova';
    font-size: 16px;
    font-weight: 300;
    // margin-top: -20px;
    // padding-top: -30px;
  }
  .off-fifty {
    color: #1b1b1b;
    opacity: 0.7;
    font-family: 'Proxima Nova';
    font-size: 16px;
    font-weight: 300;
    margin-top: -20px;
    // padding-top: -30px;
  }
  .discount-price {
    color: #fca834;
  }
  .promocode-image {
    position: relative;
    bottom: 45px;
    left: 10px;
    width: 45px;
    display: inline-block;
  }
  #promocode-input::placeholder {
    opacity: 0.5;
    color: #1b1b1b;
    font-family: 'Proxima Nova';
    font-size: 26px;
    font-weight: 300;
    // padding-left: 60px;
  }
  input:focus {
    outline: none;
  }
  .renew-subscription {
    // height: 31px;
    // width: 286px;
    color: #20282a;
    font-family: 'Proxima Nova';
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0;
    // line-height: 31px;
  }

  .test {
    font-family: 'Proxima Nova';
    font-size: 50px;
    // color:red;
  }
  .left-part-subscription {
    background-color: white;
    padding-right: 40px;
  }
  .renew .renew-button {
    margin-top: 50px;
    // margin-left: 25%;
    margin-inline: auto;
  }
  .renew .renew-button-payment {
    margin-top: 50px;
    //margin-left: 38%;
    margin-inline: auto;
    display: flex;
    justify-content: center;
  }

  .renew-button button {
    background-color: #43aa8b;
    box-shadow: 0 12px 10px 0 rgba(67, 170, 139, 0.25);
    border-radius: 43.75px;
    color: #fff;
    border: none;
    font-weight: bold;
    font-size: 20px;
    font-family: 'proxima_nova_rgbold';
    display: inline-flex;
    align-items: center;
    // padding: 12px 20px;
    // width: 65%;
    margin: 0 0 20px 10px;
    // width: 30%;
    width: 33%;
    height: 65px;
    justify-content: space-between;
    .btn-text {
      margin: 20px;
    }
    .btn-img {
      float: right;
      height: 40px;
      width: 40px;
      margin: 14px;
      background-color: #fca834;
      border-radius: 50%;
      padding: 0px 10px;
      box-shadow: 5px 5px 5px rgb(0 0 0 / 24%) !important;
      display: flex;
      align-items: center;
    }
  }
  .renew-button-payment button {
    // background-color: #43aa8b;
    // box-shadow: 0 12px 10px 0 rgba(67, 170, 139, 0.25);
    // border-radius: 43.75px;
    // color: #fff;
    // border: none;
    // font-weight: bold;
    // font-size: 20px;
    // font-family: 'proxima_nova_rgbold';
    // display: inline-flex;
    // align-items: center;
    // // padding: 12px 20px;
    // // width: 65%;
    // margin: 0 0 20px 10px;
    // // width: 30%;
    // width: 280px;
    // height: 65px;
    // // justify-content: space-between;
    // justify-content: center;

    width: 303px;
    background-color: #43aa8b;
    border-radius: 33.75px;
    color: #fff;
    border: 0;
    height: 66px;
    font-weight: bold;
    font-size: 20px;
    font-family: 'proxima_nova_rgbold';
    text-align: center;
    padding: 0px 25px;
    box-shadow: none;
    transition: 0.3s;
    opacity: 0.8;
    margin: 0 0 20px 10px;

    .btn-text {
      margin: 20px;
    }
    .btn-img {
      // float: right;
      // height: 40px;
      // width: 40px;
      // margin: 14px;
      // background-color: #fca834;
      // border-radius: 50%;
      // padding: 0px 10px;
      // box-shadow: 5px 5px 5px rgb(0 0 0 / 24%) !important;
      // display: flex;
      // align-items: center;

      float: right;
      height: 24px;
      width: 24px;
      background-color: #fca834;
      border-radius: 50%;
      padding: 0px 4px;
      box-shadow: 5px 5px 5px rgb(0 0 0 / 24%) !important;
      margin-top: 2px;
      display: flex;
      align-items: center;
    }
  }
  .payapal-button {
    max-width: 300px;
    margin-inline: auto;
    .paypal-span {
      width: 30%;
      padding-left: 17px;
    }
    .paypalnext-button {
      background-color: #43aa8b;
      box-shadow: none;
      border-radius: 33.75px;
      color: #fff;
      border: 0;
      width: 222px;
      height: 38px;
      font-size: 18px;
      font-family: 'proxima_nova_rgbold';
      text-align: left;
      padding: 0px 25px;
      transition: 0.3s;
      opacity: 0.8;
      margin-right: 10px;
      margin-bottom: 10px;
      span {
        float: right;
        height: 24px;
        width: 24px;
        background-color: #fca834;
        border-radius: 50%;
        padding: 0px 7px;
        margin-top: 4px;
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .paypal-button-container {
      min-width: 500px;
      max-width: 750px;
      display: flex;
    }
  }
}

@media only screen and (max-width: 1230px) {
  .register-main .stepper-above span {
    font-size: 15px;
  }
}
@media only screen and (max-width: 1052px) {
  .register-main .pay-fees-main .total-data h4,
  .register-main .pay-fees-main .total-data h3 {
    font-size: 17px;
  }
}
@media only screen and (max-width: 1024px) {
  .register-main .stepper-above span {
    font-size: 13px;
  }
  .register-main .register-top h2 {
    font-size: 30px;
    margin-left: 0;
  }
  .register-top img {
    width: 200px;
  }
}
@media only screen and (max-width: 860px) {
  .register-main .mydetails-main .otp-block span {
    margin-right: 5px;
  }
  .register-main .mydetails-main input {
    padding: 5px;
  }
  .register-main .mydetails-main .mydetail-input button {
    right: 1%;
    bottom: 15%;
    font-size: 13px;
  }
  .register-main .pay-fees-main .payment-main h4 {
    font-size: 18px;
  }
  .register-main .pay-fees-main .payment-main p {
    font-size: 18px;
  }
  .register-main .pay-fees-main .payment-main label {
    font-size: 15px;
  }
}
.popup {
  top: 25% !important;
  text-align: center;
}

.popup .modal-footer {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.popup .modal-footer button {
  font-weight: 900;
}

.popup .modal-footer button.btn-secondary {
  background-color: #1c1c1c !important;
}
.popup .modal-dialog {
  min-width: 524px;
}
.expired-popup .modal-dialog {
  min-width: 70%;
}

.popup .modal-content {
  height: 150px !important;
  border-radius: 10px;
  overflow: hidden !important;
  padding: 1rem;
}
.expired-popup .modal-content {
  height: 70% !important;
}
.expired-popup {
  .subject-name {
    color: #7d8283;
    font-size: 18px;
    font-family: 'Proxima Nova';
    text-align: left;
  }
  .title {
    text-align: center;
    font-family: 'Proxima Nova';
    font-size: 23px;
    color: #20282a;
    text-decoration: none;
  }
  .sub-title {
    text-align: left;
    font-size: 21px;
    margin: 12px 0;
  }
}
.book-a-demo-popup {
  top: 10px !important;
}
.book-a-demo-popup .container {
  width: 100%;
}
.book-a-demo-popup .modal-dialog {
  min-width: 71%;
}
.book-a-demo-popup .modal-content {
  height: 90% !important;
  background: #fff6e9;
}
.book-a-demo-popup .modal-body {
  padding: 0 11px 0 11px !important;
}

.book-a-demo-popup .modal-content .modal-title {
  // background: #e8e8e8;
  padding: 5px 10px;
  margin: 0 10px;
  border-radius: 10px;
  // background: #f3f3f3;
  background: #ffe8c9;
}
.book-a-demo-popup .book-a-demo-main .step-body .step-btn-part {
  margin-top: 0;
}
.book-a-demo-popup .select-placeholder-text {
  text-align: left;
}
.swal-text {
  color: black;
  font-weight: 600;
}
.popup .modal-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #20282a;
  font-size: 17px;
  // overflow-y: scroll;
  // font-weight: 900;
}

@media only screen and (max-width: 1373px) {
  .register-main {
    .option-part {
      width: 90%;
      // margin: 56px 56px;
      margin: auto;

      button {
        width: 42%;
        height: 48px;
        margin-top: 10px;
        font-size: 19px;
      }

      h5.already-account {
        margin-top: 30px;
        font-size: 30px;
      }

      .option-common {
        padding-top: 0px;
        height: 140px;
        width: 200px;
        margin-left: 34px;

        svg {
          margin-top: 22px;
        }
      }

      .option-part-head {
        h3 {
          font-size: 40px;
          margin-top: -211px;
        }

        p {
          font-size: 28px;
        }
      }
    }
  }
}
@media only screen and (max-width: 770px) {
  .register-main {
    .option-part {
      svg {
        margin-top: 22px;
        margin-left: -30px;
      }
    }
  }
}

@media only screen and (max-width: 769px) {
  .register-main {
    .phone-input-custom input {
      font-size: 18px !important;
    }

    .register-top .logo {
      height: 54px;
      width: 200px;
    }
    .container {
      width: 100%;
    }

    .option-part {
      width: 100%;

      .option-part-head h3 {
        font-size: 35px;
      }

      .option-part-head p {
        margin-top: 50px;
      }

      .option-common {
        width: 150px;
        padding-top: 0px;
        margin-left: 0px;
      }
    }

    .option-part h5.already-account {
      margin-top: 10px;
    }
  }

  .register-top {
    text-align: center;

    h2 {
      font-size: 20px;
      margin-top: 440px;
    }
  }
  .register-main .next-button {
    width: 250px;
    height: 50px;
    font-size: 16px;
  }
  .register-main .pay-fees-main .total-data {
    width: 100%;
    padding: 0;
  }
  .register-main .next-button span {
    margin-top: 0;
  }
  .register-main .coursedetails-main .course-detail-select button,
  .register-main .coursedetails-main .course-detail-select div.select,
  .register-main .coursedetails-main .course-detail-select .dropdown.true div,
  .register-main .mydetails-main input,
  .register-main .create-student-main input {
    height: 50px;
  }
  .register-main .coursedetails-main .subject-list-main .subject-list {
    padding: 10px;
  }
  .register-main .success-part h1 {
    font-size: 35px;
  }
  .register-main .success-part p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .register-main {
    .option-part-head {
      h3 {
        margin-top: 0px;
      }
    }
  }
  .register-main .step-body {
    padding: 20px;
    max-width: 95%;
  }
  .register-main h3.text-center {
    font-size: 17px !important;
    margin-bottom: 5px !important;
  }
  .register-main .coursedetails-main .course-detail-select label,
  .register-main .mydetails-main label,
  .register-main .pay-fees-main .payment-main p,
  .register-main .pay-fees-main .payment-main h4,
  .register-main .pay-fees-main .total-data h4,
  .register-main .pay-fees-main .total-data h3,
  .register-main .create-student-main label {
    font-size: 15px;
  }
  .register-main .create-student-main h4 {
    font-size: 18px;
  }
  .register-main .create-student-main .addparent-bottom,
  .register-main .create-student-main .addparent-above,
  .register-main .create-student-main .addparent-above-below {
    left: -21px;
    width: calc(100% + 41px);
  }
  .register-main .create-student-main .addparent-top {
    left: -21px;
    width: calc(100% + 48px);
  }
  .register-main .register-top h2 {
    line-height: 30px;
    margin-top: 25px;
    font-size: 25px;
  }
  .register-main .pay-fees-main .total-data {
    text-align: center;
  }
  .register-main {
    .register-top,
    .option-part {
      padding: 15px;
    }
  }
  .register-main {
    .option-part {
      .option-part-head {
        h3 {
          font-size: 26px;
        }
        p {
          font-size: 18px;
        }
      }
      .option-common {
        margin: auto;
        margin-bottom: 30px;
        display: block;
      }
      h5.already-account {
        font-size: 16px;
      }
      button {
        height: 40px;
        font-size: 16px;
      }
    }
  }
}

.register-main {
  .container {
    .register-top {
      .coursedetails-main {
        .heading-and-dropdown {
          .dropdowns {
            .course-detail-select {
              .dropdown-class {
                .css-1iwbe89-Input {
                  input[type='text']:focus {
                    outline: none !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.option-common {
  display: inline-block;
  padding-top: 14px;
  height: 150px;
  width: 160px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 5px 18px 0 rgba(140, 140, 140, 0.1);
  // margin-left:72px;
  margin-bottom: 42px;
  margin-top: 25px;
  // margin-right:53px;
  overflow: hidden;

  cursor: pointer;
  box-shadow: rgba(140, 140, 140, 0.1);
  margin-right: 20px;
  svg {
    margin-top: 15px;
  }
  p {
    margin-top: -205px;
    font-family: 'proxima_nova_rgbold';
    font-size: 25px;
  }
  &:hover {
    background-color: #43aa8b;
    svg {
      fill: #fff !important;
    }
    p {
      color: #fff !important;
    }
  }
  &.active {
    background-color: #43aa8b;
    svg {
      fill: #fff !important;
    }
    p {
      color: #fff !important;
    }
  }
}

.fgt-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.fgt-select-opt {
  padding: 15px 15px 15px;
  display: flex;
  width: 100%;
  justify-content: center;
  .option_part_reg {
    display: flex;
    @media only screen and (max-width: 680px) {
      display: block;
    }
  }
}
.exam-style {
  div.css-1s2u09g-control,
  .css-1pahdxg-control {
    height: fit-content !important;
  }
}

.registraion-payment {
  max-width: 1000px;
  margin-inline: auto;
  margin-block: 100px;
  .register-main {
    min-height: 400px;
  }
}

.register_back_btn {
  border-radius: 33.75px;
  background-color: transparent;
  color: black;
  border: 1px solid black;
  width: 150px;
  margin-right: 10px;
  height: 66px;
  font-size: 20px;
  font-family: 'proxima_nova_rgbold';
  text-align: center;
  padding: 0px 25px;
  transition: 0.3s;
  opacity: 0.8;
}
.register_back_btn_payment {
  height: 55px;
}

.register-next-btn {
  background-color: #43aa8b;
  box-shadow: none;
  border-radius: 33.75px;
  color: #fff;
  border: 0;
  width: 273px;
  height: 66px;
  font-size: 20px;
  font-family: 'proxima_nova_rgbold';
  text-align: center;
  padding: 0px 25px;
  transition: 0.3s;
  opacity: 0.8;
  box-shadow: 0px 12px 10px rgba(67, 170, 139, 0.254371);
}
.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.chip_bg {
  background-color: #f2f3ff;
  min-width: 110px;
  font-family: 'Proxima Nova';
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  color: #20282a;
  padding: 10px;
  // display: flex;
  // flex-direction: row;
  text-align: center;
  margin-right: 7px;

  // height: 60px;
  border-radius: 33.75px;
  text-align: center;
}

.info_text {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-left: 5px;
}

.sticky_bar {
  position: sticky;
  bottom: 0;
  /* margin-inline: -40px; */
  width: calc(100% + 97px);
  margin-left: -48px;
  background: #ffffff;
  box-shadow: 0px 2px 34px -10px rgb(159 159 159 / 50%);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding-inline: 50px;
  padding-block: 22px;
}
.total_amount {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height */

  text-align: right;
}

.total_value {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #43aa8b;
  text-align: right;
}

.payment_parent_student {
  color: #454c4e;
  font-family: 'Proxima Nova';
  font-size: 30px;
  font-weight: bold;
  text-align: start;
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  // width: calc(100% + 20px);
  // padding-inline: 10px;
}

.payment_remove-part {
  display: inline-block;
  margin-left: 50px;
  // margin-top: 20px;
  font-size: 20px;
  line-height: 22px;
  cursor: pointer;
  font-family: 'proxima_nova_rgbold';
  color: #fd2a5b;
  img {
    height: 12px;
    width: 12px;
    margin-top: -3px;
  }
}

.payment_grade-heading {
  opacity: 0.8;
  // font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #454c4e;
  mix-blend-mode: normal;
}

.Payment_course_details {
  opacity: 0.8;
  color: #7d8283;
  font-family: 'Proxima Nova';
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  text-transform: capitalize;
}

.payment_section {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.payment_olympiad_section {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.payment_olympiad_sec {
  opacity: 0.8;
  color: #454c4e;
  // font-family: 'Proxima Nova';
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  width: 100%;
}

.olympiad_course_details {
  opacity: 0.8;
  color: #7d8283;
  font-family: 'Proxima Nova';
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  width: 100%;
}

.olympiad_months {
  color: #40a486 !important;
}

.payment_total_amount {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.total {
  // font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 24px;
  color: #454c4e;
  opacity: 0.8;
  mix-blend-mode: normal;
  .gst-text {
    font-weight: 600;
    font-size: 18px;
  }
}

.amount {
  // font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
  color: #454c4e;
  opacity: 0.8;
}

// *:disabled {
//   background-color: #f3f3f3 !important;
// }

.sticky_bar_renew {
  position: sticky;
  bottom: 0;
  /* margin-inline: -40px; */
  width: 100%;
  /* margin-left: -48px; */
  background: #ffffff;
  box-shadow: 0px 2px 34px -10px rgb(159 159 159 / 50%);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding-inline: 50px;
  padding-block: 22px;
}
.payment_grand_total_details {
  order: 2;
  margin: auto;
  max-width: 900px;
  border-radius: 20px;

  background-color: #e3fff6;
  //padding-left: 72px;
  padding-top: 30px;
  padding-bottom: 37px;
  box-shadow: 0 12px 34px -10px rgba(0, 0, 0, 0.05);
  padding-inline: 120px;
  margin-bottom: 30px;
}

.cupon_code_input {
  padding: 9px;
  margin-left: 0x;
  width: 87%;
  height: 50px;
  border: none;
  border-radius: 10px 0px 0px 10px;
}

.cupon_code_input:disabled {
  background-color: #fff;
  cursor: not-allowed;
}

.cupon_remove {
  background-color: #e67300;
  width: 87px;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  border-radius: 0px 10px 10px 0px;
  color: white;
}

.course_stu_detail {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.cupon_code_txt {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #e67300;
}

.cupon_code_amt {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #e67300;
}

.grand_amt {
  // font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 34px;
  color: #43aa8b;
  .gst-text {
    font-weight: 600;
    font-size: 18px;
  }
}

.grand_value {
  // font-family: 'Proxima Nova';
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 37px;
  text-align: right;
  // margin-right: 27px;
  color: #43aa8b;
}

.sticky_bar_payment {
  position: sticky;
  bottom: 0;
  /* margin-inline: -40px; */
  width: calc(79% + 103px);
  margin-left: 103px;
  background: #ffffff;
  box-shadow: 0px 2px 34px -10px rgb(159 159 159 / 50%);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding-inline: 50px;
  padding-block: 22px;
}

.add_new_sticky_bar_payment {
  position: sticky;
  bottom: 0;
  /* margin-inline: -40px; */
  width: calc(80% + 130px);
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 2px 34px -10px rgb(159 159 159 / 50%);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding-inline: 50px;
  padding-block: 22px;
}

.subscribe_back_btn {
  border-radius: 33.75px;
  background-color: transparent;
  color: black;
  border: 1px solid black;
  width: 150px;
  margin-right: 10px;
  height: 66px;
  font-size: 20px;
  font-family: 'proxima_nova_rgbold';
  text-align: center;
  padding: 0px 25px;
  transition: 0.3s;
  opacity: 0.8;
}

.register-main .mydetails-main h3.text-center-renew {
  font-family: 'proxima_nova_rgbold';
  color: #454c4e;
  // font-family: 'Proxima Nova';
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  .class_name {
    margin-left: 10px;
  }
}

.section-heading1 {
  background-color: #f4f7fc;
  padding: 9px;
  width: 100%;
  text-align: left;
  color: #454c4e;
  // font-family: 'Proxima Nova';
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  .class_name {
    margin-left: 10px;
  }
}

.cupon_error {
  color: red;
  font-size: 17px;
}

.cupon_success {
  color: green;
  font-size: 17px;
}

.payment_success {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 56px;
  color: #43aa8b;
}

.margin_top_success {
  margin-top: 39px;
}

.apply_cupon {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #1b1b1b;
}
.spinner_cls {
  color: #f3722c;
  margin-left: 50%;
  margin-top: 150px;
  height: 5rem;
}

.expired_popup_type {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #454c4e;
  font-family: 'Proxima Nova';
}

.expired_popup_endDate {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #e67300;
}

.subscription_expire_modal .modal-dialog {
  max-width: 70%;
}
.subscription_expire_modal {
  height: auto;

  overflow: hidden !important;
  // padding: 46px;
  // overflow: hidden;
}

.subscription_expire_modal .modal-content {
  height: 500px !important;
  width: 752px;
  // overflow-y: scroll !important;
  overflow: hidden !important;
  border-radius: 20px;
  position: relative;
  left: 180px;
  top: 22px;
  padding: 40px 50px 40px 50px !important;
}

.subscription_expire_modal {
  .subject-name {
    color: #7d8283;
    font-size: 18px;
    font-family: 'Proxima Nova';
    text-align: left;
  }
  .title {
    text-align: center;
    font-family: 'Proxima Nova';
    font-size: 23px;
    color: #20282a;
    text-decoration: none;
    line-height: 58px;
    div {
      font-weight: bold !important;
    }
  }
  .sub-title {
    text-align: left;
    font-size: 21px;
    margin: 12px 0;
  }
}

.subscription_expire_modal .modal-body {
  overflow-y: scroll !important;
}

.subscription_expire_modal .modal-footer {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  border-top: none !important;
}
// .subscription_expire_modal .modal-content {
//   overflow-y: scroll !important;
// }

.subscription_expire_modal_name {
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
  color: #20282a;
}
.subscription_expire_modal_button {
  background-color: #43aa8b;
  box-shadow: none;
  border-radius: 33.75px;
  color: #fff;
  border: 0;
  width: 273px;
  height: 66px;
  font-size: 20px;
  font-family: 'proxima_nova_rgbold';
  text-align: center;
  padding: 0px 25px;
  transition: 0.3s;
  opacity: 0.8;
  margin-right: 10px;
  margin-bottom: 10px;
}

.subscription_expire_modal .modal-content .modal-header {
  border-bottom: 0px solid #dee2e6;
  padding-top: 0px;
  padding-left: 0px;
  color: transparent !important;
  background-color: transparent !important;
}

.contianer-sm {
  --bs-gutter-x: 0rem !important;
}

//Chandan media query changes

.register-next-btn_p {
  background-color: #43aa8b;
  box-shadow: none;
  border-radius: 33.75px;
  color: #fff;
  border: 0;
  width: 273px;
  height: 66px;
  font-size: 20px;
  font-family: 'proxima_nova_rgbold';
  text-align: center;
  padding: 0px 25px;
  transition: 0.3s;
  opacity: 0.8;
  box-shadow: 0px 12px 10px rgba(67, 170, 139, 0.254371);
}

@media only screen and (max-width: 769px) {
  .sticky_bar {
    width: calc(100% + 98px);
    padding-inline: 13px;
    // margin-left: -20px;
    align-items: baseline;
  }

  .register_back_btn {
    width: 150px;
    margin-bottom: 8px;
  }

  .register-next-btn {
    width: 150px;
  }

  .register-next-btn_p {
    width: 150px;
  }

  .paymet-summary-main .paymet-summary-details {
    padding-top: 15px;
    padding-inline: 30px;
  }
  .payment_grand_total_details {
    padding-inline: 30px;
    padding-top: 6px;
  }

  .sticky_bar_payment {
    padding-inline: 13px;
    // width: calc(79% + 100px);
    margin-left: 0px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 375px) {
  .apply_cupon {
    font-size: 15px;
  }

  .cupon_remove {
    font-size: 14px;
  }
  .register-main .stepper-above {
    display: inline-block;
    width: 18%;
    position: relative;
  }
  .sticky_bar_payment {
    width: calc(79% + 60px);
  }

  .payment_parent_student {
    font-size: 22px;
  }
  .section-heading1 {
    font-size: 17px;
  }

  .payment_grade-heading {
    font-size: 17px;
  }

  .Payment_course_details {
    font-size: 12px;
  }

  .total_value {
    font-size: 18px;
  }

  .total_amount {
    font-size: 10px;
    line-height: 3px;
  }

  .total {
    font-size: 20px;
  }

  .paymet-summary-main .usd-dollar1 {
    font-size: 17px;
  }
  .paymet-summary-main .pay-for {
    font-size: 17px;
  }

  .amount {
    font-size: 20px;
  }
  .register-main .stepper-above span {
    font-size: 8px;
  }
  .sticky_bar_payment {
    width: calc(79% + 71px);
  }
  .grand_amt {
    font-size: 21px;
  }
  .grand_value {
    font-size: 21px;
  }

  .register_back_btn {
    width: 80px;
    font-size: 12px;
    height: 45px;
    // margin-bottom: 4px;
  }

  .register-next-btn_p {
    width: 97px;
    font-size: 12px;
    height: 45px;
  }

  .register-next-btn {
    width: 80px;
    font-size: 12px;
    height: 45px;
  }
  .paymet-summary-main .paymet-summary-details {
    padding-top: 15px;
    padding-inline: 20px;
  }

  .paymet-summary-main .paymet-summary-heading {
    padding-top: 0px;
  }

  .sticky_bar {
    width: calc(100% + 37px);
    padding-inline: 13px;
    align-items: baseline;
    margin-left: -17px;
  }

  .paymet-summary-main .paymet-summary-details {
    padding-top: 15px;
    padding-inline: 20px;
  }
}

@media only screen and (min-width: 376px) and (max-width: 480px) {
  .payment_parent_student {
    font-size: 26px;
  }
  .section-heading1 {
    font-size: 20px;
  }

  .payment_grade-heading {
    font-size: 20px;
  }

  .total_value {
    font-size: 21px;
  }

  .total_amount {
    font-size: 13px;
  }

  .paymet-summary-main .usd-dollar1 {
    font-size: 20px;
  }
  .paymet-summary-main .pay-for {
    font-size: 20px;
  }

  .amount {
    font-size: 22px;
  }
  .register-main .stepper-above span {
    font-size: 8px;
  }
  .sticky_bar_payment {
    width: calc(79% + 71px);
  }
  .grand_amt {
    font-size: 26px;
  }
  .grand_value {
    font-size: 26px;
  }

  .register_back_btn {
    width: 100px;
    font-size: 13px;
    height: 45px;
    // margin-bottom: 4px;
  }

  .register-next-btn {
    width: 100px;
    font-size: 13px;
    height: 45px;
  }
  .paymet-summary-main .paymet-summary-details {
    padding-top: 15px;
    padding-inline: 20px;
  }
  .sticky_bar {
    width: calc(100% + 36px);
    padding-inline: 13px;
    align-items: baseline;
    margin-left: -14px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .step-width {
    width: 540px !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 600px) {
  .register-main .stepper-above span {
    font-size: 10px;
  }
  .sticky_bar {
    width: calc(100% + 50px);
    padding-inline: 13px;
    align-items: baseline;
    margin-left: -24px;
  }

  .step-width {
    // width: calc(100% + 50px);
  }
}

@media only screen and (min-width: 602px) and (max-width: 995px) {
  .sticky_bar_payment {
    width: calc(79% + 142px);
  }

  .paymet-summary-main .paymet-summary-details {
    padding-top: 15px;
    padding-inline: 34px;
  }

  .payment_grand_total_details {
    padding-inline: 34px;
    padding-top: 6px;
  }
}

//Chandan media query changes

//Ninganagouda media query changes//
@media only screen and (max-width: 600px) {
  .teacher-reg-main .register-top h2 {
    margin-left: 0;
  }
  .teacher-reg-main {
    .step-body {
      margin: 0;
    }
    .mydetails-main {
      p {
        font-size: 15px;
      }
      .mydetail-input {
        .mydetail-input-part {
          input::-webkit-input-placeholder {
            font-size: 15px;
          }
          input {
            height: 60px;
            font-size: 15px;
          }

          .gender-wrapper {
            .gender-button {
              padding: 6px;
              font-size: 14px;
            }
          }
        }
      }
      .fileUploaderContent {
        h3 {
          font-size: 19px;
        }
      }
    }
  }
  .book-a-demo-popup {
    .modal-dialog {
      min-width: 95%;
      .modal-content {
        .modal-title {
          font-size: 23px;
        }
      }
    }
  }

  .register-main .coursedetails-main .course-detail-select label,
  .register-main .coursedetails-main .course-detail-select div.label-div {
    font-size: 15px;
  }
  .book-a-demo-popup .select-placeholder-text {
    text-align: left;
    font-size: 15px;
  }
  .book-a-demo-main .mydetail-input,
  .book-a-demo-main .course-detail-select {
    margin-top: -10px;
  }
}
@media only screen and (max-width: 991px) {
  .book-a-demo-popup {
    .modal-dialog {
      min-width: 95%;
      .modal-content {
        .modal-body {
          padding: 0;
          > div {
            width: 100%;
          }
          .book-a-demo-main {
            width: 100%;
            .container {
              width: 100%;
            }
            .mydetails-main {
              .mydetail-input {
                button {
                  bottom: 21%;
                }
              }
            }
          }
        }
      }
      .teacher-reg-main {
        .mydetails-main {
          .mydetail-input {
            button {
              padding: 3px 11px;
              bottom: 17%;
            }
          }
        }
      }
      .register-main {
        .success_width {
          margin-left: 0;
          width: 100%;
        }
        .success-part {
          padding: 13px 11px;
          p {
            margin-left: 0;
            width: 100%;
          }
        }
        .success-button {
          button {
            // width: 50%;
            // padding: 20px;
            width: 29%;
            padding: 10px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 991px) {
  .book-a-demo-popup {
    .modal-dialog {
      margin-top: 8rem;
    }
  }
  .register-main {
    .success-button {
      button {
        width: 30%;
      }
    }
  }
}

//Ninganagouda media query changes//

@media only screen and (max-width: 1024px) {
  .register-main
    .mydetails-main
    .mydetail-input
    .mydetail-input-part
    .phone-input-custom
    input {
    padding: 9px 140px 9px 59px;
  }
}
@media only screen and (max-width: 991px) {
  .register-main
    .mydetails-main
    .mydetail-input
    .mydetail-input-part
    .phone-input-custom
    input {
    padding: 9px 89px 9px 59px;
  }
  .book-a-demo-popup
    .modal-dialog
    .modal-content
    .modal-body
    .book-a-demo-main
    .mydetails-main
    .mydetail-input
    button {
    bottom: 19%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 1023px) {
  .register-main h5.already-account {
    display: none;
  }
}

.emiSelectBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 900px;
  margin: 20px auto;
  h5 {
    margin-right: 5px;
  }
  select {
    padding: 10px;
    font-size: 16px;
  }
  .invalid {
    width: 20px;
    height: 20px;
    background-color: red;
    margin-right: 10px;
  }

  .valid {
    width: 20px;
    height: 20px;
    background-color: green;
    margin-right: 10px;
  }

  .validInvalid {
    display: inline-flex;
    margin-right: 25px;
    h6 {
      margin-right: 25px;
    }
  }
}

.paymet-summary-detailsTab {
  .nav-tabs {
    max-width: 900px;
    margin: auto;
    border: none;
    margin-bottom: 30px;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    background: #fca834;
    color: #fff !important;
    box-shadow: 0 5px 5px rgba(170, 114, 67, 0.24);
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link {
    border: none;
    color: #1b1b1b;
    border-radius: 1.5625rem;
    background: #fff;
    font-size: 18px;
    font-weight: 500;
  }
}
