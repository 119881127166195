.Header {
  border-bottom: 1px solid #dfdfdf;
  padding: 30px 40px 15px;
  .header-left {
    a {
      width: 250px;
      height: 64px;
      cursor: pointer;
      display: block;
      position: relative;
      z-index: 99;
      img {
        width: 250px;
        height: 64px;
        cursor: pointer;
      }
    }
  }
  .header-right {
    text-align: right;
    @media only screen and (min-width: 1024px) and (max-width: 1100px) {
      display: none;
    }
    ul {
      padding: 0;
      margin: 0;
      li {
        display: inline-block;
        color: #20282a;
        font-size: 18px;
        line-height: 20px;
        font-family: 'proxima_nova_rgregular';
        margin-right: 40px;
        cursor: pointer;
        @media only screen and (min-width: 1100px) and (max-width: 1300px) {
          font-size: 18px;
          margin-right: 20px;
        }
        .header_main_style {
          position: relative;
        }
        .header_main_style:after {
          content: '';
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 2px;
          bottom: 0;
          left: 0;
          background-color: #f3722c;
          // transform-origin: bottom right;
          transition: transform 0.5s ease-out;
        }
        .header_main_style:hover:after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
        a {
          text-decoration: none;
          color: #20282a;
        }
      }
      button {
        background-color: #fff;
        color: #f3722c;
        border-radius: 33.75px;
        width: 119px;
        height: 44px;
        border: 1px solid #f3722c;
        font-family: 'proxima_nova_rgbold';
        font-size: 20px;
        line-height: 24px;
        cursor: pointer;
      }
    }
  }
  .header-left {
    .istem-logo {
      // padding-left: 50px;
      // margin-left: 50px;
      height: 64px;
      width: 100px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .Header {
    .header-left,
    .header-right {
      width: 100% !important;
      text-align: center !important;
      padding: 0 !important;
    }
    .header-left a {
      display: inline-block;
      margin-bottom: 15px;
      img {
        width: 200px;
        height: 50px;
      }
    }
    .header-left {
      .istem-logo {
        padding-left: 10px;
        height: 50px;
        // width: 80px;
      }
    }
    .header-right {
      li {
        font-size: 16px !important;
      }
      button {
        width: 150px !important;
        height: 40px !important;
        font-size: 16px !important;
        // display: none;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .Header {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .header-left {
    .istem-logo {
      padding-left: 10px;
      height: 50px;
      width: 55px;
    }
  }
}
@media only screen and (max-width: 385px) {
  .header-right {
    li {
      display: block;
      width: 100%;
      margin-bottom: 20px;
      margin-right: 0 !important;
    }
  }
}

// chandan media-query
@media (max-width: 1099px) {
  .Header {
    display: none;
  }
}

@media (min-width: 1100px) {
  .mob_header {
    display: none;
  }
}

@media only screen and (max-width: 376px) and (min-width: 320px) {
  .mob_header {
    border-bottom: 1px solid #dfdfdf;
    padding: 9px 10px 10px;
    width: 100%;
    .container_flex {
      display: flex;
    }

    .istem-logo {
      // padding-left: 50px;

      width: 100px;
      // margin-left: 5px;
      .slick-container {
        height: 24px;
      }

      .slick-slider,
      .slick-list,
      .slick-track {
        // height: 100%;
        position: relative;
        top: 2px;
        // padding-top: 5px;
      }
      .silder_img {
        height: 24px;
      }
    }

    // .side_bar .ant-drawer-content {
    //   width: 60%;
    // }
  }

  .mob_header .mob_header-left {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    a {
      // width: 95px;
      height: 24px;
      cursor: pointer;
      display: block;
      position: relative;
      z-index: 99;
      img {
        width: 95px;
        height: 24px;
        cursor: pointer;
      }
    }
  }

  .mob_header .mob_header-right {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;

    .playstore_img {
      width: 30px;
      height: 30px;
    }
    .ham_icon {
      width: 25px;
      height: 27px;
      margin-left: 14px;
    }
  }

  .side_bar_logo {
    a {
      width: 95px;
      height: 24px;
      cursor: pointer;
      display: block;
      position: relative;
      z-index: 99;
      img {
        width: 95px;
        height: 24px;
        cursor: pointer;
      }
    }
  }

  .side-bar-dr .ant-drawer-header {
    background-color: #0d1252;
    display: block !important;
    background-color: #0d1252;
    padding: 17px 12px !important;
    border-radius: 0;
    border-bottom: none !important;
  }

  .side-bar-dr .ant-drawer-body {
    background-color: #02052e;
    padding: 16px;
    .ant-menu {
      background-color: #02052e;
      // padding: 0px 0px;

      .ant-menu-item a span {
        color: #fff !important;
        text-decoration: none;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        padding: 10px;
        // line-height: 18px;
      }
    }
  }

  .ant-drawer-footer {
    background-color: #02052e;
    border-top: 0px !important;
  }
  .about-us {
    // width: 30px;
    // height: 21px;
    margin: 8px;
  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 0px !important;
  }

  .ant-menu-vertical .ant-menu-item {
    padding: 0px 0px !important;
  }

  .mob-login-signin {
    width: 192px;
    height: 39px !important;
    background: #f3722c !important;
    border: 1.125px solid #f3722c !important;
    border-radius: 33.75px !important;
    color: #ffffff !important;
    font-weight: 700;
    font-size: 18px;
    font-family: 'Proxima Nova';
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 480px) and (min-width: 376px) {
  .mob_header {
    border-bottom: 1px solid #dfdfdf;
    padding: 9px 10px 10px;
    width: 100%;

    .container_flex {
      display: flex;
    }

    .istem-logo {
      // padding-left: 50px;

      width: 100px;
      margin-left: 5px;
      .slick-container {
        height: 24px;
      }

      .slick-slider,
      .slick-list,
      .slick-track {
        // height: 100%;
        position: relative;
        top: 2px;
        // padding-top: 5px;
      }
      .silder_img {
        height: 24px;
      }
    }

    // .side_bar .ant-drawer-content {
    //   width: 60%;
    // }
  }

  .mob_header .mob_header-left {
    display: flex;
    justify-content: start;
    align-items: center;
    a {
      width: 95px;
      height: 24px;
      cursor: pointer;
      display: block;
      position: relative;
      z-index: 99;
      img {
        width: 95px;
        height: 24px;
        cursor: pointer;
      }
    }
  }

  .mob_header .mob_header-right {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;

    .playstore_img {
      width: 30px;
      height: 30px;
    }
    .ham_icon {
      width: 25px;
      height: 27px;
      margin-left: 14px;
    }
  }

  .side_bar_logo {
    a {
      width: 95px;
      height: 24px;
      cursor: pointer;
      display: block;
      position: relative;
      z-index: 99;
      img {
        width: 95px;
        height: 24px;
        cursor: pointer;
      }
    }
  }

  .side-bar-dr .ant-drawer-header {
    background-color: #0d1252;
    display: block !important;
    background-color: #0d1252;
    padding: 17px 12px !important;
    border-radius: 0;
    border-bottom: none !important;
  }

  .side-bar-dr .ant-drawer-body {
    background-color: #02052e;
    padding: 16px;
    .ant-menu {
      background-color: #02052e;
      // padding: 0px 0px;

      .ant-menu-item a span {
        color: #fff !important;
        text-decoration: none;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        padding: 10px;
        // line-height: 18px;
      }
    }
  }

  .ant-drawer-footer {
    background-color: #02052e;
    border-top: 0px !important;
  }

  .about-us {
    // width: 30px;
    // height: 21px;
    margin: 8px;
  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 0px !important;
  }

  .ant-menu-vertical .ant-menu-item {
    padding: 0px 0px !important;
  }

  .mob-login-signin {
    width: 228px;
    height: 39px !important;
    background: #f3722c !important;
    border: 1.125px solid #f3722c !important;
    border-radius: 33.75px !important;
    color: #ffffff !important;
    font-weight: 700;
    font-size: 18px;
    font-family: 'Proxima Nova';
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 600px) and (min-width: 481px) {
  .mob_header {
    border-bottom: 1px solid #dfdfdf;
    padding: 9px 10px 10px;
    width: 100%;
    .container_flex {
      display: flex;
    }

    .istem-logo {
      // padding-left: 50px;

      width: 35px;
      // margin-left: 35px;
      position: relative;
      top: 5px;
      .slick-container {
        height: 28px;
      }

      .slick-slider,
      .slick-list,
      .slick-track {
        // height: 100%;
        position: relative;
        top: 2px;
        // padding-top: 5px;
      }
      .silder_img {
        height: 28px;
      }
    }

    // .side_bar .ant-drawer-content {
    //   width: 60%;
    // }
  }

  .mob_header .mob_header-left {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    a {
      // width: 95px;
      height: 24px;
      cursor: pointer;
      display: block;
      position: relative;
      z-index: 99;
      img {
        width: 131px;
        height: 37px;
        cursor: pointer;
      }
    }
  }

  .mob_header .mob_header-right {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;

    .playstore_img {
      width: 30px;
      height: 30px;
    }
    .ham_icon {
      width: 25px;
      height: 27px;
      margin-left: 14px;
    }
  }

  .side_bar_logo {
    a {
      width: 95px;
      height: 24px;
      cursor: pointer;
      display: block;
      position: relative;
      z-index: 99;
      img {
        width: 95px;
        height: 24px;
        cursor: pointer;
      }
    }
  }

  .side-bar-dr .ant-drawer-header {
    background-color: #0d1252;
    display: block !important;
    background-color: #0d1252;
    padding: 17px 12px !important;
    border-radius: 0;
    border-bottom: none !important;
  }

  .side-bar-dr .ant-drawer-body {
    background-color: #02052e;
    padding: 16px;
    .ant-menu {
      background-color: #02052e;
      // padding: 0px 0px;

      .ant-menu-item a span {
        color: #fff !important;
        text-decoration: none;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        padding: 10px;
        // line-height: 18px;
      }
    }
  }

  .ant-drawer-footer {
    background-color: #02052e;
    border-top: 0px !important;
  }

  .about-us {
    // width: 30px;
    // height: 21px;
    margin: 8px;
  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 0px !important;
  }

  .ant-menu-vertical .ant-menu-item {
    padding: 0px 0px !important;
  }

  .mob-login-signin {
    width: 228px;
    height: 39px !important;
    background: #f3722c !important;
    border: 1.125px solid #f3722c !important;
    border-radius: 33.75px !important;
    color: #ffffff !important;
    font-weight: 700;
    font-size: 18px;
    font-family: 'Proxima Nova';
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 601px) {
  .mob_header {
    border-bottom: 1px solid #dfdfdf;
    padding: 9px 10px 10px;
    width: 100%;
    .container_flex {
      display: flex;
    }

    .istem-logo {
      // padding-left: 50px;
      width: 35px;
      // margin-left: 35px;
      position: relative;
      top: 5px;
      .slick-container {
        height: 28px;
      }

      .slick-slider,
      .slick-list,
      .slick-track {
        // height: 100%;
        position: relative;
        top: 2px;
        // padding-top: 5px;
      }
      .silder_img {
        height: 28px;
      }
    }

    // .side_bar .ant-drawer-content {
    //   width: 60%;
    // }
  }

  .mob_header .mob_header-left {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    a {
      // width: 95px;
      height: 24px;
      cursor: pointer;
      display: block;
      position: relative;
      z-index: 99;
      img {
        width: 131px;
        height: 37px;
        cursor: pointer;
      }
    }
  }

  .mob_header .mob_header-right {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;

    .playstore_img {
      width: 30px;
      height: 30px;
    }
    .ham_icon {
      width: 25px;
      height: 27px;
      margin-left: 14px;
    }
  }

  .side_bar_logo {
    a {
      width: 95px;
      height: 24px;
      cursor: pointer;
      display: block;
      position: relative;
      z-index: 99;
      img {
        width: 95px;
        height: 24px;
        cursor: pointer;
      }
    }
  }

  .side-bar-dr .ant-drawer-header {
    background-color: #0d1252;
    display: block !important;
    background-color: #0d1252;
    padding: 17px 12px !important;
    border-radius: 0;
    border-bottom: none !important;
  }

  .side-bar-dr .ant-drawer-body {
    background-color: #02052e;
    padding: 16px;
    .ant-menu {
      background-color: #02052e;
      // padding: 0px 0px;

      .ant-menu-item a span {
        color: #fff !important;
        text-decoration: none;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        padding: 10px;
        // line-height: 18px;
      }
    }
  }

  .ant-drawer-footer {
    background-color: #02052e;
    border-top: 0px !important;
  }

  .about-us {
    // width: 30px;
    // height: 21px;
    margin: 8px;
  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 0px !important;
  }

  .ant-menu-vertical .ant-menu-item {
    padding: 0px 0px !important;
  }

  .mob-login-signin {
    width: 228px;
    height: 39px !important;
    background: #f3722c !important;
    border: 1.125px solid #f3722c !important;
    border-radius: 33.75px !important;
    color: #ffffff !important;
    font-weight: 700;
    font-size: 18px;
    font-family: 'Proxima Nova';
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .mob_header {
    border-bottom: 1px solid #dfdfdf;
    padding: 9px 10px 10px;
    width: 100%;
    .container_flex {
      display: flex;
    }

    .istem-logo {
      // padding-left: 50px;

      width: 100px;
      // margin-left: 35px;
      position: relative;
      top: 5px;
      .slick-container {
        height: 28px;
      }

      .slick-slider,
      .slick-list,
      .slick-track {
        // height: 100%;
        position: relative;
        top: 2px;
        // padding-top: 5px;
      }
      .silder_img {
        height: 28px;
      }
    }

    // .side_bar .ant-drawer-content {
    //   width: 60%;
    // }
  }

  .mob_header .mob_header-left {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    a {
      // width: 95px;
      height: 24px;
      cursor: pointer;
      display: block;
      position: relative;
      z-index: 99;
      img {
        width: 131px;
        height: 37px;
        cursor: pointer;
      }
    }
  }

  .mob_header .mob_header-right {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;

    .playstore_img {
      width: 30px;
      height: 30px;
    }
    .ham_icon {
      width: 25px;
      height: 27px;
      margin-left: 14px;
    }
  }

  .side_bar_logo {
    a {
      width: 95px;
      height: 24px;
      cursor: pointer;
      display: block;
      position: relative;
      z-index: 99;
      img {
        width: 95px;
        height: 24px;
        cursor: pointer;
      }
    }
  }

  .side-bar-dr .ant-drawer-header {
    background-color: #0d1252;
    display: block !important;
    background-color: #0d1252;
    padding: 17px 12px !important;
    border-radius: 0;
    border-bottom: none !important;
  }

  .side-bar-dr .ant-drawer-body {
    background-color: #02052e;
    padding: 16px;
    .ant-menu {
      background-color: #02052e;
      // padding: 0px 0px;

      .ant-menu-item a span {
        color: #fff !important;
        text-decoration: none;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        padding: 10px;
        // line-height: 18px;
      }
    }
  }

  .ant-drawer-footer {
    background-color: #02052e;
    border-top: 0px !important;
  }

  .about-us {
    // width: 30px;
    // height: 21px;
    margin: 8px;
  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 0px !important;
  }

  .ant-menu-vertical .ant-menu-item {
    padding: 0px 0px !important;
  }

  .mob-login-signin {
    width: 228px;
    height: 39px !important;
    background: #f3722c !important;
    border: 1.125px solid #f3722c !important;
    border-radius: 33.75px !important;
    color: #ffffff !important;
    font-weight: 700;
    font-size: 18px;
    font-family: 'Proxima Nova';
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1100px) and (min-width: 991px) {
  .mob_header {
    border-bottom: 1px solid #dfdfdf;
    padding: 9px 10px 10px;
    width: 100%;
    .container_flex {
      display: flex;
    }

    .istem-logo {
      // padding-left: 50px;
      width: 35px;
      // margin-left: 35px;
      position: relative;
      top: 5px;
      .slick-container {
        height: 28px;
      }

      .slick-slider,
      .slick-list,
      .slick-track {
        // height: 100%;
        position: relative;
        top: 2px;
        // padding-top: 5px;
      }
      .silder_img {
        height: 28px;
      }
    }

    // .side_bar .ant-drawer-content {
    //   width: 60%;
    // }
  }

  .mob_header .mob_header-left {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    a {
      // width: 95px;
      height: 24px;
      cursor: pointer;
      display: block;
      position: relative;
      z-index: 99;
      img {
        width: 131px;
        height: 37px;
        cursor: pointer;
      }
    }
  }

  .mob_header .mob_header-right {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;

    .playstore_img {
      width: 30px;
      height: 30px;
    }
    .ham_icon {
      width: 25px;
      height: 27px;
      margin-left: 14px;
    }
  }

  .side_bar_logo {
    a {
      width: 95px;
      height: 24px;
      cursor: pointer;
      display: block;
      position: relative;
      z-index: 99;
      img {
        width: 95px;
        height: 24px;
        cursor: pointer;
      }
    }
  }

  .side-bar-dr .ant-drawer-header {
    background-color: #0d1252;
    display: block !important;
    background-color: #0d1252;
    padding: 17px 12px !important;
    border-radius: 0;
    border-bottom: none !important;
  }

  .side-bar-dr .ant-drawer-body {
    background-color: #02052e;
    padding: 16px;
    .ant-menu {
      background-color: #02052e;
      // padding: 0px 0px;

      .ant-menu-item a span {
        color: #fff !important;
        text-decoration: none;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        padding: 10px;
        // line-height: 18px;
      }
    }
  }

  .ant-drawer-footer {
    background-color: #02052e;
    border-top: 0px !important;
  }
  .about-us {
    // width: 30px;
    // height: 21px;
    margin: 8px;
  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 0px !important;
  }

  .ant-menu-vertical .ant-menu-item {
    padding: 0px 0px !important;
  }

  .col-lg-6 {
    flex: unset !important;
    // width: auto !important;
  }

  .mob-login-signin {
    width: 300px;
    height: 39px !important;
    background: #f3722c !important;
    border: 1.125px solid #f3722c !important;
    border-radius: 33.75px !important;
    color: #ffffff !important;
    font-weight: 700;
    font-size: 18px;
    font-family: 'Proxima Nova';
    margin-bottom: 20px;
  }
}
