.myrevenue-main {
  .myExam-noData {
    width: 100%;
    text-align: center;
    color: #20282a;
    font-size: 20px;
    font-weight: 500;
  }
  .myexam-title {
    // padding: 30px 0px 15px 0px;
    align-items: center;
    .custom-show-unattempted {
      color: #20282a;
      font-size: 28px;
      //font-family: 'Proxima Nova';
      // margin-top: 12px;
      // margin-left: 43px;
      margin-left: 72px;
      margin-right: -30px;
      .form-check-input {
        // background-color: #e3fff6;
        border-color: #43aa8b;
        box-shadow: none;
        margin-top: 7px;
      }
      .form-check-input:checked {
        background-color: #43aa8b;
        border-color: #43aa8b;
      }
      // .form-check-input:focus {
      //   background-image: inherit;
      // }
      label {
        font-size: 14px;
        font-family: 'Proxima Nova';
        line-height: 1.3;
        font-weight: 500;
      }
    }
    .myexam-title-left {
      float: left;
      margin-right: 15px;
    }
    img {
      width: 40px;
      height: 50px;
    }
    h3 {
      font-size: 27px;
      color: #20282a;
      font-family: 'proxima_nova_rgbold';
      margin-bottom: 0;
      // margin-top: 10px;
    }
    .dropdown {
      position: relative;
      //  color: #333;
      //  cursor: default;
      //  box-sizing: border-box;
      //  height: 66px;
      margin: 20px 0 0 0;
      width: 200px;
      border: 0px solid #e0e0e0;
      // border-radius: 10px;
      background: none !important;
    }
    .dateTo {
      position: relative;
      //  color: #333;
      //  cursor: default;
      //  box-sizing: border-box;
      //  height: 66px;
      margin: 30px 25px 0 0;
      color: #7d8283;
      font-size: 18px;
      font-family: 'proxima_nova_rgregular';
    }
    .react-datepicker-wrapper {
      position: relative;
      //  color: #333;
      //  cursor: default;
      //  box-sizing: border-box;
      //  height: 66px;
      margin: 20px 0 0 0;
      width: 200px;
      border: 0px solid #e0e0e0;
      // border-radius: 10px;
      background: none !important;
    }
    .myexam-title-last {
      //   text-align: right;
      display: flex;
      //   justify-content: space-around;
      .dropdown-toggle {
        height: 45px;
        border: 1px solid #454c4e;
        border-radius: 10px;
        background-color: #fff;
        width: 175px;
        padding: 0px 10px;
        color: #000;
        cursor: pointer;
        text-align: left;
        position: relative;
        &::after {
          position: absolute;
          top: 50%;
          right: 10px;
        }
        &:focus {
          box-shadow: none !important;
        }
      }
      .dropdown {
        a.dropdown-item {
          font-size: 14px;
          &:hover {
            background-color: #43aa8b;
            color: #fff;
          }
        }
      }
    }
    .olymp-info_text {
      img {
        width: 19px;
        height: 19px;
      }
      .info_text {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: #3a4145;
      }
    }
  }
  .subscribe-button {
    text-align: center;
    margin-top: 30px;
    button {
      // background-color: #43aa8b;
      // box-shadow: 0 12px 10px 0 rgba(67, 170, 139, 0.25);
      // border-radius: 33.75px;
      // color: #fff;
      // border: 0;
      // font-weight: bold;
      // font-size: 20px;
      // font-family: 'proxima_nova_rgbold';
      // display: inline-flex;
      // align-items: center;
      // text-align: center;
      // padding: 21px 20px;
      // width: 343px;
      // line-height: 24px;
      // justify-content: space-between;

      width: 363px;
      background-color: #43aa8b;
      border-radius: 33.75px;
      color: #fff;
      border: 0;
      height: 66px;
      font-weight: bold;
      font-size: 20px;
      font-family: 'proxima_nova_rgbold';
      text-align: center;
      padding: 0px 25px;
      box-shadow: none;
      transition: 0.3s;
      opacity: 0.8;
      span {
        float: right;
        height: 24px;
        width: 24px;
        background-color: #fca834;
        border-radius: 50%;
        padding: 0px 2px;
        box-shadow: 5px 5px 5px rgb(0 0 0 / 24%) !important;
        margin-top: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .myexam-main-common-left {
    // display: contents;
    // max-width: 95% !important;
  }
  .myexam-common-top {
    // width: 100%;
    // margin: auto;
    .myexam-common {
      .test-audio-video {
        color: #454c4e;
        font-family: 'proxima_nova_rgregular';
        font-size: 14px;
        text-align: center;
        margin-top: 7px;
      }
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;
      margin-top: 30px;
      //margin-right: 30px;
      .myassignment-common-row {
        align-items: baseline;
      }
      button {
        background-color: #e3fff6;
        border: 0;
        height: 50px;
        display: flex;
        align-items: center;
        text-decoration: underline;
        text-align: center;
        color: #43aa8b;
        justify-content: center;
        border-radius: 10px;
        width: 100%;
        text-transform: uppercase;
        font-size: 16px;
        font-family: 'proxima_nova_rgbold';
      }
      .active-button {
        background-color: #e3fff6;
        border: 0;
        height: 50px;
        display: flex;
        align-items: center;
        text-decoration: underline;
        text-align: center;
        color: #43aa8b;
        justify-content: center;
        border-radius: 10px;
        width: 100%;
        text-transform: uppercase;
        font-size: 16px;
        font-family: 'proxima_nova_rgbold';
      }
      // .inactive-button {
      //   background-color: grey;
      //   border: 0;
      //   height: 50px;
      //   display: flex;
      //   align-items: center;
      //   text-decoration: underline;
      //   text-align: center;
      //   color: black;
      //   justify-content: center;
      //   border-radius: 10px;
      //   width: 100%;
      //   text-transform: uppercase;
      //   font-size: 16px;
      //   font-family: 'proxima_nova_rgbold';
      // }
      .myexam-common-left {
        display: contents;
        h4 {
          font-family: 'proxima_nova_rgbold';
          color: #454c4e;
          margin-bottom: 9px;
          padding-left: 15px;
          width: 38%;
        }
        h5 {
          font-size: 16px;
          color: #454c4e;
          padding-left: 15px;
        }
      }

      .myassignment-common-subject-left {
        h5 {
          //  height: 17px;
          // width: 205px;
          color: #454c4e;
          font-family: 'Proxima Nova';
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 17px;
        }
      }

      .myassignment-common-left {
        h5 {
          height: 19px;
          width: auto;
          color: #000000;
          font-family: 'Proxima Nova';
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 19px;
        }
      }

      .myassignment-common-right {
        text-align: right;
        p.p-one {
          height: 19px;
          width: 76px;
          padding: 5px 10px;
          display: inline-flex;
          margin-bottom: 3px;
          border-radius: 9.5px;
          background-color: #ffeaf1;
          display: inline-flex;
        }
        span.span-one {
          height: 14px;
          width: 52px;
          color: #fd2a5b;
          font-family: 'Proxima Nova';
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 14px;
        }
      }
      .myassignment-assigned-left {
        p.p-one {
          color: #454c4e;
          font-family: 'proxima_nova_rgregular';
          font-size: 14px;
        }
      }

      .myassignment-common-day-left {
        p.p-one {
          height: 29px;
          width: 139px;
          border-radius: 14.5px;
          background-color: #fff4e9;
        }
        span {
          color: #fca834;
          font-family: 'Proxima Nova';
          font-size: 16px;
          font-weight: 600;
          padding-left: 25px;
        }
        img {
          width: 10px;
        }
      }
      .myexam-common-right {
        text-align: right;
        p.p-one {
          background-color: #fff4e9;
          border-radius: 20px;
          padding: 5px 10px;
          display: inline-flex;
          margin-bottom: 10px;
          width: 100%;
          justify-content: space-around;
        }
        span {
          font-size: 14px;
          font-family: 'proxima_nova_rgregular';
          color: #454c4e;
        }
        span.span-one {
          color: #fca834;
          font-family: 'proxima_nova_rgbold';
        }
        span.span-two {
          margin: 0px 15px;
        }
        span.span-three {
          img {
            margin-bottom: 4px;
          }
        }
        span.span-student {
          color: #43aa8b;
          font-family: 'proxima_nova_rgbold';
          font-size: 16px;
        }
      }
    }
  }
  .my-olympiad-exam-card-wrapper {
    width: 49.2%;
    font-family: 'Proxima Nova';
    font-style: normal;
    padding: 20px;
    margin-top: 30px;
    background: #ffffff;
    border: 2px solid #43aa8b;
    box-shadow: 0px 22px 24px -10px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    .exam-title {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #454c4e;
      margin-left: 10px;
    }
    .subject-title {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      color: #20282a;
      margin-left: 10px;
    }
    .exam-type {
      background: #f2f3ff;
      border-radius: 20px;
      color: #20282a;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 30px;
      width: auto;
      padding: 0 60px;
      height: 34px;
      white-space: nowrap;
    }
    .exam-date-wrapper {
      background: #f9f9f9;
      border-radius: 4px;
      padding: 10px 0;
      margin-right: 10px;
      .date {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: #454c4e;
        text-align: center;
        margin: 0;
      }
    }
    button {
      width: 100%;
      height: 50px;
      background: #43aa8b;
      border-radius: 30px;
      font-size: 16px;
      font-weight: 700;
      color: #ffffff;
      letter-spacing: 0;
      line-height: 18px;
    }
    .disabled-btn {
      background: #d6d6d6;
    }
  }
  .my-olympiad-exam-card-wrapper-yet {
    border: 2px solid #c2c2c2;
    box-shadow: 0px 22px 24px -10px rgba(0, 0, 0, 0.03);
  }

  .subscribe-for-more-exam {
    text-align: center;
    button {
      width: 343px;
      background-color: #43aa8b;
      border-radius: 33.75px;
      color: #fff;
      border: 0;
      height: 66px;
      font-weight: bold;
      font-size: 20px;
      font-family: 'proxima_nova_rgbold';
      text-align: left;
      padding: 0px 25px;
      box-shadow: none;
      transition: 0.3s;
      opacity: 0.8;
      margin: 50px 0px;
      position: relative;
      z-index: 9;
      margin-bottom: 20px;
      span {
        float: right;
        height: 24px;
        width: 24px;
        background-color: #fca834;
        border-radius: 50%;
        padding: 0px 7px;
        margin-top: 4px;
        box-shadow: 5px 5px 5px rgb(0 0 0 / 24%) !important;
        display: flex;
        align-items: center;
      }
    }
    button:hover {
      box-shadow: 5px 5px 5px rgb(67 170 139 / 25%) !important;
      opacity: 1;
    }
  }
  .myexam-tab {
    align-items: center;
    margin-top: 10px;
    margin-bottom: 30px;
    .dropdown {
      position: relative;
      //  color: #333;
      //  cursor: default;
      //  box-sizing: border-box;
      //  height: 66px;
      //  width: 540px;
      border: 0px solid #e0e0e0;
      // border-radius: 10px;
      background: none !important;
    }
    .myexam-tab-right {
      text-align: right;
      .dropdown-toggle {
        height: 45px;
        border: 1px solid #454c4e;
        border-radius: 10px;
        background-color: #fff;
        width: 175px;
        padding: 0px 10px;
        color: #000;
        cursor: pointer;
        text-align: left;
        position: relative;
        &::after {
          position: absolute;
          top: 50%;
          right: 10px;
        }
        &:focus {
          box-shadow: none !important;
        }
      }
      .dropdown {
        a.dropdown-item {
          font-size: 14px;
          &:hover {
            background-color: #43aa8b;
            color: #fff;
          }
        }
      }
    }
    .dtoggle-bar {
      display: flex;
      padding: 2px;
      width: 100%;
      background-color: #fff5e6;

      border-radius: 1.5625rem;
      font-weight: bold;
      .toggle-maths-div {
        width: 33%;
      }
    }

    .toggle-maths-btn {
      border: none;
      color: #fca834;
      width: 100%;
      padding: 12px 20px;
      font-family: 'proxima_nova_rgregular';
      font-size: 18px;
    }
    .mathbtn-act {
      border-radius: 1.5625rem;
      background: white;
      box-shadow: 0px 5px 5px rgb(170 114 67 / 24%);
      font-family: 'Proxima Nova';
    }
    .mathbtn-un {
      border-top-left-radius: 1.5625rem;
      border-bottom-left-radius: 1.5625rem;
      border-top-right-radius: none;
      border-bottom-right-radius: none;
      background-color: #fff5e6;
    }

    .toggle-science-btn {
      border: none;
      color: #fca834;
      height: 3.125rem;
      width: 19.25vw;
    }
  }
  .myexam-totalscore-top {
    border-radius: 20px !important;
    .total-score-left {
      background-color: #7d93f2;
      height: 95px;
      border-radius: 50px;
      padding: 0px 25px;
      color: #fff;
      width: 90%;
      align-items: center;
      max-width: 300px;
      img {
        width: 50px;
        height: 50px;
      }
      p {
        margin-bottom: 4px;
        font-family: 'proxima_nova_rgregular';
        font-size: 20px;
      }
      h5 {
        margin-bottom: 0;
        font-family: 'proxima_nova_rgbold';
        font-size: 20px;
        color: #ffffff;
      }
    }
  }
  .myexam-examlist {
    margin-top: 50px;
    .examlist-title {
      h4 {
        color: #20282a;
        font-size: 28px;
        font-family: 'Proxima Nova';
        margin-bottom: 15px;
      }
    }
    .examlist-common {
      border-radius: 21px;
      margin-bottom: 30px;
      padding: 25px 20px;
      cursor: pointer;
      background-color: #ffffff;
      &.active {
        background: linear-gradient(#39c399, #00a070) !important;
        p,
        span,
        h5,
        li {
          color: #fff !important;
        }
      }
      .examlist-common-bottom {
        display: flex;
        align-items: center;
      }
      .left-icon {
        margin-right: 15px;
      }
      .left-icon,
      .right-content {
        float: left;
      }
      .right-content {
        h5 {
          margin-bottom: 0;
        }
      }
      .examlist-common-one,
      .examlist-common-two {
        margin: auto;
        --bs-gutter-x: 0;
      }
      .examlist-common-one {
        border-bottom: 1px solid #ebebeb;
        margin-bottom: 20px;

        p {
          margin-bottom: 10px;
          span {
            font-size: 15px;
            color: #454c4e;
            font-family: 'proxima_nova_rgregular';
          }
          span.span-bold {
            font-family: 'proxima_nova_rgbold';
          }
        }

        .myexam-answer-right {
          h3 {
            font-family: 'proxima_nova_rgregular';
            font-size: 24px;
            color: #20282a;
            margin-bottom: 20px;
          }
          .exam-right-common {
            background-color: #f4f7fc;
            border-radius: 20px;
            padding: 15px;
            margin-bottom: 20px;

            .exam-right-left {
              img {
                width: 35px;
                height: 25px;
                margin-top: 15px;
              }
            }
            .exam-right-center {
              .question {
                display: flex;
              }
              h5 {
                font-family: 'proxima_nova_rgregular';
                color: #20282a;
                font-size: 20px;
              }
              h5.answerRight {
                color: #43aa8b;
                font-family: 'proxima_nova_rgbold';
                margin: 10px 0px 0px 0px;
                display: flex;

                span.text {
                  height: auto;
                  width: 227px;
                  color: #20282a;
                  font-family: 'proxima_nova_rgregular';
                  font-size: 20px;
                  letter-spacing: 0;
                  line-height: 22px;
                  margin-left: 10px;

                  img {
                    height: 80px;
                    width: 80px;
                    margin-left: 20px;
                  }
                }
                span.image {
                  height: 85px;
                  width: 227px;
                  color: #20282a;
                  font-family: 'proxima_nova_rgregular';
                  font-size: 20px;
                  letter-spacing: 0;
                  line-height: 22px;
                  margin: -10px 0px 20px 15px;

                  img {
                    height: 80px;
                    width: 80px;
                    margin-left: 20px;
                  }
                }
              }
              h5.answerWrong {
                color: #fd2b5c;
                font-family: 'proxima_nova_rgbold';
                margin: 10px 0px 0px 0px;
                display: flex;

                span.text {
                  height: auto;
                  width: 227px;
                  color: #20282a;
                  font-family: 'proxima_nova_rgregular';
                  font-size: 20px;
                  letter-spacing: 0;
                  line-height: 22px;
                  margin-left: 10px;

                  img {
                    height: 80px;
                    width: 80px;
                    margin-left: 20px;
                  }
                }
                span.image {
                  height: 85px;
                  width: 227px;
                  color: #20282a;
                  font-family: 'proxima_nova_rgregular';
                  font-size: 20px;
                  letter-spacing: 0;
                  line-height: 22px;
                  margin-left: 10px;

                  img {
                    height: 80px;
                    width: 80px;
                    margin-left: 20px;
                  }
                }
              }
              .option-main {
                // height: 0px;
                .show {
                  height: auto !important;
                }
                .question-options-wrapper.selectTyleType {
                  display: grid;
                  grid-template-columns: 2fr 2fr;
                  grid-template-columns: 1fr 1fr;
                  grid-template-rows: 1fr 1fr;
                  grid-column-gap: 20px;
                  width: 70%;
                  width: 100%;
                }
                .option-wrapper-tile-type {
                  margin-bottom: 30px;
                  position: relative;
                  width: 40%;
                  height: auto !important;
                  min-height: 70px;
                  padding: 0 !important;
                }
                .option-wrapper-tile-type .optionsbtn {
                  height: 100%;
                  width: 80%;
                  left: 0px;
                  border: 0;
                  border-radius: 20px;
                  margin: 0 0 0 40px;
                  background: transparent;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                .questionHtlm-right {
                  padding: 20px 20px 20px 0px;
                  img {
                    height: 80px;
                    width: 80px;
                    object-fit: cover;
                  }
                  p {
                    margin-bottom: 0;
                  }
                }
                span.opti {
                  margin-right: 10px;
                }
                span {
                  display: inline-block !important;
                  //width: 85% !important;
                  text-align: left;
                  font-family: 'proxima_nova_rgregular';
                }
                div.icon--check-wrapper {
                  display: inline-block !important;
                  svg {
                    height: 25px;
                    width: 25px;
                    // margin-top: -5px;
                  }
                }
              }
            }
            .questionHtlm-left {
              width: 80%;
              display: inline-block;
              img {
                height: 60px;
                width: 60px;
              }
            }
            .questionHtlm-right {
              padding: 20px 20px 20px 0px;
              width: 80%;
              text-align: left;
              display: inline-block;
            }
          }
          .question-options-wrapper {
            margin-top: 15px;
            display: block;
            margin-top: 24px;
          }
          // .question-options-wrapper {
          .option-wrapper-tile-type.true {
            background-color: #00a070 !important;
            .optionsbtn {
              color: #fff;
            }
            span {
              color: #fff;
            }
            .icon--check-wrapper {
              background-color: #fff !important;
              svg {
                fill: #00a070 !important;
              }
            }
          }
          // }
          .question-box .image {
            height: auto;
            width: auto;
            cursor: pointer;
          }
          .drag-detail-table {
            border: 1px solid #dddddd;
            border-collapse: collapse;
            max-width: 100%;
            background-color: #fff;
            width: 100%;
            margin-bottom: 20px;
            th,
            td {
              border: 1px solid #dddddd;
              padding: 10px;
              font-size: 15px;
            }
            th {
              font-weight: bold;
            }
            img {
              height: 80px !important;
              width: 80px !important;
            }
          }
        }

        .option-main.show {
          height: auto !important;
        }
        .option-top {
          display: flex;
          div {
            width: 100px;
            height: auto;

            img {
              width: 56px;
              height: 56px;
            }
            span {
              width: 34px;
              height: 34px;
              border-radius: 50%;
              background-color: #a0a4a6;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #fff;
              font-family: 'proxima_nova_rgbold';
            }
          }
        }
        span.index {
          font-family: 'proxima_nova_rgbold';
        }
      }
      .exam-right-right {
        button {
          cursor: pointer;
          background-color: transparent;
          border: 0;
        }
        img {
          height: 20px;
          width: 20px;
        }
      }
      .examlist-last {
        text-align: right;
        li {
          font-family: 'proxima_nova_rgbold';
          font-size: 16px;
          color: #43aa8b;
          text-transform: uppercase;
          list-style: none;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .examlist-common-two {
      .examlisthand img {
        height: 40px;
        width: 40px;
      }
      .examlistidea img {
        height: 40px;
        width: 40px;
      }
      .examlistclipboard img {
        width: 32px;
        height: 40px;
      }
      .examlistglass img {
        width: 34px;
        height: 40px;
      }
      p {
        margin-bottom: 3px;
        font-family: 'proxima_nova_rgregular';
        font-size: 18px;
        color: #454c4e;
      }
      h5 {
        color: #20282a;
        font-family: 'Proxima Nova';
        font-size: 18px;
      }
    }
  }
  .examlist-common:hover,
  .first-record {
    background: linear-gradient(#39c399, #00a070) !important;
    p,
    span,
    h5,
    li {
      color: #fff !important;
    }
  }

  .cardRevenue {
    // margin-bottom: 2.2rem;
    // border: none;
    // border-radius: 0.5rem;
    // box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    // font-family: proxima_nova_rgbold;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 5px;
    // margin: auto;
    margin-bottom: 30px;
    padding: 20px 10px;
    .cardRevenueBody {
      .rdt_TableHead .rdt_TableHeadRow {
        background: #f4f7fc;
        border-radius: 10px;
        .rdt_TableCol {
          font-size: 16px;
          font-family: 'proxima_nova_rgbold';
          color: #20282a;
        }
      }
      .rdt_TableBody {
        .rdt_TableRow {
          border-bottom-color: rgb(0 0 0 / 3%);
        }
        .rdt_TableCell {
          color: #20282a;
          font-size: 16px;
          font-family: 'proxima_nova_rgregular';
        }
      }
      //   padding: 1.5rem;
      .floatData {
        float: left;
        border-radius: 10px;
        margin: 0 1%;
        width: 23%;
        padding: 20px 0px;
      }
      .colorCard1 {
        background-color: #e67300;
      }
      .colorCard2 {
        background-color: #f0f3ff;
      }
      .colorCard3 {
        background-color: #e3fff6;
      }
      .colorCard4 {
        background-color: #fff0f3;
      }
      .revenueInfo {
        font-family: 'proxima_nova_rgbold';
        color: #20282a;
        font-size: 16px;
        line-height: 16px;
      }

      .revenueInfoWhite {
        font-family: 'proxima_nova_rgbold';
        color: #ffffff;
        font-size: 16px;
        line-height: 16px;
      }
      .revenueTitle1 {
        font-family: 'proxima_nova_rgbold';
        font-size: 24px;
        line-height: 24px;
        color: #ffffff;
      }
      .revenueTitle2 {
        font-family: 'proxima_nova_rgbold';
        font-size: 24px;
        line-height: 24px;
        color: #637dee;
      }
      .revenueTitle3 {
        font-family: 'proxima_nova_rgbold';
        font-size: 24px;
        line-height: 24px;
        color: #43aa8b;
      }
      .revenueTitle4 {
        font-family: 'proxima_nova_rgbold';
        font-size: 24px;
        line-height: 24px;
        color: #e9415f;
      }
      .PaymentsTotalStu {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
    }
  }
}

.react-datepicker__input-container input::placeholder {
  color: #20282a;
  font-size: 16px !important;
}
