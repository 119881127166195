.terms_of {
  // background-image: url('../../../assets/images/termsanduseimg.svg');
  // background-repeat: no-repeat;
  text-align: center;
  .terms_of_text {
    font-family: 'proxima_nova_rgregular';
    font-size: 60px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 80px;
    margin-top: 75px;
    .inner_color {
      color: #f3722c;
    }
  }
}

.terms_of_read {
  color: #20282a;
  font-family: 'proxima_nova_rgregular';
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: 30px;
}

.welcome_part {
  .welcome_text {
    color: #20282a;
    font-family: 'proxima_nova_rgregular';
    font-size: 52px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 64px;
  }
  .welcome_textLast {
    color: #20282a;
    font-family: 'proxima_nova_rgregular';
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 64px;
  }
  .welcome_data1 {
    color: #20282a;
    font-family: 'proxima_nova_rgregular';
    font-size: 20px;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: 45px;
    .linkcolor {
      color: #f9844a;
      // font-family: Proxima Nova Semibold;
    }
    .welcomeBold {
      font-family: 'proxima_nova_rgbold';
    }
  }
  .welcome_data11 {
    color: #20282a;
    font-family: 'proxima_nova_rgregular';
    font-size: 20px;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: 15px;
    .linkcolor {
      color: #f9844a;
      // font-family: Proxima Nova Semibold;
    }
    .welcomeBold {
      font-family: 'proxima_nova_rgbold';
    }
  }
}

.accordian_content {
  padding-bottom: 80px;
  padding-top: 45px;
}

.summaryKey {
  color: #20282a;
  font-family: 'proxima_nova_rgbold';
  font-size: 28px;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: 45px;
  margin-bottom: 20px;
}

.title_info {
  border-radius: 35px;
  background-color: #ffffff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
  padding: 15px 25px 15px 25px;
  margin-top: 22px;
  .content_text {
    color: #20282a;
    font-family: 'proxima_nova_rgregular';
    font-size: 20px;
    letter-spacing: 0;
    line-height: 32px;
  }
  .title_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title_text {
      color: #20282a;
      font-family: 'proxima_nova_rgregular';
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
      margin-top: 20px;
    }
  }
}

.font-weight-bold {
  font-weight: bold;
}

.privacyListData ul {
  color: #20282a;
  font-family: 'proxima_nova_rgregular';
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: 45px;
  list-style-type: disc;
  margin-left: 30px !important;
  .linkcolor {
    color: #f9844a;
    // font-family: Proxima Nova Semibold;
  }
}

.privacyListData1 ul {
  color: #20282a;
  font-family: 'proxima_nova_rgregular';
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: 45px;
  margin-bottom: 45px !important;
  list-style-type: auto;
  margin-left: 30px !important;
  .linkcolor {
    color: #f9844a;
    // font-family: Proxima Nova Semibold;
  }
}

.privacyListData1 .customStyleType {
  list-style-type: disc;
}

.privacyListData1 ul li {
  margin-top: 30px;
}

.privacyListData1 .customStyleType li {
  margin-top: 15px;
}

.tableofContents ul {
  color: #20282a;
  font-family: 'proxima_nova_rgregular';
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: 45px;
  list-style-type: disc !important;
  margin-left: 30px !important;
}

.tableofContents ul li {
  margin-bottom: 10px;
}

.removeMargin {
  margin-bottom: 0;
}

.termsof {
  padding-left: 3rem;
  padding-right: 3rem;
}

@media only screen and (max-width: 600px) {
  .termsof {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .terms_of_text {
    font-size: 35px !important;
    line-height: 45px !important;
    margin-top: 35px !important;
  }

  .welcome_part .welcome_text {
    font-size: 35px;

    line-height: 45px;
  }

  .termsof {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .terms_row {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .terms_of {
    text-align: left !important;
  }

  .welcome_part {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .terms_of_read {
    font-size: 18px;
    line-height: 28px;
    margin-top: 10px;
  }

  .welcome_part .welcome_textLast {
    font-family: 'proxima_nova_rgbold';
    font-size: 20px;

    letter-spacing: 0;
    line-height: 35px;
  }

  .welcome_part .welcome_data1 {
    font-size: 18px;
    margin-top: 20px;
  }

  .privacyListData ul {
    font-size: 18px;
    margin-top: 25px;

    margin-left: 5px !important;
  }

  .welcomeBold {
    font-size: 18px;
  }

  .summaryKey {
    font-size: 22px;
    margin-bottom: 15px;
  }

  .accordian_content {
    padding-top: 0px;
  }

  .title_info .title_flex .title_text {
    font-size: 18px;
    line-height: 28px;
  }

  .title_info .content_text {
    font-size: 18px;
    line-height: 28px;
  }

  .tableofContents {
    padding-left: 15px;
    padding-right: 15px;
  }

  .tableofContents ul {
    font-size: 18px;
    margin-top: 25px;

    margin-left: 5px !important;
  }

  .privacyListData1 ul {
    font-size: 18px;
    margin-top: 25px;

    margin-left: 5px !important;
  }
}
