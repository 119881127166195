.video-container {
  margin: 0 !important;
  max-height: auto !important;
  overflow: unset !important;
  .videos-treee {
    position: relative;
    button {
      margin: 0;
      width: 100%;
      max-width: 243px;
      background-color: #43aa8b;
      border-radius: 33.75px;
      color: #fff;
      border: 0;
      height: 50px;
      font-weight: bold;
      font-size: 18px;
      font-family: 'proxima_nova_rgbold';
      text-align: left;
      padding: 0px 20px;
      box-shadow: none;
      position: relative;
      z-index: 9;
      display: block;
      float: right;
      transition: 0.3s;
      opacity: 0.8;
      span {
        float: right;
        height: 22px;
        width: 22px;
        background-color: #fca834;
        border-radius: 50%;
        padding: 0px 7px;
        box-shadow: none;
        display: flex;
        align-items: center;
        margin-top: 2px;
        margin-left: 10px;
      }
    }
    .videolist-btn {
      background-color: #fca834;
    }
    button:hover {
      box-shadow: 5px 5px 5px rgba(67, 170, 139, 0.25) !important;
      opacity: 1 !important;
      span {
        box-shadow: 5px 5px 5px rgba(56, 38, 38, 0.24) !important;
      }
    }

    .side-line {
      width: 1px;
      border-left: 2.5px solid rgba(27, 27, 27, 0.2) !important;
      height: 94%;
      padding: 0.5px;
      position: absolute;
      left: 0px;
      top: 10px;
    }

    .branch {
      display: flex;
      align-items: center;
      margin-bottom: 26px;

      .branch-line {
        height: 1px;
        margin-left: 4px;
        margin-right: 9px;
        border-bottom: 2.5px solid rgba(27, 27, 27, 0.2) !important;
      }
      .type {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 5px 10px;
        border: none;
        border-radius: 2px;
        img {
          margin-right: 5px;
        }
        p {
          color: #43aa8b;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 12px;
          margin: 0;
          padding: 0;
        }
      }
      a {
        text-decoration: none;
        h4 {
          color: #1b1b1b;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 20px;
          white-space: nowrap;
          margin: 0;
          margin-left: 20px;
          margin-right: 10px;
          padding: 0;
        }
      }
    }
  }
}
.synopsis-modal {
  background: #fff;
  width: 80vw;
  height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 30px;
}

.modalContentScroll {
  height: 650px;
  overflow: scroll;
}
