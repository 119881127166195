.slick-track {
  display: flex !important;
  margin-inline: 20px;
}

.slick-slide {
  margin: 0 5px;
  margin-inline: 10px;

  .sliderBox {
    border-radius: 20px;
    background-color: #ffffff !important;
    box-shadow: 0 12px 34px -10px rgba(0, 0, 0, 0.05) !important;
    padding: 30px;
    margin: 5px;
    width: 80%;

    .sub-box-main {
      .sub-box-right {
        text-align: right;
        button {
          background-color: transparent;
          border: 0;
          cursor: pointer;
          color: #f3722c;
          text-transform: uppercase;
          font-family: 'proxima_nova_rgbold';
        }
      }
    }
  }

  .subname {
    color: #000000;
    font-family: 'Proxima Nova';
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 0px;
  }

  p {
    margin-bottom: 0px;
  }

  // .floatleft {
  //     float: left;
  // }

  .gradesection {
    padding-top: 10px;
  }

  .viewdetailsbutton {
    border-radius: 10px;
    background-color: #e3fff6;
    border: none;
    width: 100%;
    padding: 10px;
    color: #43aa8b;
    font-weight: bold;
  }

  .viewdetailsbutton a {
    color: #43aa8b;
    font-family: 'Proxima Nova';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
  }

  .buttondiv {
    padding-top: 10px;
    float: left;
    width: 100%;
  }
}

.slick-slider {
  .slick-prev:before {
    content: url('../../../assets/images/sliderRightIcon.svg') !important;
  }
}

.slick-slider {
  .slick-next:before {
    content: url('../../../assets/images/sliderlefticon.svg') !important;
  }

  .slick-prev {
    left: -17px;
    z-index: 1;
    top: 45%;
  }

  .slick-next {
    right: 0px;
    top: 45%;
  }
}

.modal-content {
  // height: 650px;
  // overflow: scroll;

  .modal-header {
    border-bottom: 0px solid #dee2e6;
    padding-top: 20px;
    padding-left: 30px;
    color: #fca834;
    background-color: #fff5e6;
  }

  .model-title br span {
    color: #454c4e;
    font-family: 'Proxima Nova';
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
  }

  .modelsecdiv {
    padding-left: 30px;
    padding-top: 10px;
  }

  .Clockicon {
    padding-right: 5px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }
  .Clockicon2 {
    padding-right: 5px;
    height: 40px;
    width: 40px;
    // border-radius: 50%;
  }
  .padd0 {
    padding: 0px;
  }
  // .floatleft {
  //     float: left;
  // }

  .tabheadtext {
    color: #20282a;
    font-family: 'Proxima Nova';
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 29px;
  }

  .tabsubhead {
    color: #454c4e;
    font-family: 'Proxima Nova';
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
  }

  .tabcontent {
    color: #20282a;
    font-family: 'Proxima Nova';
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    padding-top: 30px;
    padding-right: 20px;
  }

  .tabsection {
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .firsttabcontent {
    padding: 40px;
  }

  .secondtabcontent {
    padding-top: 20px;
  }

  .buttondiv {
    margin-top: 10px;
    float: left;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    background-color: #e3fff6;
    border: none;
  }

  .buttondiv a {
    color: #43aa8b;
    font-family: 'Proxima Nova';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
  }

  .modal-title {
    color: #20282a;
    font-family: 'Proxima Nova';
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 58px;
    // background: #f3f3f3;
    border-radius: 10px;
  }

  .nav-tabs .nav-link {
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    color: #454c4e;
    font-family: 'Proxima Nova';
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
  }

  .nav-tabs .nav-link.active {
    color: #43aa8b;
    border-color: #fff #fff #eee;
    border-bottom: 6px solid #43aa8b !important;
  }

  .subtopic {
    color: #454c4e;
    font-family: 'Proxima Nova';
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
  }

  .studentlistdiv {
    border-radius: 10px 10px 0px 0px;
    background: linear-gradient(125.92deg, #c4dafe 0%, #f4f7fc 100%);
    padding: 15px 15px;
  }
  .viewassign {
    background-color: #79a2e7;
    border-radius: 0px 0px 10px 10px;
    text-align: center;
    padding: 5px;
  }

  .viewassign a {
    color: #f4f7fc;
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    padding-right: 5px;
  }

  .studentname {
    color: #20282a;
    font-family: 'Proxima Nova';
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    text-align: left;
  }

  .studentgrade {
    color: #454c4e;
    font-family: 'Proxima Nova';
    font-size: 14px;
    letter-spacing: 0;
    line-height: 15px;
    text-align: left;
  }

  .label-main {
    display: flex;
    justify-content: space-between;
  }

  // .progressbarsection {
  //     padding-top: 10px;
  // }

  .divpadd {
    padding-left: 15px;
    padding-top: 15px;
  }

  .close :before {
    content: url('../../../assets/images/modelclose.svg') !important;
    visibility: visible;
    color: 'black';
    font-weight: bold;
    margin-right: -50px;
  }

  .sr-only::before {
    visibility: hidden;
  }

  .close {
    visibility: hidden;
    position: absolute;
    right: 32px;
    top: 10px;
    width: 20px;
    height: 20px;
    background-color: rgb(247, 12, 12, 0.5);
  }

  .thirdtabcontent {
    padding-top: 20px;
  }
  .assignstudlistdiv {
    padding-top: 20px;
  }

  .atteddiv .attendance {
    color: #43aa8b;
    font-family: 'Proxima Nova';
    height: 19px;
    width: 97px;
    border-radius: 9.5px;
    background-color: #e3fff6;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 14px;
    text-align: center;
    padding: 8px 15px 8px 15px;
  }
  .atteddiv .attendanceAbsent {
    color: #fd2a5b;
    font-family: 'Proxima Nova';
    height: 19px;
    width: 97px;
    border-radius: 9.5px;
    background-color: #ffeaf1;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 14px;
    text-align: center;
    padding: 8px 15px 8px 15px;
  }
  .notsubmitted {
    color: #f4f7fc;
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
  }
  viewassignnotsubmitted {
    background-color: #f4f7fc;
    border-radius: 0px 0px 10px 10px;
    text-align: center;
    padding: 5px;
  }
  .label-left {
    color: #454c4e;
    font-family: 'Proxima Nova';
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    padding-top: 8px;
  }
}
