.successBack {
  background-color: #e3fff6 !important;
  border: 2pt solid #43aa8b;
}
.failure {
  background-color: white !important;
  border: 2pt solid red;
}
.horizontal-cell,
.vertical-cell {
  min-height: 80px;
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  .opti {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    img {
      width: 100px !important;
      height: 100px !important;
    }
  }
}
.answered-section {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .answer-stattus {
    margin-left: 5rem;
  }
}
.failureBack {
  border: 3px solid red;
}

.customAnswerTitle {
  display: inline-flex;
}

.customAnswerTitle span p {
  padding-left: 10px !important;
  color: #43aa8b;
}

.custom-item-wrapper img {
  width: 45px;
}

.customAlignBtnPrac {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.customAEndBtnPrac {
  text-align: end;
  padding-right: 30px;
}

.customIcon--check-wrapper {
  background-color: #fca834;
}

.successTextColor {
  color: #43aa8b !important;
  * {
    color: #43aa8b !important;
  }
  strong {
    span {
      color: #43aa8b !important;
    }
  }
  p {
    span {
      color: #43aa8b !important;
    }
  }
}

.failureTextColor {
  color: #fca834;
}

.answer-title-padding {
  padding-top: 20px;
}
.answer-title {
  margin-top: 0 !important;
}

.custom-question-box {
  margin-bottom: 25px;
  @media only screen and (max-width: 480px) {
    margin-bottom: 20px;
  }
}

.answer-wrong {
  color: red !important;
  * {
    color: red !important;
  }
  strong {
    span {
      color: red !important;
    }
  }
  p {
    span {
      color: red !important;
    }
  }
}
.answer-correct {
  color: #43aa8b;
}
.retake-btn {
  width: 100%;
  max-width: 243px;
  background-color: #43aa8b;
  border-radius: 33.75px;
  color: #fff;
  border: 0;
  height: 50px;
  font-weight: 700;
  font-size: 18px;
  font-family: 'proxima_nova_rgbold';
  text-align: left;
  padding: 0 20px;
  box-shadow: none;
  position: relative;
  z-index: 9;
  display: block;
  float: right;
  margin-top: 15px;
  transition: 0.3s;
  opacity: 0.8;
  span {
    float: right;
    height: 22px;
    width: 22px;
    background-color: #fca834;
    border-radius: 50%;
    padding: 0 7px;
    box-shadow: none;
    display: flex;
    align-items: center;
    margin-top: 2px;
  }
}
.retake-test-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.speaker-btn {
  margin-right: 10px;
  margin-bottom: 3px;
  height: 30px;
  width: 30px;
}
.vertical-option-wrapper {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50% !important;
  img {
    // height: 40px;
  }
}
.optionsbtn {
  padding-left: 24px;
}

.myconcept-head-top {
  padding: 15px 20px;
  background-color: #f5a833;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .breadcrumb {
    margin: 0;
    a {
      text-decoration: none;
      color: #4a5052;
      font-family: 'proxima_nova_rgregular';
      font-size: 18px;
    }
    li.active {
      color: #fff;
      font-family: 'proxima_nova_rgregular';
      font-size: 18px;
    }
    li.breadcrumb-item + .breadcrumb-item {
      &::before {
        content: url('../../../../../assets/images/pathrightsvg.svg');
        top: 2px;
        position: relative;
      }
    }
  }
}
.practicetestHeaderWrapper {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);

  .congrats-detail-sub-part {
    .congrats-detail-sub-part-right {
      p {
        color: #20282a;
        padding-bottom: 6px;
        @media only screen and (max-width: 480px) {
          font-weight: 400;
          font-size: 12px;
          line-height: 13px;
        }
      }
      h5 {
        @media only screen and (max-width: 480px) {
          font-weight: 700;
          font-size: 14px;
          line-height: 14px;
        }
      }
    }
  }
}

.openPracticeStyleWrapper {
  background-color: #f4f7fc;
  .openpracticeBody {
    .drag-and-drop-que-part {
      // .drag-and-drop-stiky-option {
      //   @media only screen and (max-width: 1024px) {
      //     position: sticky;
      //     top: 0;
      //     background-color: #f4f7fc;
      //   }
      // }
      .object-container {
        @media only screen and (max-width: 480px) {
          // margin-top: 0;
          display: flex !important;
          flex-wrap: wrap;
          gap: 13px;
          margin-block: 20px;
        }
        .drag-item {
          @media only screen and (max-width: 480px) {
            padding: 20px;
            min-width: 63px;
            min-height: 63px;
            border: 1.4px solid #9ba4a7 !important;
            box-shadow: none;
            border-radius: 10px;
          }
          .drag-item-index {
            @media only screen and (max-width: 480px) {
              display: none;
            }
          }
          .drag-item-sub {
            @media only screen and (max-width: 480px) {
              min-height: initial;
            }
            img {
              @media only screen and (max-width: 480px) {
                margin: auto;
              }
            }
          }
        }
      }
    }
    .fill-the-blank {
      .question-box {
        .speaker-btn-wrapper {
          margin-top: 0;
        }
      }
    }
    .question-box {
      .fill-in-the-blanks {
        span {
          @media only screen and (max-width: 480px) {
            font-weight: 400;
            font-size: 16px;
          }
        }
        input {
          @media only screen and (max-width: 480px) {
            font-weight: 700;
            font-size: 16px;
          }
        }
        input:disabled {
          @media only screen and (max-width: 480px) {
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }
      .fill-drag-option {
        cursor: auto;
        @media only screen and (max-width: 480px) {
          font-weight: 700;
          font-size: 16px;
          flex-wrap: wrap;
          gap: 10px 0px;
        }
        .fill-in-the-blanks-shuffled {
          cursor: grab;
        }
        .practice-test-audio {
          margin-right: 0px;
          @media only screen and (max-width: 480px) {
            margin: auto 0px auto auto;
          }
        }
      }
    }

    .button-common {
      @media only screen and (max-width: 480px) {
        display: block !important;
      }
      @media only screen and (max-width: 1025px) {
        display: block !important;
      }
    }
    .test-header-left {
      .test-header {
        @media only screen and (max-width: 480px) {
          font-weight: 700;
          font-size: 20px;
          line-height: 20px;
          margin-bottom: 0;
        }
      }
      .bradcrumWrapper {
        padding: 0;
        @media only screen and (max-width: 480px) {
          padding: 0;
        }
        .breadcrumb-item + .breadcrumb-item {
          @media only screen and (max-width: 480px) {
            padding-left: 5px;
          }
        }
        .breadcrumb {
          @media only screen and (max-width: 480px) {
            margin-bottom: 8px;
          }
          li {
            a {
              @media only screen and (max-width: 480px) {
                font-weight: 400;
                font-size: 12px;
                line-height: 13px;
                color: #20282a;
              }
            }
          }
          li.breadcrumb-item + .breadcrumb-item {
            &::before {
              // content: url('../../../../../assets/images/pathrightsvg.svg');
              // top: 2px;
              // position: relative;
              @media only screen and (max-width: 480px) {
                padding-right: 5px;
              }
            }
          }
          li.active {
            a {
              color: #f5a833;
              @media only screen and (max-width: 480px) {
                font-weight: 400;
                font-size: 12px;
                line-height: 13px;
              }
            }
          }
        }
      }
    }
    .question-box {
      @media only screen and (max-width: 480px) {
        padding: 20px 16px;
        border-radius: 10px;
      }

      img {
        @media only screen and (max-width: 480px) {
          max-width: 100%;
        }
      }
      h4 {
        @media only screen and (max-width: 480px) {
          font-weight: 600;
          font-size: 14px;
        }
      }
      h2 {
        @media only screen and (max-width: 480px) {
          font-weight: 400;
          font-size: 16px;
        }
      }
      .practice-test-audio {
        @media only screen and (max-width: 480px) {
          align-self: flex-start;
          margin-top: -29px;
        }
        .speaker-btn {
          @media only screen and (max-width: 480px) {
            height: 20px;
            width: 20px;
          }
        }
      }
      .speaker-btn-wrapper {
        align-self: flex-start;
        @media only screen and (max-width: 480px) {
          margin-top: -29px;
        }
        .speaker-btn {
          @media only screen and (max-width: 480px) {
            height: 20px;
            width: 20px;
          }
        }
      }
    }
    .answer-title {
      @media only screen and (max-width: 480px) {
        font-weight: 400;
        font-size: 16px;
      }
    }
    .answer-correct {
      @media only screen and (max-width: 480px) {
        font-weight: 600;
        font-size: 14px;
      }
    }
    .answer-wrong {
      @media only screen and (max-width: 480px) {
        font-weight: 600;
        font-size: 14px;
      }
    }
    .practice-test-audio {
      @media only screen and (max-width: 480px) {
        padding: 0;
      }
      img {
        @media only screen and (max-width: 480px) {
          height: 20px;
          width: 20px;
        }
      }
    }
    .question-options-wrapper {
      @media only screen and (max-width: 480px) {
        grid-template-columns: 1fr;
        grid-row-gap: 10px;
        margin-top: 12px;
      }
      .option-wrapper-tile-type {
        @media only screen and (max-width: 480px) {
          margin-bottom: 0px;
          border-radius: 10px;
          border-width: 1px;
          border-style: solid;
        }
        span {
          @media only screen and (max-width: 480px) {
            font-weight: 400;
            font-size: 12px;
          }
        }
        button {
          // align-items: baseline;
          .questionHtlm-right {
            @media only screen and (max-width: 480px) {
              padding: 10px 5px;
              display: flex;
              align-items: center;
            }
          }
          .opti {
            @media only screen and (max-width: 480px) {
              margin-right: 0;
            }
          }
          .successbg {
            background-color: #43aa8b;
          }
          .failurebg {
            background-color: #f44336;
          }
          div.icon--check-wrapper {
            display: flex !important;
            align-items: center;
            @media only screen and (max-width: 480px) {
              width: 16px;
              height: 16px;
            }
            svg {
              @media only screen and (max-width: 480px) {
                // margin-top: -8px;
                width: inherit;
              }
            }
          }
        }
      }
    }
    .submitSection {
      @media only screen and (max-width: 480px) {
        margin-top: 30px;

        .submit-answer-button {
          margin: auto;
          .button-wrapper {
            height: 48px;
            span {
              font-weight: 600;
              font-size: 16px;
              line-height: 16px;
            }
          }
          .button-wrapper-active {
            height: 48px;
            span {
              font-weight: 600;
              font-size: 16px;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}

.bradcrumWrapper {
  padding: 15px 20px;
  // background-color: #f5a833;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .breadcrumb {
    margin: 0;
    a {
      text-decoration: none;
      color: #4a5052;
      font-family: 'proxima_nova_rgregular';
      font-size: 18px;
    }
    li {
      color: #4a5052;
      font-family: 'proxima_nova_rgregular';
      font-size: 20px;
    }
    li.active {
      color: #f5a833;
      font-family: 'proxima_nova_rgregular';
      font-size: 20px;
    }
    li.breadcrumb-item + .breadcrumb-item {
      &::before {
        content: url('../../../../../assets/images/pathrightsvg.svg');
        top: 2px;
        position: relative;
      }
    }
  }
}
.myconcept-head-top-parent {
  margin-right: 2rem;
}
.congrats-detail-sub-part-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 30px;
    height: 30px;
    object-fit: scale-down;
  }
  p {
    font-size: 14px;
  }
  h5 {
    font-size: 17px;
  }
}

.custom-congrats-detail-sub-part-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 30px;
    height: 30px;
    object-fit: scale-down;
  }
  p {
    font-size: 16px;
    color: #454c4e;
  }
  h5 {
    font-family: 'proxima_nova_rgbold';
    color: #454c4e;
    font-size: 20px;
  }
}
.option-wrapper-solution {
  padding: 20px 20px 20px 10px;
  border: 3px #01a171 solid;
  border-radius: 20px;
  background-color: #e3fff6;
  margin-top: 10px;
  font-family: 'proxima_nova_rgregular';
  color: #20282a;
  font-size: 20px;
  @media only screen and (max-width: 480px) {
    border: 1px solid #43aa8b;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    padding: 16px;

    h4 {
      font-weight: 600;
      font-size: 16px;
    }
    img {
      max-width: 100%;
    }
  }
  h4 {
    font-weight: 600;
  }
}
.vertical-text {
  word-break: inherit;
  margin-right: 12px;
  // max-width: 80%;
  // width: 100%;
}
.answered-section {
  display: flex;
  align-items: center;
  justify-content: center;
}
.right-answer-modal {
  width: 90% !important;
  .congrats-detail {
    width: 100%;
  }
}
.practice-test-audio {
  display: flex;
  justify-content: flex-end;
  padding: 0 24px;
}

.course-detail-select .label-div .info-icon {
  height: 20px;
  width: 20px;
  background-color: #fca834;
  display: inline-flex;
  border-radius: 50%;
  color: #fff;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-left: 10px;
  position: relative;
  top: -3px;
}

.course-detail-select {
  .dropdown {
    position: relative;
    color: #333;
    cursor: default;
    box-sizing: border-box;
    height: 52px;
    width: 450px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    background-color: #ffffff;
  }
  .country-flag-and-selected-value {
    img {
      width: 35px;
      height: 21px;
      margin: auto 10px auto 7px;
    }
    input {
      margin: -1px;
      line-height: 2.5;
      font-size: 1.2rem;
      border-radius: 2px;
      box-sizing: border-box;
      cursor: pointer;
      outline: none;
      padding: 8px 8px 8px 10px;
      transition: all 200ms ease;
      // width: 100%;
      box-sizing: border-box;
      height: 66px;
      width: 450px;
      border: 1px solid #e0e0e0;
      border-radius: 0 10px 10px 0;
      background-color: #ffffff;
    }
  }
  .country-flag-and-selected-novalue {
    img {
      width: 35px;
      height: 21px;
      margin: 22px 10px 0px 7px;
    }
    input {
      margin: -1px;
      line-height: 2.5;
      font-size: 1.2rem;
      border-radius: 2px;
      box-sizing: border-box;
      cursor: pointer;
      outline: none;
      padding: 8px 8px 8px 10px;
      transition: all 200ms ease;
      // width: 100%;
      box-sizing: border-box;
      height: 66px;
      // width: 585px;
      width: 497px;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      background-color: #ffffff;
    }
  }
  .chevDown {
    position: absolute;
    top: 14px;
    right: 9px;
    color: #c6c8c9;
    display: flex;
    // padding: 8px;
    -webkit-transition: color 150ms;
    transition: color 150ms;
    box-sizing: border-box;
    @media only screen and (max-width: 480px) {
      width: 9px;
      height: 9px;
    }
  }
  .chevDown-novalue {
    position: absolute;
    /* width: 30px; */
    /* height: 60px; */
    top: 14px;
    right: 9px;
    color: #c6c8c9;
    display: flex;
    /* padding: 8px; */
    transition: color 150ms;
    box-sizing: border-box;
  }

  .ReactFlagsSelect-module_selectBtn__19wW7 {
    border-radius: 10px;
    height: 66px;
    border: 1px solid #e0e0e0;
    width: 100%;
  }
  .css-6j8wv5-Input input {
    font-size: 20px;
    // display: none
    border: 0px solid #e0e0e0 !important ;
    height: 90%;
  }
  .css-1kixbkd:hover {
    background-color: 'blue';
  }
  .css-12jo7m5 {
    border-radius: 33px;
    color: #7d8283;
    font-size: 18px;
    overflow: hidden;
    padding: 7px;
    margin: 0 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    background-color: #e3fff6;
    font-style: 'Proxima Nova';
    font-weight: 600;
  }
  .css-xb97g8 {
    border-radius: 50%;
    color: #7d8283;
    font-size: 20px;
    margin-left: -10px;
  }

  .css-xb97g8 :hover {
    background-color: #e3fff6;
    color: black;
  }

  .css-1rhbuit-multiValue {
    border-radius: 33px;
    width: auto;

    background-color: #e3fff6;
    border: 1px solid #e0e0e0;
  }
  label,
  div.label-div {
    display: flex;
    color: #7d8283;
    font-family: 'Proxima Nova';
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 5px;
    margin-top: auto;
  }
  .exam-style .css-1s2u09g-control {
    height: fit-content;
  }
  .tooltip-innercss {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    // font-size: 14px;
    background-color: #fca834 !important ;
    border-radius: 0.25rem;
    margin: 0px 0px 0 12px;
  }
  div.css-1s2u09g-control:focus,
  .css-1pahdxg-control:focus {
    // border-color:blue;
    border-color: #fff;
  }

  // .css-1s2u09g-control,
  // .css-1pahdxg-control {
  //   height: fit-content;
  // }

  div.css-1s2u09g-control,
  .css-1pahdxg-control {
    //height: fit-content;
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    // height: 66px;
    min-height: 66px;
    background-color: #fff;
    padding: 10px 10px;
    cursor: pointer;
    align-items: center;
    position: relative;
    box-shadow: none;

    .css-ijftu2-ValueContainer {
      max-width: 500px;
      padding: 0;
    }

    .css-319lph-ValueContainer {
    }
    .css-1hb7zxy-IndicatorsContainer {
      span {
        width: 0;
      }
      .css-tlfecz-indicatorContainer {
        padding: 0;
        svg {
          fill: #212529;
        }
      }
    }
  }
  div.select {
    .css-1s2u09g-control {
      margin-top: 10px;
    }
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    height: 66px;
    background-color: #fff;
    padding: 0px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    svg {
      position: absolute;
      right: 20px;
    }
  }
  button {
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    height: 66px;
    background-color: #fff;
    padding: 0px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    box-shadow: none;
  }
  select:focus {
    outline: 0 !important;
  }
  .dropdown.false {
    display: none;
  }
  .dropdown.true {
    display: block;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    margin-top: 5px;
    div {
      cursor: pointer;
      height: 80px;
      display: flex;
      align-items: center;
      padding: 20px;
    }
    div:hover {
      background-color: #e3fff6;
      color: #43aa8b;
      border-radius: 10px 10px 0 0;
    }
  }
  .dropdown-main {
    position: relative;
    .dropdown {
      position: absolute;
      width: 100%;
      background-color: #fff;
      z-index: 9;
    }
  }
}

.course-detail-select {
  .dropdown {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    .selected-value {
      display: block !important;
    }
    input {
      border: 0;
      width: 100%;
      height: 90%;
      cursor: pointer;
    }
    input:focus {
      outline: none !important;
    }
    .options {
      left: 0;
    }
    .arrow {
      top: 42%;
    }
    .country-flag-and-selected-value {
      align-items: center;
    }
  }

  .custom-dropdown {
    width: 100%;
  }

  .element-style {
    font-size: 20px;
  }

  .select-button {
    font-size: 40px;
  }

  .menu-flags {
    font-size: 40px;
    .ReactFlagsSelect-module_filterBox__3m8EU {
      padding: 15px;
      padding-bottom: 0;
      input {
        margin: 0;
      }
    }
    ul {
      li:hover {
        background-color: #e3fff6;
        span {
          color: #43aa8b;
        }
      }
    }

    .select-button::after {
      content: '';
      display: block;
      box-sizing: border-box;
      width: 8px;
      height: 8px;
      border-bottom: 2px solid #7d8283;
      // border-right: 2px solid #7d8283;
      transform: rotate(45deg);
      left: 4px;
      top: 2px;
      border-left: 0;
      border-top: 0;
      margin-right: 3px;
    }
  }

  #state {
    .css-1pahdxg-control {
      box-shadow: none;
      border: 1px solid #e0e0e0;
      height: 66px;
      cursor: pointer;
    }
    .css-1okebmr-indicatorSeparator {
      background-color: transparent;
    }
  }

  button.select-button:focus {
    border: 1px solid #43aa8b;
  }
  .ReactFlagsSelect-module_selectBtn__19wW7 {
    border-radius: 10px;
    height: 66px;
    border: 1px solid #e0e0e0;
    width: 100%;
    font-size: 20px;
  }

  .ReactFlagsSelect-module_fullWidthOptions__1XeR6 {
    max-height: 350px;
    border-radius: 10px;
  }

  .ReactFlagsSelect-module_selectOptionValue__vS99- {
    font-size: 20px;
    padding: 20px 10px;
  }
}

.course-detail-select .dropdown {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  .selected-value {
    display: block !important;
  }
}
.course-detail-select .cust-dropdown {
  max-width: 168px;
  .selected-value input {
    font-size: 14px;
    padding: 8px 27px 8px 0px;
  }
}

.custom-country-select {
  .options .open {
    display: block;
  }
  .options {
    display: none;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgb(0 0 0 / 6%);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 300px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    .option {
      display: block;
    }
  }
}

.chapters-main-container {
  .chapters-details {
    .chapters-subject-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 20px;
    }
    .chapters-subject-discription {
      font-family: 'proxima_nova_rgregular';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      max-width: 822px;
      margin-bottom: 29px;
    }

    .chapters-container {
      .chapter-name {
        h2 {
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          color: var(--color);
          margin-bottom: 12px;
        }
      }
    }
  }
}

//chandan media-query

@media only screen and (max-width: 500px) and (min-width: 320px) {
  .course-detail-select .dropdown {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 87%;

    .selected-value {
      display: block !important;
    }
  }

  .course-detail-select label,
  .course-detail-select div.label-div {
    display: flex;
    color: #7d8283;
    font-family: 'Proxima Nova';
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0px;
    margin-top: auto;
  }
}

.achiver-popup-modal-bg {
  height: 500px;
  width: 80%;
  background: #c3ffec;
  border-radius: 0px 100px;
  transform: rotate(-10deg);
}

.achiver-popup-modal {
  height: 500px;
  width: 100%;
  background: #ffffff;
  border-radius: 0px 140px;
  display: flex;
  justify-content: center;
  transform: rotate(10deg);
}

.achiver-star-bright {
  width: 90px;
  height: 85px;
  position: absolute;
  left: 0%;
}

.achiver-start-light {
  width: 144px;
  height: 139px;
  position: absolute;
  right: 10%;
}

.noquest-tag {
  font-size: 40px;
  margin-top: 17%;
  width: 70%;
  text-align: center;
  margin-left: 15%;
  font-weight: 700;
}
.achiver-success-img {
  height: 100px;
  width: 100px;
  margin-top: 35px;
  margin-left: 42%;
  // display: flex;
  // justify-content: center;
}
.achiver-txt {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 43px;
  line-height: 50px;
  text-align: center;

  color: #43aa8b;
}

.achiver-total {
  height: 33px;
  left: 0%;
  right: 0%;
  top: calc(50% - 33px / 2 - 2px);
  line-height: 33px;
  font-size: 25px;
  // margin-left: 50px;
  color: #4a5052;
  font-weight: 400;
  text-align: center;
  // margin-top: 8px;

  span {
    font-family: 'Proxima Nova';
    font-style: normal;

    font-size: 25px;
    line-height: 33px;
    color: #f3722c;
  }
}
.p-tag {
  // font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  margin-top: 8px;
  color: #4a5052;
}

.achiver-start-light-2 {
  position: absolute;
  width: 140px;
  height: 130px;
  left: 12%;
  top: 36%;
}

.achiver-star-bright-2 {
  position: absolute;
  right: 0%;
}
.achiver-modal-sub {
  width: 100%;
  height: 214px;
  background: #fff4e9;
  border-radius: 0px 112.5px;
  display: flex;
  justify-content: center;
  // margin-bottom: 40px;
  .subscribe-txt {
    // position: absolute;
    margin-top: 15px;
    margin-left: 16%;
    width: 68%;
    height: 60px;
    left: 31.72%;
    right: 30.3%;
    top: calc(50% - 60px / 2 + 66px);
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    text-align: center;

    color: #4a5052;
  }

  .sub-btn {
    // position: absolute;
    margin-left: 30%;
    margin-top: 6%;
    padding: 10px;
    text-align: left;
    background: #43aa8b;
    box-shadow: 0px 12px 10px rgba(67, 170, 139, 0.254371);
    border-radius: 33.75px;
    width: 200px;
    height: 50px;
    text-align: center;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #ffffff;
    cursor: pointer;
  }
}

@media only screen and (max-width: 499px) and (min-width: 320px) {
  .achiver-popup-modal-bg {
    background: none !important;
    width: 100%;
    transform: rotate(0deg);
  }

  .achiver-popup-modal {
    // height: 570px;
    // width: 374px;
    // background: #ffffff;
    // border-radius: 0px 140px;
    // display: flex;
    // justify-content: center;
    // transform: rotate(10deg);

    position: relative;
    margin-top: 18%;
  }

  .achiver-popup-modal {
    border-radius: 0px 70px;
    margin-top: 50%;
    transform: rotate(0deg);
    height: auto;
  }
  .achiver-start-light {
    display: none;
  }

  .achiver-star-bright {
    width: 54px;
    height: 70px;
    position: absolute;
    left: 0%;
  }

  .achiver-success-img {
    height: 88px;
    width: 84px;
    margin-top: 35px;
    margin-left: calc(70% - 100px);
  }

  .noquest-tag {
    font-size: 25px;
    margin-top: 31%;
    width: 70%;
    text-align: center;
    margin-left: 15%;
    font-weight: 700;
  }
  .achiver-txt {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    color: #43aa8b;
  }
  .achiver-total {
    font-size: 18px;
    top: 0px;
    line-height: 10px;
    font-weight: 800;
    span {
      font-size: 18px;
      font-weight: 800;
    }
  }

  .p-tag {
    line-height: 7px;
    font-size: 12px;
    font-weight: 600;
    margin-top: 0px;
    margin-top: 9px;
  }
  .achiver-star-bright-2 {
    display: none;
  }

  .achiver-start-light-2 {
    display: none;
  }

  .achiver-modal-sub {
    height: auto;
    width: auto;
    margin: 20px;
    border-radius: 0px 45.5px;
    // margin-bottom: 50px;
  }

  .achiver-modal-sub .subscribe-txt {
    margin-left: 13%;
    font-size: 15px;
    line-height: 20px;
    width: calc(90% - 47px);
  }

  .achiver-modal-sub .sub-btn {
    margin-left: 9%;
    margin-top: 0%;
    text-align: center;
    font-size: 13px;
    height: 40px;
    font-weight: 500;
    width: calc(90% - 20px);
    margin-bottom: 20px;
    span {
      display: none;
    }
  }

  .bottom-edge-star {
    height: 20px;
    position: absolute;
    bottom: 1%;
    left: 8%;
  }

  .bottom-star {
    position: relative;
    left: 84%;
  }
}

@media only screen and (min-width: 990px) {
  .bottom-edge-star {
    display: none;
  }

  .bottom-star {
    display: none;
  }
}

@media only screen and (max-width: 799px) and (min-width: 500px) {
  .achiver-popup-modal-bg {
    background: none !important;
    width: 100%;
    transform: rotate(0deg);
  }

  .achiver-popup-modal {
    // height: 570px;
    // width: 374px;
    // background: #ffffff;
    // border-radius: 0px 140px;
    // display: flex;
    // justify-content: center;
    // transform: rotate(10deg);

    position: relative;
    margin-top: 18%;
  }

  .achiver-popup-modal {
    border-radius: 0px 70px;
    // margin-top: 0%;
    transform: rotate(0deg);
    height: auto;
  }
  .achiver-start-light {
    display: none;
  }

  .noquest-tag {
    font-size: 21px;
    margin-top: 16%;
    width: 69%;
    text-align: center;
    margin-left: 15%;
  }
  .achiver-star-bright {
    width: 54px;
    height: 70px;
    position: absolute;
    left: 0%;
  }

  .achiver-success-img {
    height: 88px;
    width: 84px;
    margin-top: 35px;
    margin-left: calc(70% - 100px);
  }

  .achiver-txt {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    color: #43aa8b;
  }
  .achiver-total {
    font-size: 18px;
    top: 0px;
    line-height: 10px;
    font-weight: 800;
    span {
      font-size: 18px;
      font-weight: 800;
    }
  }

  .p-tag {
    line-height: 7px;
    font-size: 12px;
    font-weight: 600;
    margin-top: 0px;
    margin-top: 9px;
  }
  .achiver-star-bright-2 {
    display: none;
  }

  .achiver-start-light-2 {
    display: none;
  }

  .achiver-modal-sub {
    height: auto;
    width: auto;
    margin: 20px;
    border-radius: 0px 45.5px;
    /* margin-bottom: 50px; */
    margin-top: 28px;
  }

  .achiver-modal-sub .subscribe-txt {
    margin-left: 13%;
    font-size: 15px;
    line-height: 20px;
    width: calc(90% - 47px);
  }

  .achiver-modal-sub .sub-btn {
    margin-left: 9%;
    margin-top: 0%;
    text-align: center;
    font-size: 13px;
    height: 40px;
    font-weight: 500;
    width: calc(90% - 20px);
    margin-bottom: 20px;
    span {
      display: none;
    }
  }

  .bottom-edge-star {
    height: 20px;
    position: absolute;
    bottom: 1%;
    left: 8%;
  }

  .bottom-star {
    position: relative;
    // float: right;
    left: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .bottom-edge-star {
    display: none;
  }

  .bottom-star {
    display: none;
  }
}

@media only screen and (max-width: 500px) and (min-width: 320) {
  .course-detail-select .cust-dropdown {
    // max-width: 168px;
    .selected-value .country-flag-and-selected-novalue input::placeholder {
      // font-size: 14px;
      // padding: 8px 27px 8px 0px;
      font-size: 10px !important;
    }
  }
  // .course-detail-select .dropdown .selected-value input::placeholder {
  //   font-size: 10px !important;
  // }
}

@media only screen and (max-width: 991px) and (min-width: 800px) {
  .achiver-popup-modal-bg {
    background: none !important;
    width: 100%;
    transform: rotate(0deg);
  }

  .achiver-popup-modal {
    // height: 570px;
    // width: 374px;
    // background: #ffffff;
    // border-radius: 0px 140px;
    // display: flex;
    // justify-content: center;
    // transform: rotate(10deg);

    position: relative;
    // margin-top: 18%;
  }

  .noquest-tag {
    font-size: 23px;
    margin-top: 19%;
    width: 70%;
    text-align: center;
    margin-left: 14%;
    font-weight: 700;
  }
  .achiver-popup-modal {
    border-radius: 0px 70px;
    // margin-top: 0%;
    transform: rotate(0deg);
    height: auto;
  }
  .achiver-start-light {
    display: none;
  }

  .achiver-star-bright {
    width: 54px;
    height: 70px;
    position: absolute;
    left: 0%;
  }

  .achiver-success-img {
    height: 88px;
    width: 84px;
    margin-top: 35px;
    margin-left: calc(70% - 100px);
  }

  .achiver-txt {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    color: #43aa8b;
  }
  .achiver-total {
    font-size: 18px;
    top: 0px;
    line-height: 10px;
    font-weight: 800;
    span {
      font-size: 18px;
      font-weight: 800;
    }
  }

  .p-tag {
    line-height: 7px;
    font-size: 12px;
    font-weight: 600;
    margin-top: 0px;
    margin-top: 9px;
  }
  .achiver-star-bright-2 {
    display: none;
  }

  .achiver-start-light-2 {
    display: none;
  }

  .achiver-modal-sub {
    height: auto;
    width: auto;
    margin: 20px;
    border-radius: 0px 45.5px;
    /* margin-bottom: 50px; */
    margin-top: 28px;
  }

  .achiver-modal-sub .subscribe-txt {
    margin-left: 13%;
    font-size: 15px;
    line-height: 20px;
    width: calc(90% - 47px);
  }

  .achiver-modal-sub .sub-btn {
    margin-left: 9%;
    margin-top: 0%;
    text-align: center;
    font-size: 13px;
    height: 40px;
    font-weight: 500;
    width: calc(90% - 20px);
    margin-bottom: 20px;
    span {
      display: none;
    }
  }

  .bottom-edge-star {
    height: 20px;
    position: absolute;
    bottom: 1%;
    left: 8%;
  }

  .bottom-star {
    position: relative;
    // float: right;
    left: 100%;
  }
}
.confetiPopup {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  div {
    margin: auto !important;
  }
}
