.test-container {
  padding: 24px 0;
}
.background-on-success {
  background-color: #f4f7fc;
}

.background-on-error {
  background-color: #fef4e9;
}

.test-container .sub-links-normal {
  color: #4a5052;
  font-family: 'proxima_nova_rgregular';
  font-size: 20px;
}

.test-container .sub-links-normal.bold {
  font-size: 16px;
  color: #454c4e;
}

.test-container .sub-links-active {
  color: #f3722c;
  font-family: 'proxima_nova_rgregular';
  font-size: 20px;
}
.question-box {
  background-color: #ffffff;
  padding: 24px;
  height: auto;
  border-radius: 40px;
  .question-box-common {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 70px;
  }
}
.question-box h4 {
  color: #78828a;
  font-size: 20px;
  font-family: 'proxima_nova_rgregular';
  font-weight: 700;
}
.question-box h2 {
  color: #20282a;
  font-size: 24px;
  font-family: 'proxima_nova_rgregular';
  margin-top: 15px;

  span img.span-one {
    margin-right: 10px;
    margin-bottom: 3px;
    height: 30px;
    width: 30px;
  }
  button {
    border: 0;
    background-color: transparent;
  }
}

.question-box .image {
  height: 68px;
  width: 68px;
  cursor: pointer;
}

.option-wrapper-box-type {
  height: 110px;
  width: 130px;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 20px;
  border: 1.44px solid #42494b;
  color: #6f797c;
  margin-right: 35px;
  button {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 20px;
    background: transparent;
  }
}

.option-wrapper-box-type span {
  font-size: 50px;
  font-weight: bold;
  letter-spacing: 0;
}

.option-wrapper-tile-type {
  height: 70px;
  width: 390px;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 20px;
  color: #6f797c;
  border: 1.44px solid #9ba4a7;
  background-color: #ffffff;
}

.option-wrapper-tile-type span {
  font-size: 20px;
  font-family: 'proxima_nova_rgbold';
  letter-spacing: 0;
}

.answer-title {
  font-size: 20px;
  color: #78828a;
  font-family: 'proxima_nova_rgregular';
  font-weight: 700;
}
.select-answer {
  color: #ffffff;
  background-color: #fca834;
}

.submit-answer-button {
  color: #ffffff;
  border: none;
  border-radius: 33px;
  outline: none;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  padding: 0;
  background-color: transparent;
  margin-top: 60px;
}
.button-wrapper {
  width: 273px;
  height: 66px;
  border-radius: 33px;
  padding: 0px 36px;
  background-color: #dddddd;
  // box-shadow: 0 12px 10px 0 rgba(67, 170, 139, 0.25);
  font-family: 'proxima_nova_rgbold';
  font-size: 20px;
}
.button-wrapper-active {
  width: 273px;
  height: 66px;
  border-radius: 33px;
  padding: 0px 36px;
  background-color: #43aa8b;
  box-shadow: none;
  font-family: 'proxima_nova_rgbold';
  font-size: 20px;
  transition: 0.3s;
  opacity: 0.8;
  div.icon-wrapper {
    box-shadow: none;
  }
}
.button-wrapper-active:hover {
  box-shadow: 0 12px 10px 0 rgba(67, 170, 139, 0.25);
  opacity: 1;
  div.icon-wrapper {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.24);
  }
}

.button-wrapper .icon-wrapper {
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background-color: #c3c3c3;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.24);
}
.button-wrapper-active .icon-wrapper {
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background-color: #fca834;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.24);
}

.success-pallete {
  color: #43aa8b !important;
}

.test-header {
  font-family: 'proxima_nova_rgbold';
  color: #20282a;
  font-size: 40px;
}

.sub-links-bold {
  font-family: 'proxima_nova_rgbold';
  color: #454c4e;
  font-size: 20px;
}

.question-options-wrapper {
  margin-top: 15px;
  display: flex;
  margin-top: 24px;
  // display: grid;
  // grid-template-columns: repeat(2, 390px);
  // column-gap: 20px;
  // row-gap: 20px;
}
.question-options-wrapper div input {
  box-sizing: border-box;
  height: 70px;
  width: 169px;
  border: 1.44px solid #9ba4a7;
  border-radius: 20px;
  background-color: #ffffff;
}
.question-options-wrapper div input:focus {
  outline: none;
}

.icon--check-wrapper {
  height: 24px;
  width: 24px;
  background-color: #e8ecf5;
  border-radius: 50%;
}

.icon--check-wrapper svg {
  fill: #fff;
}

.select-answer {
  border: 1.44px solid transparent !important;
}

.select-answer .icon--check-wrapper {
  background-color: #fff;
}

.select-answer .icon--check-wrapper svg {
  fill: #fca834;
}

.right-answer-container {
  background-color: #e3fff6;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.right-answer-container .right-answer-modal-bg {
  height: 500px;
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-52%, -50%) rotatez(-10deg);
  background-color: #c3ffec;
  z-index: 1;
  border-radius: 0 140px 0 140px;
}

.right-answer-container .right-answer-modal {
  height: 479px;
  width: 80%;
  background-color: #ffffff;
  z-index: 5;
  border-radius: 0 140px 0 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.right-answer-modal .success-img {
  height: 170px;
  width: 170px;
  background-color: teal;
}

.right-answer-modal h1 {
  color: #43aa8b;
  font-family: 'proxima_nova_rgbold';
  font-size: 50px;
  margin-bottom: 25px;
}

.right-answer-modal .score-text {
  color: #4a5052;
  font-family: 'proxima_nova_rgregular';
  font-size: 30px;
  display: flex;
  align-items: center;
  line-height: 36px;
}
/* SubscriberScreenOne start */
.subscriberscreen-one {
  overflow: none;
  background-color: #e3fff6;
  height: 100vh;
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.subscriberscreen-one .right-answer-modal h1 {
  height: 84px;
  width: 495px;
  color: #43aa8b;
  font-family: 'proxima_nova_rgbold';
  font-size: 69.3px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 84px;
  text-align: center;
}

.subscriberscreen-one .success-bonus p {
  font-family: 'proxima_nova_rgbold';
  font-size: 29.7px;
  letter-spacing: 0;
  line-height: 35px;
  color: #4a5052;
}

.subscriberscreen-one .success-bonus p span {
  color: #fca834;
  font-family: 'proxima_nova_rgbold';
  font-weight: bold;
  margin-left: 10px;
  height: 35px;
  width: 222px;
  font-size: 29.7px;
  letter-spacing: 0;
  line-height: 35px;
}
.sub-screen-right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: 100vh;
  border-radius: 100px 0 0 100px;
  background-color: white;
}
.sub-screen-right-img {
  color: white;
  margin-bottom: 54px;
  text-align: center;
}
.sub-screen-right-heading {
  height: 159px;
  width: 458px;
  color: #454c4e;
  font-family: 'proxima_nova_rgbold';
  font-size: 44px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 53px;
  text-align: center;
  margin-bottom: 52px;
}
.sub-screen-right-headtwo {
  color: #454c4e;
  font-family: 'proxima_nova_rgregular';
  font-size: 24px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  font-weight: bold;
}
.button-common {
  height: 66px;
  width: 303px;
  border-radius: 33.75px;
  background-color: #43aa8b;
  border: 0;
  text-align: center;
  padding: 0px 20px;
  color: #fff;
  font-size: 20px;
  font-family: 'proxima_nova_rgbold';
  line-height: 24px;
  margin-top: 68px;
  position: relative;
  transition: 0.3s;
  opacity: 0.8;
}
.button-common span {
  float: right;
  height: 24px;
  width: 24px;
  background-color: #fca834;
  border-radius: 50%;
  padding: 0px 7px;
  margin-top: 0px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
}
// .button-common:before {
//   content: "";
//   position: absolute;
//   height: 25px;
//   width: 27px;
//   border-radius: 10px 0 10px 10px;
//   background-color: #e3e5ff;
//   top: 40px;
//   z-index: -1;
//   right: -50px;
// }
// .button-common:after {
//   content: "";
//   position: absolute;
//   height: 30px;
//   width: 35px;
//   border-radius: 10px 0 10px 10px;
//   background-color: #fbe8ff;
//   top: 115px;
//   z-index: -1;
//   left: 20%;
// }
.button-common span svg {
  margin-top: 0 !important;
}
.button-common:hover {
  box-shadow: 5px 5px 5px rgba(67, 170, 139, 0.25) !important;
  opacity: 1 !important;
  span {
    box-shadow: 5px 5px 5px rgba(56, 38, 38, 0.24) !important;
  }
}

/* SubscriberScreenOne end */
.reattemp-wrapper {
  height: 224px;
  width: 1260px;
  border-radius: 40px;
  background-color: #ffffff;
  padding: 24px;
}
.reattemp-wrapper h4 {
  height: 22px;
  width: 168px;
  color: #78828a;
  font-family: 'proxima_nova_rgregular';
  font-size: 20px;
  letter-spacing: 0;
  line-height: 22px;
}

.question-box-common .image {
  height: 68px;
  width: 68px;
  cursor: pointer;
}

.question-box-common {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 70px;
  margin-top: 0;
}
.reattemp-wrapper .question-box-common button {
  background: none;
  border: none;
}
.question-box-common span div {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: none;
  text-align: center;
  background-color: #a0a4a6;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  color: white;
  letter-spacing: 0;
  margin-bottom: 0.5rem;
}

.background-on-error {
  .option-wrapper-box-type.select-answer {
    background-color: #fef4e9;
    border: none;
    button {
      border-radius: 20px;
      background-color: #ffe9ee;
      border: none;
      color: white;
      span {
        height: 61px;
        width: 30px;
        color: #fd2a5b;
        font-size: 50px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 61px;
      }
    }
  }
}

.background-on-error {
  .option-wrapper-box-type.correctAnswer {
    background-color: #fef4e9;
    border: none;
    button {
      color: white;
      border-radius: 20px;
      background-color: #e3fff6;
      border: none;
      span {
        height: 61px;
        width: 28px;
        color: #43aa8b;
        font-family: 'proxima_nova_rgbold';
        font-size: 50px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 61px;
      }
    }
  }
}

.second-attempt-correct {
  background-color: #43aa8b !important;
}

.score-text .score {
  color: #43aa8b;
  font-family: 'proxima_nova_rgbold';
  margin-left: 10px;
}

.score-text .success-badge {
  height: 32px;
  width: 65px;
  border-radius: 16.5px;
  background-color: #e3fff6;
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #20282a;
  font-family: 'proxima_nova_rgbold';
  font-size: 24px;
}

.right-answer-modal .success-bonus {
  border-radius: 20px;
  background-color: #fff4e9;
  padding: 7px 55px;
  color: #4a5052;
  font-family: 'proxima_nova_rgregular';
  font-size: 20px;
}

.success-bonus p {
  margin: 0;
}

.success-bonus p span {
  color: #fca834;
  font-family: 'proxima_nova_rgbold';
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
}
.test-header-right {
  .mb-4 {
    position: relative;
  }
}

/* New changes */

.answer-title {
  display: block;
}
.question-options-wrapper.selectTileType {
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-column-gap: 20px;
  width: 70%;
  .option-wrapper-tile-type {
    margin-bottom: 30px;
    position: relative;
    width: 100%;
    button {
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      border: 0;
      border-radius: 20px;
      background: transparent;
      span {
        display: inline-block !important;
        width: 85% !important;
        text-align: left;
        font-family: 'proxima_nova_rgregular';
      }
      div.icon--check-wrapper {
        display: inline-block !important;
        svg {
          height: 25px;
          width: 25px;
          margin-top: -5px;
        }
      }
    }
  }
}

.question-options-wrapper div input {
  box-sizing: border-box;
  height: 70px;
  width: 169px;
  border: 1.44px solid #9ba4a7;
  border-radius: 20px;
  background-color: #ffffff;
  padding-left: 20px;
}
.question-options-wrapper div input:focus {
  outline: none;
}

.subscriberscreen-one {
  .subscriberscreen-row-main {
    margin: auto;
  }
  .subscriberscreen-right {
    padding-right: 0;
    .sub-screen-right {
      padding: 40px 20px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .subscriberscreen-one .right-answer-modal h1 {
    font-size: 45px;
    width: auto;
    height: auto;
  }
  .subscriberscreen-one .success-bonus p {
    font-size: 20px;
  }
  .sub-screen-right-heading {
    width: auto;
    height: auto;
    font-size: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .subscriberscreen-one .subscriberscreen-right .sub-screen-right {
    .sub-screen-right-img {
      img {
        width: 200px;
      }
    }
  }
  .subscriberscreen-one .subscriberscreen-right .sub-screen-right {
    .button-common {
      width: 200px !important;
    }
  }
  .question-options-wrapper.selectTileType {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .subscriberscreen-one {
    padding: 50px 30px;
  }
  .subscriberscreen-one .subscriberscreen-right {
    margin-top: 100px;
  }
}
@media only screen and (max-width: 600px) {
  .question-options-wrapper.selectTileType {
    grid-template-columns: 1fr;
    .option-wrapper-tile-type {
      max-width: 100%;
    }
  }
}
@media only screen and (max-width: 480px) {
  .question-options-wrapper.selectTileType {
    column-count: 1;
    .option-wrapper-tile-type {
      max-width: 100%;
    }
  }
}

/* New changes End */

@media only screen and (max-width: 1100px) {
  // .test-header-left,
  // .test-header-right {
  //   // width: 100% !important;
  //   // display: block !important;
  // }
  .test-header-left.mb-4 {
    margin-bottom: 0 !important;
  }
  .test-header-right.mb-4 {
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 767px) {
  .test-header-left {
    h3.test-header {
      font-size: 30px;
    }
    span {
      font-size: 16px !important;
    }
  }
  .question-box {
    h4,
    h2 {
      font-size: 18px;
    }
    .image {
      height: 50px;
      width: 50px;
    }
  }
  .question-options-wrapper {
    .option-wrapper-box-type {
      height: 80px;
      width: 80px;
      span {
        font-size: 35px;
      }
    }
  }
  .submit-answer-button {
    margin-top: 10px !important;
  }
  .answer-title {
    font-size: 17px;
  }
  .question-box h2 span.span-one svg {
    height: 25px;
    width: 25px;
    color: #454c4e;
    margin-top: -1px;
  }
}

@media only screen and (max-width: 577px) {
  .test-container.px-5 {
    .sub-links-normal.bold {
      font-size: 15px;
    }
  }
}
@media only screen and (max-width: 575px) {
  .test-container.px-5 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .question-options-wrapper .option-wrapper-box-type {
    height: 60px;
    width: 60px;
    margin-right: 15px;
  }
  .test-header-right .mb-4 {
    margin-bottom: 0 !important;
  }
  .right-answer-container .right-answer-modal-bg {
    display: none;
  }
  .right-answer-container .right-answer-modal {
    height: auto;
    width: 100%;
    padding: 50px 0px;
    border-radius: 20px;
  }
  .right-answer-modal .success-img {
    height: 100px;
    width: 100px;
  }
  .right-answer-container,
  .subscriberscreen-one .subscriberscreen-right .sub-screen-right {
    height: auto;
  }
  .subscriberscreen-one .subscriberscreen-right {
    margin-top: 50px;
    .sub-screen-right {
      border-radius: 20px;
    }
  }
  .subscriberscreen-one {
    padding: 50px 10px;
  }
  .subscriberscreen-one .right-answer-modal h1 {
    font-size: 32px;
  }
  .right-answer-modal .success-bonus {
    padding: 10px 20px;
  }
  .subscriberscreen-one .success-bonus p span {
    font-size: 25px;
  }
  .sub-screen-right-heading {
    font-size: 25px;
    line-height: 40px;
  }
  .sub-screen-right-headtwo {
    font-size: 19px;
  }
}
