.customQuestionHtml p {
  margin: 0;
  padding-left: 5px;
}

.custonmFontOptions {
  font-size: 18px;
}

.option-wrapper-tile-type {
  height: auto !important;
}
