.login_main_otp {
  height: 100% !important;
}

.login-page {
  background-color: #f4f7fc;
  min-height: 100vh;
  padding: 50px 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  // @media only screen and (max-height: 1800px) {
  //   height: 100%;
  // }

  @media only screen and (max-width: 800px) {
    height: 100vh;
  }
  .login-main {
    max-width: 640px;
    background-color: #fff;
    border-radius: 40px;
    // height: 50%;
    padding: 30px;
    margin: auto;
    // margin-bottom: 0px;
    width: 100%;
    box-shadow: 0px 0px 10px rgba(189, 189, 189, 0.07);

    .login-head {
      img {
        width: 220px;
        height: 56px;
      }
      h3 {
        font-size: 24px;
        font-family: 'Proxima Nova';
        color: #20282a;
        margin: 30px 0px;
      }
      h4 {
        font-size: 20px;
        font-family: 'Proxima Nova';
        color: #20282a;
        font-weight: 400;
      }
    }
    form {
      label {
        display: block;
        margin-bottom: 30px;
        color: #7d8283;
        font-size: 18px !important;
        font-family: 'Proxima Nova';
        input[type='text'],
        input[type='password'],
        input[type='number'] {
          width: 100%;
          height: 66px;
          border-radius: 10px;
          border: 1px solid #e0e0e0;
          padding-left: 18px;
          outline: none !important;
        }
        input:focus {
          outline: none;
        }
        input::placeholder {
          color: #7d8283 !important;
          font-size: 16px;
        }
        input::-ms-input-placeholder {
          color: #7d8283 !important;
          font-size: 16px;
        }
        input::-ms-input-placeholder {
          color: #7d8283 !important;
          font-size: 16px;
        }
        input.error {
          border: 1px solid #fd2a5b;
        }
      }
      .password-label {
        position: relative;
        button {
          position: absolute;
          top: 51%;
          right: 4%;
          background: transparent;
          border: 0;
        }
      }
      .remember-forgot {
        margin-bottom: 20px;
        margin-top: 35px;
        label {
          color: #20282a;
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: #eee;
            border-radius: 2px;
          }
          .checkmark:after {
            left: 9px;
            top: 4px;
            width: 7px;
            height: 13px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }
          .checkmark:after {
            content: '';
            position: absolute;
            display: none;
          }
        }
        label:hover input ~ .checkmark {
          background-color: #ccc;
        }
        label input:checked ~ .checkmark {
          background-color: #43aa8b;
        }
        label input:checked ~ .checkmark:after {
          display: block !important;
        }
        .text-right {
          text-align: right;
        }
      }
      .submit-btn {
        background-color: #43aa8b;
        box-shadow: none;
        border-radius: 38px;
        color: #fff;
        border: 0;
        width: 250px;
        height: 55px;
        font-size: 20px;
        font-family: 'Proxima Nova';
        text-align: center;
        padding: 0px 20px;
        margin-bottom: 30px;
        max-width: 100%;
        transition: 0.3s;
        opacity: 0.8;
        span {
          float: right;
          height: 24px;
          width: 24px;
          background-color: #fca834;
          border-radius: 50%;
          padding: 0px 7px;
          margin-top: 6px;
          box-shadow: none;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .submit-btn:hover {
        box-shadow: 0 12px 10px 0 rgba(67, 170, 139, 0.25);
        opacity: 1;
        span {
          box-shadow: 5px 5px 5px rgb(0 0 0 / 24%) !important;
        }
      }
      p.error-msg {
        color: #fd2a5b;
        margin-bottom: 12px;
        position: relative;
        top: -5px;
        span {
          width: 24px;
          height: 24px;
          border: 2px solid #fd2a5b;
          border-radius: 50%;
          display: inline-block;
          margin-right: 15px;
          padding: 0px 8px;
          float: left;
          b {
            position: relative;
            top: -2px;
          }
        }
      }
    }
    .reset_pass_style {
      p {
        margin-top: 20px;
      }
    }
    p,
    a {
      font-family: 'Proxima Nova';
      font-size: 18px;
      color: #20282a;
      text-decoration: none;
      // margin-top: 20px;
    }
    p.green,
    a.green {
      color: #43aa8b;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .login-page .login-main form .submit-btn {
    width: 275px;
    height: 60px;
  }
  .submit-btn {
    width: 275px;
    height: 60px;
  }
}

@media only screen and (max-width: 575px) {
  .login-page .login-main {
    padding: 20px;
    .login-head img {
      width: 150px;
      height: 37px;
    }
    form {
      label {
        font-size: 16px;
        input[type='text'],
        input[type='password'] {
          height: 50px;
          padding-left: 15px;
        }
      }
      p.error-msg {
        span {
          display: none;
        }
      }
      .remember-forgot {
        label {
          margin-bottom: 18px;
          font-size: 14px;
          .checkmark {
            height: 20px;
            width: 20px;
          }
          .checkmark:after {
            left: 7px;
            top: 3px;
            width: 6px;
            height: 10px;
          }
        }
        div {
          text-align: left !important;
        }
      }
      .submit-btn {
        height: 45px;
        font-size: 16px;
        span {
          margin-top: 0;
        }
      }
    }
    p,
    a {
      font-size: 14px;
    }
    p.not-register {
      a {
        display: block;
        margin-top: 10px;
      }
    }
  }
  .submit-btn {
    height: 45px;
    font-size: 16px;
    span {
      margin-top: 0;
    }
  }
}

.submit-btn {
  height: 45px;
  font-size: 16px;
  span {
    margin-top: 0;
  }
}

.login-page .login-main {
  .submit-btn.btndis {
    background-color: #dddddd !important;
    color: #ffffff !important;
    span {
      background-color: #c3c3c3 !important;
      color: #ffffff !important;
    }
  }
  .submit-btn.btndis:hover {
    box-shadow: none !important;
    opacity: 1;
    span {
      box-shadow: none !important;
      svg {
        margin-left: 0px;
      }
    }
  }
}

.submit-btn {
  background-color: #43aa8b;
  box-shadow: none;
  border-radius: 38px;
  color: #fff;
  border: 0;
  width: 200px;
  height: 55px;
  font-size: 20px;
  font-family: 'Proxima Nova';
  text-align: center;
  padding: 0px 20px;
  margin-bottom: 30px;
  max-width: 100%;
  transition: 0.3s;
  opacity: 1;
  span {
    float: right;
    height: 20px;
    width: 20px;
    background-color: #fca834;
    border-radius: 50%;
    padding: 0px 7px;
    margin-top: 4px;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-left: 0px !important;
    }
  }
}
.submit-btn:hover {
  box-shadow: 0 12px 10px 0 rgba(67, 170, 139, 0.25);
  opacity: 1;
  span {
    box-shadow: 5px 5px 5px rgb(0 0 0 / 24%) !important;
    svg {
      margin-left: 0px;
    }
  }
}
.submit-btn:disabled {
  opacity: 0.5;
}

.modal_success_text {
  font-size: 34px;
  color: #43aa8b;
  font-family: 'Proxima Nova';
}
.modal_success_content {
  font-size: 24px;
  // font-family: 'Proxima Nova';
  font-weight: 400;
}

.validationError {
  outline: none !important;
  border: 2px solid red !important;
}
.validationError :focus {
  outline: none !important;
}

.rescheduleTextarea {
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  padding-left: 18px;
  outline: none !important;
  width: 100%;
  height: 124px;
  resize: none;
  padding-top: 10px;
  color: #7d8283;
  font-family: 'Proxima Nova';
  font-size: 18px;
  &::placeholder {
    color: #7d8283;
    font-family: 'Proxima Nova';
    font-size: 20px;
    opacity: 0.7;
  }
}
